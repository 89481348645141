import axios from 'axios'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory, useLocation, useParams} from 'react-router'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import TableTzBottom from '../../components/layout/table_tz/TableTzBottom'
import TableTzTop from '../../components/layout/table_tz/TableTzTop'
import {useGlobalContext} from '../../store/context/context'
import './tz_kpi.css'

export const ClientTz = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        setPopupLogs,
        renderFeedback,
        setServerError
    } = useGlobalContext()

    // useEffect(() => {
    //     setShowFeedback(false)
    // }, [setShowFeedback])

    const {id} = useParams()
    const location = useLocation()
    const history = useHistory()

    const urlEndPart = useMemo(() => location.pathname.split('/').slice(5).join('/'), [location.pathname])
    const tzType = useMemo(() => location.pathname.includes('kpi') ? 'kpi' : 'billing', [location.pathname])

    const [tableHead, setTableHead] = useState({})
    const [tableBody, setTableBody] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const getTableHeadAndBody = useCallback(async () => {
        // console.log('.......... getting table head and body ..........');
        // const urlEndPart = location.pathname.split('/').slice(5).join('/')
        // console.log(urlEndPart);
        try {
            setLoading(true)
            const request_table_head = axios.get(`${urlEndPart}/head/`)
            const request_table_body = axios.get(`${urlEndPart}/body/`)
            const [response_table_head, response_table_body] = await axios.all([request_table_head, request_table_body])
            setTableHead(response_table_head.data)
            setTableBody(response_table_body.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setError(error.response?.data?.message)
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
            if (error.response.status === 403) {
                history.goBack()
            }
        } finally {
            setLoading(false)
        }
    }, [history, setLoading, renderFeedback, urlEndPart, setServerError])

    useEffect(() => {
        getTableHeadAndBody()
    }, [location.pathname, getTableHeadAndBody])

    useEffect(() => {
        setActiveSidebarLink('clients')
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData, id])

    useEffect(() => {
        const urlStartPart2 = location.pathname.split('/').slice(0, 5).join('/')
        const urlStartPart1 = location.pathname.split('/').slice(0, 4).join('/')

        setBreadcrumbs([
            {path: '/clients', breadcrumb: 'Клиенты'},
            {path: `${urlStartPart1}`, breadcrumb: `Бренды`}, // i.e. /clients/id/brands/id ==>> /clients/id/brands
            {path: `${urlStartPart2}`, breadcrumb: `Бренд`},  // links[getURL()] WAS HERE PREVIOUSLY)
            {path: `${location.pathname}`, breadcrumb: `ТЗ`},  // links[getURL()] WAS HERE PREVIOUSLY)
        ])
    }, [setBreadcrumbs, id, location.pathname, tzType])

    useEffect(() => {
        // window.onbeforeunload = (event) => {
        //     const e = event || window.event;
        //     // Cancel the event
        //     e.preventDefault();
        //     console.log('eeee');
        //     if (e) {
        //       e.returnValue = ''; // Legacy method for cross browser support
        //       console.log('dddddddde');
        //     }

        //     // console.log('efefefefef');
        //     return ''; // Legacy method for cross browser support
        // };
        // window.beforeunload = (e) => {
        //     console.log('Stop this');
        //     e.preventDefault()
        //     e.returnValue = '';
        // };
    }, [])


    const buttons = useMemo(() => {
        let dynamicBtns = []
        if (tableHead.send) {  // ALERT  // LOADING
            dynamicBtns.push({
                text: `Отправить НОК`,
                icon: 'send',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/send/`)
                        // console.log(response.data);
                        const {message} = response.data
                        renderFeedback('success', message || 'Success')
                        const path = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`
                        history.push(path)
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
                alert: {
                    title: 'Внимание',
                    body: 'Вы собираетесь отправить данное ТЗ, продолжить?',
                },
            })
        }
        if (tableHead.accept) {  // LOADING
            dynamicBtns.push({
                text: `Подтвердить`,
                icon: 'vector',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/accept/`)
                        // console.log(response.data);
                        const {message} = response.data
                        renderFeedback('success', message || 'Success')
                        getTableHeadAndBody()
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
            })
        }
        if (tableHead.decline) {  // LOADING
            dynamicBtns.push({
                text: `Отклонить`,
                icon: 'vector',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/decline/`)
                        // console.log(response.data);
                        const {message} = response.data
                        renderFeedback('success', message || 'Success')
                        getTableHeadAndBody()
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
            })
        }
        if (tableHead.check) {  // LOADING
            dynamicBtns.push({
                text: `Готово`,
                icon: 'confirm',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/check/`)
                        // console.log(response.data);
                        const {message} = response.data
                        renderFeedback('success', message || 'Success')
                        const path = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`
                        history.push(path)
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
            })
        }
        if (tableHead.archive) {  // ALERT  // LOADING
            dynamicBtns.push({
                text: `В архив`,
                icon: 'vector',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/archive/`)
                        // console.log(response.data);
                        const {message} = response.data
                        renderFeedback('success', message || 'Success')
                        getTableHeadAndBody()
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
                alert: {
                    title: 'Внимание',
                    body: 'Вы собираетесь заархивировать данное ТЗ, продолжить?',
                },
            })
        }
        if (tableHead.start) {  // LOADING
            dynamicBtns.push({
                text: `Возобновить`,
                icon: 'vector',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/start/`)
                        // console.log(response.data);
                        const {message} = response.data
                        renderFeedback('success', message || 'Success')
                        getTableHeadAndBody()
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
            })
        }
        if (tableHead.pause) {  // LOADING
            dynamicBtns.push({
                text: `Приостановить`,
                icon: 'vector',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/pause/`)
                        // console.log(response.data);
                        const {message} = response.data
                        renderFeedback('success', message || 'Success')
                        getTableHeadAndBody()
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
            })
        }

        if (tzType === 'kpi') { // ON EDIT PAGE FOR KPI
            if (location.pathname.endsWith('/edit')) {  // EDIT
                const kpiEditStaticBtns = [
                    {
                        text: `История`,
                        icon: 'vector',
                        async onClickFunction() {
                            try {
                                const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/logs/`)
                                const {data, date_from, date_to} = response.data
                                // console.log(data);
                                setPopupLogs({
                                    visible: true,
                                    data,
                                    title: 'История изменений',
                                    url: `${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/logs/`,
                                    date_from,
                                    date_to
                                })
                            } catch (error) {
                                // console.log(error.message);
                                renderFeedback('danger', error.response?.data?.message || 'Server Error')
                            }
                        },
                    },
                    {
                        text: `Разархивировать`,
                        icon: 'activate',
                        async onClickFunction() {
                            if (loading) return
                            try {
                                setLoading(true)
                                const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/activate/`)
                                // console.log(response.data);
                                const {message} = response.data
                                renderFeedback('success', message || 'Success')
                                const path = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`
                                history.push(path)
                            } catch (error) {
                                // console.log(error.message);
                                renderFeedback('danger', error.response?.data?.message || 'Server Error')
                            } finally {
                                setLoading(false)
                            }
                        },
                        alert: {
                            title: 'Внимание',
                            body: 'Вы собираетесь разархивировать данное ТЗ, продолжить?',
                        },
                    }
                ]
                return [...kpiEditStaticBtns, ...dynamicBtns]
            } else {  // VIEW
                const kpiViewStaticBtns = [  // ON VIEW PAGE FOR BOTH KPI/BILLING
                    {  // edit
                        text: `Редактировать`,
                        icon: 'edit',
                        async onClickFunction() {
                            try {
                                await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/edit/head/`)
                                history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`)
                            } catch (error) {
                                renderFeedback('danger', error.response?.data?.message || 'Server Error')
                            }
                        },
                    },
                    {  // copy ALERT  // LOADING
                        text: `Скопировать`,
                        icon: 'copy',
                        async onClickFunction() {
                            if (loading) return
                            try {
                                setLoading(true)
                                // console.log(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/copy/`);
                                const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/copy/`)
                                // console.log(response.data);
                                const {message, tz_id} = response.data
                                const path = `/tzs/kpi/${tz_id}/edit`
                                renderFeedback('success', message || 'Success')
                                history.push(path)
                            } catch (error) {
                                // console.log(error.message);
                                renderFeedback('danger', error.response?.data?.message || 'Server Error')
                            } finally {
                                setLoading(false)
                            }
                        },
                        alert: {
                            title: 'Внимание',
                            body: 'Вы собираетесь скопировать данное ТЗ, продолжить?',
                        },
                    },
                    {  // split  ALERT  // LOADING
                        text: `Раздробить`,
                        icon: 'parallel',
                        async onClickFunction() {
                            if (loading) return
                            try {
                                setLoading(true)
                                // console.log(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/split/`);
                                const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/split/`)
                                // console.log(response.data);
                                const {message} = response.data
                                renderFeedback('success', message || 'Success')
                                const path = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`
                                history.push(path)
                            } catch (error) {
                                // console.log(error.message);
                                renderFeedback('danger', error.response?.data?.message || 'Server Error')
                            } finally {
                                setLoading(false)
                            }
                        },
                        alert: {
                            title: 'Внимание',
                            body: 'Вы собираетесь раздробить данное ТЗ, продолжить?',
                        },
                    },
                    {  // history
                        text: `История`,
                        icon: 'vector',
                        async onClickFunction() {
                            try {
                                const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/logs/`)
                                const {data, date_from, date_to} = response.data
                                // console.log(data);
                                setPopupLogs({
                                    visible: true,
                                    data,
                                    title: 'История изменений',
                                    url: `${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/logs/`,
                                    date_from,
                                    date_to
                                })
                            } catch (error) {
                                // console.log(error.message);
                                renderFeedback('danger', error.response?.data?.message || 'Server Error')
                            }
                        },
                    },
                    {
                        text: `Разархивировать`,
                        icon: 'activate',
                        async onClickFunction() {
                            if (loading) return
                            try {
                                setLoading(true)
                                const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/activate/`)
                                // console.log(response.data);
                                const {message} = response.data
                                renderFeedback('success', message || 'Success')
                                // const path = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`
                                // history.push(path)
                                getTableHeadAndBody()
                            } catch (error) {
                                // console.log(error.message);
                                renderFeedback('danger', error.response?.data?.message || 'Server Error')
                            } finally {
                                setLoading(false)
                            }
                        },
                        alert: {
                            title: 'Внимание',
                            body: 'Вы собираетесь разархивировать данное ТЗ, продолжить?',
                        },
                    }
                ]
                return [...kpiViewStaticBtns, ...dynamicBtns]
            }

        } else if (tzType === 'billing') {  // ON EDIT PAGE FOR BILLING
            if (location.pathname.endsWith('/edit')) {  // EDIT
                const billingEditStaticBtns = [
                    {   // check
                        text: `Готово`,
                        icon: 'confirm',
                        async onClickFunction() {  // SEND TO VIEW
                            try {
                                // console.log(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`);
                                history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`)
                            } catch (error) {
                                renderFeedback('danger', error.response?.data?.message || 'Server Error')
                            }
                        },
                    },
                    {  // history
                        text: `История`,
                        icon: 'vector',
                        async onClickFunction() {
                            try {
                                const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/logs/`)
                                const {data, date_from, date_to} = response.data
                                // console.log(data);
                                setPopupLogs({
                                    visible: true,
                                    data,
                                    title: 'История изменений',
                                    url: `${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/logs/`,
                                    date_from,
                                    date_to
                                })
                            } catch (error) {
                                // console.log(error.message);
                                renderFeedback('danger', error.response?.data?.message || 'Server Error')
                            }
                        },
                    },
                ]
                return [...billingEditStaticBtns, ...dynamicBtns]
            } else { // VIEW
                const billingViewStaticBtns = [
                    {  // edit
                        text: `Редактировать`,
                        icon: 'edit',
                        async onClickFunction() {
                            try {
                                // console.log(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/edit/head/`);
                                await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/edit/head/`)
                                history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`)
                            } catch (error) {
                                renderFeedback('danger', error.response?.data?.message || 'Server Error')
                            }
                            // history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`)
                        },
                    },
                    {  // history
                        text: `История`,
                        icon: 'vector',
                        async onClickFunction() {
                            try {
                                const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/logs/`)
                                const {data, date_from, date_to} = response.data
                                // console.log(data);
                                setPopupLogs({
                                    visible: true,
                                    data,
                                    title: 'История изменений',
                                    url: `${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/logs/`,
                                    date_from,
                                    date_to
                                })
                            } catch (error) {
                                // console.log(error.message);
                                renderFeedback('danger', error.response?.data?.message || 'Server Error')
                            }
                        },
                    },
                ]
                return [...billingViewStaticBtns, ...dynamicBtns]
            }
        }
    }, [location.pathname, tzType, renderFeedback, setPopupLogs, history, tableHead, getTableHeadAndBody, urlEndPart, loading])

    if (loading) return <LoaderComponent/>
    if (error) return <div>{error.message}</div>
    return (<>
            <div className="client-tz page">
            </div>

            {!loading && tableBody.hasOwnProperty('headers') && <>
                <TableTzTop table={tableHead} setTable={setTableHead} buttons={buttons}
                            deleteOptionEnabledInSelect={true}/>
                <TableTzBottom table={tableBody} setTable={setTableBody}
                               head_request_id={tableHead.rows[0].request_id.value} tzType={tzType}
                               refreshTableBottomData={getTableHeadAndBody}/>
            </>}
        </>
    )
}


// const [tableFoot, setTableFoot] = useState({})
// const [tableHeadError, setTableHeadError] = useState({})
// const [tableHeadLoading, setTableHeadLoading] = useState(true)
// const [tableBodyError, setTableBodyError] = useState({})
// const [tableBodyLoading, setTableBodyLoading] = useState(true)
// const [tableFootError, setTableFootError] = useState({})
// const [tableFootLoading, setTableFootLoading] = useState(true)


// const getHead = async () => {
//     try {
//         setTableHeadLoading(true)
//         const response = await axios.get(`${location.pathname}/head/`)
//         console.log(response.data);
//         setTableHead(response.data);
//     } catch (error) {
//         console.log(error.response);
//         setShowFeedback(true)
//         setFeedbackInfo({ type: 'danger', message: error.response.data.message})
//         if (error.response.status === 403) {
//             history.goBack()
//         }
//         setTableHeadError(error.response.data.message || 'Error')
//     } finally {
//         setTableHeadLoading(false)
//     }
// }
// const getBody = async () => {
//     try {
//         setTableBodyLoading(true)
//         const response = await axios.get(`${location.pathname}/body/`)
//         console.log(response.data);
//         setTableBody(response.data);
//     } catch (error) {
//         console.log(error);
//         // setTableBodyError(error.response.data.message || 'Error')
//     } finally {
//         setTableBodyLoading(false)

//     }
// }
// const getFoot = async () => {
//     try {
//         setTableFootLoading(true)
//         const response = await axios.get(`${location.pathname}/foot/`)
//         console.log(response.data);
//         setTableFoot(response.data);
//     } catch (error) {
//         setTableFootError(error.response.data.message || 'Error')
//     } finally {
//         setTableFootLoading(false)
//     }
// }
