import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { Redirect } from "react-router";
import './app.css'
import {useGlobalContext} from "../store/context/context";

//COMPONENTS
import Sidebar from "../components/layout/navigation/sidebar/Sidebar";
import Topbar from "../components/layout/navigation/topbar/Topbar";
import Login from "../pages/login/Login";
import Clients from "../pages/clients/Clients";
import Popup from "../components/layout/popup/Popup";
import Fills from "../pages/fills/Fills";
import Footer from "../components/layout/footer/Footer";
import Client from "../pages/client/Client";
import Feedback from "../components/layout/feedback/Feedback";
import Brand from "../pages/brand/Brand";
import TzsKpi from "../pages/tzs_kpi/TzsKpi";
import TzsBilling from "../pages/tzs_billing/TzsBilling";
import Nomenclatures from "../pages/nomenclatures/Nomenclatures";
import Providers from "../pages/providers/Providers";
import Provider from "../pages/provider/Provider";
import Fill from "../pages/fill/Fill";
import Rates from "../pages/rates/Rates";
import Departments from "../pages/departments/Departments";
import Positions from "../pages/positions/Positions";
import Permissions from "../pages/permissions/Permissions";
import Team from "../pages/team/Team";
import Staff from "../pages/staff/Staff";
import StaffPermissions from "../pages/staff_permissions/StaffPermissions";
import TzKpi from "../pages/tz_kpi/TzKpi";
import Account from "../pages/account/Account";
import Feedback2 from "../components/layout/feedback_2/Feedback_2";
import {LoaderComponent} from "../components/layout/loader/Loader";
import {PopupLogs} from "../components/layout/popup/PopupLogs";
import {PopupFillKpi} from "../components/layout/popup/PopupFillKpi";
import {PopupFillBilling} from "../components/layout/popup/PopupFillBilling";
import {KpiTable} from "../pages/kpi_table/KpiTable";
import {ServerError} from "../pages/500/ServerError";
import {KpiArchive} from "../pages/kpi_archive/KpiArchive";
import {KpiAnalysis} from "../pages/kpi_analysis/KpiAnalysis";
import {Empty} from "../pages/empty/Empty";
import {Avrs} from "../pages/avrs/Avrs";
import {AvrNew} from "../pages/avr_new/AvrNew";
import {ClientTz} from "../pages/client_tz/ClientTz";
import {ClientAvr} from "../pages/avr/ClientAvr";
import {Avr} from "../pages/avr/Avr";
import {Helper} from "../pages/helper/Helper";
import {Alert} from "../components/layout/alert/Alert";
import {Tasks} from "../pages/tasks/Tasks";
import {UserWeeklyTasks} from "../pages/user_tasks/UserWeeklyTasks";
import {UserMonthlyTasks} from "../pages/user_tasks/UserMonthlyTasks";
import {Holidays} from "../pages/holidays/Holidays";
import {Weekends} from "../pages/weekends/Weekends";
import {PopupTasks} from "../components/layout/popup/PopupTasks";
import {UserProjectTasks} from "../pages/user_tasks/UserProjectTasks";
import {StaffProjects} from "../pages/staff_projects/StaffProjects";
import {PropertiesBonus} from "../pages/properties_bonus/PropertiesBonus";
import {Workload} from "../pages/workload/Workload";
import Additional from "../pages/additional/Additional";
import {KpiAnalysisArchive} from "../pages/kpi_analysis_archive/KpiAnalysisArchive";
import {ReportsManagerBilling} from "../pages/reports/ReportsManagerBilling";
import {ReportsManagerKpi} from "../pages/reports/ReportsManagerKpi";
import {ReportsAnalytics} from "../pages/reports/ReportsAnalytics";
import {UserCurrentTasks} from "../pages/user_tasks/UserCurrentTasks";
import {UserCurrentTasksStatistics} from "../pages/user_tasks/UserCurrentTasksStatistics";
import {UserPotentialTasksStatistics} from "../pages/user_tasks/UserPotentialTasksStatistics";
import {UserPotentialTasks} from "../pages/user_tasks/UserPotentialTasks";
import {Disqualification} from "../pages/user_tasks/Disqualification";
import {UserTasks} from "../pages/user_tasks/UserTasks";
import {ServiceTable} from "../pages/service_table/ServiceTable";


// if (localStorage && localStorage.token) {
//   axios.defaults.headers.common['Authorization'] = `Token ${localStorage.token}`
// }

function App() {
    const {
        mainCenterTopBottomMargin,
        loggedIn,
        showFeedback,
        showFeedback2,
        popup,
        popupLogs,
        popupFill,
        popupTasks,
        isLoading,
        serverError = false,
        alert,
        me,
        sidebarOpen
    } = useGlobalContext()

    // const location = useLocation()
    // console.log('APP: ', location.pathname);
    // return <PopupForTest />

    // return <MultilineTextFields />
//   if (!loggedIn) {
//     return <Login />
//   }
    // const handleFieldChange = (event, keyToChange=null) => {
    //   // console.log(event);
    //   console.log({[event.target.name]: event.target.value});

    //   if (keyToChange) {
    //       return console.log({[keyToChange]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value})
    //   }
    //     console.log({value: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value})
    // }
    // return <SelectTextField onChange={handleFieldChange} />
    // return <CheckboxTextareaField onChange={handleFieldChange} />
    // if (serverError) return <ServerError />  // THIS IS A BAD SOLUTION

    // useEffect(() => {
    //   const
    // }, [])
    // return <TextEditor />

    if (serverError) return <ServerError/>

    return (
        <Router>
            {/* <HashRouter> */}
            {!loggedIn && <>
                {/* <Route path="/register" component={Register}/> */}
                <Route path="/auth" component={Login}/>
                <Redirect to="/auth"/>
                {showFeedback && <Feedback/>}
            </>}
            {loggedIn &&
                <div className="App">
                    <Sidebar/>
                    {/* <SwipeUp /> */}
                    <div className="content">
                        <Topbar/>
                        {showFeedback && <Feedback/>}
                        {showFeedback2 && <Feedback2/>}
                        {popup.visible && <Popup/>}
                        {popupLogs.visible && <PopupLogs/>}
                        {popupTasks.visible && <PopupTasks/>}
                        {popupFill.visible && (popupFill.type === 'kpi' ? <PopupFillKpi/> : <PopupFillBilling/>)}
                        {isLoading && <LoaderComponent className={'fixed'}/>}
                        {alert.visible && <Alert/>}
                        {/* {<SwipeUp />} */}
                        <main className="main">
                            <div className="main__center" style={{
                                margin: `${mainCenterTopBottomMargin}px auto`,
                                width: `calc((100vw - ${sidebarOpen ? '225px' : '56px'}) * .95)`
                            }}>
                                <Switch>
                                    {/* <Route exact path="/" component={Home}/> */}
                                    {/* <Route exact path="/home" render={() => <Redirect to='/' />}/> */}
                                    <Route exact path="/users/tasks/weekly" component={UserWeeklyTasks}/>
                                    <Route exact path="/properties/brandtask/current" component={UserCurrentTasks}/>
                                    <Route exact path="/properties/brandtask/stat_current" component={UserCurrentTasksStatistics}/>
                                    <Route exact path="/properties/brandtask/potential" component={UserPotentialTasks}/>
                                    <Route exact path="/properties/brandtask/stat_potential" component={UserPotentialTasksStatistics}/>
                                    <Route exact path="/properties/brandtask/disqualification" component={Disqualification}/>
                                    <Route exact path="/users/tasks/monthly" component={UserMonthlyTasks}/>
                                    <Route exact path="/users/tasks/project" component={UserProjectTasks}/>

                                    <Route exact path="/clients" component={Clients}/>
                                    <Route exact path="/clients/:id" render={(props) => {
                                        // console.log(props);
                                        return props.history.goBack()
                                        // return <Redirect to={`/clients/${props.match.params.id}/brands`}/>
                                    }}/>
                                    <Route exact path="/clients/:id/brands" component={Client}/>
                                    <Route exact path="/clients/:id/brands/:id" component={Brand}/>
                                    <Route exact path="/clients/:id/brands/:id/tzs/kpi/:id/view" component={ClientTz}/>
                                    <Route exact path="/clients/:id/brands/:id/tzs/kpi/:id/edit" component={ClientTz}/>
                                    <Route exact path="/clients/:id/brands/:id/tzs/billing/:id/view"
                                           component={ClientTz}/>
                                    <Route exact path="/clients/:id/brands/:id/tzs/billing/:id/edit"
                                           component={ClientTz}/>
                                    {/* <Route exact path="/clients/:id/brands/:id/tzs/kpi/new" component={TzNew}/>
                            <Route exact path="/clients/:id/brands/:id/tzs/billing/new" component={TzNew}/> */}

                                    <Route exact path="/clients/:id/contracts" component={Client}/>
                                    <Route exact path="/clients/:id/contracts/:id/additional" component={Additional}/>

                                    <Route exact path="/clients/:id/accounts" component={Client}/>
                                    <Route exact path="/clients/:id/accounts/:id" component={Account}/>

                                    <Route exact path="/clients/:id/avrs" component={Client}/>
                                    <Route exact path="/clients/:id/avrs/:id/view" component={ClientAvr}/>
                                    <Route exact path="/clients/:id/avrs/:id/edit" component={ClientAvr}/>
                                    <Route exact path="/clients/:id/avrs/new" component={AvrNew}/>

                                    <Route exact path="/tzs/kpi" component={TzsKpi}/>
                                    <Route exact path="/tzs/kpi/:id/view" component={TzKpi}/>
                                    <Route exact path="/tzs/kpi/:id/edit" component={TzKpi}/>

                                    <Route exact path="/tzs/billing" component={TzsBilling}/>
                                    <Route exact path="/tzs/billing/:id/view" component={TzKpi}/>
                                    <Route exact path="/tzs/billing/:id/edit" component={TzKpi}/>

                                    <Route exact path="/tzs/kpi/table" component={KpiTable}/>
                                    <Route exact path="/tzs/kpi/table/archive" component={KpiArchive}/>
                                    <Route exact path="/tzs/kpi/analysis/" component={KpiAnalysis}/>
                                    <Route exact path="/tzs/kpi/analysis/archive" component={KpiAnalysisArchive}/>
                                    {/* <Route exact path="/tzs/kpi" render={() => {
                              return <Redirect to={'/tzs/kpi?status=Активно'} />
                            }}/> */}

                                    <Route exact path="/tzs/fills" component={Fills}/>
                                    <Route exact path="/tzs/fills/view" component={Fill}/>

                                    <Route exact path="/avrs" component={Avrs}/>
                                    <Route exact path="/avrs/:id/view" component={Avr}/>
                                    <Route exact path="/avrs/:id/edit" component={Avr}/>

                                    <Route exact path="/telegram/buttons" component={Empty}/>

                                    <Route exact path="/reports/manager_billing" component={ReportsManagerBilling}/>
                                    <Route exact path="/reports/manager_kpi" component={ReportsManagerKpi}/>
                                    <Route exact path="/reports/analytics" component={ReportsAnalytics}/>

                                    <Route exact path="/finance/nomenclatures" component={Nomenclatures}/>
                                    <Route exact path="/finance/providers" component={Providers}/>
                                    <Route exact path="/finance/providers/:id/contracts" component={Provider}/>
                                    <Route exact path="/finance/rates" component={Rates}/>
                                    <Route exact path="/finance/service_table" component={ServiceTable}/>
                                    {/* HR */}
                                    <Route exact path="/properties/bonus" component={PropertiesBonus}/>
                                    <Route exact path="/properties/departments" component={Departments}/>
                                    <Route exact path="/properties/departments/:id/positions" component={Positions}/>
                                    <Route exact path="/properties/departments/:id/positions/:id/permissions"
                                           component={Permissions}/>
                                    <Route exact path="/properties/departments/:id/positions/:id/tasks"
                                           component={Tasks}/>
                                    <Route exact path="/properties/departments/:id/workload" component={Workload}/>
                                    <Route exact path="/properties/departments/:id/workload/:id/users/tasks/weekly"
                                           component={UserWeeklyTasks}/>
                                    <Route exact path="/properties/departments/:id/workload/:id/users/tasks/monthly"
                                           component={UserMonthlyTasks}/>
                                    <Route exact path="/properties/departments/:id/workload/:id/users/tasks/project"
                                           component={UserProjectTasks}/>

                                    <Route exact path="/properties/calendar/holidays" component={Holidays}/>
                                    {/* <Route exact path="/tzs/kpi" render={() => {
                              return <Redirect to={'/tzs/kpi?status=Активно'} />
                            }}/> */}
                                    <Route exact path="/properties/calendar/weekends" component={Weekends}/>
                                    {/* <Route exact path="/properties/holidays/:id/positions" component={Positions}/> */}

                                    <Route exact path="/users/team" component={Team}/>

                                    <Route exact path="/users/staff" component={Staff}/>
                                    {/* <Route exact path="/users/staff/:id/bonuses" component={StaffBonuses}/> */}
                                    <Route exact path="/users/staff/:id/permissions" component={StaffPermissions}/>
                                    <Route exact path="/users/staff/:id/projects" component={StaffProjects}/>
                                    <Route exact path="/helper/helper1" component={Helper}/>
                                    {/* <Route exact path="/helper/new" component={HelperNew}/> */}
                                    <Route exact path="/helper/*" component={Empty}/>
                                    {/* <Route exact path="/kpitable" component={Clients}/> */}
                                    {/* <Redirect to='/' /> */}
                                    <Redirect to={'/users/tasks/weekly'}/>
                                </Switch>
                                <Footer/>
                                {/* </>} */}
                            </div>
                        </main>
                    </div>
                </div>
            }
            {/* </HashRouter> */}
        </Router>
    );
}

export default App;
