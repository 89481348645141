import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import './index.css';
import {ContextProvider} from './store/context/context';
import './utils/styles/global-styles/global-styles.css'

ReactDOM.render(
    <React.StrictMode>
        <ContextProvider>
            <App/>
        </ContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
