import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import {ru} from 'date-fns/locale';
import moment from 'moment';

export default function DateMUIMonth({value, onChange}) {  // PREVIOUSLY NAMED AS CustomDay()
    const dateMomentObject = moment(value, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    const dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
    // console.log(dateObject);

    const [date, setDate] = React.useState(value ? dateObject : new Date());

    const currentView = React.useRef(null)

//   const handleClickOutside = React.useCallback(event => {
//     if (dateRef.current.contains(event.target)) {
//         // console.log('Clicked inside react-calendar');
//         return
//     }
//     // console.log('Clicked outside react-calendar');
//     const label = dateRef.current.querySelector('label')
//     if (!dateValue) { // CALENDAR IS CLOSED WITHOUT CHOSING ANY DATE, THUS RETURN THE LABEL BACK TO ITS INITIAL PLACE
//         label.classList.remove('focused', 'filled')
//     } else { // CALENDAR IS CLOSED AND DATE IS CHOSED, THUS APPLY NECESSARY STYLES
//         label.classList.add('focused', 'filled')
//     }

//     setShowCalendar(false) // CLICKED OUTSIDE REACT-CALENDAR, THUS CLOSE IT
// }, [date])
// useEffect(() => {
//     document.addEventListener('mousedown', handleClickOutside)
//     return () => document.removeEventListener('mousedown', handleClickOutside)
// }, [handleClickOutside])

    const handleOnChange = (newValue) => {
        if (currentView.current === 'year') return

        setDate(newValue);
        onChange(moment(newValue).format('DD.MM.YYYY'))
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
            <StaticDatePicker
                displayStaticWrapperAs="desktop"
                label="Month picker"
                value={date}
                onChange={handleOnChange}
                renderInput={(params) => <TextField {...params} />}
                // disabled
                onViewChange={view => currentView.current = view}

                views={['year', 'month']}
                openTo="month"

                minDate={new Date('2021-01-01')}  // YYYY-MM-DD
                maxDate={new Date('2030-12-31')}  // YYYY-MM-DD
            />
        </LocalizationProvider>
    );
}



