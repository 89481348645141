import * as React from 'react';
import {styled} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import PickersDay from '@mui/lab/PickersDay';
import endOfWeek from 'date-fns/endOfWeek';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfWeek from 'date-fns/startOfWeek';
import {ru} from 'date-fns/locale';
import moment from 'moment';

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({theme, dayIsBetween, isFirstDay, isLastDay}) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
}));

export default function DateMUIWeek({value, onChange}) {  // PREVIOUSLY NAMED AS CustomDay()
    const dateMomentObject = moment(value, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    const dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
    // console.log(dateObject);

    const [date, setDate] = React.useState(value ? dateObject : new Date());

//   const handleClickOutside = React.useCallback(event => {
//     if (dateRef.current.contains(event.target)) {
//         // console.log('Clicked inside react-calendar');
//         return
//     }
//     // console.log('Clicked outside react-calendar');
//     const label = dateRef.current.querySelector('label')
//     if (!dateValue) { // CALENDAR IS CLOSED WITHOUT CHOSING ANY DATE, THUS RETURN THE LABEL BACK TO ITS INITIAL PLACE
//         label.classList.remove('focused', 'filled')
//     } else { // CALENDAR IS CLOSED AND DATE IS CHOSED, THUS APPLY NECESSARY STYLES
//         label.classList.add('focused', 'filled')
//     }

//     setShowCalendar(false) // CLICKED OUTSIDE REACT-CALENDAR, THUS CLOSE IT
// }, [date])
// useEffect(() => {
//     document.addEventListener('mousedown', handleClickOutside)
//     return () => document.removeEventListener('mousedown', handleClickOutside)
// }, [handleClickOutside])

    const handleOnChange = newValue => {
        const numberOfDaysFromMonday = newValue.getDay() === 0 ? 6 : newValue.getDay() - 1  // IF SELECTED DAY IS SUNDAY THEN getDate() === 0 THUS RETURN 6 ELSE SUBTRACT 1
        const mondayDate = moment(newValue).subtract(numberOfDaysFromMonday, 'days').format('DD.MM.YYYY')
        // console.log(newValue, numberOfDaysFromMonday, mondayDate);
        onChange(mondayDate)

        if (newValue.getDay() === 0) return setDate(new Date(newValue.setDate(newValue.getDate() - 1)))
        setDate(newValue);
    }

    const renderWeekPickerDay = (eachDate, selectedDates, pickersDayProps) => {
        if (!date) {
            return <PickersDay {...pickersDayProps} />;
        }

        const start = startOfWeek(date);
        start.setDate(start.getDate() + 1)
        const end = endOfWeek(date);
        end.setDate(end.getDate() + 1)

        const dayIsBetween = isWithinInterval(eachDate, {start, end});
        const isFirstDay = isSameDay(eachDate, start);
        const isLastDay = isSameDay(eachDate, end);

        return (
            <CustomPickersDay
                {...pickersDayProps}
                disableMargin
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
            />
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
            <StaticDatePicker
                displayStaticWrapperAs="desktop"
                label="Week picker"
                value={date}
                onChange={handleOnChange}
                renderDay={renderWeekPickerDay}
                renderInput={(params) => <TextField {...params} />}
                inputFormat="'Week of' MMM d"
                // disabled
                // orientation='portrait'
                // className="testttt"
            />
        </LocalizationProvider>
    );
}



