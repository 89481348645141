import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import Text from '../text/Text'
import './multi_select.css'
import {ReactComponent as ArrowOpen} from '../../../assets/sidebar-icons/arrow-open.svg'
import {ReactComponent as ArrowClose} from '../../../assets/sidebar-icons/arrow-close.svg'

const MultiSelectField = ({options, value = [], label, name, onChange, rowClickable, editable = true, sign = null}) => {
    const [showOptionsList, setShowOptionsList] = useState(false)
    const [inputValue, setInputValue] = useState(value)
    const [searchText, setSearchText] = useState('')
    const selectRef = useRef(null)

    const lowerCaseString = input => input.toString().toLowerCase()

    const handleClickOutside = useCallback(event => {
        if (!selectRef.current.contains(event.target)) {
            console.log('Clicked outside select');
            setShowOptionsList(false)
            const label = selectRef.current.querySelector('label')

            if (inputValue.length) {
                label.classList.add('focused', 'filled')
                // setInputValue(selectedOption)
            } else {
                console.log('correct');
                label.classList.remove('focused', 'filled')
                // setInputValue('')
            }
        }
    }, [inputValue.length]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [handleClickOutside])

    const handleOptionClick = option => {
        console.log('FUNCTION 1');
        let arrayOfValues = [...value]
        arrayOfValues.push(option)
        console.log(arrayOfValues);

        setShowOptionsList(false)

        setInputValue(arrayOfValues)
        setSearchText('')

        const label = selectRef.current.querySelector('label')
        label.classList.add('focused', 'filled')


        const event = {
            target: {
                name,
                value: arrayOfValues
            }
        }
        onChange && onChange(event)
    }
    const handleValueRemoveButtonClick = option => {
        console.log('FUNCTION 2');

        let arrayOfValues = [...value]
        arrayOfValues = arrayOfValues.filter(value => value !== option)
        console.log(arrayOfValues);

        setShowOptionsList(false)

        setInputValue(arrayOfValues)

        const label = selectRef.current.querySelector('label')
        label.classList.add('focused', 'filled')


        const event = {
            target: {
                name,
                value: arrayOfValues
            }
        }
        onChange && onChange(event)
    }

    const handleTextChange = event => {
        setSearchText(event.target.value)
    }

    const toggleFormFieldBottom = event => {
        if (!editable) return

        const label = selectRef.current.querySelector('label')

        if (!showOptionsList) {
            label.classList.remove('focused', 'filled')
            label.classList.add('focused')
        } else {

            if (inputValue.length) {
                label.classList.add('focused', 'filled')
            } else {
                label.classList.remove('focused', 'filled')
            }
        }

        setShowOptionsList(!showOptionsList)
        setSearchText('')
    }


    const filteredOptions = useMemo(() => options?.filter(option => !inputValue.includes(option) && lowerCaseString(option).includes(lowerCaseString(searchText))), [inputValue, options, searchText])
    return (
        <div className={`multi-select select form-field ${editable ? 'editable' : 'uneditable'}`} ref={selectRef}>
            <div onClick={toggleFormFieldBottom} className={`form-field__top ${showOptionsList ? 'focused' : ''}`}>
                <Text type="text" label={label} value={inputValue} parentForm="multi_select" rowClickable={rowClickable}
                      handleValueRemoveButtonClick={handleValueRemoveButtonClick}/>
                {/* <div className="multi-select__values text__input">{inputValue.map(singleOption => <span className="multi-select__value">{singleOption}</span>)}</div> */}
                <button className="svg-container form-field__open-button" type="button">{showOptionsList ?
                    <ArrowClose/> : <ArrowOpen/>}</button>
            </div>
            {showOptionsList &&
                <ul className="select__options form-field__bottom">
                    <Text type="text" required={false} value={searchText} placeholder="Поиск..."
                          onChange={handleTextChange} parentForm="select" rowClickable={rowClickable}
                          editable={editable}/>
                    {filteredOptions.length ?
                        filteredOptions.map((option, index) => {
                            return <li
                                key={index}
                                className="select__option"
                                onClick={() => handleOptionClick(option)}
                            >
                                {sign ? (option * 100).toFixed(2) + "%"
                                    : option.toString()
                                }
                            </li>
                        }) :
                        <li className="select__option selected">
                            Никаких вариантов нет
                        </li>
                    }
                </ul>
            }
        </div>
    )
}

export default MultiSelectField


// if (lowerCaseString(inputValue) !== lowerCaseString(selectedOption)) {
//     // inputValue DOES NOT MATCH selectedOption, then clear the input field AND remove additional styles from LABEL
//     setInputValue('')
//     label.classList.remove('focused', 'filled')
// } else if (!lowerCaseString(inputValue)) {
//     // they match, but input field IS empty (i.e. erased with backspace completely), then remove additional styles from LABEL
//     label.classList.remove('focused', 'filled')
// } else {
//     // they match, and input field IS NOT empty, then add additional styles to LABEL
//     label.classList.add('focused', 'filled')
// }
