import React, {useCallback, useEffect, useMemo, useState} from 'react'
import './popup.css'

import CloseButton from '../../buttons/close/CloseButton'
// import Radio from '../../forms/radio/Radio'
// import SelectField from '../../forms/select/Select_1'
import TextFieldTextArea from '../../forms/text/TextTextArea'
import RadioField from '../../forms/radio/Radio'
import NumberField from '../../forms/number/Number'
import CheckboxField from '../../forms/checkbox/Checkbox'
import TextAreaFieldMU from '../../forms/textarea_mu/TextAreaMU'
import axios from 'axios'
import {useGlobalContext} from '../../../store/context/context'
import MultiSelectField from '../../forms/multi_select/MultiSelect'

import {SelectField} from '../../forms/select/Select'
import {CheckboxTextareaField} from '../../forms/checkbox_textarea/CheckboxTextarea'
import {SelectTextField} from '../../forms/select_text/SelectText'
import {AiFillDelete} from 'react-icons/ai'
import {SelectField3Num} from '../../forms/select/Select3Num'
import {LoaderComponent} from '../loader/Loader'
import DateNumberField from '../../forms/date/DateNumber'
import DateRangeField from '../../forms/date/DateRange'
import {SelectPositionedField} from '../../forms/select/SelectPositioned'

const Popup = () => {
    const {
        popup: {
            type,
            data,
            url,
            callback,
            title,
            button: {visible = true, text = 'Отправить'} = {},
            targetingsEditable = true
        } = {}, setPopup, renderFeedback, setShowFeedback
    } = useGlobalContext()


    const [didMount, setDidMount] = useState(false);

    useEffect(() => {
        setDidMount(true);
        return () => setDidMount(false);
    }, [])


    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const [loading, setLoading] = useState(false)  // isLoading FROM STORE CAN'T BE USED AS IT UPDATES THE TABLE WHEN POPUP IS CLOSED WHICH IS A PROBLEM ON TZ_NEW AS WE LOOSE ALL THE FILLED DATA WHEN CREATING NEW TZ THUS USE LOCAL loading STATE
    const [formFields] = useState({
        text_field: TextFieldTextArea,
        select_field: SelectPositionedField,
        // date_field: DateField,
        date_field: DateNumberField,
        radio_field: RadioField,
        number_field: NumberField,
        multi_select_field: MultiSelectField,
        checkbox_textarea_field: CheckboxTextareaField,
        select_text_field: SelectTextField,
        textarea_field: TextAreaFieldMU,
        checkbox_field: CheckboxField,
        select_field3: SelectField3Num,
        date_range_field: DateRangeField,
    })


    const getInitialTargetinFields = useMemo(() => {
        if (data.targetings) {
            const newObj = {}
            Object.keys(data.targetings).forEach(accessor => {
                if (data.targetings[accessor].value) {
                    newObj[accessor] = data.targetings[accessor]
                }
            })
            return newObj
        }
        return {}
    }, [data.targetings])

    const [targetingFields, setTargetingFields] = useState(getInitialTargetinFields)
    const allOptions = useMemo(() => data.targetings ? Object.keys(data.targetings).reduce((acc, accessor) => acc.concat(data.targetings[accessor].label), []) : null, [data.targetings])
    const [availableOptions, setAvailableOptions] = useState(allOptions)

    const [formData, setFormData] = useState(data)

    const handleFieldChange = (event, keyToChange = null) => {
        // console.log(event);
        console.log({[event.target.name]: event.target.value});

        if (keyToChange) {
            console.log({[keyToChange]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value});
            return setFormData({
                ...formData,
                [event.target.name]: {
                    ...formData[event.target.name],
                    [keyToChange]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
                }
            })
        }
        setFormData({
            ...formData,
            [event.target.name]: {
                ...formData[event.target.name],
                value: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
            }
        })
    }

    const handleTargetingsFieldChange = event => {
        // console.log({[event.target.name]: event.target.value});
        setTargetingFields({
            ...targetingFields,
            [event.target.name]: {
                ...targetingFields[event.target.name],
                value: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
            }
        })
    }

    const handleSubmit = useCallback(async event => {
        if (loading) return
        console.log(formData);
        console.log(event);
        event && event.preventDefault()
        console.log('submitting...');
        try {
            const areAllFieldsFilled = Object.keys(formData).every(accessor => !formData[accessor].required || !formData[accessor].editable || !!String(formData[accessor].value))
            if (!areAllFieldsFilled) {
                renderFeedback('danger', 'Пожалуйста, заполните все обязательные поля!')
                return
            }

            if (data.hasOwnProperty('targetings')) {
                const clearedDataTargetings = JSON.parse(JSON.stringify(data.targetings))
                for (let accessor in clearedDataTargetings) {
                    clearedDataTargetings[accessor].value = ''
                }
                setLoading(true)
                const response = await axios.post(url, {
                    ...formData,
                    targetings: {...clearedDataTargetings, ...targetingFields}
                })
                renderFeedback('success', response.data.message || 'Success')
            } else {
                setLoading(true)
                const response = await axios.post(url, formData)
                renderFeedback('success', response.data.message || 'Success')
            }

            setPopup({visible: false, data: {}, url: null})
            // setTimeout(() => {
            //     window.location.reload();
            // }, 500)
            // history.go(0)
            callback && callback()
        } catch (error) {
            // console.log(error.message, error.response.data.message);
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        } finally {
            setLoading(false)
        }
    }, [formData, callback, data, loading, renderFeedback, setPopup, targetingFields, url])

    useEffect(() => {
        function handleEscKeyClick(event) {
            if (loading) return
            const key = event.key; // const {key} = event; in ES6+
            if (key === "Escape") {
                setPopup({visible: false, data: {}, url: null})
            }

            if (key === 'Enter') {
                console.log(event.target.classList.contains('text__input'))
                if (event.target.classList.contains('text__input')) return
                handleSubmit()
            }
        }

        document.addEventListener('keydown', handleEscKeyClick);

        return () => document.removeEventListener('keydown', handleEscKeyClick)
    }, [setPopup, handleSubmit, loading])

    const closePopup = () => setPopup({visible: false, data: {}})

    useEffect(() => {
        const pickedOptions = Object.keys(targetingFields).map(accessor => targetingFields[accessor].label)
        const updatedAvailableOptions = allOptions?.filter(option => !pickedOptions.includes(option))
        setAvailableOptions(updatedAvailableOptions)
    }, [targetingFields, allOptions])

    const addTargetingField = (event, targetings) => {
        const newTargetingFieldAccessor = Object.keys(targetings).find(accessor => targetings[accessor].label === event.target.value)
        setTargetingFields({...targetingFields, [newTargetingFieldAccessor]: targetings[newTargetingFieldAccessor]})
    }

    const removeTargetingField = (accessor) => {
        const targetingsCopy = JSON.parse(JSON.stringify(targetingFields))
        delete targetingsCopy[accessor]
        setTargetingFields(targetingsCopy)
    }

    const getTargetings = targetings => {
        return (
            <div key='targetings' className="popup2__form__targetings">
                <h3 className="warning-word">ВНИМАНИЕ!</h3>
                <p className="warning-text">ТАРГЕТИНГИ - ЗАЛОГ КАЧЕСТВА РК! Заполняя используй логику и здравый
                    смысл.</p>

                {/* <SelectField key={'targetings'} name={'accessor'} value={'value'} input_value={'input_value'} options={targetingsOptions} editable={true} required={false} hint={'hint'} onChange={event => addNewField(event, targetings)} /> */}
                {targetingsEditable &&
                    <SelectField key={'targetings'} name={'targetings'} value={''} options={availableOptions}
                                 editable={true} required={false} hint={'targetings'}
                                 onChange={event => addTargetingField(event, targetings)}/>}
                {Object.keys(targetingFields).map(accessor => {
                    const {
                        hint,
                        label,
                        type,
                        value,
                        options,
                        editable,
                        required,
                        input_value
                    } = targetingFields[accessor]
                    const FormField = formFields[type]
                    return (
                        <div key={accessor} className="popup2__form__field"
                             style={{gridTemplateColumns: '355px 445px auto', margin: '1rem 0'}}>
                            <label htmlFor={'accessor'} className="popup2__form__label">{label}</label>
                            {/* <TextAreaField key={index} name={accessor} value={value} input_value={input_value} options={options} editable={editable} required={required} hint={hint} onChange={handleFieldChange} /> */}
                            <FormField key={label} name={accessor} value={value} input_value={input_value}
                                       options={options} editable={editable && targetingsEditable} required={required}
                                       hint={hint} onChange={handleTargetingsFieldChange}/>
                            {targetingsEditable &&
                                <button type="button" style={{color: 'red', width: '1.3rem', justifySelf: 'center'}}
                                        onClick={() => removeTargetingField(accessor)}><AiFillDelete
                                    style={{width: 'inherit'}}/></button>}
                        </div>
                    )
                })}
            </div>
        )
    }


    if (!didMount) return null;

    // const targetings
    if (type === 'popup2') {

        if (loading) return (
            <div className="popup-container background">
                <div className="popup2" onDoubleClick={event => event.stopPropagation()}>
                    <LoaderComponent text="Отправляется..."/>
                </div>
            </div>
        )

        return (
            <div className="popup-container background">
                <div className="popup2" onDoubleClick={event => event.stopPropagation()}>
                    <h2 className="popup2__title">{title}</h2>
                    {/* <div className="popup2__close-btn-container" onClick={closePopup}>
                        <CloseButton />
                    </div> */}
                    <div className="popup__content">
                        <form action="" autoComplete="off" className="popup2__form">
                            {Object.keys(formData).map((accessor, index) => {
                                if (formData[accessor] instanceof String || typeof formData[accessor] === 'string') {
                                    return <p key={accessor} className="popup__form__subtitle">{formData[accessor]}</p>
                                }
                                if (accessor === 'targetings') return getTargetings(formData.targetings)

                                const {
                                    hint,
                                    label,
                                    type,
                                    value,
                                    options,
                                    editable,
                                    required,
                                    input_value
                                } = formData[accessor]
                                // console.log(formData[accessor]);
                                const FormField = formFields[type]
                                return (
                                    <div key={accessor} className="popup2__form__field">
                                        <label htmlFor={accessor} className="popup2__form__label">{label}</label>
                                        <FormField key={label} name={accessor} value={value} input_value={input_value}
                                                   options={options} editable={editable && targetingsEditable}
                                                   required={required} hint={hint} onChange={handleFieldChange}/>
                                    </div>
                                )

                            })}
                        </form>
                    </div>
                    <div className="popup2__btns-container">
                        {visible && <button type="submit" className="popup2__submit-btn button"
                                            onClick={handleSubmit}>{text}</button>}
                        <button type="submit" className="popup2__close-btn button" onClick={closePopup}>Отменить
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    if (loading) return (
        <div className="popup-container background" onDoubleClick={closePopup}>
            <div className="popup" onDoubleClick={event => event.stopPropagation()}>
                <LoaderComponent text="Отправляется..."/>
            </div>
        </div>
    )

    // if (loading) return <LoaderComponent />  // NOT WORKING
    return (
        <div className="popup-container background" onDoubleClick={closePopup}>
            <div className="popup" onDoubleClick={event => event.stopPropagation()}>
                {/* <div className="popup-outer"> */}
                <h2 className="popup__title">{title}</h2>
                <div className="popup__close-btn-container" onClick={closePopup}>
                    <CloseButton/>
                </div>
                <div className="popup__content">
                    <form autoComplete="off" className="popup__form">
                        {Object.keys(formData).map((accessor, index) => {
                            if (formData[accessor] instanceof String || typeof formData[accessor] === 'string') {
                                return <p key={accessor} className="popup__form__subtitle">{formData[accessor]}</p>
                            }
                            if (accessor === 'targetings') return null

                            const {
                                hint,
                                label,
                                type,
                                value,
                                options,
                                editable,
                                required,
                                input_value,
                                sign
                            } = formData[accessor]
                            // console.log(formData[accessor]);
                            const FormField = formFields[type]
                            return <FormField key={index} label={label} name={accessor} value={value}
                                              input_value={input_value} sign={sign} options={options}
                                              editable={editable} required={required} hint={hint}
                                              onChange={handleFieldChange}/>
                        })}
                        {/* <DateField label="Date1" name="date1" value="" parent="popup" onChange={handleFieldChange}/>
                    <DateField label="Date2" name="date2" value="" parent="popup" onChange={handleFieldChange}/>
                    <TextField type="text" label="Login" name="login" value={formData.login} onChange={handleFieldChange} />
                    <TextField type="password" label="Password" name="password" value={formData.password} onChange={handleFieldChange} />
                    <NumberField type="text" label="Amount" name="amount1" value={formData.amount1} onChange={handleFieldChange} />
                    <SelectField label="Select" name="select" pack={packTest} onChange={handleFieldChange}/>
                    <SelectField label="Валюта" name="currency" pack={packCurrency} onChange={handleFieldChange}/>
                    <RadioField label="Gender" name="gender" value={formData.gender} onChange={handleFieldChange} options={genderOptions}/>
                    <TextAreaField label="Password" name="textarea" value={formData.textarea} onChange={handleFieldChange} />
                    <RadioField label="Подтверждено бухгалтерией" name="approved-by-account-manager" value={formData['approved-by-account-manager']} onChange={handleFieldChange} options={approvedByAccountManagerOptions}/>
                    <CheckboxField label="Подтверждено бухгалтерией" name="checkbox" onChange={handleFieldChange} helperText="Be careful" options={formData.checkbox}/>
                    <CheckboxField label="Пол" name="checkbox2" onChange={handleFieldChange} helperText="Выберите все необходимые варианты" options={formData.checkbox2}/>
                    <TextDivField label="contenteditable" name="contenteditable" value={formData.contenteditable} onChange={handleFieldChange} /> */}

                        {visible && <button type="submit" className="popup__submit-btn button"
                                            onClick={handleSubmit}>{text}</button>}
                    </form>
                </div>
            </div>
            {/* </div> */}
        </div>
    )
}

export default Popup


// accessor: {
//     label: 'aaaaaa',
//     type: 'text_field',
//     hint: 'hhhhhh',
//     value: 'ggg',
//     editable: true
// }
// accessor: {
//     label: 'aaaaaa',
//     type: 'date_field',
//     hint: 'hhhhhh',
//     value: 'ggg',
//     editable: true
// }
// accessor: {
//     label: 'aaaaaa',
//     type: 'select_field',
//     hint: 'hhhhhh',
//     value: 'ggg',
//     options: [
//         {label: 'a1', backend_id: 'name1'},
//         {label: 'a2', backend_id: 'name2'}
//     ],
//     editable: true
// }
// accessor: {
//     label: 'aaaaaa',
//     type: 'checkbox_field',
//     hint: 'hhhhhh',
//     checkbox: [
//         {value: true, label: 'Gilad Gray', name: 'gilad'},
//         {value: false, label: 'Jason Killian', name: 'jason'},
//         {value: false, label: 'Antoine Llorca', name: 'antoine'},
//         // {value: 'disabled', label: '(Disabled option)', disabled: true},
//     ],
//     editable: true
// }
// accessor: {
//     label: 'aaaaaa',
//     type: 'radio_field',
//     hint: 'hhhhhh',
//     value: 'female',
//     options: [
//         {value: 'female', label: 'Female'},
//         {value: 'male', label: 'Male'},
//         {value: 'other', label: 'Other'},
//         {value: 'disabled', label: '(Disabled option)', disabled: true},
//     ],
//     editable: true
// }


// accessor: {
//     type: 'checkbox_field2',
//     hint: 'hhhhhh',
//     value: true/false
//     editable: true
// }
