import React, {useEffect, useState} from 'react'
import './number.css'
import NumberFormat from 'react-number-format';
import {useGlobalContext} from '../../../store/context/context';

const displayValueProperly = (sign, value) => !!sign ? Number((value * 100).toFixed(2)) : value  // IF SIGN="%" THEN MULTIPLY VALUE BY 100 TO CORRECTLY DISPLAY VALUE && WHEN SENDING VALUE DELETE BY 100

export const NumberFieldV = ({
                                 value = '',
                                 onChange,
                                 label,
                                 name,
                                 editable = true,
                                 required,
                                 sign,
                                 onFocus,
                                 parentComponent = 'DefaultComponent'
                             }) => {
    const {showFeedback, popup} = useGlobalContext()

    const [focused, setFocused] = useState(!!value.toString().length ? 'focused filled' : '')

    useEffect(() => {
        if (!editable) {
            // console.log(value);
            setFocused(value === 0 || !!value?.toString().length ? 'focused filled' : '')
        }
    }, [value, editable])

    const handleOnChange = values => {
        console.log(values.floatValue);
        // console.log(values);
        const event = {
            target: {
                name,
                value: values.floatValue !== undefined ? !!sign ? values.floatValue / 100 : values.floatValue : ''  // OTHERWISE IT IS EQUAL TO undefined AND !!String(undefined) = true AND IT PASSES THE IF IN POPUP FUNCTION PRIOR TO SENDING REQUEST
            }
        }
        onChange && editable && onChange(event);  // DUE SORT AND FILTER CAUSING INFINITE RERENDER
    }

    const handleBlur = (event) => setFocused(!!event.target.value.toString().length ? 'focused filled' : '')  // IF value = 0 THEN THE LABEL GOES DOWN AND COVERS THE NUMBER FIELD
    return (
        <div className={`number form-field ${editable ? 'editable' : 'uneditable'}`}>
            <label htmlFor={name} className={`text__label ${focused}`}
                   style={{display: !label && "none"}}>{label}</label>
            <NumberFormat
                id={name}
                // value={value}
                value={displayValueProperly(sign, value)}
                // value={editable ? numberValue : value === '' ? null : value}
                className="text__input"
                thousandSeparator={' '}  // CUSTOM THOUSAND SEPARATOR
                onValueChange={handleOnChange}
                onFocus={() => {
                    setFocused('focused');
                    onFocus && onFocus()
                }}
                // onBlur={() => setFocused(!!value ? 'focused filled' : '')}  // IF value = 0 THEN THE LABEL GOES DOWN AND COVERS THE NUMBER FIELD
                onBlur={handleBlur}
                suffix={sign ? ` ${sign}` : ''}
                disabled={!editable}
                max={10}
                style={{borderColor: required && !value && showFeedback && popup.visible ? 'red' : ''}}
                autoComplete="off"
                // allowNegative={false}  // TRUE BY DEFAULT
                // prefix={'$'}
                // hintText="Some placeholder"
                decimalSeparator=","  // DEFAULT VALUE
                // displayType={'text'}  // renders it as a normal text in a span, ONLY readable
                // renderText={(value, props) => <div {...props}>{value}</div>}
                // thousandsGroupStyle="thousand"  // DEFAULT VALUE
            />
        </div>
    )
}
