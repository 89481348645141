import React, {useCallback, useEffect, useState} from 'react'
import './staff_projects.css'

import {useGlobalContext} from '../../store/context/context'
import {useHistory, useLocation} from 'react-router'
import {useParams} from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'
import ClientInfo from '../../components/layout/client-info/ClientInfo'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import {TableV} from '../../components/layout/table/TableV'

export const StaffProjects = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        setActiveTopbarLink,
        setPopup,
        renderFeedback,
        setShowFeedback,
        setServerError
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const {id} = useParams()
    const history = useHistory()
    const location = useLocation()

    const [dateFields, setDateFields] = useState({
        // month: {
        //     label: 'month',
        //     value: response.data.month || '',
        //     type: 'select_field',
        //     options: [],
        // },
        // year: {
        //     label: 'year',
        //     value: response.data.year || '',
        //     type: 'select_field',
        //     options: [],
        // }
    })

    const [table, setTable] = useState({})

    const [client, setClient] = useState({name: ''})
    const [loadingClient, setLoadingClient] = useState(true)
    const [loadingTable, setLoadingTable] = useState(true)
    const [errorClient, setErrorClient] = useState(null)
    const [errorTable, setErrorTable] = useState(null)

    const getClient = useCallback(async () => {
        try {
            setLoadingClient(true)
            const response_client = await axios.get(`users/staff/${id}/`)
            setClient(response_client.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorClient(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})

            // setErrorClient(error.response?.data|| 'Server error')
        } finally {
            setLoadingClient(false)
        }
    }, [id, setServerError])

    const getInitialDataAndReplace = useCallback(async () => {
        console.log('getInitialDataAndReplace called................');
        try {
            setErrorTable(false)
            setLoadingTable(true)
            const response = await axios.get(`${location.pathname}/`)
            setTable(response.data)

            if (response.data.year) {
                setDateFields({
                    month: {
                        label: 'месяц',
                        value: response.data.month || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1)
                    },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    }
                })
            } else {
                setDateFields([])
            }

            history.replace(location.pathname + `?month=${response.data.month}&year=${response.data.year}`)

        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTable(error.response?.data.message || 'Server error')
        } finally {
            setLoadingTable(false)
        }
    }, [location.pathname, history, setServerError])
    // console.log(table);

    const getInitialData = useCallback(async () => {
        console.log('getInitialData called................');
        try {
            setErrorTable(false)
            setLoadingTable(true)

            const response = await axios.get(`${location.pathname}/` + location.search)

            setTable(response.data)

            if (response.data.year) {
                setDateFields({
                    month: {
                        label: 'месяц',
                        value: response.data.month || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1)
                    },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    }
                })
            } else {
                setDateFields([])
            }

        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTable(error.response?.data.message || 'Server error')
        } finally {
            setLoadingTable(false)
        }
    }, [location.search, location.pathname, setServerError])

    useEffect(() => {
        getClient()
    }, [getClient])

    useEffect(() => {
        if (history.action === 'REPLACE') return  // TABLE DATA IS ALREADY SET
        if (!location.search) return getInitialDataAndReplace()  // ON FIRST VISIT
        getInitialData() // ON PAGE REFRESH
    }, [getInitialData, getInitialDataAndReplace, location, history.action])


    const handleFieldChange = event => {
        // console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)
    const handleDateSubmitButtonClick = async () => {
        console.log('Called........  2');
        try {
            setErrorTable(false)
            setLoadingOnDateSubmit(true)

            const response = await axios.get(location.pathname + `/?month=${dateFields.month.value}&year=${dateFields.year.value}`)

            setTable(response.data)

            history.replace(location.pathname + `?month=${response.month.value}&year=${response.data.year}`)
        } catch (error) {
            console.log(error.message);
            renderFeedback('danger', error.response?.data.message || 'Server error')
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }

    useEffect(() => {
        setActiveSidebarLink('users/staff')  // NEED TO BE EQUAL WITH THIS VALUE SO THAT SIDEBAR WORKS AS NEEDED
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setActiveTopbarLink(location.pathname)  // `/clients/${id}/brands`
    }, [setActiveTopbarLink, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [
                // {value: 'Бонусы', url: `/users/staff/${id}/bonuses`},
                {value: 'Доступы', url: `/users/staff/${id}/permissions`},
                {value: 'Проектные задачи', url: `/users/staff/${id}/projects`},
            ],
            sublinks: null
        })
    }, [setTopbarData, id])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/users/staff', breadcrumb: 'Сотрудники'},
            {path: `${location.pathname}`, breadcrumb: `${table.title || ''}`},  // links[getURL()] WAS HERE PREVIOUSLY
        ])
    }, [setBreadcrumbs, id, location.pathname, client.name, table.title])

    const buttons = [
        {
            text: 'Добавить проектную задачу',
            icon: 'plus',
            url: `${location.pathname}/new/`,
            popup_title: 'Новая проектная задача',
            popup_callback() {
                getInitialData()
            },
        },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value

        const url = `${location.pathname}/${request_id}/edit/`
        try {
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать проектную задачу',
                callback() {
                    getInitialData()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- ROW CLICK START ---------------------

    // ----------------- EDIT CLIENT START ---------------------
    const editClient = async () => {
        const url = `users/staff/${id}/edit/`
        try {
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать данные сотрудника',
                callback() {
                    getClient()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            renderFeedback('danger', error.response?.data?.messagem || 'Server Error')
        }
    }
    // ----------------- EDIT CLIENT STOP ---------------------

    return (<>
            <div className='staff-projects page'>
                {/* <ClientInfo inline client={client} editClient={editClient}/> */}
                {/* <ClientInfo client={client} editClient={editClient}/> */}
                {loadingClient ? <LoaderComponent/> : errorClient ? <div>{errorClient.message}</div> :
                    <ClientInfo client={client} editClient={editClient}/>}

            </div>

            {loadingOnDateSubmit
                ? <LoaderComponent text='Loading on date submit...'/>
                : loadingTable
                    ? <LoaderComponent/>
                    : errorTable
                        ? <div>{errorTable.message}</div>
                        : <TableV
                            table={table}
                            date_fields={dateFields}
                            buttons={buttons.reverse()}
                            handleRowClick={handleRowClick}
                            onDateFieldsChange={handleFieldChange}
                            handleDateSubmitButtonClick={handleDateSubmitButtonClick}
                            refreshData={getInitialData}
                        />
            }


        </>
    )
}
