import React, {useMemo, useRef, useState} from 'react'
import './table.css'

import {ReactComponent as Plus} from '../../../utils/icons/plus.svg'
import {ReactComponent as Pencil} from '../../../utils/icons/pencil.svg'
import {ReactComponent as Trash1} from '../../../utils/icons/trash_1.svg'
import {ReactComponent as Coin1} from '../../../utils/icons/coin_1.svg'
import {ReactComponent as Tick1} from '../../../utils/icons/tick_1.svg'


import {useGlobalContext} from '../../../store/context/context'
import RadioField from '../../forms/radio/Radio'
import NumberField from '../../forms/number/Number'
import axios from 'axios'
import CheckboxField from '../../forms/checkbox/Checkbox'
import TextAreaFieldMU from '../../forms/textarea_mu/TextAreaMU'
import useDebounce from '../../../utils/js/useDebounce'
import {PopupField} from '../../forms/popup_field/PopupField'
import {LoaderComponent} from '../loader/Loader'
import {CheckboxField2} from '../../forms/checkbox/Checkbox2'
import SwipeUp from '../swipe-up/SwipeUp'
import DateNumberField from '../../forms/date/DateNumber'
import {Hint, HintTable} from '../hint/Hint'
import {SelectPositionedField} from '../../forms/select/SelectPositioned'
import {formatValue} from './formatValue'
import {useLocation} from "react-router-dom";



export const TableForMonthlyTasksBody = ({
                                             table,
                                             setTasksTable,
                                             totalTable,
                                             setTotalTable,
                                             sum_all,
                                             head_request_id,
                                             tzType,
                                             refreshTableBottomData,
                                             parentComponent,
                                             tableType,
                                             updateTableHead,
                                             startDate,
                                             add_subtask_btn = true,
                                             valueType
                                         }) => {
    const {
        setPopup,
        setPopupFill,
        renderFeedback,
        tableIcons,
        SPECIAL_VALUES
    } = useGlobalContext()

    const location = useLocation()

    const [columnAccessors] = useState(table.order?.length ? table.order : Object.keys(table.headers))
    const addNewRowButtonColSpan = useMemo(() => {
        return columnAccessors.reduce((prev, curr) => {
            if (curr === 'children') {
                return prev + Object.keys(table.headers.children).reduce((prev, curr) => {
                    return table.headers.children[curr].visible ? prev + 1 : prev
                }, 0)
            }
            return table.headers[curr].visible ? prev + 1 : prev
        }, 0)
    }, [table.headers, columnAccessors])

    const [showSwipeUp, setShowSwipeUp] = useState(false)
    const [swipeUpData, setSwipeUpData] = useState(false)

    const tableContentRef = useRef(null)
    const [tbodyDisplay, setTBodyDisplay] = useState(JSON.parse(localStorage.getItem('tbodyDisplayObject'))?.[table.request_id] || 'table-row-group')  // OR 'none'


    const [loading, setLoading] = useState(false)

    const [formFields] = useState({
        text_field: TextAreaFieldMU,
        select_field: SelectPositionedField,
        select_field2: PopupField,
        date_field: DateNumberField,
        radio_field: RadioField,
        number_field: NumberField,
        checkbox_field: CheckboxField,
        checkbox_field2: CheckboxField2,
    })

    const [sendRequest, setSendRequest] = useState(false)  // THIS IS USED AS A TRIGGER FOR SENDING REQUEST WHEN FIELD HAS BEEN STOP FROM CHANGING FOR 0.5s
    const [sendRequestImmediately, setSendRequestImmediately] = useState(false)  // THIS IS USED AS A TRIGGER FOR SENDING REQUEST WHEN FIELD HAS BEEN STOP FROM CHANGING FOR 0.5s
    const useDebounceInfo = useRef({
        url: '',
        dataToSend: {},
        isOutsideChildrenClicked: null,
        row_index: null,
        child_index: null,
    })

    useDebounce(() => {
        const sendRequest = async () => {
            try {
                const {url, dataToSend, isOutsideChildrenClicked, row_index, child_index} = useDebounceInfo.current
                const response = await axios.post(url, {...dataToSend})

                const {additional_info} = response.data



                let updatedRows = JSON.parse(JSON.stringify(table.rows))
                if (isOutsideChildrenClicked) {
                    updatedRows = updatedRows.map((row, i) => i === row_index ? {children: [...row.children], ...response.data} : row)
                } else {
                    updatedRows = updatedRows.map((row, i) => i === row_index ? {
                        ...row,
                        children: row.children.map((child, i) => i === child_index ? {...response.data} : child)
                    } : row)
                }

                setTasksTable(oldTableDate => {
                    return {
                        ...oldTableDate,
                        body: oldTableDate.body.map(singleTable => singleTable.request_id === table.request_id ? {
                            ...singleTable,
                            rows: updatedRows,
                            headers: {
                                ...singleTable.headers,
                                children: {
                                    ...singleTable.headers.children,
                                    subtask_time: {
                                        ...singleTable.headers.children.subtask_time,
                                        label: additional_info.sum_table
                                    }
                                }
                            }
                        } : singleTable)
                    }
                })

                const totalTableCopy = JSON.parse(JSON.stringify(totalTable))
                totalTableCopy.headers.children['subtask_time'].label = additional_info.sum_all
                setTotalTable(totalTableCopy)
            } catch (error) {
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            }
        }

        sendRequest()
    }, 500, [sendRequest])  // SEND REQUEST AFTER .5 seconds
    useDebounce(() => {
        const sendRequest = async () => {
            try {
                // setLoading(true)  // BAD IDEA CAUSE THIS FLASHES THE TZ ON EDIT PAGE AS WELL AS BRINGS THE SCROLL TO INITIAL POSITION
                const {url, dataToSend, isOutsideChildrenClicked, row_index, child_index} = useDebounceInfo.current
                const response = await axios.post(url, {...dataToSend})

                const {additional_info} = response.data


                let updatedRows = JSON.parse(JSON.stringify(table.rows))
                if (isOutsideChildrenClicked) {
                    updatedRows = updatedRows.map((row, i) => i === row_index ? {children: [...row.children], ...response.data} : row)
                } else {
                    updatedRows = updatedRows.map((row, i) => i === row_index ? {
                        ...row,
                        children: row.children.map((child, i) => i === child_index ? {...response.data} : child)
                    } : row)
                }

                setTasksTable(oldTableDate => {
                    return {
                        ...oldTableDate,
                        body: oldTableDate.body.map(singleTable => singleTable.request_id === table.request_id ? {
                            ...singleTable,
                            rows: updatedRows,
                            headers: {
                                ...singleTable.headers,
                                children: {
                                    ...singleTable.headers.children,
                                    subtask_time: {
                                        ...singleTable.headers.children.subtask_time,
                                        label: additional_info.sum_table
                                    }
                                }
                            }
                        } : singleTable)
                    }
                })

                const totalTableCopy = JSON.parse(JSON.stringify(totalTable))
                totalTableCopy.headers.children['subtask_time'].label = additional_info.sum_all
                setTotalTable(totalTableCopy)
            } catch (error) {
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            }
        }

        sendRequest()
    }, 0, [sendRequestImmediately])  // SEND REQUEST IMMEDIATELY

    const getOptions = (accessor, dataToSend, isOutsideChildrenClicked = false) => {
        return new Promise(async (resolve, reject) => {
            try {
                const {get_url} = isOutsideChildrenClicked ? table.headers[accessor] : table.headers['children'][accessor]
                const response = await axios.post(`${location.pathname}/${get_url}`, dataToSend)
                const options = response.data
                resolve(options)
            } catch (error) {
                reject(error)
            }
        })
    }

    const handleFieldChange = (event, row_index, parent_request_id, isOutsideChildrenClicked = false, child_index) => {
        if (event.target.name !== document.activeElement.name) return // IN AVR FOOT, WHEN WE CHANGE ONE CELL, UPDATED CHILD IS RECEIVED AND ROWS_STATE IS CHANGED,
        // WHICH IN TURN FORCES THE FORCEFULLY CHANGED CELL(DIFFERENT) CALL THIS FUNCTION AND ANOTHER REQUEST IS SEND AGAIN UNWILLINGLY. TO PREVENT THIS WE CHECK IF THE CHANGED CELL IS FOCUSED OR NOT

        let dataToSend
        let rowsCopy = JSON.parse(JSON.stringify(table.rows))
        if (isOutsideChildrenClicked) {
            dataToSend = rowsCopy[row_index]

            if (dataToSend[event.target.name].is_task_name) {
                const row = dataToSend
                const newRow = {
                    ...row,
                    [event.target.name]: {  // [event.target.name] === [accessor]
                        ...row[event.target.name],
                        value: event.target.value
                    }
                }
                return setTasksTable(oldTableDate => {
                    return {
                        ...oldTableDate,
                        body: oldTableDate.body.map(singleTable => singleTable.request_id === table.request_id ? {
                            ...singleTable,
                            rows: singleTable.rows.map(row => row.request_id.value === parent_request_id ? newRow : row)
                        } : singleTable)
                    }
                })

            }
            delete dataToSend.children
        } else {
            dataToSend = rowsCopy[row_index].children[child_index]
        }
        dataToSend[event.target.name].value = event.target.value
        if (event.target.value_id) dataToSend[event.target.name].value_id = event.target.value_id // FOR SELECT FIELD 2

        dataToSend['additional_info'] = {
            'task_name': rowsCopy[row_index]['task_name'],
            'sum_table': table.headers.children['subtask_time'].label,
            'sum_all': sum_all,
        }


        let url = isOutsideChildrenClicked // NOW WE ARE DEALING WITH "TZ KPI"
            ? `${location.pathname}/${table.headers[event.target.name].set_url}`
            : `${location.pathname}/${table.headers['children'][event.target.name].set_url}`


        useDebounceInfo.current = {
            url,
            dataToSend,
            isOutsideChildrenClicked,
            row_index,
            child_index,
            accessor: event.target.name
        }

        if (dataToSend[event.target.name].type === 'select_field' || dataToSend[event.target.name].type === 'select_field2' || dataToSend[event.target.name].type === 'date_field') {
            setSendRequestImmediately(!sendRequestImmediately)
        } else {
            setSendRequest(!sendRequest)
        }
    }

    const handleRateIconClick = (child_index, child, row_index, row, isEditableRateCell = true) => {
        if (location.pathname.includes('view') || !isEditableRateCell) return
        useDebounceInfo.current = {
            url: parentComponent === 'avr' ? `avrs/${tableType}/set/inst_rate_default/${head_request_id}/` : 'tzs/kpi/set/inst_rate_default/',
            dataToSend: child,
            isOutsideChildrenClicked: false,
            row_index,
            child_index,
        }

        setSendRequestImmediately(!sendRequestImmediately)
    }

    const handleIconButtonClick = async (icon_accessor, child_index, child, row_index, row, isEditable) => {
        if (!Boolean(isEditable)) return // NOT EDITABLE
        if (icon_accessor === 'add') {
            try {
                setLoading(true)
                let url
                if (parentComponent === 'avr') {
                    const parent_request_id = row.request_id.value
                    url = `avrs/${tableType}/add/row/?avr_set_id=${parent_request_id}`  // BOTH FOR BODY AND FOOT
                } else {
                    url = `tzs/${tzType}/add/inst/`
                }
                const response = await axios.post(url)
                const newChild = response.data
                setTasksTable({
                    ...table,
                    rows: table.rows.map((row, i) => i === row_index ? {
                        ...row,
                        children: [...row.children, newChild]
                    } : row)
                })
            } catch (error) {
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            } finally {
                setLoading(false)
            }
        } else if (icon_accessor === 'fill') {
            try {
                if (tzType === 'kpi') {
                    setLoading(true)
                    const response = await axios.post(`tzs/${tzType}/get/fill/?tz_set_id=${row.request_id.value}`)
                    setPopupFill({
                        type: 'kpi',
                        visible: true,
                        data: response.data,
                        url: `tzs/${tzType}/set/fill/?tz_set_id=${row.request_id.value}`,
                        title: 'Пополнение',
                        button: {
                            visible: true,
                            text: 'Отправить'
                        }
                    })
                } else if (tzType === 'billing') {
                    setLoading(true)
                    const response = await axios.post(`tzs/${tzType}/get/fill/?inst_id=${child.request_id.value}`)
                    setPopupFill({
                        type: 'billing',
                        visible: true,
                        data: response.data,
                        url: `tzs/${tzType}/set/fill/?inst_id=${child.request_id.value}`,
                        title: 'Пополнение',
                        button: {
                            visible: true,
                            text: 'Отправить'
                        }
                    })
                }

            } catch (error) {
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            } finally {
                setLoading(false)
            }
        } else if (icon_accessor === 'remove') {
            try {
                setLoading(true)
                const response = await axios.post(`${location.pathname}/data/delete/`, child)

                let rowsCopy = JSON.parse(JSON.stringify(table.rows))
                if (rowsCopy[row_index].children.length === 1) throw {response: {data: {message: 'Подзадача должна быть выполнена как минимум 1 раз!'}}} // У этой подзадачи должно быть как минимум
                else rowsCopy[row_index].children = rowsCopy[row_index].children.filter((_, i) => i !== child_index)
                setTasksTable(oldTableDate => {
                    return {
                        ...oldTableDate,
                        body: oldTableDate.body.map(singleTable => singleTable.request_id === table.request_id ? {
                            ...singleTable,
                            rows: singleTable.rows.map((row, i) => i === row_index ? rowsCopy[0] : row)
                        } : singleTable)
                    }
                })

                renderFeedback('success', response.data.message || 'Success')
            } catch (error) {
                renderFeedback('danger', error.response?.data?.message || 'Server Error')

            } finally {
                setLoading(false)
            }

        } else if (icon_accessor === 'open') {
            const url = `tzs/kpi/set/open/?inst_id=${child.request_id.value}&inst_type=${child.inst_type.value}`

            try {
                const response = await axios.post(`tzs/kpi/get/open/`, child)
                let {data} = response
                if (location.pathname.includes('view')) {
                    for (let accessor in data) {
                        if (accessor === 'targetings') {
                            for (let tAccessor in data['targetings']) {
                                data['targetings'][tAccessor].editable = true
                            }
                        } else {
                            data[accessor].editable = true
                        }
                    }
                }
                setPopup({
                    type: 'popup2',
                    visible: true,
                    data,
                    url,
                    title: 'Targetings',
                    button: {
                        visible: !location.pathname.includes('view'),
                        text: 'Отправить'
                    },
                    targetingsEditable: !location.pathname.includes('view')
                })
            } catch (error) {
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            } finally {
                setLoading(false)
            }
        } else if (icon_accessor === 'edit') {
            try {
                if (parentComponent === 'avr' && tableType === 'body') {
                    const urlForEditBtn = `avrs/body/get/edit/`  // BOTH FOR BODY AND FOOT
                    const response = await axios.post(urlForEditBtn, row)
                    setSwipeUpData({
                        table: response.data,
                        buttons: [{
                            text: 'Изменить',
                            icon: 'plus',
                            async onClick(newRows) {  // THERE ARE SECOND AND THIRD PARAMETERS AS WELL "table, columnAccessors"
                                try {
                                    const parent_request_id = row.request_id.value
                                    const response = await axios.post(`avrs/body/set/edit/?avr_set_id=${parent_request_id}`, newRows)

                                    setSwipeUpData({table: null, buttons: []})
                                    setShowSwipeUp(false)
                                    refreshTableBottomData()
                                    renderFeedback('success', response.data.message || 'Success')

                                    if (response.data.hasOwnProperty('updated_values')) {
                                        updateTableHead(response.data.updated_values.avr_sum_vat)
                                    }
                                } catch (error) {
                                    renderFeedback('danger', error.response?.data?.message || 'Server Error')
                                }
                            }
                        }]
                    })

                    setShowSwipeUp(true)
                }
            } catch (error) {
                renderFeedback('danger', error.response?.data?.message || error.message || 'Server Error')
            }
        }
    }

    const addNewSubTask = async () => {
        try {
            const responseParent = await axios.get(`${location.pathname}/new/?task_id=${table.request_id}&start_date=${startDate}`)
            const responseChild = await axios.get(`${location.pathname}/data/new/?subtask_id=${responseParent.data.request_id.value}`)
            setTasksTable(oldTableDate => {
                return {
                    ...oldTableDate,
                    body: oldTableDate.body.map(singleTable => singleTable.request_id === table.request_id ? {
                        ...singleTable,
                        rows: [...singleTable.rows, {...responseParent.data, children: [{...responseChild.data}]}]
                    } : singleTable)
                }
            })

        } catch (error) {
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        }
    }

    const handleAddButtonClick = async row_request_id => {
        try {
            const responseChild = await axios.get(`${location.pathname}/data/new/?subtask_id=${row_request_id}`)

            setTasksTable(oldTableDate => {
                return {
                    ...oldTableDate,
                    body: oldTableDate.body.map(singleTable => singleTable.request_id === table.request_id ? {
                        ...singleTable,
                        rows: singleTable.rows.map(row => row.request_id.value === row_request_id ? {
                            ...row,
                            children: [...row.children, {...responseChild.data}]
                        } : row)
                    } : singleTable)
                }
            })
        } catch (error) {
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    const handleCheckButtonClick = async row_request_id => {
        try {
            const rowToSend = table.rows.filter(row => row.request_id.value === row_request_id)[0]
            const response = await axios.post(`${location.pathname}/edit/`, rowToSend)  // UPDATED ROW, i.e., editable: true -> false, AS A RESULT THE ICON ALSO CHANGES
            return setTasksTable(oldTableDate => {  // DO I RECEIVE ONLY PARENT PART OR PARENT + CHILD
                return {
                    ...oldTableDate,
                    body: oldTableDate.body.map(singleTable => singleTable.request_id === table.request_id ? {
                        ...singleTable,
                        rows: singleTable.rows.map(row => row.request_id.value === row_request_id ? response.data : row)
                    } : singleTable)
                }
            })
        } catch (error) {
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }

    const handleEditButtonClick = row_request_id => {
        return setTasksTable(oldTableDate => {  // DO I RECEIVE ONLY PARENT PART OR PARENT + CHILD
            return {
                ...oldTableDate,
                body: oldTableDate.body.map(singleTable => singleTable.request_id === table.request_id ? {
                    ...singleTable,
                    rows: singleTable.rows.map(row => row.request_id.value === row_request_id ? {
                        ...row,
                        task_name: {...row.task_name, editable: !row.task_name.editable}
                    } : row)
                } : singleTable)
            }
        })
    }
    const handleDeleteButtonClick = async row_request_id => {
        try {
            const response = await axios.get(`${location.pathname}/status/archive/?request_id=${row_request_id}`)
            renderFeedback('success', response.data.message || 'Подзадача успешно удалена')

            setTasksTable(oldTableDate => {
                return {
                    ...oldTableDate,
                    body: oldTableDate.body.map(singleTable => singleTable.request_id === table.request_id ? {
                        ...singleTable,
                        rows: singleTable.rows.filter(row => row.request_id.value !== row_request_id)
                    } : singleTable)
                }
            })
        } catch (error) {
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }

    const handleTHeadClick = () => {
        let tbodyDisplayObject = JSON.parse(localStorage.getItem('tbodyDisplayObject')) || {}
        tbodyDisplayObject[table.request_id] = tbodyDisplay === 'none' ? 'table-row-group' : 'none'

        localStorage.setItem('tbodyDisplayObject', JSON.stringify(tbodyDisplayObject))
        setTBodyDisplay(prev => prev === 'none' ? 'table-row-group' : 'none')
    }

    const getTableTdButtons = (row_request_id, editable) => {
        return (
            <div className="buttons">
                <Hint hint='Добавить строку справа'>
                    <button className="svg-container blue-button" onClick={() => handleAddButtonClick(row_request_id)}>
                        <Plus/></button>
                </Hint>
                {editable
                    ? <Hint hint='Сохранить'>
                        <button className="svg-container green-button"
                                onClick={() => handleCheckButtonClick(row_request_id)}><Tick1/></button>
                    </Hint>
                    : <Hint hint='Редактировать'>
                        <button className="svg-container yellow-button"
                                onClick={() => handleEditButtonClick(row_request_id)}><Pencil/></button>
                    </Hint>
                }
                <Hint hint='Удалить'>
                    <button className="svg-container red-button"
                            onClick={() => handleDeleteButtonClick(row_request_id)}><Trash1/></button>
                </Hint>
            </div>
        )
    }

    const getChildPart = (child_idx, child, row_idx, row) => {
        return Object.keys(table.headers['children']).map(child_accessor => {
            if (!table.headers['children'][child_accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER

            const {value, type, editable, sign, color, value2} = child[child_accessor]
            if (type === 'icon') { // IF THE CELL IS ICON TYPE, THEN RETURN ICON
                const Icon = tableIcons[value]
                return (
                    <td key={child_accessor} className='table__td' style={{cursor: editable ? 'pointer' : 'default'}}>
                        <div className={`table__td__center ${child_accessor}`} style={{justifyContent: 'center'}}
                             onClick={() => handleIconButtonClick(child_accessor, child_idx, child, row_idx, row, child[child_accessor].editable)}>
                            {child_accessor === 'inst_status'
                                ? <PopupField name={child_accessor} value={value} editable={editable}
                                              rowClickable={table.clickable} sign={sign} popupField={{
                                    type: 'status_icon_field',
                                    title: table.headers['children'][child_accessor].label
                                }} getOptions={() => getOptions(child_accessor, child)}
                                              onChange={(event) => handleFieldChange(event, row_idx, row.request_id?.value, false, child_idx)}>
                                    <Icon style={{position: 'absolute'}}/>
                                </PopupField>
                                : <span className={`svg-container`} data-editable={editable}><Icon/></span>}
                        </div>
                    </td>
                )
            }

            if (value === null) return <td key={child_accessor} className={`table__td ${child_accessor}`}
                                           style={{backgroundColor: color || '#fff'}}>
                <div>----</div>
            </td>

            if (editable && valueType !== 'value2') {
                const FormField = formFields[type]  // MEANS THAT THIS IS EDITABLE, THUS RETURN FORM-FIELD
                return (
                    <td key={child_accessor} className={`table__td ${child_accessor}`}
                        style={{backgroundColor: color || '#fff'}}>
                        <div className={`table__td__center ${child_accessor}`}>
                            <div style={{flex: '1'}}>
                                <FormField parentComponent='PopupFillBilling' name={child_accessor}
                                           value={value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? null : value}
                                           editable={editable} rowClickable={table.clickable} sign={sign}
                                           popupField={{type, title: table.headers['children'][child_accessor].label}}
                                           getOptions={() => getOptions(child_accessor, child)}
                                           onChange={(event) => child_accessor === 'request_id' ? {} : handleFieldChange(event, row_idx, row.request_id?.value, false, child_idx)}/>
                                {child_accessor === 'inst_rate' &&
                                    <button style={{marginLeft: '0.3rem', cursor: !editable && 'default'}}
                                            onClick={() => handleRateIconClick(child_idx, child, row_idx, row, editable)}>
                                        <Coin1/></button>}
                            </div>
                        </div>
                    </td>
                )
            }
            return (  // IF THE CELL IS NOT EDITABLE, THEN RETURN DIV
                <td key={child_accessor} className={`table__td ${child_accessor}`}
                    style={{backgroundColor: color !== '#FFFFFF' ? color : ''}}>
                    <div className={`table__td__center ${child_accessor}`}>
                        <div style={{flex: '1'}}
                             dangerouslySetInnerHTML={{__html: value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? '' : value2 !== undefined && valueType === 'value2' ? formatValue(value2, '', sign) : formatValue(value, type, sign)}}>
                        </div>
                    </div>
                </td>
            )


        })
    }


    // ----------------- RENDER TABLE BODY START ---------------------
    const getTableBody = () => {
        return (<>
            {table.rows.map((row, idx) => {
                return row['children'].map((child, index) => {
                    if (index === 0) {  // IF THIS IS THE FIRST ROW THEN ADD OTHER TDs WITH ROWSPAN = CHILDREN.LENGTH
                        return (  // RETURN THE FIRST CHILD && EVERYTHING OUTSIDE THE CHILDREN OBJECT
                            <tr key={index} className={`table__row ${table.clickable && 'clickable'}`}>
                                {columnAccessors.map(accessor => {
                                    if (accessor === 'children') return getChildPart(index, child, idx, row)

                                    if (!table.headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER

                                    const {
                                        value,
                                        type,
                                        editable,
                                        sign,
                                        is_task_name,
                                        color,
                                        value2
                                    } = row[accessor]
                                    if (type === 'icon') { // IF THE CELL IS ICON TYPE, THEN RETURN ICON
                                        const Icon = tableIcons[value]
                                        return (  // THIS IS WHERE add BUTTON IS
                                            <td key={accessor} rowSpan={row['children'].length} className='table__td'
                                                style={{cursor: editable ? 'pointer' : 'default'}}
                                                onClick={() => handleIconButtonClick(accessor, index, child, idx, row, row[accessor].editable)}>
                                                <div className={`table__td__center ${accessor}`}
                                                     style={{justifyContent: 'center'}}>
                                                    <span className="svg-container"><Icon/></span>
                                                </div>
                                            </td>
                                        )
                                    }

                                    if (value === null) return <td key={accessor} className={`table__td ${accessor}`}
                                                                   style={{backgroundColor: color || '#fff'}}>
                                        <div>----</div>
                                    </td>

                                    if (editable) {
                                        const FormField = formFields[type]
                                        return (
                                            <td key={accessor} rowSpan={row['children'].length}
                                                className={`table__td ${accessor}`}
                                                style={{backgroundColor: color || '#fff'}}>
                                                <div className={`table__td__center ${accessor}`}>
                                                    {is_task_name !== undefined &&
                                                        <div className={`circle ${is_task_name}`}></div>}
                                                    <div style={{flex: '1'}}>
                                                        <FormField name={accessor}
                                                                   value={value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? null : value}
                                                                   editable={editable} rowClickable={table.clickable}
                                                                   sign={sign} popupField={{
                                                            type,
                                                            title: table.headers[accessor].label
                                                        }}
                                                                   getOptions={() => getOptions(accessor, row, 'outsideChildrenClicked')}
                                                                   onChange={(event) => handleFieldChange(event, idx, row.request_id.value, 'outsideChildrenClicked')}/>
                                                    </div>
                                                    {is_task_name !== undefined && getTableTdButtons(row.request_id.value, editable)}
                                                </div>
                                            </td>
                                        )
                                    }
                                    return (
                                        <td key={accessor} rowSpan={row['children'].length}
                                            className={`table__td ${accessor}`}
                                            style={{backgroundColor: color !== '#FFFFFF' ? color : ''}}>
                                            <div className={`table__td__center ${accessor}`}>
                                                {is_task_name !== undefined &&
                                                    <div className={`circle ${is_task_name}`}></div>}
                                                <div style={{flex: '1'}}
                                                     dangerouslySetInnerHTML={{__html: value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? '' : value2 !== undefined && valueType === 'value2' ? formatValue(value2, '', sign) : formatValue(value, type, sign)}}>
                                                </div>
                                                {is_task_name !== undefined && getTableTdButtons(row.request_id.value, editable)}
                                            </div>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    }

                    return (
                        <tr key={index} className={`table__row ${table.clickable && 'clickable'}`}>
                            {getChildPart(index, child, idx, row)}
                        </tr>
                    )
                })
            })}
        </>)
    }
    // ----------------- RENDER TABLE BODY STOP ---------------------


    // ----------------- RENDER TABLE HEADER START ---------------------

    if (loading) return <LoaderComponent/>

    return (<>
            <div className="table-block table-for-tasks-block">
                <div className="table-container" ref={tableContentRef} style={{maxHeight: '500px'}}>
                    <table className="table">
                        <thead className="table__thead" onClick={handleTHeadClick}>
                        <tr>
                            {columnAccessors.map(accessor => {
                                if (accessor === 'children') {
                                    const children = table.headers[accessor]
                                    return Object.keys(children).map(accessor => {
                                        if (!children[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER

                                        const {label, hint, width} = children[accessor]

                                        if (label === 'icon') {
                                            let Icon = accessor === 'open' ? tableIcons['target1'] : tableIcons[accessor]
                                            return (
                                                // <th key={accessor} className="table__th" style={{width: '.1%', minWidth: width || '50px'}} >
                                                <th key={accessor} className="table__th" style={{width: '.1%'}}>
                                                    <div className={`table__th__center ${accessor}`}
                                                         style={{width: width || '24px', justifyContent: 'center'}}>
                                                        <span className="svg-container"><Icon style={{width: '24px'}}/></span>
                                                        {hint && <HintTable hint={hint}/>}
                                                    </div>
                                                </th>
                                            )
                                        }

                                        return (
                                            <th key={accessor} className="table__th"
                                                style={{width: width === 'auto' ? '100%' : '1%'}}>
                                                <div className={`table__th__center ${accessor}`}
                                                     style={{width: width || '199px'}}>
                                                    <span className="table__th__value">{label}</span>
                                                    {hint && <HintTable hint={hint}/>}
                                                </div>
                                            </th>
                                        )
                                    })
                                }

                                if (!table.headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER

                                const {label, hint, width, normative, frequency} = table.headers[accessor]

                                if (label === 'icon') {
                                    let Icon = accessor === 'add' ? tableIcons['tools'] : tableIcons[accessor]

                                    return (
                                        <th key={accessor} className="table__th" style={{width: '.1%'}}>
                                            <div className={`table__th__center ${accessor}`}
                                                 style={{width: width || '24px', justifyContent: 'center'}}>
                                                <span className="svg-container"><Icon style={{width: '24px'}}/></span>
                                                {hint && <HintTable hint={hint}/>}
                                            </div>
                                        </th>
                                    )
                                }
                                return (
                                    <th key={accessor} className="table__th"
                                        style={{width: width === 'auto' ? '100%' : '1%'}}>
                                        <div className={`table__th__center ${accessor}`}
                                             style={{width: width || '199px'}}>
                                            <div className="table__th__value">
                                                {label}
                                                {(normative || frequency) &&
                                                    <div className="table__th__value__criterias">
                                                        {normative && <p>Норматив: <span>{normative} минут</span></p>}
                                                        {frequency && <p>Частота: <span>{frequency}</span></p>}
                                                    </div>
                                                }
                                            </div>
                                            {hint && <HintTable hint={hint}></HintTable>}
                                        </div>
                                    </th>
                                )
                            })}
                        </tr>
                        </thead>
                        <tbody style={{display: tbodyDisplay}}>
                        {getTableBody()}
                        {add_subtask_btn && <tr className="table__row" onClick={addNewSubTask}>
                            <td colSpan={addNewRowButtonColSpan} className="table__td table__td__add-button">
                                <div className="table__td__center" style={{justifyContent: 'center'}}>
                                    Добавить {table.type === 'other' ? 'прочую задачу' : 'подзадачу'}
                                </div>
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
            </div>

            {tableType === 'body' &&
                <SwipeUp showSwipeUp={showSwipeUp} setShowSwipeUp={setShowSwipeUp} swipeUpData={swipeUpData}
                         setSwipeUpData={setSwipeUpData}/>}

        </>
    )
}
