import React, {useCallback, useEffect, useRef, useState} from 'react'
import './popup.css'

import CloseButton from '../../buttons/close/CloseButton'
// import Radio from '../../forms/radio/Radio'
// import SelectField from '../../forms/select/Select_1'
import RadioField from '../../forms/radio/Radio'
import DateField from '../../forms/date/Date'
import NumberField from '../../forms/number/Number'
import axios from 'axios'
import {useGlobalContext} from '../../../store/context/context'
import MultiSelectField from '../../forms/multi_select/MultiSelect'
import {SelectField} from '../../forms/select/Select'
import TextFieldTextArea from '../../forms/text/TextTextArea'
import {LoaderComponent} from '../loader/Loader'

export const PopupFillBilling = () => {
    const {
        popupFill: {data, url, title, button: {visible = true, text = 'Отправить'} = {}} = {},
        setPopupFill,
        renderFeedback,
        setShowFeedback
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const [formFields] = useState({
        text_field: TextFieldTextArea,
        select_field: SelectField,
        date_field: DateField,
        radio_field: RadioField,
        number_field: NumberField,
        multi_select_field: MultiSelectField
    })

    // console.log(parent);
    // const [formDataParent, setFormDataParent] = useState(parent)
    const [formData, setFormData] = useState(data)
    const [loading, setLoading] = useState(false)
    // const history = useHistory()

    const currentField = useRef(null)
    const handleFocusChange = accessor => currentField.current = accessor

    let x = useRef(null)
    const handleFieldChange = (event, index) => {
        // console.log({[event.target.name]: event.target.value});
        const formDataCopy = JSON.parse(JSON.stringify(formData))

        formDataCopy[event.target.name].value = event.target.value

        if (event.target.name === 'fill_exchange_cost' && currentField.current === 'fill_exchange_cost') {
            if (event.target.value) {
                formDataCopy['fill_sum_account'].editable = true
                formDataCopy['fill_sum_contract_vat'].editable = true

                formDataCopy['fill_sum_account'].value = Number((x.current / event.target.value).toFixed(2))
            } else {
                formDataCopy['fill_sum_account'].editable = false
                formDataCopy['fill_sum_account'].value = 0
                formDataCopy['fill_sum_contract_vat'].editable = false
                formDataCopy['fill_sum_contract_vat'].value = 0
                formDataCopy['fill_sum_contract'].value = 0
                x.current = 0
            }
        }

        if (event.target.name === 'fill_sum_contract_vat' && currentField.current === 'fill_sum_contract_vat') {  // EDITABLE MEANS THAT WE ALREADY HAVE fill_exchange_cost VALUE
            console.log('inside if');
            if (formDataCopy['fill_vat'].value === 0.1667) {
                formDataCopy['fill_sum_contract'].value = Number((formDataCopy['fill_sum_contract_vat'].value * (1 - 0.1667)).toFixed(2))
            } else {
                formDataCopy['fill_sum_contract'].value = Number((formDataCopy['fill_sum_contract_vat'].value / (1 + formDataCopy['fill_vat'].value)).toFixed(2))
            }
            x.current = formDataCopy['fill_sum_contract'].value

            if (formDataCopy['fill_commission'].value > 0) x.current = formDataCopy['fill_sum_contract'].value / (1 + formDataCopy['fill_commission'].value)
            else if (formDataCopy['fill_discount'].value > 0) x.current = formDataCopy['fill_sum_contract'].value / (1 - formDataCopy['fill_discount'].value)
            else if (formDataCopy['fill_cashback'].value > 0) x.current = formDataCopy['fill_sum_contract'].value * (1 + formDataCopy['fill_cashback'].value)
            console.log(x.current);
            if (!!x.current) {
                console.log('qweqwr');
                formDataCopy['fill_sum_account'].value = Number((x.current / formDataCopy['fill_exchange_cost'].value).toFixed(2))
            }
        }

        if (event.target.name === 'fill_sum_account' && currentField.current === 'fill_sum_account') {   // EDITABLE MEANS THAT WE ALREADY HAVE fill_exchange_cost VALUE
            console.log('fill_sum_account IS CHANGING')
            x.current = formDataCopy['fill_sum_account'].value * formDataCopy['fill_exchange_cost'].value

            if (formDataCopy['fill_commission'].value > 0) {
                console.log('1');
                formDataCopy['fill_sum_contract'].value = Number((x.current * (1 + formDataCopy['fill_commission'].value)).toFixed(2))
            } else if (formDataCopy['fill_discount'].value > 0) {
                console.log('2');
                formDataCopy['fill_sum_contract'].value = Number((x.current * (1 - formDataCopy['fill_discount'].value)).toFixed(2))
            } else if (formDataCopy['fill_cashback'].value > 0) {
                console.log('3');
                formDataCopy['fill_sum_contract'].value = Number((x.current / (1 + formDataCopy['fill_cashback'].value).toFixed(2)))
            } else {
                formDataCopy['fill_sum_contract'].value = Number(x.current)
            }

            if (formDataCopy['fill_vat'].value === 0.1667) {
                formDataCopy['fill_sum_contract_vat'].value = Number((formDataCopy['fill_sum_contract'].value / (1 - 0.1667)).toFixed(2))
            } else {
                formDataCopy['fill_sum_contract_vat'].value = Number((formDataCopy['fill_sum_contract'].value * (1 + formDataCopy['fill_vat'].value)).toFixed(2))
            }
        }

        setFormData(formDataCopy)
    }

    // console.log(formData);

    const checkFormForCorrectness = formData => Object.keys(formData).every(accessor => typeof formData[accessor] === 'string' || !formData[accessor].required || !formData[accessor].editable || !!String(formData[accessor].value))
    const handleSubmit = useCallback(async event => {
        // console.log(event);
        event && event.preventDefault()
        console.log('submitting...');
        try {
            const areAllFieldsFilled = checkFormForCorrectness(formData)
            if (!areAllFieldsFilled) {
                renderFeedback('danger', 'Пожалуйста, заполните все обязательные поля!')
                return
            }
            setLoading(true)
            const response = await axios.post(url, {...formData})
            // console.log(response.data);
            renderFeedback('success', response.data.message || 'Success')
            setPopupFill({visible: false, data: {}, url: null})
            // setTimeout(() => {
            //     window.location.reload();
            // }, 500)
            // history.go(0)
        } catch (error) {
            // console.log(error.message, error.response.data.message);
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        } finally {
            setLoading(false)
        }
    }, [formData, renderFeedback, setPopupFill, url])

    useEffect(() => {
        function handleEscKeyClick(event) {
            if (loading) return
            const key = event.key; // const {key} = event; in ES6+
            if (key === "Escape") {
                setPopupFill({visible: false, data: {}, url: null})
            }

            if (key === 'Enter') {
                console.log(event.target.classList.contains('text__input'))
                if (event.target.classList.contains('text__input')) return
                handleSubmit()
            }
        }

        document.addEventListener('keydown', handleEscKeyClick);

        return () => document.removeEventListener('keydown', handleEscKeyClick)
    }, [setPopupFill, handleSubmit, loading])

    const closePopup = () => setPopupFill({visible: false, data: {}})

    if (loading) return (
        <div className="popup-container popup-fill-kpi background">
            <div className="popup" style={{width: '90%'}} onDoubleClick={event => event.stopPropagation()}>
                <LoaderComponent text="Отправляется..."/>
            </div>
        </div>
    )

    return (
        <div className="popup-container popup-fill-billing background" onDoubleClick={closePopup}>
            <div className="popup" style={{width: '90%'}} onDoubleClick={event => event.stopPropagation()}>
                {/* <div className="popup-outer"> */}
                <h2 className="popup__title">{title}</h2>
                <div className="popup__close-btn-container" onClick={closePopup}>
                    <CloseButton/>
                </div>
                <div className="popup__content">
                    <p className="popup__form__subtitle" style={{marginBottom: '1rem'}}>{formData['Title']}</p>
                    <form autoComplete="off" className="popup__form" style={{overflow: 'unset'}}>
                        {Object.keys(formData).map((accessor, index) => {

                            if (formData[accessor] instanceof String || typeof formData[accessor] === 'string') {
                                return null
                            }
                            const {hint, label, type, value, options, editable, required, sign} = formData[accessor]
                            // console.log(formData[accessor]);
                            const FormField = formFields[type]
                            return <FormField parentComponent='PopupFillBilling' key={accessor} label={label}
                                              name={accessor} sign={sign} value={value} options={options}
                                              editable={editable} required={required} hint={hint}
                                              onChange={handleFieldChange} onFocus={() => handleFocusChange(accessor)}/>
                        })}
                    </form>
                    <div className="popup__hr"/>
                    {/* <h3 className="popup__fill-total">
                    <span className="popup__fill-total__label">Итого:</span>
                    <span className="popup__fill-total__value">{sum}</span>
                </h3> */}
                    {visible && <button type="submit" className="popup__submit-btn button"
                                        onClick={handleSubmit}>{text}</button>}
                </div>
            </div>
        </div>
    )
}
