import axios from 'axios';
import React, {useCallback, useEffect, useState} from 'react'
import {useHistory, useLocation, useParams} from 'react-router';
import ClientInfo from '../../components/layout/client-info/ClientInfo'
import {useGlobalContext} from '../../store/context/context';
import './provider.css'
import {LoaderComponent} from '../../components/layout/loader/Loader';
import {TableV} from '../../components/layout/table/TableV';


const Provider = () => {
    const {setPopup, LLPs, setLLPs, setShowFeedback, setServerError, renderFeedback} = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    // const { response: llps, error: llpError, loading: llpLoading } = useAxios('properties/llps/')

    const {id} = useParams()
    const location = useLocation();

    const history = useHistory()

    useEffect(() => {
        const getLLPs = async () => {
            console.log('getting list of LLPs...');
            console.log(location.search);
            try {
                const response = await axios.get('properties/llps/')
                setLLPs(response.data)
                // console.log(response.data);  // list of LLPs
                history.replace({
                    // pathname: `/finance/providers/${client_id}/contracts?LLP=${encodeURIComponent(response.data[0])}`,
                    pathname: `/finance/providers/${id}/contracts`,
                    search: location.search || `?LLP=${encodeURIComponent(response.data[0])}`,
                    // state: { llps: response.data }
                })
            } catch (error) {
                console.log(error);
            }
        }
        if (!LLPs.length) {
            getLLPs()
        } else {
            console.log('we have llps');
        }
    }, [LLPs, id, setLLPs, location.search, history])


    const [table, setTable] = useState({})
    const [client, setClient] = useState({name: ''})
    const [loadingClient, setLoadingClient] = useState(true)
    const [loadingTable, setLoadingTable] = useState(true)
    const [errorClient, setErrorClient] = useState(null)
    const [errorTable, setErrorTable] = useState(null)

    const getClient = useCallback(async () => {
        try {
            setLoadingClient(true)
            const response_client = await axios.get(`finance/providers/${id}/`)
            setClient(response_client.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorClient(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})

            // setErrorClient(error.response?.data|| 'Server error')
        } finally {
            setLoadingClient(false)
        }
    }, [id, setServerError])
    const getTable = useCallback(async () => {
        try {
            setLoadingTable(true)
            const response_table = await axios.get(`${location.pathname}/${location.search}`)
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})

            // setErrorTable(error.response?.data|| 'Server error')
        } finally {
            setLoadingTable(false)
        }
    }, [location.pathname, location.search, setServerError])

    useEffect(() => {
        getClient()
    }, [getClient])
    useEffect(() => {
        if (!location.search) return
        getTable()
    }, [getTable, location.search])

    const getUrl = useCallback((root = false) => {
        if (root) {
            if (location.pathname.lastIndexOf('/') === 0) return location.pathname.substring(location.pathname.lastIndexOf('/') + 1) // /clients ==>> clients
            return location.pathname.substring(1, location.pathname.indexOf('/', 1))  // i.e. /clients/id/brands ==>> clients
        }
        return location.pathname.substring(location.pathname.lastIndexOf('/') + 1)  // i.e. /clients/id/brands ==>> brands   OR   /clients ==>> clients
    }, [location.pathname])

    const {setTopbarData, setActiveTopbarLink, setBreadcrumbs, setActiveSidebarLink} = useGlobalContext()

    useEffect(() => {
        // const parentURL = getUrl('root')
        console.log(location.pathname.substring(1));  // clients
        setActiveSidebarLink('finance/providers')
    }, [setActiveSidebarLink, getUrl, location.pathname])

    useEffect(() => {
        setActiveTopbarLink(location.pathname + decodeURI(location.search))  // `/clients/${id}/brands`
    }, [setActiveTopbarLink, location.pathname, location.search])

    useEffect(() => {
        if (!LLPs.length) return
        // const { llps } = location.state
        console.log(LLPs);
        setTopbarData({
            links: LLPs?.map(llp => {
                return {value: llp, url: `${location.pathname}?LLP=${llp}`}
            }),
            sublinks: null
        })
    }, [setTopbarData, id, location.pathname, location.search, LLPs])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/finance/providers', breadcrumb: 'Поставщики'},
            // { path: `/clients/${id}`, breadcrumb: `${client.name}` },  // in case we nadd page 'client'
            {path: `${location.pathname}`, breadcrumb: `${table.title || ''}`},  // links[getURL()] WAS HERE PREVIOUSLY
        ])
    }, [setBreadcrumbs, id, location.pathname, client.name, getUrl, table.title])


    // useEffect(() => {
    //     // console.log(location.search);
    //     if (!location.search) return
    //     const fetchData = async () => {
    //             setLoading(true)
    //         try {
    //             const request_client = axios.get(`finance/providers/${id}/`)
    //             const request_table = axios.get(`${location.pathname}/${location.search}`)
    //             const [response_client, response_table] = await axios.all([request_client, request_table])
    //             console.log('request is send in provider');
    //             setClient(response_client.data)
    //             setTable(response_table.data)
    //             setError(null)
    //         } catch (error) {
    //             console.log(error.response.data);
    //             console.log(error.message);

    //             setError(error.message)
    //             // console.log(error.response.data);
    //         } finally {
    //             setLoading(false)
    //         }
    //     }
    //     fetchData()
    // }, [id, location.pathname, location.search])

    const getText = () => {
        switch (table.title) {
            case 'Бренды':
                return 'бренд'
            case 'Договоры':
                return 'договор'
            case 'Аккаунты':
                return 'аккаунт'
            case 'АВР':
                return 'АВР'
            default:
                return ''
        }
    }
    const buttons = [
        {
            text: `Добавить ${getText()}`,
            icon: 'plus',
            url: `${location.pathname}/new/`,
            popup_title: 'Договор',
            popup_callback() {
                // getClient()
                getTable()
            }
        },
    ]

    useEffect(() => {  // ON LOCATION.SEARCH CHANGE IF POPUP IS OPEN THEN CLOSE IT AUTOMATICALLY
        setPopup({
            visible: false,
            data: {},
            url: null,
            title: ''
        })
    }, [location.pathname, setPopup, location.search])
    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
        try {
            const url = `${location.pathname}/${request_id}/edit/`
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать договор с поставщиком',
                callback() {
                    // getClient()
                    getTable()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- ROW CLICK STOP ---------------------

    // ----------------- EDIT CLIENT START ---------------------
    const editClient = async () => {
        try {
            const url = `finance/providers/${id}/edit/`
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать поставщика',
                callback() {
                    getClient()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- EDIT CLIENT STOP ---------------------

    if (!LLPs.length) return <div>Loading LLPs...</div>

    return (<>
            <div className='provider page'>
                {loadingClient
                    ? <LoaderComponent/>
                    : errorClient
                        ? <div>{errorClient.message}</div>
                        : <ClientInfo inline client={client} editClient={editClient}/>}
                {/* <ClientInfo client={client}/> */}
            </div>

            {loadingTable
                ? <LoaderComponent/>
                : errorTable
                    ? <div>{errorTable.message}</div>
                    : <TableV
                        table={table}
                        buttons={buttons.reverse()}
                        handleRowClick={handleRowClick}
                        refreshData={getTable}
                    />}
        </>
    )
}

export default Provider
