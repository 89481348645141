import axios from 'axios';
import React, {useCallback, useEffect, useState} from 'react'
import {useHistory, useLocation, useParams} from 'react-router';
import ClientInfo from '../../components/layout/client-info/ClientInfo'
import {LoaderComponent} from '../../components/layout/loader/Loader';
import {TableV} from '../../components/layout/table/TableV';
import {useGlobalContext} from '../../store/context/context';
import './workload.css'
import queryString from 'query-string'
import {BarChart} from '../../components/layout/bar-chart/BarChart';

const FIFTH_OPTION = 'Выбрать период'

export const Workload = () => {
    const {
        setTopbarData,
        setActiveTopbarLink,
        setBreadcrumbs,
        setActiveSidebarLink,
        setPopup,
        setShowFeedback,
        renderFeedback,
        setServerError,
        SPECIAL_VALUES
    } = useGlobalContext()

    const [didMount, setDidMount] = useState(false);

    useEffect(() => {
        setDidMount(true);
        return () => setDidMount(false);
    }, [])


    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const {id} = useParams()
    const location = useLocation();
    const history = useHistory()

    const [tables, setTables] = useState([])
    const [client, setClient] = useState({})
    const [loadingClient, setLoadingClient] = useState(true)
    const [loadingTable, setLoadingTable] = useState(true)
    const [errorClient, setErrorClient] = useState(null)
    const [errorTable, setErrorTable] = useState(null)
    // const [chartData, setChartData] = useState({
    //     // workload_user: [],
    //     // workload_weekly: [],
    //     // workload_month: [],
    //     // workload_project: [],
    //     // workload_void: [],
    // })

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)
    const [dateFields, setDateFields] = useState({
        period: {
            label: 'за',
            value: (new queryString.parse(location.search)).period.replaceAll('_', ' ') || '',
            type: 'select_field',
            options: [
                'Последние 7 дней',
                'Последние 14 дней',
                'Последние 30 дней',
                'Текущий месяц',
                'Прошлый месяц',
                FIFTH_OPTION,
            ],
        },
        // date_from: {
        //     label: 'c',
        //     value: response.data.date_from || '',
        // },
        // date_to: {
        //     label: 'по',
        //     value: response.data.date_to || '',
        // },
    })

    const getWorkloadChartData = ({headers, rows, title}) => {
        const workload_chart_data = {
            // labels: [],
            // datasets: []
        }
        let workload_chart_data_labels = []
        const accessors_for_chart_bar_datasets = title === SPECIAL_VALUES.WORKLOAD_TABLE_TITLE_FOR_CHART ? ['workload_weekly', 'workload_month', 'workload_project', 'workload_void'] : Object.keys(headers).slice(1)
        const accessor_for_chart_bar_labels = title === SPECIAL_VALUES.WORKLOAD_TABLE_TITLE_FOR_CHART ? 'workload_user' : 'task_name'

        // console.log(accessors_for_chart_bar, headers);
        // {
        //     // label: 'Еженедельные',
        //     label: chartData.workload_weekly[0].label,
        //     data: getVisibleLabels(chartData.workload_weekly),
        //     borderColor: 'rgb(34, 194, 146)',
        //     backgroundColor: 'rgba(87, 250, 201, .5)',
        //   },
        rows.forEach((row, index) => {

            accessors_for_chart_bar_datasets.forEach(accessor => {
                // console.log({title, special: SPECIAL_VALUES.WORKLOAD_TABLE_TITLE_FOR_CHART, accessor, row, index});

                // if (accessor === 'workload_user') {
                const new_bar_data = {
                    // id: row.request_id.value,
                    id: index,
                    text: row[accessor].value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? 0 : row[accessor].value,
                    visible: true,
                    label: headers[accessor].label,
                }
                workload_chart_data[accessor] = workload_chart_data[accessor] ? [...workload_chart_data[accessor], new_bar_data] : [new_bar_data]
                // }
                // workload_chart_data[accessor] = workload_chart_data[accessor] ? [...workload_chart_data[accessor], row[accessor].value] : [row[accessor].value]
            })
            const new_bar_data_label = {
                id: index,
                text: row[accessor_for_chart_bar_labels].value,
                visible: true,
                label: headers[accessor_for_chart_bar_labels].label,
            }
            workload_chart_data_labels = workload_chart_data_labels.length ? [...workload_chart_data_labels, new_bar_data_label] : [new_bar_data_label]
        })
        // console.log(workload_chart_data, workload_chart_data_labels);
        return {
            datasets: workload_chart_data,
            labels: workload_chart_data_labels,
        }
    }

    const getClient = useCallback(async () => {
        try {
            if (!!Object.keys(client).length) return

            setLoadingClient(true)
            const response_client = await axios.get(`properties/departments/${id}/`)
            setClient(response_client.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorClient(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorClient(error.response?.data|| 'Server error')
        } finally {
            setLoadingClient(false)
        }
    }, [id, client, setServerError])
    const getTable = useCallback(async () => {
        try {
            if (!!Object.keys(tables).length) return

            setLoadingTable(true)
            const response_table = await axios.get(`${location.pathname}/${location.search.replaceAll('_', ' ')}`)

            // setChartData(getWorkloadChartData(response_table.data.rows));
            setTables(response_table.data)
        } catch (error) {
            console.log(error);
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
        } finally {
            setLoadingTable(false)
        }
    }, [location.pathname, location.search, tables, setServerError])
    useEffect(() => {
        getClient()
    }, [getClient])
    useEffect(() => {
        getTable()
    }, [getTable])

    useEffect(() => {
        setActiveSidebarLink('properties/departments')
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setActiveTopbarLink(location.pathname)  // `/clients/${id}/brands`
    }, [setActiveTopbarLink, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [
                {value: 'Должности', url: `/properties/departments/${id}/positions`},
                {value: 'Загруженность', url: `/properties/departments/${id}/workload`},
            ],
            sublinks: null
        })
    }, [setTopbarData, id])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/properties/departments', breadcrumb: 'Отделы'},
            {path: `${location.pathname}`, breadcrumb: `Отчеты`},  // links[getURL()] WAS HERE PREVIOUSLY
        ])
    }, [setBreadcrumbs, id, location.pathname, client.name, tables])

    const handleFieldChange = event => {
        // console.log({[event.target.name]: event.target.value});
        if (event.target.value === FIFTH_OPTION) {
            setDateFields({
                ...dateFields,
                [event.target.name]: {...dateFields[event.target.name], value: event.target.value},
                date_from: {
                    label: 'c',
                    value: '',
                },
                date_to: {
                    label: 'по',
                    value: '',
                }
            })
        } else if (event.target.name === 'date_from' || event.target.name === 'date_to') {
            setDateFields({
                ...dateFields,
                [event.target.name]: {...dateFields[event.target.name], value: event.target.value},
            })
        } else {
            setDateFields({
                [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
            })
        }
    }

    const handleDateSubmitButtonClick = async () => {
        try {
            let queryParams = ''
            if (dateFields.period.value === FIFTH_OPTION) {
                if (!dateFields.date_from.value) {
                    return renderFeedback('danger', 'Выберите дату начала')
                } else if (!dateFields.date_to.value) {
                    return renderFeedback('danger', 'Выберите дату конца')
                } else if (!!dateFields.date_from.value && !!dateFields.date_to.value) {
                    queryParams = `?period=${dateFields.date_from.value}-${dateFields.date_to.value}`
                }
            } else {
                queryParams = `?period=${dateFields.period.value}`
            }

            setLoadingOnDateSubmit(true)

            const response_table = await axios.get(location.pathname + '/' + queryParams)

            // setChartData(getWorkloadChartData(response_table.data.rows));
            setTables(response_table.data)

            history.replace(location.pathname + queryParams.replaceAll(' ', '_'))
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }

            renderFeedback('danger', error.response?.data.message || 'Server Error')
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }

    const buttons = [
        // {
        //     text: `Добавить должность`,
        //     icon: 'plus',
        //     url: `${location.pathname}/new/`,
        //     popup_title: 'Новая должность',
        //     popup_callback() {
        //         getClient()
        //         getTable()
        //     }
        // },
    ]

    useEffect(() => {  // ON LOCATION.PATHNAME CHANGE IF POPUP IS OPEN THEN CLOSE IT AUTOMATICALLY
        setPopup({
            visible: false,
            data: {},
            url: null,
            title: ''
        })
    }, [location.pathname, setPopup])
    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
        history.push({
            pathname: `${location.pathname}/${request_id}/users/tasks/weekly`,
        })
    }
    // ----------------- ROW CLICK STOP ---------------------

    // ----------------- EDIT CLIENT START ---------------------
    const editClient = async () => {
        const url = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit/`
        try {
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать отдел',
                callback() {
                    getClient()
                    getTable()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- EDIT CLIENT STOP ---------------------

    if (!didMount) {
        return null;
    }

    return (<>
            <div className='workload page'>
                {loadingClient ? <LoaderComponent/> : errorClient ? <div>{errorClient.message}</div> :
                    <ClientInfo inline client={client} editClient={editClient}/>}
            </div>

            {loadingTable
                ? <LoaderComponent/>
                : errorTable
                    ? <div>{errorTable.message}</div>
                    : loadingOnDateSubmit
                        ? <LoaderComponent text='Loading on date submit...'/>
                        : tables.map((table, idx) => (
                            <React.Fragment key={table.title}>
                                <TableV
                                    table={table}
                                    date_fields={dateFields}
                                    onDateFieldsChange={handleFieldChange}
                                    handleDateSubmitButtonClick={handleDateSubmitButtonClick}
                                    buttons={buttons.reverse()}
                                    handleRowClick={handleRowClick}
                                    refreshData={getTable}
                                    href={{
                                        from: location.pathname,
                                        to: 'users/tasks/weekly'
                                    }}
                                />
                                <div style={{marginBottom: 60}}>
                                    <BarChart chartData={getWorkloadChartData(table)} title={table.title}/>
                                </div>
                            </React.Fragment>
                        ))
            }

        </>
    )
}
