import React, {useEffect, useRef, useState} from 'react'
import './number.css'
import NumberFormat from 'react-number-format';
import {useGlobalContext} from '../../../store/context/context';


export const NumberForDateField = ({
                                       value = '',
                                       onChange,
                                       label,
                                       name,
                                       editable = true,
                                       required,
                                       rowClickable = false,
                                       onFocus
                                   }) => {
    const {showFeedback, popup, renderFeedback, setShowFeedback} = useGlobalContext()

    const [focused, setFocused] = useState(!!value?.toString().length ? 'focused filled' : '')
    const [numberValue, setNumberValue] = useState(value)  // BEFORE THERE WASN'T THIS LINE#11 AND LINE#13 AND LINE#37 WAS LIKE THIS => value={value}
    const numberRef = useRef(null)

    useEffect(() => {
        if (!editable) setFocused(value === 0 || !!value?.toString().length ? 'focused filled' : '')

        // parentComponent === 'PopupFillBilling' && setNumberValue(value)  // IF CALLED INSIDE TZ IN EDIT MODE THIS CAUSES RERENDER BUT WE NEED IT TO CORRECTLY SHOW VALUE OF EDITABLE NUMBER FIELD WHEN CHANGED BY FORCE IN POPUP_FILL_BILLING THUS WE CHECK HERE
        setNumberValue(value)  // ___PREVIOUSLY___ THE ABOVE LINE WAS RELEVANT BUT WHEN DOING КУРС ВАЛЮТ BUTTON THIS ISSUE SOMEHOW CEASED
    }, [value, editable])

    const handleOnChange = values => {
        // console.log(values);
        setNumberValue(values.value)

        // if (values.value.length !== 8 && values.value.length !==0) return
        if (values.value.length === 0) {
            // console.log(numberRef.current);
            // numberRef.current.style.outlineColor = 'red'
            renderFeedback('danger', 'Нельзя удалять дату, пожалуйста, заполните поле для даты!')
            return
        }
        if (values.value.length !== 8) return

        // numberRef.current.style.outlineColor = 'blue'  // NEED TO FIND THE RIGHT COLOR FOR FOCUSED STATE

        let formattedValue = values.formattedValue.replace(/\//g, '.')
        const event = {
            target: {
                name,
                value: values.formattedValue !== undefined ? formattedValue : ''  // OTHERWISE IT IS EQUAL TO undefined AND !!String(undefined) = true AND IT PASSES THE IF IN POPUP FUNCTION PRIOR TO SENDING REQUEST
            }
        }
        const [MM] = values.formattedValue.split('.')

        if (MM > 12) return renderFeedback('danger', 'Введите правильную дату')
        else setShowFeedback(false)

        onChange && editable && onChange(event);  // DUE SORT AND FILTER CAUSING INFINITE RERENDER
    }
    const handleFormFieldClick = event => rowClickable && event.stopPropagation()

    const handleBlur = (event) => setFocused(!!event.target.value.toString().length ? 'focused filled' : '')  // IF value = 0 THEN THE LABEL GOES DOWN AND COVERS THE NUMBER FIELD
    return (
        <div className={`number form-field ${editable ? 'editable' : 'uneditable'}`} onClick={handleFormFieldClick}>
            <label htmlFor={name} className={`text__label ${focused}`}
                   style={{display: !label && "none"}}>{label}</label>
            <NumberFormat
                id={name}
                name={name}
                value={numberValue}
                disabled={!editable}
                format="##/##/####"
                placeholder="DD/MM/YYYY"
                mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}

                onValueChange={handleOnChange}
                onFocus={() => {
                    setFocused('focused');
                    onFocus && onFocus()
                }}
                onBlur={handleBlur}

                style={{borderColor: editable && required && !value && showFeedback && popup.visible ? 'red' : ''}}
                className="text__input"
                // ref={numberRef}
                getInputRef={elm => numberRef.current = elm}

                // autoComplete="off"
                // allowNegative={false}  // TRUE BY DEFAULT
                // prefix={'$'}
                // hintText="Some placeholder"
                // decimalSeparator="."  // DEFAULT VALUE
                // displayType={'text'}  // renders it as a normal text in a span, ONLY readable
                // renderText={(value, props) => <div {...props}>{value}</div>}
                // thousandSeparator={' '}  // CUSTOM THOUSAND SEPARATOR
                // thousandsGroupStyle="thousand"  // DEFAULT VALUE
            />
        </div>
    )
}
