import React, {useCallback, useEffect, useState} from 'react'
import './kpi_archive.css'

import {useGlobalContext} from '../../store/context/context'
import {useHistory, useLocation} from 'react-router'
import axios from 'axios'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import {TableV} from '../../components/layout/table/TableV'

// function useQuery() {
//     return new queryString.parse(useLocation().search);
// }

export const KpiArchive = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        setActiveTopbarLink,
        setShowFeedback,
        setServerError
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const history = useHistory()
    const location = useLocation()

    const [dateFields, setDateFields] = useState({
        // date_from: {
        //     label: 'c',
        //     value: response.data.date_from || '',
        // },
        // date_to: {
        //     label: 'по',
        //     value: response.data.date_to || '',
        // }
    })

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [table, setTable] = useState({})

    const getInitialDataAndReplace = useCallback(async () => {
        console.log('getInitialDataAndReplace called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get(`${location.pathname}/`)
            setTable(response.data)

            if (response.data.date_from && response.data.date_to) {
                setDateFields({
                    date_from: {
                        label: 'c',
                        value: response.data.date_from || '',
                    },
                    date_to: {
                        label: 'по',
                        value: response.data.date_to || '',
                    }
                })
            } else {
                setDateFields([])
            }

            history.replace(location.pathname + `?date_from=${response.data.date_from}&date_to=${response.data.date_to}`)

        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTable(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, history, setServerError])

    const getInitialData = useCallback(async () => {
        console.log('getInitialData called................');
        try {
            setError(false)
            setLoading(true)

            const response = await axios.get(`${location.pathname}/` + location.search)

            setTable(response.data)

            if (response.data.date_from && response.data.date_to) {
                setDateFields({
                    date_from: {
                        label: 'c',
                        value: response.data.date_from || '',
                    },
                    date_to: {
                        label: 'по',
                        value: response.data.date_to || '',
                    }
                })
            } else {
                setDateFields([])
            }

        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTable(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.search, location.pathname, setServerError])

    useEffect(() => {
        if (history.action === 'REPLACE') return  // TABLE DATA IS ALREADY SET
        if (!location.search) return getInitialDataAndReplace()  // ON FIRST VISIT
        getInitialData() // ON PAGE REFRESH
    }, [getInitialData, getInitialDataAndReplace, location, history.action])


    const handleFieldChange = event => {
        console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)
    const handleDateSubmitButtonClick = async () => {
        try {
            setError(false)
            setLoadingOnDateSubmit(true)

            const response = await axios.get(location.pathname + `/?&date_from=${dateFields.date_from.value}&date_to=${dateFields.date_to.value}`)
            setTable(response.data)

            setActiveTopbarLink(location.pathname + `?&date_from=${response.data.date_from}&date_to=${response.data.date_to}`)  // /tzs/kpi?status=Пополнено&date_from=${response.data.date_from}&date_to=${response.data.date_to}
            history.replace(location.pathname + `?&date_from=${response.data.date_from}&date_to=${response.data.date_to}`)

        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }


    useEffect(() => {
        setActiveSidebarLink(location.pathname.substring(1))  // tzs/kpi?status=Активно
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/tzs/fills', breadcrumb: table?.title || '', clickable: false},
        ])
    }, [setBreadcrumbs, table?.title])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    const buttons = [
        // {
        //     text: 'Добавить tz',
        //     icon: 'plus',
        //     url: 'clients/new/',
        //     popup_title: 'клиент',
        // },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        // const request_id = row['request_id'].value
        //row['request_id'].value,
    }
    // ----------------- ROW CLICK START ---------------------

    if (loading) return <LoaderComponent/>
    if (error) return <div>{error.message}</div>
    return (<>
            <div className="kpi-archive page">
            </div>

            {loadingOnDateSubmit
                ? <div>Loading on date submit...</div>
                : <TableV
                    table={table}
                    date_fields={dateFields}
                    buttons={buttons.reverse()}
                    handleRowClick={handleRowClick}
                    onDateFieldsChange={handleFieldChange}
                    handleDateSubmitButtonClick={handleDateSubmitButtonClick}
                    refreshData={getInitialData}
                />
            }
        </>
    )
}
