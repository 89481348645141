import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useLocation, useParams} from 'react-router'
import {useGlobalContext} from '../../store/context/context'
import './user_tasks.css'

import {TableForTasksHead} from '../../components/layout/table/TableForTasksHead'

import axios from 'axios'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import moment from 'moment'
import {TableForMonthlyTasksBody} from '../../components/layout/table/TableForMonthlyTasksBody'

const KEYWORD_TO_DETERMINE_IF_ITS_COMING_FROM_WORKDLOAD_PAGE_OR_ON_MAIN_PAGE = 'workload'

export const UserCurrentTasksStatistics = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        renderFeedback,
        setActiveTopbarLink,
        setServerError,
        SPECIAL_VALUES,
        me
    } = useGlobalContext()

    const location = useLocation()

    const [table, setTable] = useState({})
    const [errorTable, setErrorTable] = useState(null)
    const [loadingTable, setLoadingTable] = useState(true)

    const [totalTable, setTotalTable] = useState(null)

    const [tasks, setTasks] = useState([
        // {label: "Еженедельный", period: "weekly", badge: 0},
        // {label: "Ежемесячный", period: "monthly", badge: 0},
        // {label: "Ежеквартальный", period: "quarterly", badge: 0},
    ])

    const {id} = useParams()
    const {tasksURL, tableURL, activeSidebarLink, breadcrumbs} = useMemo(() => {
        let tasksURL = 'properties/tasks/'
        let tableURL = 'users/tasks/monthly/'
        let activeSidebarLink = 'users/tasks/weekly'
        let breadcrumbs = [
            {path: '/', breadcrumb: 'Главная - Статистика по текущим брендам'},
        ]
        let noTaskMessage = 'У вас нет ежемесячных задач'

        if (location.pathname.includes(KEYWORD_TO_DETERMINE_IF_ITS_COMING_FROM_WORKDLOAD_PAGE_OR_ON_MAIN_PAGE)) {
            tasksURL += `?staff_id=${id}`
            tableURL += `?staff_id=${id}`
            activeSidebarLink = 'properties/departments'
            // '/properties/departments/22/workload?period=Последние_7_дней'
            breadcrumbs = [
                {path: '/properties/departments', breadcrumb: 'Отделы'},
                {
                    path: `${location.pathname.split('/').slice(0, 5).join('/')}?period=Последние_7_дней`,
                    breadcrumb: `Загруженность`
                },  // links[getURL()] WAS HERE PREVIOUSLY
                {path: `${location.pathname}`, breadcrumb: `Задачи сотрудника`},  // links[getURL()] WAS HERE PREVIOUSLY
            ]

            noTaskMessage = 'У этого сотрудника нет ежемесячных задач'
        }
        return {tasksURL, tableURL, activeSidebarLink, breadcrumbs, noTaskMessage}
    }, [id, location.pathname])

    useEffect(() => {
        function handleEscKeyClick(event) {
            const key = event.key; // const {key} = event; in ES6+

            if (key === 'Enter') {
                const focusedElement = document.activeElement
                const focusedColumnAccessor = document.activeElement.name

                const inputs = document.querySelectorAll(`div.table__td__center.${focusedColumnAccessor} input`)

                let indexOfNextInputElementToFocus = [...inputs].findIndex(input => input === focusedElement) + 1;

                if (indexOfNextInputElementToFocus === inputs.length) return  // IF THIS IS THE LAST FOSUCED INPUT ELEMENT JUST DO NOTHING

                inputs[indexOfNextInputElementToFocus].focus()  // ELSE FORCEFULLY FOCUS THE NEXT INPUT ELEMENT
            }
        }

        document.addEventListener('keydown', handleEscKeyClick);

        return () => document.removeEventListener('keydown', handleEscKeyClick)
    }, [])

    // const getTabs = useCallback(async () => {
    //     try {
    //         const response = await axios.get('properties/tasks/')
    //         if (!response.data.length) {
    //             setErrorTable({ message : 'У вас нет ежемесячных задач' })
    //             setLoadingTable(false)
    //             return setTabs(null)
    //         }
    //         setTabs(response.data)
    //     } catch (error) {
    //         if (error.response.status === 500) {
    //             // return setErrorTable({message: error.response.statusText })
    //             return setServerError(true)
    //         }

    //         renderFeedback('danger', error.response?.data.message || 'Server Error')
    //     }
    // }, [renderFeedback, setServerError])

    const getTotal = ({head, body}, sum_subtask_time) => {  // NOT A REQUEST
        const order = Object.keys(head.headers)
        let headers = {}
        order.forEach(accessor => {
            if (accessor === 'children') {
                let children_order = Object.keys(head.headers['children'])
                headers['children'] = {}
                children_order.forEach(child_accessor => {
                    headers['children'][child_accessor] = {
                        ...head.headers['children'][child_accessor],
                        label: head.headers['children'][child_accessor].type === 'number' ? sum_subtask_time : ''
                    }
                })
            } else {
                headers[accessor] = {
                    ...head.headers[accessor],
                    label: body.reduce((acc, currentTable) => accessor === 'task_name' ? 'Итого' : currentTable.headers[accessor].label + acc, 0)
                }
            }
        })

        return {
            type: "total",
            clickable: false,
            headers,
            order,
            rows: [],
        }
    }

    // const getTable = useCallback(async () => {
    //     try {
    //         setLoadingTable(true)
    //         const response = await axios.get(`${location.pathname}/`)
    //         const tasksTable = response.data

    //         if (tasksTable.body.length) {
    //             var sum_subtask_time = 0
    //             tasksTable.body.forEach(table => {
    //                 Object.keys(table.headers.children).forEach((accessor) => {
    //                     if (table.headers.children[accessor].type === 'number' && accessor !== 'request_id') {
    //                         table.headers.children[accessor].label = table.rows.reduce((acc, curr_table) => {
    //                             return acc + curr_table.children.reduce((acc, curr_child) => curr_child[accessor].value === -999999 ? acc : acc + curr_child[accessor].value, 0)
    //                         }, 0)
    //                         sum_subtask_time += table.headers.children[accessor].label
    //                     }
    //                 })
    //             })
    //             setTotalTable(getTotal(tasksTable, sum_subtask_time) )
    //         }

    //         setTable(tasksTable)
    //     } catch (error) {
    //         if (error.response.status === 500) {
    //             // return setErrorTable({message: error.response.statusText })
    //             return setServerError(true)
    //         }
    //         setErrorTable(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
    //         // setErrorTable(error.response?.data.message || 'Server Error')
    //         renderFeedback('danger', error.response?.data.message || 'Server Error')
    //     } finally {
    //         setLoadingTable(false)
    //     }
    // }, [location.pathname, renderFeedback, setServerError])

    // useEffect(() => {
    //     getTabs()
    // }, [getTabs])

    // useEffect(() => {
    //     (tabs && tabs.length) && getTable()
    // }, [getTable, tabs])


    const getTasks = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(tasksURL)
                // setTasks(response.data)

                // const { monthly } = response.data
                // if (!monthly) {
                //     reject({ message: noTaskMessage })
                // }

                resolve(response.data)
            } catch (error) {
                if (error.response.status === 500) {
                    // return setErrorTable({message: error.response.statusText })
                    return setServerError(true)
                }

                reject({message: error.response?.data.message || 'Server Error'})
                renderFeedback('danger', error.response?.data.message || 'Server Error')
            }
        })
    }, [renderFeedback, setServerError, tasksURL])

    const getTable = useCallback(async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(tableURL)

                resolve(response.data)
            } catch (error) {
                if (error.response.status === 500) {
                    // return setErrorTable({message: error.response.statusText })
                    return setServerError(true)
                }

                reject({message: error.response?.data.message || 'Server Error'})
                renderFeedback('danger', error.response?.data.message || 'Server Error')
            }
        })
    }, [renderFeedback, setServerError, tableURL])

    const start = useCallback(async () => {
        try {
            const fetchedTasks = await getTasks()
            setTasks(fetchedTasks)

            setLoadingTable(true)
            const fetchedTable = await getTable()
            if (fetchedTable.body.length) {
                var sum_subtask_time = 0
                fetchedTable.body.forEach(table => {
                    Object.keys(table.headers.children).forEach((accessor) => {
                        if (table.headers.children[accessor].type === 'number' && accessor !== 'request_id') {
                            table.headers.children[accessor].label = table.rows.reduce((acc, curr_table) => {
                                return acc + curr_table.children.reduce((acc, curr_child) => curr_child[accessor].value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? acc : acc + curr_child[accessor].value, 0)
                            }, 0)
                            sum_subtask_time += table.headers.children[accessor].label
                        }
                    })
                })
                setTotalTable(getTotal(fetchedTable, sum_subtask_time))
            }

            setTable(fetchedTable)
        } catch (error) {
            setErrorTable({message: error.message || 'ERROR IN USER_MONTHLY_TASKS'})
        } finally {
            setLoadingTable(false)
        }
    }, [getTasks, getTable, SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD])

    useEffect(() => start(), [start])

    useEffect(() => {
        setActiveSidebarLink(activeSidebarLink)
    }, [setActiveSidebarLink, activeSidebarLink])

    useEffect(() => {
        setActiveTopbarLink('/properties/brandtask/stat_current')  // `/clients/${id}/brands`
    }, [setActiveTopbarLink])

    useEffect(() => {
        if(me && me.show_all_tasks || me && me.department_id === 15){
            setTopbarData({
                links: [
                    {
                        value: "Еженедельные",
                        url: `/users/tasks/weekly`,
                        badge: null
                    },
                    {
                        value: "Ежемесячные",
                        url: `/users/tasks/monthly`,
                        badge: null
                    },
                    {
                        value: "Проектные",
                        url: `/users/tasks/project?status=Создать`,
                        badge: null
                    },
                    {
                        value: "Загруженность",
                        url: `/properties/departments/${me?.department_id}/workload?period=Последние_7_дней`
                    },
                    {
                        value: "Текущие",
                        url: `/properties/brandtask/current`,
                        badge: null
                    },
                    {
                        value: "Статистика текущие",
                        url: `/properties/brandtask/stat_current`,
                        badge: null
                    },
                    {
                        value: "Потенциальные",
                        url: `/properties/brandtask/potential`,
                        badge: null
                    },
                    {
                        value: "Статистика потенциальные",
                        url: `/properties/brandtask/stat_potential`,
                        badge: null
                    },
                    {
                        value: "Дисквалификация",
                        url: `${location.pathname.split('/').slice(0, -1).join('/')}/disqualification`,
                        badge: null
                    },
                ],
                sublinks: null,
            })
        }else{
            if(me && me.department_id === 19 || me && me.department_id === 30
                || me && me.department_id === 26
                || me && me.department_id === 28
            ){
                setTopbarData({
                    links: [
                        {
                            value: "Текущие",
                            url: `/properties/brandtask/current`,
                            badge: null
                        },
                        {
                            value: "Статистика текущие",
                            url: `/properties/brandtask/stat_current`,
                            badge: null
                        },
                        {
                            value: "Потенциальные",
                            url: `/properties/brandtask/potential`,
                            badge: null
                        },
                        {
                            value: "Статистика потенциальные",
                            url: `/properties/brandtask/stat_potential`,
                            badge: null
                        },
                        {
                            value: "Дисквалификация",
                            url: `${location.pathname.split('/').slice(0, -1).join('/')}/disqualification`,
                            badge: null
                        },
                    ],
                    sublinks: null,
                })
            }else{
                setTopbarData({
                    links: [
                        {
                            value: "Еженедельные",
                            url: `/users/tasks/weekly`,
                            badge: null
                        },
                        {
                            value: "Ежемесячные",
                            url: `/users/tasks/monthly`,
                            badge: null
                        },
                        {
                            value: "Проектные",
                            url: `/users/tasks/project?status=Создать`,
                            badge: null
                        },
                        {
                            value: "Загруженность",
                            url: `/properties/departments/${me?.department_id}/workload?period=Последние_7_дней`
                        },
                    ],
                    sublinks: null,
                })
            }
        }

    }, [setTopbarData, location.pathname, me?.department_id])

    useEffect(() => {
        setBreadcrumbs(breadcrumbs)
    }, [setBreadcrumbs, breadcrumbs])

    const handleDateChange = async direction => {
        // console.log(direction);
        try {
            setLoadingTable(true)
            let newStartDate = direction  // HERE direction = DD.MM.YYYY // IT'S COMING FROM DateMUIWeek.jsx (WEEKLY-CALENDAR)

            if (typeof direction === 'number') {  // IF NUMBER, THEN IT'S EITHER 1(FORWARD BTN CLICKED) OR -1(BACK BTN CLICKED)
                const dateMomentObject = moment(table.head.date.start, "DD.MM.YYYY"); // 1st argument - string, 2nd argument - format
                const dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object

                newStartDate = direction === -1 ? moment(dateObject).subtract(1, 'months').format('DD.MM.YYYY') : moment(dateObject).add(1, 'months').format('DD.MM.YYYY')
                // console.log(newStartDate);
            }

            let url = tableURL
            if (location.pathname.includes(KEYWORD_TO_DETERMINE_IF_ITS_COMING_FROM_WORKDLOAD_PAGE_OR_ON_MAIN_PAGE)) url += `&start_date=${newStartDate}`
            else url += `?start_date=${newStartDate}`
            const response = await axios.get(url)
            const tasksTable = response.data

            if (tasksTable.body.length) {
                var sum_subtask_time = 0
                tasksTable.body.forEach(table => {
                    Object.keys(table.headers.children).forEach((accessor) => {
                        if (table.headers.children[accessor].type === 'number' && accessor !== 'request_id') {
                            table.headers.children[accessor].label = table.rows.reduce((acc, curr_table) => {
                                return acc + curr_table.children.reduce((acc, curr_child) => curr_child[accessor].value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? acc : acc + curr_child[accessor].value, 0)
                            }, 0)
                            sum_subtask_time += table.headers.children[accessor].label
                        }
                    })
                })
                setTotalTable(getTotal(tasksTable, sum_subtask_time))
            }

            setTable(tasksTable)
        } catch (error) {
            // setErrorTable(error.response?.data.message || 'Server Error')
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        } finally {
            setLoadingTable(false)
        }
    }

    const [valueType, setValueType] = useState('value')
    const handleSwitchChange = (isChecked) => {
        if (isChecked) {
            return setValueType('value2')
        }
        setValueType('value')
    }


    return (<>
            <div className='tasks monthly-tasks page'>
                {/* {me && <ClientInfo client={me} closeable={false}/>} */}
                {/* {me && <ClientInfo client={me}/>} */}
            </div>

            {/*{loadingTable ? <LoaderComponent/> : !!errorTable ? <div>{errorTable.message}</div> :*/}
            {/*    <div className="tasks" style={{marginBottom: '3rem'}}>*/}
            {/*        <TableForTasksHead*/}
            {/*            table={table.head}*/}
            {/*            handleDateChange={handleDateChange}*/}
            {/*            isMonth={true}*/}
            {/*            refreshData={getTable}*/}
            {/*            handleSwitchChange={handleSwitchChange}*/}
            {/*        />*/}
            {/*        {!!table.body.length && table.body.map(singleTable => (*/}
            {/*            <TableForMonthlyTasksBody*/}
            {/*                key={singleTable.request_id}*/}
            {/*                table={singleTable}*/}
            {/*                setTasksTable={setTable}*/}
            {/*                totalTable={totalTable}*/}
            {/*                setTotalTable={setTotalTable}*/}
            {/*                sum_all={totalTable.headers.children['subtask_time'].label}*/}
            {/*                startDate={table.head.date.start}*/}
            {/*                valueType={valueType}*/}
            {/*            />*/}
            {/*        ))}*/}
            {/*        {totalTable && (*/}
            {/*            <TableForMonthlyTasksBody*/}
            {/*                table={totalTable}*/}
            {/*                add_subtask_btn={false}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*}*/}

        </>
    )
}
