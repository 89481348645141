import React, {useState} from 'react'
import Text from '../../components/forms/text/Text'
import './login.css'
// import { get, post } from '../../utils/js/axios'
import axios from 'axios'
import {useGlobalContext} from '../../store/context/context'

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Login = () => {
    const {setLoggedIn, setFeedbackInfo, setShowFeedback} = useGlobalContext()

    // const [response, setResponse] = useState(undefined);
    // const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        username: '',
        password: ''
    })

    const handleChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    const login = async (event) => {
        event.preventDefault()
        console.log('LOGGING IN...');

        try {
            const areAllFieldsFilled = Object.keys(formData).every(accessor => !!formData[accessor])
            if (!areAllFieldsFilled) {
                throw new Error('Пожалуйста, заполните все поля!')
            }

            setLoading(true)
            // console.log(`${SERVER_URL}/auth/`, JSON.stringify(formData));
            // const response = await axios.post('/auth/', formData)
            const response = await fetch(`${SERVER_URL}/auth/`, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            })

            const data = await response.json()

            if (!response.ok) throw new Error(data.non_field_errors[0]);

            // console.log(data);
            localStorage.setItem('token', data.token)
            axios.defaults.headers.common['Authorization'] = `Token ${localStorage.token}`
            setLoggedIn(true)
        } catch (error) {
            // if (error.response?.status === 500) {
            //     // return setErrorTable({message: error.response.statusText })
            //     return setServerError(true)
            // }

            setFeedbackInfo({type: 'danger', message: error.message})
            setShowFeedback(true)
        } finally {
            setLoading(false)
        }
    }

    // if (loading) {
    //     return <div>Loading...</div>
    // }

    // if (error) {
    //     return <div>Error...
    //         <pre>{JSON.stringify(error, null, 2)}</pre>
    //     </div>
    // }

    // useEffect(() => {
    //     let inputs = document.querySelectorAll('input')
    //     [...inputs].forEach(input => {
    //         console.log(input);
    //     })
    // }, [])

    return (
        <div className="login">
            <h1 className="login__logo"><span className="login__logo__yellow">TDS</span>team</h1>
            <div className="login__center">
                <h2 className="login__center__title">Вход в систему</h2>
                <form className="login__center__form" autoComplete="off">
                    <Text type="text" label="Username" name="username" value={formData.username}
                          onChange={handleChange}/>
                    <Text type="password" label="Password" name="password" value={formData.password}
                          onChange={handleChange}/>
                    <button className="login__center__login-btn button" type="submit" onClick={loading ? () => {
                    } : login}>Войти
                    </button>
                </form>
            </div>
        </div>
    )
}

export default Login
