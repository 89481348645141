import React, {useCallback, useEffect, useRef, useState} from 'react'
import Text from '../text/Text'
import './select.css'
import {ReactComponent as ArrowOpen} from '../../../assets/sidebar-icons/arrow-open.svg'
import {ReactComponent as ArrowClose} from '../../../assets/sidebar-icons/arrow-close.svg'
import {useGlobalContext} from '../../../store/context/context'
import SelectOptions from './SelectOptions'

export const SelectField = ({
                                options: defaultOptions = [{value: '', value_id: ''}],
                                value = '',
                                label,
                                name,
                                onChange,
                                rowClickable,
                                editable = true,
                                required,
                                parent,
                                getOptions,
                            }) => {
    const [showOptionsList, setShowOptionsList] = useState(false)
    const [inputValue, setInputValue] = useState(value || '')
    const [selectedOption, setSelectedOption] = useState(value || '')
    const [searchText, setSearchText] = useState('')
    const selectRef = useRef(null)
    const [options, setOptions] = useState(defaultOptions)


    useEffect(() => {  // ADDED WHEN DOING TARGETINGS SELECT FIELD IN POPUP2
        // if (isFirstRender.current) return isFirstRender.current = false

        setOptions(defaultOptions)
    }, [defaultOptions])

    const lowerCaseString = input => input.toString().toLowerCase()

    const {renderFeedback} = useGlobalContext()

    const handleClickOutside = useCallback(event => {
        // console.log(e.target.parentElement.parentElement.dataset.name );
        // if ((!e.target.classList.contains("select__selected-text") &&
        //     !e.target.classList.contains("select__option") &&
        //     !e.target.classList.contains("select__open-select-button") &&
        //     !e.target.parentElement.parentElement.classList.contains("select__selected-text"))
        // ) {
        if (!selectRef.current.contains(event.target)) {
            console.log('Clicked outside select');
            // CLICKED OUTSIDE THE SELECT
            // THUS CLOSE OPTIONS DIV AND CLEAR SEARCH TEXT (so all options could be seen next time select gets opened)
            setShowOptionsList(false)
            setSearchText('')

            // // AND HANDLE INPUT FIELD VALUE, SEARCH TEXT VALUE AND LABEL STYLES
            const label = selectRef.current.querySelector('label')

            if (lowerCaseString(selectedOption)) {
                label.classList.add('focused', 'filled')
                setInputValue(selectedOption)
            } else {
                console.log('correct');
                label.classList.remove('focused', 'filled')
                setInputValue('')
            }
        }
    }, [selectedOption]);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [handleClickOutside])

    const selectOptionsRef = useRef(null)
    // const transformCalendar = useCallback(() => {
    //     console.log(selectOptionsRef);
    //     let windowBottom, windowLeft, windowRight
    //     if (parent) {
    //         windowBottom = document.querySelector(`.${parent}`).getBoundingClientRect().bottom
    //         // windowLeft = document.querySelector(`.${parent}`).getBoundingClientRect().left
    //         windowRight = document.querySelector(`.${parent}`).getBoundingClientRect().right
    //     } else {
    //         windowBottom = window.innerHeight
    //         windowLeft = window.screenX // window.screenLeft // window.pageXOffset
    //         windowRight = window.innerWidth
    //     }

    //     const textBottom = selectOptionsRef.current.previousSibling.getBoundingClientRect().bottom
    //     // const textLeft = calendarRef.current.getBoundingClientRect().left
    //     const textRight = selectOptionsRef.current.getBoundingClientRect().right

    //     const optionsHeight = selectOptionsRef.current.getBoundingClientRect().height
    //     // const optionsWidth = calendarRef.current.getBoundingClientRect().width

    //     if (windowBottom - textBottom < optionsHeight) {
    //         if (windowRight - textRight < 0) {
    //             selectOptionsRef.current.style.transform = `translateY(${(windowBottom - textBottom) - optionsHeight- 30}px) translateX(${(windowRight - textRight) - 10}px)`
    //         } else {
    //             selectOptionsRef.current.style.transform = `translateY(${(windowBottom - textBottom) - optionsHeight- 30}px) translateX(0px)`
    //         }
    //     } else if (windowBottom - textBottom > optionsHeight) {
    //         if (windowRight - textRight < 0) {
    //             selectOptionsRef.current.style.transform = `translateY(0px) translateX(${(windowRight - textRight) - 10}px)`
    //         } else {
    //             selectOptionsRef.current.style.transform = `translateY(0px) translateX(0px)`
    //         }
    //     }
    // }, [parent])
    // useEffect(() => {
    //     showOptionsList && transformCalendar()
    // }, [showOptionsList, transformCalendar])

    const handleOptionClick = async option => {
        let event
        if (option.hasOwnProperty('value_id')) {
            event = {
                target: {
                    name,
                    value: option.value,
                    value_id: option.value_id
                }
            }
        } else {
            event = {
                target: {
                    name,
                    value: option
                }
            }
        }
        console.log(event.target);

        document.activeElement.name = name
        // if (showOnlyUnselectedOptions) {
        //     setSelectedOptions(event.target.value)
        // }

        if (parent === "table-container") {  // WHEN CHANGING FIELDS INSIDE TABLE CELLS WITHOUT CHANGING REQUEST RIGHT AWAY, ADDING EMPLOYER'S BONUS year, month, bonus TABLE
            try {
                const {message} = await onChange(event)
                console.log(message);

                renderFeedback('success', message || 'Success')

                setShowOptionsList(false)
                setInputValue(event.target.value)
                setSelectedOption(event.target.value)
                setSearchText('')

                const label = selectRef.current.querySelector('label')
                label.classList.add('focused', 'filled')
            } catch (error) {
                console.log(error);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')

            }
        } else { // WHEN CHANGING IN POPUP COMPONENT
            onChange && onChange(event)
            setShowOptionsList(false)
            setInputValue(event.target.value)
            setSelectedOption(event.target.value)
            setSearchText('')

            const label = selectRef.current.querySelector('label')
            label.classList.add('focused', 'filled')
        }
    }

    const handleTextChange = event => {
        setInputValue(event.target.value)
        setSearchText(event.target.value)
    }

    const toggle = () => {
        const label = selectRef.current.querySelector('label')
        const input = selectRef.current.querySelector('input')

        if (!showOptionsList) {
            input.focus()
            label.classList.remove('focused', 'filled')
            label.classList.add('focused')
        } else {
            input.blur()

            setSearchText('')

            if (lowerCaseString(selectedOption)) {
                label.classList.add('focused', 'filled')
                setInputValue(selectedOption)
            } else {
                label.classList.remove('focused', 'filled')
                setInputValue('')
            }
        }

        setShowOptionsList(!showOptionsList)
    }

    const toggleFormFieldBottom = async event => {
        if (!editable) return

        // toggleTableContainerOverflow()

        if (getOptions && !showOptionsList) {
            try {
                // const options = await getOptions(name)
                const options = await getOptions()
                console.log(options);
                setOptions(options)
                toggle()
            } catch (error) {
                console.log(error.message);
            }
        } else {
            toggle()
        }
    }


    return (
        <div className={`select form-field ${editable ? 'editable' : 'uneditable'}`} ref={selectRef}>
            <div onClick={toggleFormFieldBottom} className="form-field__top">
                <Text type="text" label={label} name={name} value={inputValue} onChange={handleTextChange}
                      parentForm="select" rowClickable={rowClickable} editable={editable} required={required}/>
                {!!editable &&
                    <button className="svg-container form-field__open-button" type="button">{showOptionsList ?
                        <ArrowClose/> : <ArrowOpen/>}</button>}
            </div>
            {showOptionsList &&
                <SelectOptions ref={selectOptionsRef} options={options} searchText={searchText}
                               selectedOption={selectedOption} handleOptionClick={handleOptionClick}
                               className="form-field__bottom"/>
            }
        </div>
    )
}

// if (lowerCaseString(inputValue) !== lowerCaseString(selectedOption)) {
//     // inputValue DOES NOT MATCH selectedOption, then clear the input field AND remove additional styles from LABEL
//     setInputValue('')
//     label.classList.remove('focused', 'filled')
// } else if (!lowerCaseString(inputValue)) {
//     // they match, but input field IS empty (i.e. erased with backspace completely), then remove additional styles from LABEL
//     label.classList.remove('focused', 'filled')
// } else {
//     // they match, and input field IS NOT empty, then add additional styles to LABEL
//     label.classList.add('focused', 'filled')
// }
