import React, {useCallback, useEffect, useState} from 'react'
import './weekends.css'

import {useGlobalContext} from '../../store/context/context'
import {useHistory, useLocation} from 'react-router'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import axios from 'axios'
import {TableV} from '../../components/layout/table/TableV'

export const Weekends = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        setActiveTopbarLink,
        renderFeedback,
        setShowFeedback,
        setServerError
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const history = useHistory()
    const location = useLocation()

    const [dateFields, setDateFields] = useState({
        // month: {
        //     label: 'month',
        //     value: response.data.month || '',
        //     type: 'select_field',
        //     options: [],
        // },
        // year: {
        //     label: 'year',
        //     value: response.data.year || '',
        //     type: 'select_field',
        //     options: [],
        // }
    })

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [table, setTable] = useState({})

    const getInitialDataAndReplace = useCallback(async () => {
        console.log('getInitialDataAndReplace called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get('properties/weekends/')
            setTable(response.data)

            if (response.data.year) {
                setDateFields({
                    // month: {
                    //     label: 'месяц',
                    //     value: response.data.month || '',
                    //     type: 'select_field',
                    //     options: Array.from({length: 12}, (val, index) => index + 1)
                    // },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    }
                })
            } else {
                setDateFields([])
            }

            history.replace(location.pathname + `?year=${response.data.year}`)

        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setError(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, history, setServerError])
    // console.log(table);

    const getInitialData = useCallback(async () => {
        console.log('getInitialData called................');
        try {
            setError(false)
            setLoading(true)

            const response = await axios.get('properties/weekends/' + location.search)
            setTable(response.data)

            if (response.data.year) {
                setDateFields({
                    // month: {
                    //     label: 'месяц',
                    //     value: response.data.month || '',
                    //     type: 'select_field',
                    //     options: Array.from({length: 12}, (val, index) => index + 1)
                    // },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    }
                })
            } else {
                setDateFields([])
            }

        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setError(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.search, setServerError])

    useEffect(() => {
        console.log(location, history.action)
        if (history.action === 'REPLACE') return
        if (!location.search) return getInitialDataAndReplace()
        getInitialData()
    }, [getInitialData, getInitialDataAndReplace, location, history.action])


    const handleFieldChange = event => {
        console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)
    const handleDateSubmitButtonClick = async () => {
        console.log('Called........  2');
        try {
            setError(false)
            setLoadingOnDateSubmit(true)

            // const response = await axios.get(location.pathname + `/?month=${dateFields.month.value}&year=${dateFields.year.value}`)
            const response = await axios.get(`properties/weekends/?year=${dateFields.year.value}`)
            setTable(response.data)

            history.replace(location.pathname + `?year=${response.data.year}`)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }

            renderFeedback('danger', error.response?.data.message || 'Server error')
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }

    useEffect(() => {
        setActiveSidebarLink('properties/calendar/holidays')  // NEED TO BE EQUAL WITH THIS VALUE SO THAT SIDEBAR WORKS AS NEEDED
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setActiveTopbarLink(location.pathname)  // `/clients/${id}/brands`
    }, [setActiveTopbarLink, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [
                {value: 'Праздники', url: `/properties/calendar/holidays`},
                {value: 'Weekends', url: `/properties/calendar/weekends`},
            ],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/properties/weekends', breadcrumb: table?.title || ''},
        ])
    }, [setBreadcrumbs, table?.title, location])

    const buttons = [
        {
            text: 'Сохранить',
            icon: 'confirm',
            async onClick(rows) {
                try {
                    const response = await axios.post(`properties/weekends/edit/`, rows)
                    console.log(rows);
                    renderFeedback('success', response.data.message || 'Server Error')
                } catch (error) {
                    renderFeedback('danger', error.response?.data.message || 'Server Error')
                }
            }
        },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {

    }
    // ----------------- ROW CLICK START ---------------------

    if (loading) return <LoaderComponent/>
    if (error) return <div>{error.message}</div>
    return (<>
            <div className="weekends page">
            </div>

            {loadingOnDateSubmit
                ? <LoaderComponent text='Loading on date submit...'/>
                : <TableV
                    table={table}
                    date_fields={dateFields}
                    buttons={buttons.reverse()}
                    handleRowClick={handleRowClick}
                    onDateFieldsChange={handleFieldChange}
                    handleDateSubmitButtonClick={handleDateSubmitButtonClick}
                    refreshData={getInitialData}
                />
            }
        </>
    )
}
