import React, {useCallback, useEffect, useState} from 'react'
import './tzs_billing.css'

import {useGlobalContext} from '../../store/context/context'
import {useHistory, useLocation} from 'react-router'
import axios from 'axios'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import {TableV} from '../../components/layout/table/TableV'

// function useQuery() {
//     // console.log(new URLSearchParams(useLocation().search));
//     return new queryString.parse(useLocation().search);
// }

const TzsBilling = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        setActiveTopbarLink,
        setShowFeedback,
        setServerError
    } = useGlobalContext()

    const history = useHistory()
    const location = useLocation()
    // console.log(location);

    // const query = useQuery()
    // console.log(query);

    // const { response: table, error, loading } = useAxios(location.pathname + '/' + location.search)  // /tzs/kpi + / + ?status=Ожидает

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [table, setTable] = useState({})

    const getInitialData = useCallback(async () => {
        console.log('called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get(location.pathname + '/' + location.search)
            setTable(response.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setError(error.response?.data|| 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, location.search, setServerError])
    useEffect(() => {
        getInitialData()
    }, [setTopbarData, setActiveTopbarLink, getInitialData])


    useEffect(() => {
        setActiveSidebarLink(location.pathname.substring(1))  // tzs/kpi?status=Активно
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setActiveTopbarLink(location.pathname + decodeURI(location.search))  // /tzs/kpi?status=Ожидает
    }, [setActiveTopbarLink, location.pathname, location.search])

    useEffect(() => {
        setTopbarData({
            links: [
                {value: 'Активно', url: `/tzs/billing?status=Активно`},
                {value: 'Приостановлено', url: `/tzs/billing?status=Приостановлено`},
                // {value: 'Завершено', url: `/tzs/kpi/?status=${encodeURI('Завершено')}`},
            ],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/tzs/billing', breadcrumb: table?.title || ''},
            // { path: '/users/:id', breadcrumb: 'id-breadcrumb' },
        ])
    }, [setBreadcrumbs, table?.title])

    const buttons = [
        // {
        //     text: 'Добавить tz',
        //     icon: 'plus',
        //     url: 'clients/new/',
        //     popup_title: 'клиент',
        // },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
        // console.log('CLIENTS: rowclick');
        history.push({
            pathname: `/tzs/billing/${request_id}/view`,

        })
    }
    // ----------------- ROW CLICK START ---------------------

    if (loading) return <LoaderComponent/>
    if (error) return <div>{error.message}</div>
    return (<>
            <div className="tzs-billing page">
            </div>

            <TableV
                table={table}
                buttons={buttons.reverse()}
                handleRowClick={handleRowClick}
                refreshData={getInitialData}
                href={{
                    from: '/tzs/billing',
                    to: 'view'
                }}
            />

        </>
    )
}

export default TzsBilling


// const handleRowClick = async client_id => {
//     console.log('rowclick');
//     try {

//         const requestOne = axios.get(`clients/${client_id}/`)
//         const requestTwo = axios.get(`clients/${client_id}/brands/`)
//         const [responseOne, responseTwo] = await axios.all([requestOne, requestTwo])
//         console.log(responseOne, responseTwo);
//         history.push({
//             pathname: `clients/${client_id}/brands`,
//             state: {
//                 client: {...responseOne.data},
//                 table: {...responseTwo.data}
//             }
//         })
//         console.log(response.data);
//     } catch (error) {
//         console.log(error.message);
//         // console.log(error.response.data);
//     }
// }
