const SPECIAL_NUMBER = -999999

export const formatValue = (value, type, sign = '', is_round = null) => {
    if (type === 'number_field' && typeof value === 'number') {
        if (value === SPECIAL_NUMBER) return ''
        // let number = !!sign ? (+value * 100).toFixed(2) : value  // VALUE FROM BACKEND SOMETIMES COMES AS xx.65000000001 THUS DO value.toFixed(2)
        // let number = !!sign ? (+value * 100).toFixed(2) : String(value).includes('.') ? value.toFixed(2) : value  // VALUE FROM BACKEND SOMETIMES COMES AS xx.65000000001 THUS DO value.toFixed(2)
        let number = !!sign ? (+value * 100).toFixed(2) : !!is_round ? +(value.toFixed(is_round)) : value  // VALUE FROM BACKEND SOMETIMES COMES AS xx.65000000001 THUS DO value.toFixed(2)
        let [whole, decimal] = String(number).split('.')

        let reversed = whole.split('').reverse().join('')
        let arr = []
        for (let i = 0; i < reversed.length; i = i + 3) {
            arr.push(reversed.substring(i, i + 3).split('').reverse().join(''))
        }

        const valueToReturn = decimal ? arr.reverse().join(' ') + '<span class="bold-comma">,</span>' + decimal : arr.reverse().join(' ')
        return !!sign ? `${valueToReturn} %` : valueToReturn
    } else if (type === 'boolean_field') {
        return !!value ? 'Да' : 'Нет'
    }

    return value
}
