import React, { useState, useRef } from 'react'
import axios from 'axios'
import './table_tz.css'

// import {ReactComponent as Plus} from '../../../utils/icons/plus.svg'
// import {ReactComponent as Pencil} from '../../../utils/icons/pencil.svg'
// import {ReactComponent as Book} from '../../../utils/icons/book.svg'
// import {ReactComponent as Parallel1} from '../../../utils/icons/parallel_1.svg'
// import {ReactComponent as Confirm} from '../../../utils/icons/confirm.svg'
// import {ReactComponent as Vector} from '../../../utils/icons/vector.svg'
// import {ReactComponent as Copy} from '../../../utils/icons/copy.svg'
// import {ReactComponent as Analysis} from '../../../utils/icons/Analysis.svg'
// import {ReactComponent as Trash1} from '../../../utils/icons/trash_1.svg'
// import {ReactComponent as Tools} from '../../../utils/icons/tools.svg'

// import { BsArrowRight, BsArrowLeft } from 'react-icons/bs'
// import { MdSend } from 'react-icons/md'
// import { TiArrowBackOutline, TiArrowMaximise } from 'react-icons/ti'
// import { AiOutlineFileSearch } from 'react-icons/ai'

import { useGlobalContext } from '../../../store/context/context'

// import DateField from '../../forms/date/Date'
// import TextField from '../../forms/text/Text'
import RadioField from '../../forms/radio/Radio'
import NumberField from '../../forms/number/Number'
import { useHistory, useLocation } from 'react-router'
import CheckboxField from '../../forms/checkbox/Checkbox'
import TextAreaFieldMU from '../../forms/textarea_mu/TextAreaMU'
import useDebounce from '../../../utils/js/useDebounce'
// import { PopupField } from '../../forms/popup_field/PopupField'
import { CheckboxField2 } from '../../forms/checkbox/Checkbox2'
import DateNumberField from '../../forms/date/DateNumber'
import { SelectPositionedField } from '../../forms/select/SelectPositioned'
import { formatValue } from '../table/formatValue'
// import { BsArrowUpRightSquare, BsBoxArrowUpRight } from 'react-icons/bs'
import { IoIosArrowDropright } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { HintHeader } from '../hint/Hint'


const TableTzTop = ({ table, setTable, buttons=[], handleRowClick, parentComponent, getBody, getFoot, head_request_id, setHead_Request_Id, deleteOptionEnabledInSelect=false }) => {
    const {renderFeedback, setAlert, buttonIcons } = useGlobalContext()

    const history = useHistory()
    const location = useLocation()

    const [columnAccessors] = useState(table.order?.length ? table.order : Object.keys(table.headers))
    const [headers] = useState(table.headers)

    // const [rows, setRows] = useState(table.rows)

    const tableContentRef = useRef(null)
    const tableCaptionContainerRef = useRef(null)

    const [formFields] = useState({
        text_field: TextAreaFieldMU,
        // select_field: PopupField,
        // select_field2: PopupField,
        select_field: SelectPositionedField,
        select_field2: SelectPositionedField,
        // date_field: PopupField,
        date_field: DateNumberField,
        radio_field: RadioField,
        number_field: NumberField,
        checkbox_field: CheckboxField,
        checkbox_field2: CheckboxField2,
        // textarea_field: TextAreaField,
    })

    // const resizeTable = useCallback(() => {
    //     // resizeCells()
    //     const topbar = document.querySelector('.topbar')
    //     const windowHeight = window.innerHeight
    //     const topbarHeight = topbar.getBoundingClientRect().height
    //     const topBottomMargins = mainCenterTopBottomMargin * 2
    //     const tableCaptionContainerHeight = tableCaptionContainerRef.current.getBoundingClientRect().height  // table-caption-container
    //     const newHeight = windowHeight - topbarHeight - topBottomMargins - tableCaptionContainerHeight - 1  // -1 because otherwise appears horizontal scroll bar
    //     tableContentRef.current.style.height = `${newHeight}px`
    //
    // }, [mainCenterTopBottomMargin])

    // const resizeCells = useCallback(() => {
    //     console.log('RESIZE CELLS');
    //     // const tableWidth = document.querySelector('.tables')?.offsetWidth
    //     const tableWidth = tableContentRef.current?.offsetWidth
    //     const numberOfColumns = columnAccessors.length - 1
    //     const cellWidth = tableWidth / numberOfColumns - 18;
    //     console.log(tableWidth, numberOfColumns, cellWidth);
    //     const tableThs = document.querySelectorAll('.table__th');
    //     [...tableThs].forEach(tableTh => {
    //         // tableTh.firstElementChild.style.width = cellWidth > 60 ? cellWidth + "px" : "60px"
    //         // tableTh.firstElementChild.style.width = cellWidth > 100 ? cellWidth + "px" : "150px"
    //     });
    //     // const tableTds = document.querySelectorAll('.table__td')
    //     // [...tableTds].forEach(tableTd => {
    //     //     // tableTd.firstElementChild.style.width = cellWidth > 60 ? cellWidth + "px" : "60px"
    //     //     tableTd.firstElementChild.style.width = cellWidth > 100 ? cellWidth + "px" : "100px"
    //     // })
    // }, [])

    // useEffect(() => {
    //     resizeTable() // ALSO RESIZES CELLS
    //     window.addEventListener('resize', resizeTable)
    //     return () => window.removeEventListener('resize', resizeTable)
    // }, [resizeTable])

    // useEffect(() => {
    //     resizeCells() // ALSO RESIZES CELLS
    //     window.addEventListener('resize', resizeCells)
    //     return () => window.removeEventListener('resize', resizeCells)
    // }, [resizeCells])

    // useEffect(() => {  // RESIZE CELLS ON SIDEBAR OPEN/CLOSE
    //     document.querySelector('.sidebar').addEventListener('transitionend', resizeCells)
    //     return () => document.querySelector('.sidebar').removeEventListener('transitionend', resizeCells)
    // }, [resizeCells])


    // ----------------- BUTTON CLICK START ---------------------
    const handleButtonClick = async ({url, popup_title, feedback, onClickFunction, alert=null}) => {
        // console.log(url, popup_title);
        // try {
        //     const response = await axios.get(url)
        //     // console.log(response.data);
        //     if (feedback) {  // IT MEANS THAT WE DO NOT NEED TO SHOW THE POPUP INSTEAD WE SHOW FEEDBACK
        //         setFeedbackInfo({ type: 'success', message: response.data.message || '' })
        //         setShowFeedback(true)
        //         history.goBack()
        //         // setTimeout(() => {
        //         // }, 2000)
        //         return
        //     }
        //     setPopup({
        //         visible: true,
        //         data: response.data,
        //         url,
        //         title: popup_title[0].toUpperCase() + popup_title.substring(1),
        //     })
        // } catch (error) {
        //     console.log(error.message);
        //     setPopup({
        //         visible: false,
        //         data: {},
        //         url: null,
        //         title: ''
        //     })
        //     if (feedback) {
        //         setFeedbackInfo({ type: 'danger', message: error.message || 'Error!' })
        //         setShowFeedback(true)
        //     }
        // }
        if (alert) {
            setAlert({
                visible: true,
                title: alert.title,
                body: alert.body,
                onSuccessFunction: onClickFunction
            })

            return
        }

        onClickFunction && onClickFunction()
    }
    // ----------------- BUTTON CLICK START ---------------------
    // // ----------------- ROW CLICK START ---------------------
    // const handleRowClick = async (client_id) => {
    //     console.log('rowclick');
    //     history.push(`clients/${client_id}`)
    //     try {
    //         // const response = await axios.get('')

    //     } catch (error) {

    //     }
    // }
    // // ----------------- ROW CLICK START ---------------------
    // const [newRow, setNewRow] = useState({
    //     bonus_year: {
    //         color: "#FFFFFF",
    //         type: "select_field",
    //         value: '',
    //         options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
    //     },
    //     bonus_month: {
    //         color: "#FFFFFF",
    //         type: "select_field",
    //         value: '',
    //         options: Array.from({length: 12}, (val, index) => index + 1)
    //     },
    //     bonus_percent: {
    //         color: "#FFFFFF",
    //         type: "number_field",
    //         value: '',
    //         sign: "%"
    //     },
    // })


    // useDebounce(() => {
    //     const sendRequest = async () => {
    //         try {
    //             console.log(rowToSend);
    //             const response = await axios.post(urlToSend.current, {...rowToSend})
    //             console.log(response.data);
    //         } catch (error) {
    //             console.log(error.message);
    //         } finally {
    //             setRowToSend(null)
    //         }
    //     }

    //     sendRequest()
    // }, 500, [rows])

    const getOptions = accessor => {
        return new Promise(async (resolve, reject) => {
            try {
                const { get_url } = headers[accessor]
                const response = await axios.post(get_url, table.rows[0])
                console.log(response.data);
                const options = response.data
                resolve(options)
                // resolve([1,3,4,5,6,7])
            } catch (error) {
                console.log(error.message);
                reject(error.message)
            }
        })
    }

    // const handleFieldChange = (event, index) => {
    //     const newRows = rows.map((row, i) => {
    //         if (i === index) {
    //             let updatedRow = {...row, [event.target.name]: {...row[event.target.name], value: event.target.value}}
    //             if (event.target.value_id) {
    //                 updatedRow[event.target.name].value_id = event.target.value_id
    //             }
    //             // console.log(updatedRow);
    //             // console.log(headers[event.target.name].set_url);
    //             setRowToSend(updatedRow)
    //             urlToSend.current = headers[event.target.name].set_url
    //             return updatedRow
    //         }
    //         return row
    //     })
    //     setRows(newRows)
    //     console.log(newRows);
    // }

    const [sendRequest, setSendRequest] = useState(false)  // THIS IS USED AS A TRIGGER FOR SENDING REQUEST WHEN FIELD HAS BEEN STOP FROM CHANGING FOR 0.5s
    const [sendRequestImmediately, setSendRequestImmediately] = useState(false)  // THIS IS USED AS A TRIGGER FOR SENDING REQUEST WHEN FIELD HAS BEEN STOP FROM CHANGING FOR 0.5s
    const useDebounceInfo = useRef({
        url: '',
        dataToSend: {},
        row_index: null,
        accessor: ''
    })

    useDebounce(() => {
        const sendRequest = async () => {
            try {
                // setLoading(true)  // BAD IDEA CAUSE THIS FLASHES THE TZ ON EDIT PAGE AS WELL AS BRINGS THE SCROLL TO INITIAL POSITION
                const { url, dataToSend, row_index } = useDebounceInfo.current
                const response = await axios.post(url, {...dataToSend})
                console.log(response.data);

                // setTableHeadRows(tableHeadRows.map((row, i) => i === row_index ? response.data : row))
                setTable({...table, rows: table.rows.map((row, i) => i === row_index ? response.data : row)})
                // updatedTableHeadRows.current =
                // setHead_Request_Id && setHead_Request_Id(tableHeadRows[0].request_id.value)

                // setLoading(false)
            } catch (error) {
                console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            }
        }

        sendRequest()
    }, 500, [sendRequest])  // SEND REQUEST AFTER .5 seconds
    useDebounce(() => {
        console.log('Send Request Immediately...');
        const sendRequest = async () => {
            try {
                // setLoading(true)  // BAD IDEA CAUSE THIS FLASHES THE TZ ON EDIT PAGE AS WELL AS BRINGS THE SCROLL TO INITIAL POSITION
                const { url, dataToSend, row_index, accessor, callback=null } = useDebounceInfo.current
                const response = await axios.post(url, {...dataToSend})
                console.log(response.data);

                // setTableHeadRows(tableHeadRows.map((row, i) => i === row_index ? response.data : row))
                setTable({...table, rows: table.rows.map((row, i) => i === row_index ? response.data : row)})

                callback && callback()  // ----- CURRENTLY COMES FROM SELECT_POSITIONED_FIELD ONLY -----

                // updatedTableHeadRows.current =
                // setHead_Request_Id && setHead_Request_Id(tableHeadRows[0].request_id.value)

                // NEW AVR NEW AVR NEW AVR
                if (parentComponent === 'avr') {

                    if (accessor === 'avr_contract' && !head_request_id) {
                        const new_request_id_value = response.data.request_id.value
                        setHead_Request_Id && setHead_Request_Id(new_request_id_value)

                        const parentPart = location.pathname.substring(0, location.pathname.lastIndexOf('/'))
                        const url = parentPart + '/' + new_request_id_value + '/edit'
                        console.log(url);
                        history.replace({
                            pathname: url
                        })
                    }
                    if ((accessor === 'avr_contract' || accessor === 'avr_type') && !!head_request_id) {
                        getBody && getBody()
                        getFoot && getFoot()
                    }
                }
                // setLoading(false)
            } catch (error) {
                console.log(error.message);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            }
        }

        sendRequest()
    }, 0, [sendRequestImmediately])  // SEND REQUEST IMMEDIATELY

    const handleFieldChange = (event, callback, row_index) => {
        let dataToSend
        let rowsCopy = JSON.parse(JSON.stringify(table.rows))
        dataToSend = rowsCopy[row_index]  // THERE IS ONLY ONE ROW WITH INDEX = 0
        dataToSend[event.target.name].value = event.target.value
        if (event.target.value_id) dataToSend[event.target.name].value_id = event.target.value_id // FOR SELECT FIELD 2

        let url = headers[event.target.name].set_url

        useDebounceInfo.current = {
            url,
            dataToSend,
            row_index,
            accessor: event.target.name,
            callback,
        }

        if (dataToSend[event.target.name].type === 'select_field' || dataToSend[event.target.name].type === 'select_field2' || dataToSend[event.target.name].type === 'date_field') {
            setSendRequestImmediately(!sendRequestImmediately)
        } else {
            setSendRequest(!sendRequest)
        }
    }

    // ----------------- RENDER TABLE BODY START ---------------------
    const getTableBody = () => {
        return (<>
            {table.rows.map((row, idx) => {
                return  (
                    <tr key={idx} className={`table__row ${table.clickable && 'clickable'}`} onClick={() => table.clickable ? handleRowClick(row) : {}}>
                        {/* WHEN FILLS ROW CLICKED, WE NEED TO SEND THE WHOLE ROW, THUS SEND "row" AS A SECOND PARAMETER */}
                        {columnAccessors.map(accessor => {
                            if (!headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER

                            const {value, type, editable, sign} = row[accessor]

                            // const FormField = formFields[type]
                            // const colors_for_statuses = {
                            //     Активно: '#98CF97',
                            //     Ожидает: '#F3D191',
                            //     Завершено: '#CDD6E5',
                            //     Черновик: '#F98383',
                            //     Приостановлено: '#F98383',
                            // }
                            // const STYLES_FOR_STATUS_CELL = {
                            //     backgroundColor: colors_for_statuses[value],
                            //     borderRadius: '5px',
                            //     color: '#fff',
                            // }
                            // return (
                            //     <td key={accessor} className="table__td" >
                            //         <div className={`table__td__center ${accessor}`} style={accessor === 'tz_status' ? STYLES_FOR_STATUS_CELL : {}}>
                            //             {type === 'boolean_field'
                            //             ?
                            //             value === 0 ? 'Нет' : 'Да'
                            //             :
                            //             // <FormField name={accessor} value={value} onChange={handleFieldChange} editable={editable} parent="table-container" initialValue="" pack={packTest} options={formData.checkbox2} rowClickable={table.clickable}/>
                            //             <FormField
                            //                 name={accessor}
                            //                 value={value}
                            //                 editable={editable}
                            //                 rowClickable={table.clickable}
                            //                 sign={sign}
                            //                 popupField={{ type, title: headers[accessor].label}}
                            //                 getOptions={() => getOptions(accessor)}
                            //                 onChange={(event, callback) => handleFieldChange(event, callback, idx)}
                            //                 deleteOptionEnabledInSelect={deleteOptionEnabledInSelect}  // FOR SELECT_POSITIONED_FIELD
                            //             />
                            //             }
                            //         {/* helperText="Выберите все необходимые варианты */}
                            //         </div>
                            //     </td>
                            // )

                            const colors_for_statuses = {
                                Активно: '#98CF97',
                                Ожидает: '#F3D191',
                                Завершено: '#CDD6E5',
                                Черновик: '#F98383',
                                Приостановлено: '#F98383',
                            }
                            const STYLES_FOR_STATUS_CELL = {
                                backgroundColor: colors_for_statuses[value],
                                // borderRadius: '5px',
                                color: '#fff',
                                justifyContent: 'center'
                            }
                            if (editable || type === 'checkbox_field2') {
                                const FormField = formFields[type]
                                return (
                                    <td key={accessor} className="table__td" >
                                        <div className={`table__td__center ${accessor}`} style={accessor === 'tz_status' ? STYLES_FOR_STATUS_CELL : {}}>
                                            <FormField
                                                name={accessor}
                                                value={value}
                                                editable={editable}
                                                rowClickable={table.clickable}
                                                sign={sign}
                                                popupField={{ type, title: headers[accessor].label}}
                                                getOptions={() => getOptions(accessor)}
                                                onChange={(event, callback) => handleFieldChange(event, callback, idx)}
                                                deleteOptionEnabledInSelect={deleteOptionEnabledInSelect}  // FOR SELECT_POSITIONED_FIELD
                                            />
                                        </div>
                                    </td>
                                )
                            }

                            return (
                                <td key={accessor} className="table__td">
                                    <div className={`table__td__center ${accessor} ${type}`} style={accessor === 'tz_status' ? STYLES_FOR_STATUS_CELL : {}} dangerouslySetInnerHTML={{ __html: formatValue(value, type, sign)}}>
                                        {/* {formatValue(value, type, sign)} */}
                                    </div>
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
        </>)
    }
    // ----------------- RENDER TABLE BODY STOP ---------------------


    // ----------------- RENDER TABLE HEADER START ---------------------
    return (
        <div className="table-block table-tz-block">
            <div className="table-caption-container" ref={tableCaptionContainerRef}>
                <span className="table-caption">{table.title}</span>
                {buttons && <div className="btns-container">
                    {buttons.map(button => {
                        return <button key={button.text} className={`button button-2 ${button.icon}`} onClick={() => handleButtonClick(button)}>
                            <span className="svg-container">{buttonIcons[button.icon]}</span>
                            <span>{button.text}</span>
                        </button>
                    })}
                </div>}
            </div>
            <div className="table-container" ref={tableContentRef}>
                {/* <div className="table-container" style={{height: `${tableContainerHeight}px`}} ref={tableContentRef}> */}
                <table className="table">
                    <thead className="table__thead">
                    <tr>
                        {columnAccessors.map(accessor => {
                            const header = headers[accessor]
                            if (!headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                            return (
                                // <th key={idx} className={idx === sortedColumnIndex ? `table__th ${sortOrder}` : "table__th"} onClick={() => sort(idx, header.type)}>
                                <th key={accessor} className="table__th" style={{ width: '1%', minWidth: header.width || '200px' }}>
                                    <div className={`table__th__center ${accessor}`} style={{ paddingLeft: '4px' }}>
                                        <div className="table__th__value" >
                                            {header.label}
                                            {header.label === 'Бренд' && table.client_id
                                                ? <HintHeader hint="Перейти к брендам"><Link to={`/clients/${table.client_id}/brands`}><IoIosArrowDropright /></Link></HintHeader>
                                                : header.label === 'Договор' && table.client_id
                                                    ? <HintHeader hint="Перейти к договорам"><Link to={`/clients/${table.client_id}/contracts`}><IoIosArrowDropright /></Link></HintHeader>
                                                    : null
                                            }
                                        </div>
                                        {/* <button className="svg-container table__th__unfold" onClick={(event) => lengthenColumn(event, idx)}>{unfolded[idx] ? <BsArrowLeft /> : <BsArrowRight />}</button> */}
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {getTableBody()}
                    </tbody>
                </table>
            </div>
        </div>
    )
    // ----------------- RENDER TABLE HEADER STOP ---------------------
}

export default TableTzTop
