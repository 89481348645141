import axios from 'axios';
import React, {useCallback, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router';
import ClientInfo from '../../components/layout/client-info/ClientInfo'
import {LoaderComponent} from '../../components/layout/loader/Loader';
import {TableV} from '../../components/layout/table/TableV';
import {useGlobalContext} from '../../store/context/context';
import './fill.css'
// import { useAxios } from '../../utils/js/useAxios';


const Fill = () => {
    const {
        setTopbarData,
        setActiveTopbarLink,
        setBreadcrumbs,
        setActiveSidebarLink,
        setPopup,
        renderFeedback,
        setShowFeedback,
        setServerError
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const {id} = useParams()
    const location = useLocation();
    // const history = useHistory()


    const [table, setTable] = useState({})
    const [client, setClient] = useState({name: ''})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const fetchData = useCallback(async () => {
        console.log('fill called ....');
        setLoading(true)
        try {
            const response_client_table = await axios.get(`${location.pathname}/${location.search}`)
            setClient(response_client_table.data.info)
            setTable(response_client_table.data.table)
            // setError(null)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})

            // setError(error.response?.data || error || 'Server error')
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, location.search, renderFeedback, setServerError])
    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        setActiveSidebarLink('tzs/fills')
    }, [setActiveSidebarLink])

    useEffect(() => {
        setActiveTopbarLink('')
    }, [setActiveTopbarLink])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData, id])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/tzs/fills?status=Ожидает', breadcrumb: 'Ожидающие пополнения'},
            // { path: `/clients/${id}`, breadcrumb: `${client.name}` },  // in case we nadd page 'client'
            {path: `${location.pathname}`, breadcrumb: `${table.title || ''}`},  // links[getURL()] WAS HERE PREVIOUSLY
        ])
    }, [setBreadcrumbs, id, location.pathname, client.name, table.title])


    const buttons = [
        {
            text: `Пополнить все`,
            icon: 'plus',
            url: `${location.pathname}/accept/${location.search}`,
            popup_title: '',
            feedback: true
        },
    ]

    useEffect(() => {  // ON LOCATION.PATHNAME CHANGE IF POPUP IS OPEN THEN CLOSE IT AUTOMATICALLY
        setPopup({
            visible: false,
            data: {},
            url: null,
            title: ''
        })
    }, [location.pathname, setPopup])

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value

        try {
            // const response = await axios.get(`clients/${id}/contracts/${request_id}/edit/`)
            const response = await axios.get(`tzs/fills/view/${request_id}/?fill_inst_type=${row.fill_inst_type.value}`)
            setPopup({
                visible: true,
                data: response.data,
                url: `tzs/fills/view/${request_id}/decline/`,
                title: 'Пополнения',
                button: {
                    visible: true,
                    text: 'Отклонить'
                },
                callback() {
                    fetchData()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })
            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- ROW CLICK STOP ---------------------

    if (loading) return <LoaderComponent/>
    if (error) return <div>{error.message || 'Server Error'}</div>
    return (<>
            <div className='fill page'>
                {/* <ClientInfo inline client={client} editClient={editClient}/> */}
                <ClientInfo client={client} closeable={false}/>
            </div>

            <TableV
                table={table}
                buttons={buttons.reverse()}
                handleRowClick={handleRowClick}
                refreshData={fetchData}
            />
        </>
    )
}

export default Fill

// const buttons = [
//     {
//         text: 'Новый АВР',
//         icon: 'plus',
//         url: ''
//     },
//     {
//         text: 'Edit',
//         icon: 'edit',
//         url: ''
//     },
//     {
//         text: 'История изменений ТЗ',
//         icon: 'book',
//         url: ''
//     },
// ]

// const client = {
//     name: "Асем Ай",
//     data: [
//         {
//             key: 'Клиент',
//             value: 'Dizzy TOO'
//         },
//         {
//             key: 'БИИ/ИНН/ИИН',
//             value: '"1234567890"'
//         },
//         {
//             key: 'Тип бренда',
//             value: 'KPI'
//         },
//     ]
// }


// useEffect(() => {
//    console.log(location.pathname); // result: '/secondpage'
//    console.log(location.state); // result: 'some_value'
// }, [location]);

// const { response: table, error: tableError, loading: tableLoading } = useAxios(`${location.pathname}/`)
// const { response: client, error: clientError, loading: clientLoading } = useAxios(`clients/${id}/`)
