import React, {useCallback, useEffect, useMemo, useState} from 'react'
import './fills.css'
import queryString from 'query-string'

import {useGlobalContext} from '../../store/context/context'
import {useHistory, useLocation} from 'react-router'
import axios from 'axios'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import {TableV} from '../../components/layout/table/TableV'
import downloadCSV from '../../utils/js/downloadCSV'

// function useQuery() {
//     return new queryString.parse(useLocation().search);
// }

const Fills = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        setActiveTopbarLink,
        setPopup,
        renderFeedback,
        setShowFeedback,
        setServerError
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const history = useHistory()
    const location = useLocation()
    const query = useMemo(() => new queryString.parse(location.search), [location.search])
    // console.log(location);

    // const query = useQuery()
    // console.log(query);

    // const { response: table, error, loading } = useAxios(location.pathname + '/' + location.search)  // /tzs/fills + / + ?status=Ожидает

    const [dateFields, setDateFields] = useState({
        // date_from: {
        //     label: 'c',
        //     value: response.data.date_from || '',
        // },
        // date_to: {
        //     label: 'по',
        //     value: response.data.date_to || '',
        // }
    })

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [table, setTable] = useState({})

    const getInitialData = useCallback(async () => {
        console.log('called................');
        try {
            setError(false)
            setLoading(true)

            let url = location.pathname + '/' + location.search
            if (query.status === 'ClickRu Пополнения') {
                url = '/external_api/clickru/fills/'
            } else if (query.status === 'ClickRu Бюджеты') {
                url = '/external_api/clickru/budgets/'
            }
            const response = await axios.get(url)

            setTable(response.data)

            if (response.data.date_from && response.data.date_to) {
                setDateFields({
                    date_from: {
                        label: 'c',
                        value: response.data.date_from || '',
                    },
                    date_to: {
                        label: 'по',
                        value: response.data.date_to || '',
                    }
                })
            } else {
                setDateFields([])
            }

            setTopbarData({
                links: [
                    {value: 'Ожидает', url: `/tzs/fills?status=Ожидает`},
                    {
                        value: 'Пополнено',
                        url: `/tzs/fills?status=Пополнено&date_from=${response.data.date_from || ''}&date_to=${response.data.date_to || ''}`
                    },
                    {
                        value: 'Отклонено',
                        url: `/tzs/fills?status=Отклонено&date_from=${response.data.date_from || ''}&date_to=${response.data.date_to || ''}`
                    },
                    {value: 'ClickRu Пополнения', url: `/tzs/fills?status=ClickRu Пополнения`},
                    {value: 'ClickRu Бюджеты', url: `/tzs/fills?status=ClickRu Бюджеты`},
                ],
                sublinks: null
            })

            // console.log(query);
            if (query.status === 'Ожидает' || query.status === 'ClickRu Пополнения' || query.status === 'ClickRu Бюджеты') {
                setActiveTopbarLink(location.pathname + `?status=${query.status}`)  // /tzs/kpi?status=Ожидает
                history.replace(location.pathname + `?status=${query.status}`)
            } else {
                setActiveTopbarLink(location.pathname + `?status=${query.status}&date_from=${response.data.date_from}&date_to=${response.data.date_to}`)  // /tzs/kpi?status=Ожидает
                history.replace(location.pathname + `?status=${query.status}&date_from=${response.data.date_from}&date_to=${response.data.date_to}`)
            }
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setError(error.response?.data || 'Server error')
            console.log(error.message);
        } finally {
            setLoading(false)
        }
    }, [location.pathname, location.search, setTopbarData, setActiveTopbarLink, setServerError, query.status])
    useEffect(() => {
        getInitialData()
    }, [getInitialData])

    const handleFieldChange = event => {
        console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)
    const handleDateSubmitButtonClick = async () => {
        console.log('Called........  2');
        try {
            setError(false)
            setLoadingOnDateSubmit(true)

            // const query = new queryString.parse(location.search);
            // console.log(query);
            const response = await axios.get(location.pathname + `/?status=${query.status}&date_from=${dateFields.date_from.value}&date_to=${dateFields.date_to.value}`)

            setTopbarData({
                links: [
                    {value: 'Ожидает', url: `/tzs/fills?status=Ожидает`},
                    {
                        value: 'Пополнено',
                        url: `/tzs/fills?status=Пополнено&date_from=${response.data.date_from || ''}&date_to=${response.data.date_to || ''}`
                    },
                    {
                        value: 'Отклонено',
                        url: `/tzs/fills?status=Отклонено&date_from=${response.data.date_from || ''}&date_to=${response.data.date_to || ''}`
                    },
                    {value: 'ClickRu Пополнения', url: `/tzs/fills?status=ClickRu Пополнения`},
                    {value: 'ClickRu Бюджеты', url: `/tzs/fills?status=ClickRu Бюджеты`},
                ],
                sublinks: null
            })

            if (query.status === 'Ожидает') {
                setActiveTopbarLink(location.pathname + `?status=${query.status}`)  // /tzs/kpi?status=Ожидает
                history.replace(location.pathname + `?status=${query.status}`)
            } else {
                setActiveTopbarLink(location.pathname + `?status=${query.status}&date_from=${response.data.date_from}&date_to=${response.data.date_to}`)  // /tzs/kpi?status=Пополнено&date_from=${response.data.date_from}&date_to=${response.data.date_to}
                history.replace(location.pathname + `?status=${query.status}&date_from=${response.data.date_from}&date_to=${response.data.date_to}`)
            }
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setError(error.message)
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }

    useEffect(() => {
        setActiveSidebarLink(location.pathname.substring(1))  // tzs/kpi?status=Активно
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/tzs/fills', breadcrumb: table?.title || '', clickable: false},
        ])
    }, [setBreadcrumbs, table?.title])

    const buttons = useMemo(() => {
        if (query.status === 'ClickRu Пополнения') {
            return [
                {
                    text: 'Добавить пополнение',
                    icon: 'plus',
                    url: '/external_api/clickru/fills/new/',
                    popup_title: 'Добавить пополнение',
                },
            ]
        }
        if (query.status === 'Пополнено') {
            return [
                {
                    text: 'Скачать',
                    icon: 'download',
                    onClick() {
                        try {
                            downloadCSV(table, table.rows, "Пополнения.csv")
                        } catch (error) {
                            // console.error(error);
                            renderFeedback('danger', error.message || 'Unknown error!')
                        }
                    }
                },
                {
                    text: 'Скачать (фильтр)',
                    icon: 'download',
                    onClick(_, __, ___, ____, filteredRows) {
                        try {
                            downloadCSV(table, filteredRows, "Пополнения (Filtered).csv")
                        } catch (error) {
                            // console.error(error);
                            renderFeedback('danger', error.message || 'Unknown error!')
                        }
                    }
                },
            ]
        }
        return []
    }, [query.status, renderFeedback, table])

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        // const request_id = row['request_id'].value
        //row['request_id'].value,
        // console.log('CLIENTS: rowclick');

        // const query = new queryString.parse(location.search);
        if (query.status === 'Ожидает') {
            // console.log(row);
            const search = `fill_business_id=${row.fill_business_id.value}&fill_account=${row.fill_account.value}&fill_account_currency=${row.fill_account_currency.value}&fill_inst_type=${row.fill_inst_type.value}`
            // console.log(search);
            history.push({
                pathname: `/tzs/fills/view`,
                search
            })
        } else {
            const request_id = row['request_id'].value
            try {
                const url = `/tzs/fills/view/${request_id}/?fill_inst_type=${row.fill_inst_type.value}`
                const response = await axios.get(url)
                setPopup({
                    visible: true,
                    data: response.data,
                    url,
                    title: 'Пополнения',
                    button: {
                        visible: false,
                        text: ''
                    }
                })
            } catch (error) {
                setPopup({
                    visible: false,
                    data: {},
                    url: null,
                    title: ''
                })

                if (error.response.status === 500) return setServerError(true)
                renderFeedback('danger', error.response?.data.message || 'Server Error')
            }
        }
    }
    // ----------------- ROW CLICK START ---------------------

    if (loading) return <LoaderComponent/>
    if (error) return <div>{error.message}</div>
    return (<>
            <div className="fills page">
            </div>

            {loadingOnDateSubmit
                ? <LoaderComponent text='Loading on date submit...'/>
                : <TableV
                    table={table}
                    date_fields={dateFields}
                    buttons={buttons.reverse()}
                    handleRowClick={handleRowClick}
                    onDateFieldsChange={handleFieldChange}
                    handleDateSubmitButtonClick={handleDateSubmitButtonClick}
                    refreshData={getInitialData}
                    paginationEnable={50}
                    href={(() => {
                        let href
                        switch (query.status) {
                            case 'Ожидает':
                                href = {
                                    from: '/tzs/fills/view',
                                    request_id: false,
                                    to: '',
                                    params: ['fill_business_id', 'fill_account', 'fill_account_currency', 'fill_inst_type'],
                                }
                                break
                            default:
                                href = null
                        }
                        return href
                    })()}
                />
            }
        </>
    )
}

export default Fills


// const handleRowClick = async client_id => {
//     console.log('rowclick');
//     try {

//         const requestOne = axios.get(`clients/${client_id}/`)
//         const requestTwo = axios.get(`clients/${client_id}/brands/`)
//         const [responseOne, responseTwo] = await axios.all([requestOne, requestTwo])
//         console.log(responseOne, responseTwo);
//         history.push({
//             pathname: `clients/${client_id}/brands`,
//             state: {
//                 client: {...responseOne.data},
//                 table: {...responseTwo.data}
//             }
//         })
//         console.log(response.data);
//     } catch (error) {
//         console.log(error.message);
//         // console.log(error.response.data);
//     }
// }
