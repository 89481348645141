import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory, useLocation, useParams} from 'react-router'
// import ClientInfo from '../../components/layout/client-info/ClientInfo'
import {useGlobalContext} from '../../store/context/context'
import './user_tasks.css'

// import { TableForWeeklyTasksBody } from '../../components/layout/table/TableForWeeklyTasksBody'
// import { TableForTasksHead } from '../../components/layout/table/TableForTasksHead'
// import { TextField } from '@material-ui/core'
// import TextField from '@mui/material/TextField';
// import { DatePicker } from '@material-ui/pickers'
// import { DesktopDatePicker, LocalizationProvider } from '@mui/lab'
// import AdapterDateFns from '@mui/lab/AdapterDateFns'
// import DateMUIField from '../../components/forms/date/DateMUI'
import axios from 'axios'
import {LoaderComponent} from '../../components/layout/loader/Loader'
// import moment from 'moment'
// import Calendar from 'react-calendar'
// import { TableForMonthlyTasksBody } from '../../components/layout/table/TableForMonthlyTasksBody'
import {TableV} from '../../components/layout/table/TableV'
import {TableForProjectTasksBody} from '../../components/layout/table/TableForProjectTasksBody'
import queryString from 'query-string'

const CREATE_NEW_PROJECT = 'Создать'
const KEYWORD_TO_DETERMINE_IF_ITS_COMING_FROM_WORKDLOAD_PAGE_OR_ON_MAIN_PAGE = 'workload'


export const UserProjectTasks = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        renderFeedback,
        setActiveTopbarLink,
        setActiveTopbarSubLink,
        setServerError,
        setPopup,
        me
    } = useGlobalContext()

    const [didMount, setDidMount] = useState(false);

    useEffect(() => {
        setDidMount(true);
        // setTable(null)
        return () => setDidMount(false);
    }, [])

    const location = useLocation()
    const history = useHistory()

    const [dateFields, setDateFields] = useState({
        // month: {
        //     label: 'month',
        //     value: response.data.month || '',
        //     type: 'select_field',
        //     options: [],
        // },
        // year: {
        //     label: 'year',
        //     value: response.data.year || '',
        //     type: 'select_field',
        //     options: [],
        // }
    })

    const [table, setTable] = useState({})
    const [errorTable, setErrorTable] = useState(null)
    const [loadingTable, setLoadingTable] = useState(true)

    // const [totalTable, setTotalTable] = useState(null)


    const [tasks, setTasks] = useState([
        // {label: "Еженедельный", period: "weekly", badge: 0},
        // {label: "Ежемесячный", period: "monthly", badge: 0},
        // {label: "Ежеквартальный", period: "quarterly", badge: 0},
    ])

    const {id} = useParams()
    const {tasksURL, tableURL, activeSidebarLink, breadcrumbs, addButtonURL} = useMemo(() => {
        let tasksURL = 'properties/tasks/'
        let tableURL = 'users/tasks/project/'
        let activeSidebarLink = 'users/tasks/weekly'
        let breadcrumbs = [
            {path: '/', breadcrumb: 'Главная - Мои задачи'},
        ]

        // let addButton = {
        //     text: 'Добавить проектную задачу',
        //     icon: 'plus',
        //     url: `users/tasks/project/create/`,
        //     popup_title: 'Новая проектная задача',
        //     popup_callback() {
        //         // getTabs()
        //         // // getTable()
        //         // getInitialData()
        //         start()
        //     },
        // }
        let addButtonURL = `users/tasks/project/create/`
        let noTaskMessage = 'У вас нет проектных задач'

        if (location.pathname.includes(KEYWORD_TO_DETERMINE_IF_ITS_COMING_FROM_WORKDLOAD_PAGE_OR_ON_MAIN_PAGE)) {
            tasksURL += `?staff_id=${id}`
            tableURL += `?staff_id=${id}`
            activeSidebarLink = 'properties/departments'
            // '/properties/departments/22/workload?period=Последние_7_дней'
            breadcrumbs = [
                {path: '/properties/departments', breadcrumb: 'Отделы'},
                {
                    path: `${location.pathname.split('/').slice(0, 5).join('/')}?period=Последние_7_дней`,
                    breadcrumb: `Загруженность`
                },  // links[getURL()] WAS HERE PREVIOUSLY
                {path: `${location.pathname}`, breadcrumb: `Задачи сотрудника`},  // links[getURL()] WAS HERE PREVIOUSLY
            ]

            addButtonURL = `users/staff/${id}/projects/new/`

            noTaskMessage = 'У этого сотрудника нет проектных задач'
        }
        return {tasksURL, tableURL, activeSidebarLink, breadcrumbs, addButtonURL, noTaskMessage}
    }, [id, location.pathname])

    useEffect(() => {
        function handleEscKeyClick(event) {
            const key = event.key; // const {key} = event; in ES6+

            if (key === 'Enter') {
                const focusedElement = document.activeElement
                const focusedColumnAccessor = document.activeElement.name

                const inputs = document.querySelectorAll(`div.table__td__center.${focusedColumnAccessor} input`)

                let indexOfNextInputElementToFocus = [...inputs].findIndex(input => input === focusedElement) + 1;

                if (indexOfNextInputElementToFocus === inputs.length) return  // IF THIS IS THE LAST FOSUCED INPUT ELEMENT JUST DO NOTHING

                inputs[indexOfNextInputElementToFocus].focus()  // ELSE FORCEFULLY FOCUS THE NEXT INPUT ELEMENT
            }
        }

        document.addEventListener('keydown', handleEscKeyClick);

        return () => document.removeEventListener('keydown', handleEscKeyClick)
    }, [])

    // const getTabs = useCallback(async () => {
    //     try {
    //         const response = await axios.get('properties/tasks/')
    //         if (!response.data.length) {
    //             // setErrorTable({ message: 'Нет задач' })
    //             setLoadingTable(false)

    //             setTabs(null)
    //             return
    //         }
    //         setTabs(response.data)
    //     } catch (error) {
    //         if (error.response.status === 500) {
    //             // return setErrorTable({message: error.response.statusText })
    //             return setServerError(true)
    //         }

    //         renderFeedback('danger', error.response?.data.message || 'Server Error')
    //     }
    // }, [renderFeedback, setServerError])

    // const getTotal = ({ head, body }) => {  // NOT A REQUEST
    //     const order = head.order

    //     let headers = {}, request_id = 0

    //     order.forEach(accessor => {
    //         headers[accessor] = { ...head.headers[accessor], label: body.reduce((acc, currentTable) => accessor === 'task_name' ? 'Итого' : currentTable.headers[accessor].label + acc, 0) }
    //         request_id = body.reduce((acc, currentTable) => currentTable.request_id * acc, 1)
    //         // if (!!body[0].rows[0][accessor].is_task_name) headers[accessor].label = 'Итого'  // DOES NOT WORK WHEN THERE IS ZERO ROWs
    //     })

    //     return {
    //         request_id,
    //         type: "total",
    //         clickable: false,
    //         headers,
    //         order,
    //         rows: [],
    //     }
    // }

    // const getTable = useCallback(async () => {
    //     try {
    //         setLoadingTable(true)
    //         const response = await axios.get(`${location.pathname}/${location.search}`)

    //         // response.data.body.forEach(table => {
    //         //     Object.keys(table.headers).forEach(accessor => {
    //         //         if (table.headers[accessor].type === 'number') {
    //         //             table.headers[accessor].label = table.rows.reduce((acc, curr) => curr[accessor].value === -999999 ? acc : acc + curr[accessor].value, 0)
    //         //         }
    //         //     })
    //         // })

    //         // setTable({ ...response.data, body: [ ...response.data.body, getTotal(response.data) ] })
    //         setTable(response.data)
    //     } catch (error) {
    //         if (error.response.status === 500) {
    //             // return setErrorTable({message: error.response.statusText })
    //             return setServerError(true)
    //         }
    //         setErrorTable(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
    //         // setErrorTable(error.response?.data.message || 'Server Error')
    //         renderFeedback('danger', error.response?.data.message || 'Server Error')
    //     } finally {
    //         setLoadingTable(false)
    //     }
    // }, [location.pathname, renderFeedback, location.search, setServerError])

    const getInitialDataAndReplace = useCallback(async () => {
        console.log('getInitialDataAndReplace called................');
        try {
            setErrorTable(false)
            // setLoadingTable(true)

            let url = tableURL
            if (location.pathname.includes(KEYWORD_TO_DETERMINE_IF_ITS_COMING_FROM_WORKDLOAD_PAGE_OR_ON_MAIN_PAGE)) url += `&status=Создать`
            else url += `?status=Создать`
            const response = await axios.get(url)
            console.log(response.data, 'CHECK POINT');
            setTable(response.data)

            if (response.data.year) {
                setDateFields({
                    month: {
                        label: 'месяц',
                        value: response.data.month || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1)
                    },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    }
                })
            } else {
                setDateFields([])
            }

            history.replace(location.pathname + `?status=Создать&month=${response.data.month}&year=${response.data.year}`)

        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTable(error.response?.data.message || 'Server error')
        } finally {
            setLoadingTable(false)
        }
    }, [location.pathname, history, setServerError, tableURL])
    // console.log(table);

    const getInitialData = useCallback(async () => {
        console.log('getInitialData called................');
        try {
            setErrorTable(false)
            // setLoadingTable(true)

            let url = tableURL
            if (location.pathname.includes(KEYWORD_TO_DETERMINE_IF_ITS_COMING_FROM_WORKDLOAD_PAGE_OR_ON_MAIN_PAGE)) url += `&${location.search.slice(1)}`
            else url += `?${location.search.slice(1)}`
            const response = await axios.get(url)
            // const response = await axios.get(`${location.pathname}/` + location.search)

            setTable(response.data)

            if (response.data.year) {
                setDateFields({
                    month: {
                        label: 'месяц',
                        value: response.data.month || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1)
                    },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    }
                })
            } else {
                setDateFields([])
            }

        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTable(error.response?.data.message || 'Server error')
        } finally {
            setLoadingTable(false)
        }
    }, [location.search, location.pathname, setServerError, tableURL])

    // useEffect(() => {
    //     getTabs()
    // }, [getTabs])

    // useEffect(() => {
    //     if (tabs && tabs.length) {
    //         getTable()
    //     }
    // }, [getTable, tabs])

    // useEffect(() => {
    //     loadingTable || setTotalTable(getTotal(table))
    // }, [loadingTable, table])

    // useEffect(() => {
    //     const query = new queryString.parse(location.search)
    //     if (query.status === 'Создать') {
    //         if (history.action === 'REPLACE') return  // TABLE DATA IS ALREADY SET
    //         if (query.status === 'Создать' && (!query.month || !query.year)) return getInitialDataAndReplace()  // ON FIRST VISIT
    //         getInitialData() // ON PAGE REFRESH
    //     }
    //     else if (tabs && tabs.length) {
    //         getInitialData() // ON PAGE REFRESH
    //     }
    //     else setErrorTable({ message: 'У вас нет проектных задач'})
    // }, [getInitialData, getInitialDataAndReplace, location, history.action, tabs])

    const getTasks = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(tasksURL)
                // if (!response.data.length) {
                //     reject({ message : 'У вас нет еженедельных задач' })
                // }

                resolve(response.data)
            } catch (error) {
                if (error.response.status === 500) {
                    // return setErrorTable({message: error.response.statusText })
                    return setServerError(true)
                }

                reject({message: error.response?.data.message || 'Server Error'})
                renderFeedback('danger', error.response?.data.message || 'Server Error')
            }
        })
    }, [renderFeedback, setServerError, tasksURL])

    const start = useCallback(async () => {
        try {
            if (history.action === 'REPLACE') return  // TABLE DATA IS ALREADY SET
            setLoadingTable(true)

            const fetchedTasks = await getTasks()
            setTasks(fetchedTasks)
            // const { project } = fetchedTasks
            const query = new queryString.parse(location.search)
            if (query.status === 'Создать') {
                if (!query.month || !query.year) getInitialDataAndReplace()  // ON FIRST VISIT
                else getInitialData() // ON PAGE REFRESH
            }
                // else if (!!project) {
                //     getInitialData() // ON PAGE REFRESH
                // }
                // else {
                //     setErrorTable({ message: noTaskMessage })
                //     setLoadingTable(false)
            // }
            else getInitialData()

        } catch (error) {
            setErrorTable({message: error.message})
            setLoadingTable(false)

        } finally {
        }
    }, [getInitialDataAndReplace, getInitialData, getTasks, history.action, location])


    useEffect(() => start(), [start])

    const handleFieldChange = event => {
        // console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)
    const handleDateSubmitButtonClick = async () => {
        console.log('Called........  2');
        try {
            setErrorTable(false)
            setLoadingOnDateSubmit(true)

            const response = await axios.get(location.pathname + `/?status=Создать&month=${dateFields.month.value}&year=${dateFields.year.value}`)

            setTable(response.data)

            // history.replace(location.pathname + `${location.search}&month=${dateFields.month.value}&year=${response.data.year}`)
            history.replace(location.pathname + `?status=Создать&month=${dateFields.month.value}&year=${response.data.year}`)
        } catch (error) {
            console.log(error.message);
            renderFeedback('danger', error.response?.data.message || 'Server error')
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }

    useEffect(() => {
        setActiveSidebarLink(activeSidebarLink)
    }, [setActiveSidebarLink, activeSidebarLink])

    useEffect(() => {
        console.log(decodeURI(location.search));
        setActiveTopbarLink(location.pathname)  // `/clients/${id}/brands`
        setActiveTopbarSubLink(location.pathname + decodeURI(location.search))
    }, [setActiveTopbarLink, location.pathname, location.search, setActiveTopbarSubLink])

    useEffect(() => {
        if(me && me.show_all_tasks || me && me.department_id === 15){
            setTopbarData({
                links: [
                    {
                        value: "Еженедельные",
                        url: `/users/tasks/weekly`,
                        badge: null
                    },
                    {
                        value: "Ежемесячные",
                        url: `/users/tasks/monthly`,
                        badge: null
                    },
                    {
                        value: "Проектные",
                        url: `/users/tasks/project?status=Создать`,
                        badge: null
                    },
                    {
                        value: "Загруженность",
                        url: `/properties/departments/${me?.department_id}/workload?period=Последние_7_дней`
                    },
                    {
                        value: "Текущие",
                        url: `/properties/brandtask/current`,
                        badge: null
                    },
                    {
                        value: "Статистика текущие",
                        url: `/properties/brandtask/stat_current`,
                        badge: null
                    },
                    {
                        value: "Потенциальные",
                        url: `/properties/brandtask/potential`,
                        badge: null
                    },
                    {
                        value: "Статистика потенциальные",
                        url: `/properties/brandtask/stat_potential`,
                        badge: null
                    },
                    {
                        value: "Дисквалификация",
                        url: `${location.pathname.split('/').slice(0, -1).join('/')}/disqualification`,
                        badge: null
                    },
                ],
                sublinks: null,
            })
        }else{
            if(me && me.department_id === 19 || me && me.department_id === 30
                || me && me.department_id === 26
                || me && me.department_id === 28
            ){
                setTopbarData({
                    links: [
                        {
                            value: "Текущие",
                            url: `/properties/brandtask/current`,
                            badge: null
                        },
                        {
                            value: "Статистика текущие",
                            url: `/properties/brandtask/stat_current`,
                            badge: null
                        },
                        {
                            value: "Потенциальные",
                            url: `/properties/brandtask/potential`,
                            badge: null
                        },
                        {
                            value: "Статистика потенциальные",
                            url: `/properties/brandtask/stat_potential`,
                            badge: null
                        },
                        {
                            value: "Дисквалификация",
                            url: `${location.pathname.split('/').slice(0, -1).join('/')}/disqualification`,
                            badge: null
                        },
                    ],
                    sublinks: null,
                })
            }else{
                setTopbarData({
                    links: [
                        {
                            value: "Еженедельные",
                            url: `/users/tasks/weekly`,
                            badge: null
                        },
                        {
                            value: "Ежемесячные",
                            url: `/users/tasks/monthly`,
                            badge: null
                        },
                        {
                            value: "Проектные",
                            url: `/users/tasks/project?status=Создать`,
                            badge: null
                        },
                        {
                            value: "Загруженность",
                            url: `/properties/departments/${me?.department_id}/workload?period=Последние_7_дней`
                        },
                    ],
                    sublinks: null,
                })
            }
        }

    }, [setTopbarData, location.pathname, me?.department_id])


    useEffect(() => {
        setBreadcrumbs(breadcrumbs)
    }, [setBreadcrumbs, breadcrumbs])

    // const handleDateChange = async direction => {
    //     try {
    //         setLoadingTable(true)
    //         let newStartDate = direction  // HERE direction = DD.MM.YYYY // IT'S COMING FROM DateMUIWeek.jsx (WEEKLY-CALENDAR)

    //         if (typeof direction === 'number') {  // IF NUMBER, THEN IT'S EITHER 1(FORWARD BTN CLICKED) OR -1(BACK BTN CLICKED)
    //             const dateMomentObject = moment(table.head.date.start, "DD.MM.YYYY"); // 1st argument - string, 2nd argument - format
    //             const dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
    //             // dateObject.setDate(direction === 'back' ? dateObject.getDate() - 7 : dateObject.getDate() + 7)
    //             newStartDate = direction === -1 ? moment(dateObject).subtract(7,'days').format('DD.MM.YYYY') : moment(dateObject).add(7,'days').format('DD.MM.YYYY')
    //             // console.log(newStartDate);
    //         }

    //         const response = await axios.get(`${location.pathname}/?start_date=${newStartDate}`)
    //         // setTable({ ...response.data, body: [ ...response.data.body, getTotal(response.data) ] })
    //         setTable(response.data)
    //     } catch (error) {
    //         // setErrorTable(error.response?.data.message || 'Server Error')
    //         renderFeedback('danger', error.response?.data.message || 'Server Error')
    //     } finally {
    //         setLoadingTable(false)
    //     }
    // }


    const buttons = [
        {
            text: 'Добавить проектную задачу',
            icon: 'plus',
            url: addButtonURL,
            popup_title: 'Новая проектная задача',
            popup_callback() {
                // getTabs()
                // // getTable()
                // getInitialData()
                start()
            },
        },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value

        const url = `${location.pathname}/${request_id}/edit/`
        try {
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать проектную задачу',
                callback() {
                    // getTabs()
                    // getTable()
                    // getInitialData()
                    start()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- ROW CLICK START ---------------------


    const query = useMemo(() => new queryString.parse(location.search), [location.search])

    if (!didMount) {
        return null;
    }

    if (loadingOnDateSubmit) return <LoaderComponent text='Loading on date submit...'/>

    return (<>
            <div className='tasks project-tasks page'>
            </div>
            {/* <TableV table={pTable} buttons={buttons.reverse()} handleRowClick={handleRowClick} /> */}

            {loadingTable ? <LoaderComponent/> : !!errorTable ? <div>{errorTable.message}</div> :
                <div className="tasks" style={{marginBottom: '3rem'}}>
                    {query.status === CREATE_NEW_PROJECT
                        ? <TableV
                            table={table}
                            setTable={setTable}
                            date_fields={dateFields}
                            buttons={buttons.reverse()}
                            handleRowClick={handleRowClick}
                            onDateFieldsChange={handleFieldChange}
                            handleDateSubmitButtonClick={handleDateSubmitButtonClick}
                            refreshData={getInitialData}
                        />
                        : <TableForProjectTasksBody
                            table={table}
                            // table={pTable}
                            setTable={setTable}
                            handleRowClick={handleRowClick}
                        />
                    }
                </div>
            }
        </>
    )
}

