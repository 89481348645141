import React, {useCallback, useEffect, useState} from 'react'
import './properties_bonus.css'

import {useGlobalContext} from '../../store/context/context'
import {useLocation} from 'react-router'
import axios from 'axios'
import {TableV} from '../../components/layout/table/TableV'

export const PropertiesBonus = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        setPopup,
        setShowFeedback,
        setServerError,
        renderFeedback
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const location = useLocation()

    const [table, setTable] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const getTable = useCallback(async () => {
        try {
            setLoading(true)
            const response_table = await axios.get(location.pathname + "/")
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setError(error.response?.data|| 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, setServerError])
    useEffect(() => {
        getTable()
    }, [getTable])

    const getUrl = useCallback((root = false) => {
        if (root) {
            if (location.pathname.lastIndexOf('/') === 0) return location.pathname.substring(location.pathname.lastIndexOf('/') + 1) // /clients ==>> clients
            return location.pathname.substring(1, location.pathname.indexOf('/', 1))  // i.e. /clients/id/brands ==>> clients
        }
        return location.pathname.substring(location.pathname.lastIndexOf('/') + 1)  // i.e. /clients/id/brands ==>> brands   OR   /clients ==>> clients
    }, [location.pathname])
    useEffect(() => {
        setActiveSidebarLink(location.pathname.substring(1))
    }, [setActiveSidebarLink, getUrl, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            {path: location.pathname, breadcrumb: table?.title || ''},
            // { path: '/users/:id', breadcrumb: 'id-breadcrumb' },
        ])
    }, [setBreadcrumbs, table?.title, location.pathname])

    const buttons = [
        {
            text: 'Добавить тип клиента',
            icon: 'plus',
            url: `${location.pathname}/new/`,
            popup_title: 'Добавить тип клиента',
            popup_callback() {
                getTable()
            }
        },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
        try {
            const url = `${location.pathname}/${request_id}/edit/`
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать тип клиента',
                callback() {
                    getTable()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- ROW CLICK START ---------------------

    if (loading) return <div>Loading...</div>
    if (error) return <div>{error.message}</div>

    return (<>
            <div className="properties-bonus page">
            </div>

            <TableV
                table={table}
                buttons={buttons.reverse()}
                handleRowClick={handleRowClick}
                refreshData={getTable}
            />
        </>
    )
}
