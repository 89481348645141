import axios from 'axios'
import React, {useCallback, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import TableTzBottom from '../../components/layout/table_tz/TableTzBottom'
import TableTzTop from '../../components/layout/table_tz/TableTzTop'
// import Table from '../../components/layout/table/Table'
import {useGlobalContext} from '../../store/context/context'
import './avr_new.css'

export const AvrNew = () => {
    const {setTopbarData, setBreadcrumbs, setActiveSidebarLink, setShowFeedback, setServerError} = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const {id} = useParams()
    const location = useLocation()
    // const history = useHistory()

    // const tzType = useMemo(() => location.pathname.includes('kpi') ? 'kpi' : 'billing', [location.pathname])

    const [tableHead, setTableHead] = useState({})
    const [tableBody, setTableBody] = useState({})
    const [tableFoot, setTableFoot] = useState({})
    const [loadingTableHead, setLoadingTableHead] = useState(true)
    const [loadingTableBody, setLoadingTableBody] = useState(true)
    const [loadingTableFoot, setLoadingTableFoot] = useState(true)
    const [errorTableHead, setErrorTableHead] = useState(null)
    const [errorTableBody, setErrorTableBody] = useState(null)
    const [errorTableFoot, setErrorTableFoot] = useState(null)


    const getHead = useCallback(async () => {
        try {
            setLoadingTableHead(true)
            const response_table_head = await axios.get(`${location.pathname}/head/`)
            setTableHead(response_table_head.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTableHead(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTableHead(error.response?.data?.message)
        } finally {
            setLoadingTableHead(false)
        }
    }, [location.pathname, setServerError])

    const getBody = useCallback(async () => {
        try {
            setLoadingTableBody(true)
            const response_table_body = await axios.get(`${location.pathname}/body/`)
            setTableBody(response_table_body.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTableBody(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTableBody(error.response?.data?.message)
        } finally {
            setLoadingTableBody(false)
        }
    }, [location.pathname, setServerError])

    const getFoot = useCallback(async () => {
        try {
            setLoadingTableFoot(true)
            const response_table_foot = await axios.get(`${location.pathname}/foot/`)
            setTableFoot(response_table_foot.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTableFoot(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTableFoot(error.response?.data?.message)
        } finally {
            setLoadingTableFoot(false)
        }
    }, [location.pathname, setServerError])

    useEffect(() => {
        getHead()
        getBody()
        getFoot()
    }, [getHead, getBody, getFoot])

    // useEffect(() => {
    //     const fetchData = async () => {
    //         console.log('sending request....');
    //         try {
    //             setIsLoading(true)
    //             // const previousPath = location.pathname.split('/').slice(0, -2).join('/')

    //             const request_table_head = axios.get(`${location.pathname}/head/`)
    //             const request_table_body = axios.get(`${location.pathname}/body/`)
    //             const request_table_foot = axios.get(`${location.pathname}/foot/`)
    //             const [response_table_head, response_table_body] = await axios.all([request_table_head, request_table_body])
    //             setTableHead(response_table_head.data)
    //             setTableBody(response_table_body.data)
    //         } catch (error) {
    //             console.log(error.message);

    //             renderFeedback('danger', error.response?.data?.message || 'Server Error')
    //             if (error.response.status === 403) {
    //                 history.goBack()
    //             }
    //         } finally {
    //             setIsLoading(false)
    //         }
    //     }
    //     fetchData()
    // }, [renderFeedback, location.pathname, setIsLoading, history])


    useEffect(() => {
        setActiveSidebarLink('clients')
    }, [setActiveSidebarLink])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData, id])

    useEffect(() => {
        const prevPath = location.pathname.substring(0, location.pathname.lastIndexOf('/'))
        setBreadcrumbs([
            {path: '/clients', breadcrumb: 'Клиенты'},
            {path: prevPath, breadcrumb: `АВР`},
            {path: `${location.pathname}`, breadcrumb: `Новый АВР`},
        ])
    }, [setBreadcrumbs, id, location.pathname])

    const [head_request_id, setHead_Request_Id] = useState(null)

    return (<>
            <div className="avr-new page">
            </div>
            {loadingTableHead ? <LoaderComponent/> : errorTableHead ? <div>{errorTableHead.message}</div> :
                <TableTzTop table={tableHead} setTable={setTableHead} head_request_id={head_request_id}
                            setHead_Request_Id={setHead_Request_Id} getBody={getBody} getFoot={getFoot}
                            parentComponent="avr"/>}
            {loadingTableBody || loadingTableHead ? <LoaderComponent/> : errorTableBody ?
                <div>{errorTableBody.message}</div> :
                <TableTzBottom table={tableBody} head_request_id={head_request_id} parentComponent="avr"
                               tableType="body" refreshTableBottomData={getBody}/>}
            {loadingTableFoot || loadingTableHead ? <LoaderComponent/> : errorTableFoot ?
                <div>{errorTableFoot.message}</div> :
                <TableTzBottom table={tableFoot} head_request_id={head_request_id} parentComponent="avr"
                               tableType="foot" refreshTableBottomData={getFoot}/>}
        </>
    )
}
