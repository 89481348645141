import React, {useState} from 'react'
import {TableV} from '../table/TableV' // TableV IS NOT USED BECAUSE IN AVR WE HAVE CHECK FIELD
import {ReactComponent as ArrowOpen} from '../../../assets/sidebar-icons/arrow-open.svg'
import './swipe-up.css'
import {useGlobalContext} from '../../../store/context/context'

const SwipeUp = ({
                     showSwipeUp,
                     setShowSwipeUp,
                     swipeUpData: {table = null, buttons = [], sendAvrButton = null} = {},
                     setSwipeUpData,
                     refreshTableData
                 }) => {
    const {setAlert, renderFeedback, buttonIcons} = useGlobalContext()
    const refreshData = () => {
        setSwipeUpData({table: null, buttons: []})
        refreshTableData && refreshTableData()
    }


    const [shouldSendAvr, setShouldSendAvr] = useState(false)

    const handleSendAvrClick = () => {
        if (shouldSendAvr) {
            const {onClickFunction, alert} = sendAvrButton
            if (alert) {
                setAlert({
                    visible: true,
                    title: alert.title,
                    body: alert.body,
                    onSuccessFunction: onClickFunction
                })

                return
            }
        }

        renderFeedback('danger', 'Пожалуйста, проверьте все ВП и убедитесь что нигде нет красных ячеек, затем подтвердите поставив галочку!')
    }

    return (
        <div className={showSwipeUp ? "swipe-up-container background" : "swipe-up-container background hide"}
             onClick={() => setShowSwipeUp(false)} onMouseOver={e => e.target.style.background = 'rgba(0, 0, 0, .4)'}>
            <div className="swipe-up" onClick={e => e.stopPropagation()} onMouseOver={e => {
                e.stopPropagation();
                e.target.parentElement.style.background = ''
            }}>
                <button className="svg-container swipe-up__close-btn" onClick={() => {
                    setShowSwipeUp(false);
                    setSwipeUpData({table: null, buttons: []})
                }}><ArrowOpen/></button>
                {/* {table && <TableV id="tableToCopy" table={table} caption="Ожидает НОК" buttons={buttons} refreshData={refreshData} parentElement="swipe-up"/>} */}
                {table && <TableV id="tableToCopy" table={table} buttons={buttons} refreshData={refreshData}
                                  parentElement="swipe-up"/>}
                {sendAvrButton && <>
                    <div className="swipe-up__confirm-form">
                        <input type="checkbox" id="confirm" checked={shouldSendAvr}
                               onChange={e => setShouldSendAvr(e.target.checked)}/>
                        <label htmlFor="confirm">Подтверждаю, что я проверил ВП и что все в порядке!</label>
                    </div>
                    <button className="button button-2" onClick={handleSendAvrClick}
                            style={{marginLeft: 'auto', marginTop: '1rem'}}>
                        <span className="svg-container">{buttonIcons[sendAvrButton.icon]}</span>
                        <span>{sendAvrButton.text}</span>
                    </button>
                </>}
            </div>
        </div>
    )
}

export default SwipeUp
