import axios from 'axios';
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory, useLocation} from 'react-router';
import ClientInfo from '../../components/layout/client-info/ClientInfo'
import {LoaderComponent} from '../../components/layout/loader/Loader';
import {TableV} from '../../components/layout/table/TableV';
import {useGlobalContext} from '../../store/context/context';
import './brand.css'
// import { useAxios } from '../../utils/js/useAxios';

const Brand = () => {
    const {
        setTopbarData,
        setActiveTopbarLink,
        setBreadcrumbs,
        setActiveSidebarLink,
        setPopup,
        renderFeedback,
        setShowFeedback,
        setServerError
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    // const {id} = useParams()
    const location = useLocation();
    const history = useHistory()
    const [table, setTable] = useState({})
    const [client, setClient] = useState(null)
    const [loadingClient, setLoadingClient] = useState(true)
    const [loadingTable, setLoadingTable] = useState(true)
    const [errorClient, setErrorClient] = useState(null)
    const [errorTable, setErrorTable] = useState(null)
    const [loadingNewTz, setLoadingNewTz] = useState(false)

    const getClient = useCallback(async () => {
        try {
            setLoadingClient(true)
            const response_client = await axios.get(`${location.pathname}/`)
            setClient(response_client.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorClient(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})

            // setErrorClient(error.response?.data|| 'Server error')
        } finally {
            setLoadingClient(false)
        }
    }, [location.pathname, setServerError])
    const getTable = useCallback(async () => {
        try {
            setLoadingTable(true)
            const response_table = await axios.get(`${location.pathname}/tzs/`)
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
        } finally {
            setLoadingTable(false)
        }
    }, [location.pathname, setServerError])
    useEffect(() => {
        getClient()
        getTable()
    }, [getClient, getTable])


    const getUrl = useCallback((root = false) => {
        if (root) {
            if (location.pathname.lastIndexOf('/') === 0) return location.pathname.substring(location.pathname.lastIndexOf('/') + 1) // /clients ==>> clients
            return location.pathname.substring(1, location.pathname.indexOf('/', 1))  // i.e. /clients/id/brands ==>> clients
        }
        return location.pathname.substring(location.pathname.lastIndexOf('/') + 1)  // i.e. /clients/id/brands ==>> brands   OR   /clients ==>> clients
    }, [location.pathname])


    useEffect(() => {
        const parentURL = getUrl('root')
        // console.log(parentURL);  // clients
        setActiveSidebarLink(parentURL)
    }, [setActiveSidebarLink, getUrl, location.pathname])

    useEffect(() => {
        const url = getUrl()
        // console.log(url);  // brands
        setActiveTopbarLink(url)
    }, [setActiveTopbarLink, location.pathname, getUrl])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/clients', breadcrumb: 'Клиенты'},
            {path: `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}`, breadcrumb: `Бренды`}, // i.e. /clients/id/brands/id ==>> /clients/id/brands
            {path: `${location.pathname}`, breadcrumb: `Бренд`},  // links[getURL()] WAS HERE PREVIOUSLY)
        ])
    }, [table.title, location.pathname, setBreadcrumbs])


    const buttons = useMemo(() => {
        return [
            {
                text: `Добавить ТЗ`,
                icon: 'plus',
                async onClick() {  // ON CLICK SEND REQUEST TO NEW, GET NEW_ID, THEN PUSH TO "NEW_ID/EDIT"
                    console.log('add tz click');
                    try {
                        setLoadingNewTz(true)
                        const response_table_head = await axios.get(`${location.pathname}/tzs/${client?.data[0].value.toLowerCase()}/new/head/`)
                        const request_id = response_table_head.data.rows[0].request_id.value
                        console.log(`${location.pathname}/tzs/${client?.data[0].value.toLowerCase()}/${request_id}/edit`);
                        history.push({
                            pathname: `${location.pathname}/tzs/${client?.data[0].value.toLowerCase()}/${request_id}/edit`,
                        })
                    } catch (error) {
                        console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || error.message || 'Server Error')
                    } finally {
                        setLoadingNewTz(false)
                    }

                }
            },
        ]
    }, [client?.data, location.pathname, history, renderFeedback])

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        try {
            const request_id = row['request_id'].value
            // console.log(client);
            // console.log('CLIENT: rowclick', `/tzs/${client.data[0].value.toLowerCase()}/${request_id}/view`);
            // console.log(`${location.pathname}/tzs/${client.data[0].value.toLowerCase()}/${request_id}/view`);

            await axios.get(`tzs/${client.data[0].value.toLowerCase()}/${request_id}/view/head/`)

            history.push({
                pathname: `${location.pathname}/tzs/${client.data[0].value.toLowerCase()}/${request_id}/view`,
            })
        } catch (error) {
            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- ROW CLICK START ---------------------

    const editClient = async () => {
        try {
            const response = await axios.get(`${location.pathname}/edit/`)
            setPopup({
                visible: true,
                data: response.data,
                url: `${location.pathname}/edit/`,
                title: 'Редактирование бренда',
                callback() {
                    getClient()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null
            })

            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }

    if (loadingNewTz) return <LoaderComponent/>

    return (<>
            <div className='brand page'>
                {loadingClient ? <LoaderComponent/> : errorClient ? <div>{errorClient.message}</div> :
                    <ClientInfo inline client={client} editClient={editClient}/>}
                {/* <ClientInfo client={client}/> */}
            </div>

            {loadingTable
                ? <LoaderComponent/>
                : errorTable
                    ? <div>{errorTable.message}</div>
                    : <TableV
                        table={table}
                        buttons={buttons.reverse()}
                        handleRowClick={handleRowClick}
                        refreshData={getTable}
                        href={{
                            from: `${location.pathname}/tzs/${client.data[0].value.toLowerCase()}`,
                            to: 'view'
                        }}
                    />}

        </>
    )
}

export default Brand


// useEffect(() => {
//     const fetchData = async () => {
//             setLoading(true)
//         try {
//             // const request_client = axios.get(`clients/${id}/`)
//             const request_client = axios.get(`${location.pathname}/`)
//             const request_table = axios.get(`${location.pathname}/tzs/`)
//             console.log('requesting...');
//             const [response_client, response_table] = await axios.all([request_client, request_table])
//             setClient(response_client.data)
//             setTable(response_table.data)
//             setError(null)
//         } catch (error) {
//             // console.log(error.message);
//             // console.log(error.response.data);

//             setError(error.message)
//         } finally {
//             setLoading(false)
//         }
//     }
//     fetchData()
// }, [id, location.pathname])

// const buttons = [
//     {
//         text: 'Новый АВР',
//         icon: 'plus',
//         url: ''
//     },
//     {
//         text: 'Edit',
//         icon: 'edit',
//         url: ''
//     },
//     {
//         text: 'История изменений ТЗ',
//         icon: 'book',
//         url: ''
//     },
// ]

// const client = {
//     name: "Асем Ай",
//     data: [
//         {
//             key: 'Клиент',
//             value: 'Dizzy TOO'
//         },
//         {
//             key: 'БИИ/ИНН/ИИН',
//             value: '"1234567890"'
//         },
//         {
//             key: 'Тип бренда',
//             value: 'KPI'
//         },
//     ]
// }


// useEffect(() => {
//    console.log(location.pathname); // result: '/secondpage'
//    console.log(location.state); // result: 'some_value'
// }, [location]);

// const { response: table, error: tableError, loading: tableLoading } = useAxios(`${location.pathname}/`)
// const { response: client, error: clientError, loading: clientLoading } = useAxios(`clients/${id}/`)
