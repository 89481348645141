import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useLocation, useParams} from 'react-router'
import {useGlobalContext} from '../../store/context/context'
import './user_tasks.css'

import {TableForWeeklyTasksBody} from '../../components/layout/table/TableForWeeklyTasksBody'
import {TableForTasksHead} from '../../components/layout/table/TableForTasksHead'

import axios from 'axios'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import moment from 'moment'
import {useHistory} from "react-router-dom";

const KEYWORD_TO_DETERMINE_IF_ITS_COMING_FROM_WORKDLOAD_PAGE_OR_ON_MAIN_PAGE = 'workload'

export const UserWeeklyTasks = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        renderFeedback,
        setActiveTopbarLink,
        setServerError,
        SPECIAL_VALUES,
        me
    } = useGlobalContext()

    const location = useLocation()
    const [table, setTable] = useState({})
    const [errorTable, setErrorTable] = useState(null)
    const [loadingTable, setLoadingTable] = useState(true)

    const [totalTable, setTotalTable] = useState(null)

    const history = useHistory();


    const [tasks, setTasks] = useState([
        // {label: "Еженедельный", period: "weekly", badge: 0},
        // {label: "Ежемесячный", period: "monthly", badge: 0},
        // {label: "Project", period: "project", badge: 10},
    ])

    const {id} = useParams()
    const {tasksURL, tableURL, activeSidebarLink, breadcrumbs} = useMemo(() => {
        let tasksURL = 'properties/tasks/'
        let tableURL = 'users/tasks/weekly/'
        let activeSidebarLink = 'users/tasks/weekly'
        let noTaskMessage = 'У вас нет еженедельных задач'

        let breadcrumbs = [
            {path: '/', breadcrumb: 'Главная - Мои задачи'},
        ]
        if (location.pathname.includes(KEYWORD_TO_DETERMINE_IF_ITS_COMING_FROM_WORKDLOAD_PAGE_OR_ON_MAIN_PAGE)) {
            tasksURL += `?staff_id=${id}`
            tableURL += `?staff_id=${id}`
            activeSidebarLink = 'properties/departments'
            // '/properties/departments/22/workload?period=Последние_7_дней'
            breadcrumbs = [
                {path: '/properties/departments', breadcrumb: 'Отделы'},
                {
                    path: `${location.pathname.split('/').slice(0, 5).join('/')}?period=Последние_7_дней`,
                    breadcrumb: `Загруженность`
                },  // links[getURL()] WAS HERE PREVIOUSLY
                {path: `${location.pathname}`, breadcrumb: `Задачи сотрудника`},  // links[getURL()] WAS HERE PREVIOUSLY
            ]
            noTaskMessage = 'У этого сотрудника нет еженедельных задач'
        }
        return {tasksURL, tableURL, activeSidebarLink, breadcrumbs, noTaskMessage}
    }, [id, location.pathname])

    useEffect(() => {
        function handleEscKeyClick(event) {
            const key = event.key; // const {key} = event; in ES6+

            if (key === 'Enter') {
                const focusedElement = document.activeElement
                const focusedColumnAccessor = document.activeElement.name

                const inputs = document.querySelectorAll(`div.table__td__center.${focusedColumnAccessor} input`)

                let indexOfNextInputElementToFocus = [...inputs].findIndex(input => input === focusedElement) + 1;

                if (indexOfNextInputElementToFocus === inputs.length) return  // IF THIS IS THE LAST FOSUCED INPUT ELEMENT JUST DO NOTHING

                inputs[indexOfNextInputElementToFocus].focus()  // ELSE FORCEFULLY FOCUS THE NEXT INPUT ELEMENT
            }
        }

        document.addEventListener('keydown', handleEscKeyClick);

        return () => document.removeEventListener('keydown', handleEscKeyClick)
    }, [])

    // const getTabs = useCallback(async () => {
    //     try {
    //         const response = await axios.get('properties/tasks/')
    //         if (!response.data.length) {
    //             setErrorTable({ message : 'У вас нет еженедельных задач' })
    //             setLoadingTable(false)
    //             return setTabs(null)
    //             // history.replace({
    //             //     pathname: `/users/tasks/project`,
    //             //     search: `?status=Создать`,
    //             // })

    //             // return
    //         }

    //         setTabs(response.data)

    //         getTable()
    //     } catch (error) {
    //         if (error.response.status === 500) {
    //             // return setErrorTable({message: error.response.statusText })
    //             return setServerError(true)
    //         }

    //         renderFeedback('danger', error.response?.data.message || 'Server Error')
    //     }
    // }, [renderFeedback, setServerError, getTable])

    // const getTable = useCallback(async () => {
    // try {
    //     setLoadingTable(true)
    //     const response = await axios.get(`${location.pathname}/`)

    //     response.data.body.forEach(table => {
    //         Object.keys(table.headers).forEach(accessor => {
    //             if (table.headers[accessor].type === 'number') {
    //                 table.headers[accessor].label = table.rows.reduce((acc, curr) => curr[accessor].value === -999999 ? acc : acc + curr[accessor].value, 0)
    //             }
    //         })
    //     })

    //     // setTable({ ...response.data, body: [ ...response.data.body, getTotal(response.data) ] })
    //     setTable(response.data)
    // } catch (error) {
    //     if (error.response.status === 500) {
    //         // return setErrorTable({message: error.response.statusText })
    //         return setServerError(true)
    //     }
    //     setErrorTable(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
    //     // setErrorTable(error.response?.data.message || 'Server Error')
    //     renderFeedback('danger', error.response?.data.message || 'Server Error')
    // } finally {
    //     setLoadingTable(false)
    // }
    // }, [location.pathname, renderFeedback, setServerError])

    // useEffect(() => {
    //     getTabs()
    // }, [getTabs])

    // useEffect(() => {
    //     (tabs && tabs.length) && getTable()
    // }, [getTable, tabs])

    useEffect(() => {
        Object.keys(table).length && setTotalTable(getTotal(table))
    }, [table])

    const getTasks = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(tasksURL)
                // const { weekly } = response.data
                // if (!weekly) {
                //     reject({ message : noTaskMessage })
                // }

                // setTasks(response.data)

                resolve(response.data)
            } catch (error) {
                if (error.response.status === 500) {
                    // return setErrorTable({message: error.response.statusText })
                    return setServerError(true)
                }

                reject({message: error.response?.data.message || 'Server Error'})
                renderFeedback('danger', error.response?.data.message || 'Server Error')
            }
        })
    }, [renderFeedback, setServerError, tasksURL])

    const getTotal = ({head, body}) => {  // NOT A REQUEST
        const order = head.order

        let headers = {}

        order.forEach(accessor => {
            headers[accessor] = {
                ...head.headers[accessor],
                label: body.reduce((acc, currentTable) => accessor === 'task_name' ? 'Итого' : currentTable.headers[accessor].label + acc, 0)
            }
        })

        return {
            type: "total",
            clickable: false,
            headers,
            order,
            rows: [],
        }
    }

    const getTable = useCallback(async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(tableURL)

                response.data.body.forEach(table => {
                    Object.keys(table.headers).forEach(accessor => {
                        if (table.headers[accessor].type === 'number') {
                            table.headers[accessor].label = table.rows.reduce((acc, curr) => curr[accessor].value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? acc : acc + curr[accessor].value, 0)
                        }
                    })
                })

                resolve(response.data)
            } catch (error) {
                if (error.response.status === 500) {
                    // return setErrorTable({message: error.response.statusText })
                    return setServerError(true)
                }

                reject({message: error.response?.data.message || 'Server Error'})
                renderFeedback('danger', error.response?.data.message || 'Server Error')
            }
        })
    }, [renderFeedback, setServerError, tableURL])

    const start = useCallback(async () => {
        try {
            const fetchedTasks = await getTasks()
            setTasks(fetchedTasks)

            setLoadingTable(true)
            const fetchedTable = await getTable()
            setTable(fetchedTable)
            setTotalTable(getTotal(fetchedTable))
        } catch (error) {
            setErrorTable({message: error.message})
        } finally {
            setLoadingTable(false)
        }
    }, [getTasks, getTable])

    useEffect(() => start(), [start])

    useEffect(() => {
        setActiveSidebarLink(activeSidebarLink)
    }, [setActiveSidebarLink, activeSidebarLink])

    useEffect(() => {
        setActiveTopbarLink('/users/tasks/weekly')  // `/clients/${id}/brands`
    }, [setActiveTopbarLink])

    useEffect(() => {
        if(me && me.show_all_tasks || me && me.department_id === 15){
            setTopbarData({
                links: [
                    {
                        value: "Еженедельные",
                        url: `/users/tasks/weekly`,
                        badge: null
                    },
                    {
                        value: "Ежемесячные",
                        url: `/users/tasks/monthly`,
                        badge: null
                    },
                    {
                        value: "Проектные",
                        url: `/users/tasks/project?status=Создать`,
                        badge: null
                    },
                    {
                        value: "Загруженность",
                        url: `/properties/departments/${me?.department_id}/workload?period=Последние_7_дней`
                    },
                    {
                        value: "Текущие",
                        url: `/properties/brandtask/current`,
                        badge: null
                    },
                    {
                        value: "Статистика текущие",
                        url: `/properties/brandtask/stat_current`,
                        badge: null
                    },
                    {
                        value: "Потенциальные",
                        url: `/properties/brandtask/potential`,
                        badge: null
                    },
                    {
                        value: "Статистика потенциальные",
                        url: `/properties/brandtask/stat_potential`,
                        badge: null
                    },
                    {
                        value: "Дисквалификация",
                        url: `${location.pathname.split('/').slice(0, -1).join('/')}/disqualification`,
                        badge: null
                    },
                ],
                sublinks: null,
            })
        }else{
            if(me && me.department_id === 19 || me && me.department_id === 30
                || me && me.department_id === 26
                || me && me.department_id === 28
            ){
                history.replace("/properties/brandtask/current");
                setTopbarData({
                    links: [
                        {
                            value: "Текущие",
                            url: `/properties/brandtask/current`,
                            badge: null
                        },
                        {
                            value: "Статистика текущие",
                            url: `/properties/brandtask/stat_current`,
                            badge: null
                        },
                        {
                            value: "Потенциальные",
                            url: `/properties/brandtask/potential`,
                            badge: null
                        },
                        {
                            value: "Статистика потенциальные",
                            url: `/properties/brandtask/stat_potential`,
                            badge: null
                        },
                        {
                            value: "Дисквалификация",
                            url: `${location.pathname.split('/').slice(0, -1).join('/')}/disqualification`,
                            badge: null
                        },
                    ],
                    sublinks: null,
                })
            }else{
                setTopbarData({
                    links: [
                        {
                            value: "Еженедельные",
                            url: `/users/tasks/weekly`,
                            badge: null
                        },
                        {
                            value: "Ежемесячные",
                            url: `/users/tasks/monthly`,
                            badge: null
                        },
                        {
                            value: "Проектные",
                            url: `/users/tasks/project?status=Создать`,
                            badge: null
                        },
                        {
                            value: "Загруженность",
                            url: `/properties/departments/${me?.department_id}/workload?period=Последние_7_дней`
                        },
                    ],
                    sublinks: null,
                })
            }
        }

    }, [setTopbarData, location.pathname, me?.department_id])

    useEffect(() => {
        setBreadcrumbs(breadcrumbs)
    }, [setBreadcrumbs, breadcrumbs])

    const handleDateChange = async direction => {
        try {
            setLoadingTable(true)
            let newStartDate = direction  // HERE direction = DD.MM.YYYY // IT'S COMING FROM DateMUIWeek.jsx (WEEKLY-CALENDAR)

            if (typeof direction === 'number') {  // IF NUMBER, THEN IT'S EITHER 1(FORWARD BTN CLICKED) OR -1(BACK BTN CLICKED)
                const dateMomentObject = moment(table.head.date.start, "DD.MM.YYYY"); // 1st argument - string, 2nd argument - format
                const dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
                // dateObject.setDate(direction === 'back' ? dateObject.getDate() - 7 : dateObject.getDate() + 7)
                newStartDate = direction === -1 ? moment(dateObject).subtract(7, 'days').format('DD.MM.YYYY') : moment(dateObject).add(7, 'days').format('DD.MM.YYYY')
                // console.log(newStartDate);
            }

            let url = tableURL
            if (location.pathname.includes(KEYWORD_TO_DETERMINE_IF_ITS_COMING_FROM_WORKDLOAD_PAGE_OR_ON_MAIN_PAGE)) url += `&start_date=${newStartDate}`
            else url += `?start_date=${newStartDate}`

            // const response = await axios.get(`${location.pathname}/?start_date=${newStartDate}`)
            const response = await axios.get(url)
            // setTable({ ...response.data, body: [ ...response.data.body, getTotal(response.data) ] })
            setTable(response.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            // setErrorTable(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })

            renderFeedback('danger', error.response?.data.message || 'Server Error')
        } finally {
            setLoadingTable(false)
        }
    }

    const [valueType, setValueType] = useState('value')
    const handleSwitchChange = (isChecked) => {
        if (isChecked) {
            return setValueType('value2')
        }
        setValueType('value')
    }


    return (<>
            <div className='tasks weekly-tasks page'>
            </div>

            {loadingTable ? <LoaderComponent/> : !!errorTable ? <div>{errorTable.message}</div> :
                <div className="tasks" style={{marginBottom: '3rem'}}>
                    <TableForTasksHead
                        table={table.head}
                        handleDateChange={handleDateChange}
                        handleSwitchChange={handleSwitchChange}
                    />
                    {table.body.map(singleTable => (
                        <TableForWeeklyTasksBody
                            key={singleTable.request_id}
                            table={singleTable}
                            setTasksTable={setTable}
                            startDate={table.head.date.start}
                            valueType={valueType}
                        />
                    ))}
                    {totalTable && (
                        <TableForWeeklyTasksBody
                            table={totalTable}
                            add_subtask_btn={false}
                        />
                    )}
                </div>
            }

        </>
    )
}


// WEEKLY TASK FORMAT
// const rawData = {
//     head: {
//         headers: {
//             request_id: { type: "number", label: "ID", width: 200, pinned: false, visible: false },
//             task: { type: "text", label: "Название задачи", width: 'auto', pinned: false, visible: true },
//             monday: { type: "text", label: "Пн\n07", width: 40, pinned: false, visible: true },
//             tuesday: { type: "text", label: "Вт\n08", width: 40, pinned: false, visible: true },
//             wednesday: { type: "text", label: "Ср\n09", width: 40, pinned: false, visible: true },
//             thursday: { type: "text", label: "Чт\n10", width: 40, pinned: false, visible: true },
//             friday: { type: "text", label: "Пт\n11", width: 40, pinned: false, visible: true },
//             saturday: { type: "text", label: "Сб\n12", width: 40, pinned: false, visible: true },
//             sunday: { type: "text", label: "Вс\n13", width: 40, pinned: false, visible: true },
//         },
//         order: ["request_id", "task", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
//         date: {
//              isToday: true,
//              month: "6-12 Декабрь",
//              start: "06.12.2021",
//              today: "friday",
//              year: 2021,
//         }
//     },
//     body: [
//         {
//             request_id: '1',
//             type: "task",
//             clickable: false,
//             headers: {
//                 request_id: { type: "number", label: "ID", width: 200, pinned: false, visible: false },
//                 task: { type: "text", label: "Задача 1: Проверить работают ли объявления.", width: 'auto', pinned: false, visible: true, normative: '20 минут', frequency: 'Не менее 2-х раз в неделю' },
//                 monday: { type: "number", label: 50, width: 40, pinned: false, visible: true },
//                 tuesday: { type: "number", label: 0, width: 40, pinned: false, visible: true },
//                 wednesday: { type: "number", label: 20, width: 40, pinned: false, visible: true },
//                 thursday: { type: "number", label: 0, width: 40, pinned: false, visible: true },
//                 friday: { type: "number", label: 20, width: 40, pinned: false, visible: true },
//                 saturday: { type: "number", label: 0, width: 40, pinned: false, visible: true },
//                 sunday: { type: "number", label: 0, width: 40, pinned: false, visible: true },
//             },
//             order: ["request_id", "task", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
//             rows: [
//                 {
//                     request_id: { type: "number_field", color: "#FFFFFF", value: 15, editable: false },
//                     task: { type: "text_field", color: "#FFFFFF", value: "Подзадача №1", editable: false, isCompleted: true },
//                     monday: { type: "number_field", color: "#FFFFFF", value: 30, editable: true },
//                     tuesday: { type: "number_field", color: "#FFFFFF", value: undefined, editable: true },
//                     wednesday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                     thursday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                     friday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                     saturday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                     sunday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                 },
//                 {
//                     request_id: { type: "number_field", color: "#FFFFFF", value: 16, editable: false },
//                     task: { type: "text_field", color: "#FFFFFF", value: "Подзадача №2", editable: false, isCompleted: false },
//                     monday: { type: "number_field", color: "#FFFFFF", value: undefined, editable: true },
//                     tuesday: { type: "number_field", color: "#FFFFFF", value: undefined, editable: true },
//                     wednesday: { type: "number_field", color: "#FFFFFF", value: 10, editable: false },
//                     thursday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                     friday: { type: "number_field", color: "#FFFFFF", value: 20, editable: false },
//                     saturday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                     sunday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                 }
//             ],
//         },
//         {
//             request_id: '2',
//             type: "task",
//             clickable: false,
//             headers: {
//                 request_id: { type: "number", label: "ID", width: 200, pinned: false, visible: false },
//                 task: { type: "text", label: "Задача 2: Найм человека", width: 'auto', pinned: false, visible: true },
//                 monday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 tuesday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 wednesday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 thursday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 friday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 saturday: { type: "number", label: 0, width: 40, pinned: false, visible: true },
//                 sunday: { type: "number", label: 0, width: 40, pinned: false, visible: true },
//             },
//             order: ["request_id", "task", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
//             rows: [
//                 {
//                     request_id: { type: "number_field", color: "#FFFFFF", value: 15, editable: false },
//                     task: { type: "text_field", color: "#FFFFFF", value: "Казахстан Менеджер", editable: false, isCompleted: false },
//                     monday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     tuesday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     wednesday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     thursday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     friday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     saturday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                     sunday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                 },
//                 {
//                     request_id: { type: "number_field", color: "#FFFFFF", value: 16, editable: false },
//                     task: { type: "text_field", color: "#FFFFFF", value: "Москва Рекрутер", editable: false, isCompleted: true },
//                     monday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     tuesday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     wednesday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     thursday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     friday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     saturday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                     sunday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                 },
//                 {
//                     request_id: { type: "number_field", color: "#FFFFFF", value: 16, editable: false },
//                     task: { type: "text_field", color: "#FFFFFF", value: "Узбекистан Директор", editable: false, isCompleted: true },
//                     monday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     tuesday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     wednesday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     thursday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     friday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     saturday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                     sunday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                 },
//             ],
//         },
//         {
//             request_id: '3',
//             type: "other",
//             clickable: false,
//             headers: {
//                 request_id: { type: "number", label: "ID", width: 200, pinned: false, visible: false },
//                 task: { type: "text", label: "Прочие задачи", width: 'auto', pinned: false, visible: true },
//                 monday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 tuesday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 wednesday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 thursday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 friday: { type: "number",label: 15, width: 40, pinned: false, visible: true },
//                 saturday: { type: "number", label: 0, width: 40, pinned: false, visible: true },
//                 sunday: { type: "number", label: 0, width: 40, pinned: false, visible: true },
//             },
//             order: ["request_id", "task", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
//             rows: [
//                 {
//                     request_id: { type: "number_field", color: "#FFFFFF", value: 15, editable: false },
//                     task: { type: "text_field", color: "#FFFFFF", value: "Прочая задача №1", editable: false },
//                     monday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     tuesday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     wednesday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     thursday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     friday: { type: "number_field", color: "#FFFFFF", value: 5, editable: false },
//                     saturday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                     sunday: { type: "number_field", color: "#FFFFFF", value: null, editable: false },
//                 },
//             ],
//         },
//         {
//             request_id: '4',
//             type: "total",
//             clickable: false,
//             headers: {
//                 request_id: { type: "number", label: "ID", width: 200, pinned: false, visible: false },
//                 task: { type: "text", label: "Итого", width: 'auto', pinned: false, visible: true },
//                 monday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 tuesday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 wednesday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 thursday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 friday: { type: "number", label: 15, width: 40, pinned: false, visible: true },
//                 saturday: { type: "number", label: 0, width: 40, pinned: false, visible: true },
//                 sunday: { type: "number", label: 0, width: 40, pinned: false, visible: true },
//             },
//             order: ["request_id", "task", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
//             rows: [],
//         },
//     ],
// }
