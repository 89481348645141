const buildCSV = (headers, data) => {
    if (!headers && !data) return;

    let csvContent = "";

    if (headers) {
        csvContent += headers.join(";") + "\r\n";
    }

    if (data) {
        data.forEach(row => {
            csvContent += row.join(";") + "\r\n";
        });
    }
    return csvContent;
}

function download(data, fileName) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    let blob = new Blob([data], {type: "text/csv"})
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
}

function downloadFile(headersValue, rowsValue, fileName) {
    let data = buildCSV(headersValue, rowsValue)
    download(data, fileName);
}

export default function downloadCSV(table, rows, fileName) {
    const headersValue = table.order.map(accessor => table.headers[accessor].label)
    const rowsValue = rows.map(row => table.order.map(accessor => {
            if (row[accessor].type === 'number_field') {
                return String(row[accessor].value).replace('.', ',')
            } else if (row[accessor].type === 'text_field') {
                if(row[accessor].value){
                    return (row[accessor].value).trim().replaceAll('\n', '')
                }else{
                    return ''
                }
            }
            return row[accessor].value
        }
    ))

    downloadFile(headersValue, rowsValue, fileName)
}


// const headersValue = table.order.map(accessor => table.headers[accessor].label)
// const rowsValue = table.rows.map(row => table.order.map(accessor => row[accessor].value))

// const csv_rows = [headersValue, ...rowsValue]

// let csvContent = "data:text/csv;charset=utf-8,";
// /* add the column delimiter as comma(,) and each row splitted by new line character (\n) */
// csv_rows.forEach(rowArray => {
//     const row = rowArray.join(";");
//     csvContent += row + "\r\n";
// });

// console.log(csvContent);

// /* create a hidden <a> DOM node and set its download attribute */
// const encodedUri = encodeURI(csvContent);
// const link = document.createElement("a");
// link.setAttribute("href", encodedUri);
// link.setAttribute("download", "Report.csv");
// document.body.appendChild(link);
// /* download the data file named "Report_Boss.csv" */
// // link.click();
