import React, {useCallback, useEffect, useMemo, useState} from 'react'
import './tzs_kpi.css'
import queryString from 'query-string'

import {useGlobalContext} from '../../store/context/context'
import {useHistory, useLocation} from 'react-router'
import axios from 'axios'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import {TableV} from '../../components/layout/table/TableV'


// function useQuery() {
//     return new queryString.parse(useLocation().search);
// }

const TzsKpi = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        setActiveTopbarLink,
        setShowFeedback,
        setServerError,
        renderFeedback
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const history = useHistory()
    const location = useLocation()
    const query = useMemo(() => new queryString.parse(location.search), [location.search])


    const [dateFields, setDateFields] = useState({
        // month: {
        //     label: 'month',
        //     value: response.data.month || '',
        //     type: 'select_field',
        //     options: [],
        // },
        // year: {
        //     label: 'year',
        //     value: response.data.year || '',
        //     type: 'select_field',
        //     options: [],
        // }
    })

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [table, setTable] = useState({})

    // const getInitialData = useCallback(async () => {
    //     console.log('called................');
    //     try {
    //         setError(false)
    //         setLoading(true)
    //         const response = await axios.get(location.pathname + '/' + location.search)
    //         setTable(response.data)

    //         // setTopbarData({
    //         //     links: [
    //         //         {value: 'Ожидает', url: `/tzs/fills?status=Ожидает`},
    //         //         {value: 'Пополнено', url: `/tzs/fills?status=Пополнено&date_from=${response.data.date_from || ''}&date_to=${response.data.date_to || ''}`},
    //         //         {value: 'Отклонено', url: `/tzs/fills?status=Отклонено&date_from=${response.data.date_from || ''}&date_to=${response.data.date_to || ''}`},
    //         //     ],
    //         //     sublinks: []
    //         // })

    //     } catch (error) {
    //         if (error.response.status === 500) {
    //             // return setErrorTable({message: error.response.statusText })
    //             return setServerError(true)
    //         }
    //         setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
    //         // setError(error.response?.data|| 'Server error')
    //     } finally {
    //         setLoading(false)
    //     }
    // }, [location.pathname, location.search, setServerError])
    // useEffect(() => {
    //     getInitialData()
    // }, [setTopbarData, setActiveTopbarLink, getInitialData])


    const getInitialDataAndReplace = useCallback(async () => {
        console.log('getInitialDataAndReplace called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get(location.pathname + '/' + location.search)
            setTable(response.data)

            if (response.data.year) {
                setDateFields({
                    month: {
                        label: 'месяц',
                        value: response.data.month || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1)
                    },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    }
                })
                history.replace(location.pathname + `?status=${query.status}&month=${response.data.month}&year=${response.data.year}`)
            } else {
                setDateFields([])
            }

        } catch (error) {
            console.log(error);
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTable(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, history, setServerError, location.search, query.status])
    // console.log(table);

    const getInitialData = useCallback(async () => {
        console.log('getInitialData called................');
        try {
            setError(false)
            setLoading(true)

            const response = await axios.get(`${location.pathname}/` + location.search)

            setTable(response.data)

            if (response.data.year) {
                setDateFields({
                    month: {
                        label: 'месяц',
                        value: response.data.month || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1)
                    },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    }
                })
            } else {
                setDateFields([])
            }

        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTable(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.search, location.pathname, setServerError])

    useEffect(() => {
        if (history.action === 'REPLACE') return  // TABLE DATA IS ALREADY SET
        if (query.status === 'Archive_Doc' && (!query.month || !query.year)) return getInitialDataAndReplace()  // ON FIRST VISIT
        getInitialData() // ON PAGE REFRESH
    }, [getInitialData, getInitialDataAndReplace, location, history.action, query])


    const handleFieldChange = event => {
        // console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)
    const handleDateSubmitButtonClick = async () => {
        try {
            setError(false)
            setLoadingOnDateSubmit(true)

            const response = await axios.get(location.pathname + `/?status=${query.status}&month=${dateFields.month.value}&year=${dateFields.year.value}`)

            setTable(response.data)

            history.replace(location.pathname + `?status=${query.status}&month=${response.data.month}&year=${response.data.year}`)
        } catch (error) {
            renderFeedback('danger', error.response?.data.message || 'Server error')
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }


    useEffect(() => {
        setActiveSidebarLink(location.pathname.substring(1))  // tzs/kpi?status=Активно
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {

        // console.log(`?status=${new queryString.parse(location.search).status}`, decodeURI(location.search));
        // setActiveTopbarLink(location.pathname + decodeURI(location.search))  // /tzs/kpi?status=Ожидает
        setActiveTopbarLink(location.pathname + `?status=${new queryString.parse(location.search).status}`)  // /tzs/kpi?status=Ожидает
    }, [setActiveTopbarLink, location.pathname, location.search])

    useEffect(() => {
        setTopbarData({
            links: [
                {value: 'Ожидает', url: `/tzs/kpi?status=Ожидает`},
                {value: 'Активно', url: `/tzs/kpi?status=Активно`},
                {value: 'Завершено', url: `/tzs/kpi?status=Завершено`},
                {value: 'Alert ТЗ/АВР', url: `/tzs/kpi?status=Alert`},
                {value: 'Alert Док', url: `/tzs/kpi?status=Doc`},
                {value: 'Alert Док Архив', url: `/tzs/kpi?status=Archive_Doc`},
                {value: 'Alert Инструмент API', url: `/tzs/kpi?status=Alert_Inst_API`},
                {value: 'Alert Инструмент MANUAL', url: `/tzs/kpi?status=Alert_Inst_MANUAL`},
                // {value: 'Завершено', url: `/tzs/kpi/?status=${encodeURI('Завершено')}`},
            ],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/tzs/kpi', breadcrumb: table?.title || ''},
            // { path: '/users/:id', breadcrumb: 'id-breadcrumb' },
        ])
    }, [setBreadcrumbs, table?.title])

    const buttons = [
        // {
        //     text: 'Добавить tz',
        //     icon: 'plus',
        //     url: 'clients/new/',
        //     popup_title: 'клиент',
        // },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
        // console.log('CLIENTS: rowclick');
        history.push({
            pathname: `/tzs/kpi/${request_id}/view`,
        })
    }
    // ----------------- ROW CLICK START ---------------------

    if (loadingOnDateSubmit) return <LoaderComponent text='Loading on date submit...'/>
    if (loading) return <LoaderComponent/>
    if (error) return <div>{error.message}</div>
    return (<>
            <div className="tzs-kpi page">
            </div>

            <TableV
                table={table}
                buttons={buttons.reverse()}
                handleRowClick={handleRowClick}
                refreshData={getInitialData}

                date_fields={dateFields}
                onDateFieldsChange={handleFieldChange}
                handleDateSubmitButtonClick={handleDateSubmitButtonClick}

                href={{
                    from: '/tzs/kpi',
                    to: 'view'
                }}
            />

        </>
    )
}

export default TzsKpi


// const handleRowClick = async client_id => {
//     console.log('rowclick');
//     try {

//         const requestOne = axios.get(`clients/${client_id}/`)
//         const requestTwo = axios.get(`clients/${client_id}/brands/`)
//         const [responseOne, responseTwo] = await axios.all([requestOne, requestTwo])
//         console.log(responseOne, responseTwo);
//         history.push({
//             pathname: `clients/${client_id}/brands`,
//             state: {
//                 client: {...responseOne.data},
//                 table: {...responseTwo.data}
//             }
//         })
//         console.log(response.data);
//     } catch (error) {
//         console.log(error.message);
//         // console.log(error.response.data);
//     }
// }
