import React, {createRef, useCallback, useEffect, useRef, useState} from 'react'
import {Link} from "react-router-dom";
import './sidebar.css'

// SVGs
import {ReactComponent as Analytics} from '../../../../utils/icons/analytics.svg'
import {ReactComponent as Analytics1} from '../../../../utils/icons/analytics1.svg'
import {ReactComponent as Approved} from '../../../../utils/icons/approved.svg'
import {ReactComponent as Archive} from '../../../../utils/icons/archive.svg'
import {ReactComponent as Arrow1} from '../../../../utils/icons/arrow-1.svg'
import {ReactComponent as ArrowClose} from '../../../../utils/icons/arrow-close.svg'
import {ReactComponent as ArrowOpen} from '../../../../utils/icons/arrow-open.svg'
import {ReactComponent as Billing} from '../../../../utils/icons/Billing.svg'
import {ReactComponent as Clipboard} from '../../../../utils/icons/clipboard.svg'
import {ReactComponent as Coin1} from '../../../../utils/icons/coin1.svg'
import {ReactComponent as Contract} from '../../../../utils/icons/contract.svg'
import {ReactComponent as Customer} from '../../../../utils/icons/customer.svg'
import {ReactComponent as Exchange} from '../../../../utils/icons/exchange.svg'
import {ReactComponent as Hamburger} from '../../../../utils/icons/hamburger.svg'
import {ReactComponent as Home} from '../../../../utils/icons/home.svg'
import {ReactComponent as Hr1} from '../../../../utils/icons/hr1.svg'
import {ReactComponent as Kpi} from '../../../../utils/icons/KPI.svg'
import {ReactComponent as Logout} from '../../../../utils/icons/logout.svg'
import {ReactComponent as Manag} from '../../../../utils/icons/manag.svg'
import {ReactComponent as MmanagRep} from '../../../../utils/icons/mmanag-rep.svg'
import {ReactComponent as Money} from '../../../../utils/icons/money.svg'
import {ReactComponent as Network1} from '../../../../utils/icons/network1.svg'
import {ReactComponent as Package} from '../../../../utils/icons/package.svg'
import {ReactComponent as Pixels1} from '../../../../utils/icons/pixels1.svg'
import {ReactComponent as Target1} from '../../../../utils/icons/target-1.svg'
import {ReactComponent as UserLine} from '../../../../utils/icons/user-line.svg'
import {ReactComponent as Wallet} from '../../../../utils/icons/wallet.svg'
import {BiArchive, BiBot} from 'react-icons/bi'
import {IoIosPeople} from 'react-icons/io'
import {MdOutlineCreate} from 'react-icons/md'
import {RiQuestionAnswerLine} from 'react-icons/ri'
import {BsDot, BsFileEarmarkPerson} from 'react-icons/bs'
import {GoCalendar} from 'react-icons/go'
import {useGlobalContext} from '../../../../store/context/context';
import axios from 'axios';
import {Hint} from '../../hint/Hint';


const Sidebar = () => {
    const {
        activeSidebarLink,
        setActiveSidebarLink,
        setLoggedIn,
        setMe,
        me,
        sidebarOpen,
        setSidebarOpen
    } = useGlobalContext()

    const [icons] = useState({
        home: <Home/>,
        main: <Pixels1/>,
        clients: <Target1/>,
        tzs_kpi: <MmanagRep/>,
        tzs_billing: <Billing/>,
        fills: <Wallet/>,
        avr: <Clipboard/>,
        statistics: <Analytics1/>,
        kpi: <Kpi/>,
        archive_kpi: <Archive/>,
        average_cc: <Coin1/>,
        archive_average_cc: <BiArchive style={{width: '24px', color: '#585e69'}}/>,
        hr: <Hr1/>,
        employers: <Customer/>,
        sales_team: <Network1/>,
        departments: <IoIosPeople style={{width: '24px', color: '#585e69'}}/>,
        finance: <Money/>,
        providers: <Package/>,
        nomenclature: <Approved/>,
        exchange: <Exchange/>,
        report: <Contract/>,
        report_manager: <Manag/>,
        report_analytics: <Analytics/>,
        bot: <BiBot style={{width: '24px', color: '#585e69'}}/>,
        helper: <RiQuestionAnswerLine style={{width: '24px', color: '#585e69'}}/>,
        dot: <BsDot style={{width: '24px', color: '#585e69'}}/>,
        holidays: <GoCalendar style={{width: '24px', color: '#585e69'}}/>,
        properties_bonus: <BsFileEarmarkPerson style={{width: '24px', color: '#585e69'}}/>,
        helper_new: <MdOutlineCreate style={{width: '24px', color: '#585e69'}}/>,
    })
    const [links] = useState([
        // {
        //     icon: 'home',
        //     text: 'Главная',
        //     path: ''
        // },
        {
            icon: 'home',
            text: 'Главная',
            path: 'users/tasks/weekly'
        },
        {
            icon: 'main',
            text: 'Основное',
            innerLinks: [
                {
                    icon: 'clients',
                    text: 'Клиенты',
                    path: 'clients'
                },
                {
                    icon: 'tzs_kpi',
                    text: 'ТЗ KPI',
                    path: 'tzs/kpi',
                    query_string: '?status=Активно'
                },
                {
                    icon: 'tzs_billing',
                    text: 'ТЗ Billing',
                    path: 'tzs/billing',
                    query_string: '?status=Активно'
                },
                {
                    icon: 'fills',
                    text: 'Пополнения',
                    path: 'tzs/fills',
                    query_string: '?status=Ожидает'
                },
                {
                    icon: 'avr',
                    text: 'АВР',
                    path: 'avrs'
                },
            ]
        },
        {
            icon: 'statistics',
            text: 'Статистика',
            innerLinks: [
                {
                    icon: 'kpi',
                    text: 'KPI',
                    path: 'tzs/kpi/table'
                },
                {
                    icon: 'archive_kpi',
                    text: 'Архив KPI',
                    path: 'tzs/kpi/table/archive'
                },
                {
                    icon: 'average_cc',
                    text: 'Средняя С/С',
                    path: 'tzs/kpi/analysis/'
                },
                {
                    icon: 'archive_average_cc',
                    text: 'Архив Средняя С/С',
                    path: 'tzs/kpi/analysis/archive'
                },
            ]
        },
        {
            icon: 'hr',
            text: 'HR',
            innerLinks: [
                {
                    icon: 'employers',
                    text: 'Сотрудники',
                    path: 'users/staff'
                },
                {
                    icon: 'sales_team',
                    text: 'Команды продаж',
                    path: 'users/team'
                },
                {
                    icon: 'properties_bonus',
                    text: 'Типы клиентов',
                    path: 'properties/bonus'
                },
                {
                    icon: 'departments',
                    text: 'Отделы',
                    path: 'properties/departments'
                },
                {
                    icon: 'holidays',
                    text: 'Рабочий календарь',
                    path: 'properties/calendar/holidays'
                },
                // {
                //     icon: 'bot',
                //     text: 'Телеграм Бот',
                //     path: 'telegram/buttons'
                // },
            ]
        },
        {
            icon: 'finance',
            text: 'Финансы',
            innerLinks: [
                {
                    icon: 'providers',
                    text: 'Поставщики',
                    path: 'finance/providers'
                },
                {
                    icon: 'nomenclature',
                    text: 'Номенклатуры',
                    path: 'finance/nomenclatures'
                },
                {
                    icon: 'exchange',
                    text: 'Курсы валют',
                    path: 'finance/rates'
                },
                {
                    icon: 'providers',
                    text: 'Доп услуги',
                    path: 'finance/service_table'
                },
            ]
        },
        {
            icon: 'report',
            text: 'Отчеты',
            innerLinks: [
                {
                    icon: 'report_manager',
                    text: 'Report Manager Billing',
                    path: 'reports/manager_billing'
                },
                {
                    icon: 'report_manager',
                    text: 'Report Manager KPI',
                    path: 'reports/manager_kpi'
                },
                {
                    icon: 'report_analytics',
                    text: 'Report Analytics',
                    path: 'reports/analytics'
                },
            ]
        },
        {
            icon: 'helper',
            text: 'Helper',
            innerLinks: [
                {
                    icon: 'dot',
                    text: 'Helper 1',
                    path: 'helper/helper1'
                },
                // {
                //     icon: 'dot',
                //     text: 'Teams',
                //     path: 'helper/teams'
                // },
                // {
                //     icon: 'dot',
                //     text: 'Context',
                //     path: 'helper/context'
                // },
                // {
                //     icon: 'dot',
                //     text: 'Finance',
                //     path: 'helper/finance'
                // },
                // {
                //     icon: 'dot',
                //     text: 'HR',
                //     path: 'helper/hr'
                // },
                // {
                //     icon: 'dot',
                //     text: 'Lawyer',
                //     path: 'helper/lawyer'
                // },
                // {
                //     icon: 'helper_new',
                //     text: 'Создать',
                //     path: 'helper/new'
                // },
            ]
        },
    ])
    // const location = useLocation()

    useEffect(() => {
        const fetchMe = async () => {
            try {
                const response = await axios.get('users/me/')
                setMe(response.data)
            } catch (error) {
                console.log(error);
            }
        }

        fetchMe()
    }, [setMe])

    // const [activeSidebarLink, setActiveSidebarLink] = useState(sessionStorage.activeSidebarLink === getUrl('root') // CHECK IF THEY MATCH BECAUSE ONE CAN CHANGE THE URL AT THE TOP OF BROWSER
    //     ? sessionStorage.activeSidebarLink // THEN AS THE WEB PAGE IS NOT CLOSED YET SESSION STORAGE DOES NOT END AND THUS IF THEY MATCH JUST SHOW ANY OF THEM
    //     : getUrl('root'))  // THERE IS NOTHING IN SESSION STORAGE i.e. ONE JUST COPIED URL FROM ONE PAGE AND PASTED INTO NEW ONE AND THUS SET ROOT URL OF PASTED URL AS ACTIVE SIDEBAR LINK


    // MAIN CONSTANTS
    // const [sidebarOpen, setSidebarOpen] = useState(localStorage.sidebarOpen === 'true')
    // INNER LINKS CONSTANTS
    const reduceLinks = value => {
        return links.reduce((acc, link) => {
            if (link.hasOwnProperty('innerLinks')) {
                acc[link.text] = value === 'false' ? false : createRef()
            }
            return acc
        }, {})
    }
    const [innerLinksOpen, setInnerLinksOpen] = useState(reduceLinks('false'))
    const innerLinksRef = useRef(reduceLinks())


    // LINK CLICK HANDLER
    const handleLinkClick = useCallback(link => {
        if (link.path !== undefined) {  // BECAUSE HOME PAGE PATH IS EQUAL TO '' (empty string) AND IT DOESN'T PASS THE IF CONDITION
            setActiveSidebarLink(link.path)
            sessionStorage.setItem('activeSidebarLink', link.path)
        }
        Object.values(innerLinksRef.current).forEach(innerLinkRef => innerLinkRef.current.style.height = 0)  // CLOSE ALL INNER LINKS DIV

        if (link.innerLinks) {
            const activeInnerLinksDiv = innerLinksRef.current[link.text].current  // GET THE ACTIVE INNER LINKS DIV
            const height = activeInnerLinksDiv.firstChild.clientHeight // GET THE ACTUAL HEIHGT
            activeInnerLinksDiv.style.height = activeInnerLinksDiv.clientHeight ? 0 : height + "px"  // OPEN/CLOSE ONLY ACTIVE INNER LINKS DIV

            setInnerLinksOpen(Object.keys(innerLinksOpen).reduce((acc, innerLinkOpenKey) => { // SET THE ARROW DIRECTIONS ACCORDINGLY
                acc[innerLinkOpenKey] = innerLinkOpenKey === link.text && !activeInnerLinksDiv.clientHeight
                return acc
            }, {}))
        } else {
            setInnerLinksOpen(Object.keys(innerLinksOpen).reduce((acc, innerLinkOpenKey) => {  // SET ALL ARROW DIRECTIONS TO OPEN
                acc[innerLinkOpenKey] = false
                return acc
            }, {}))
        }
    }, [innerLinksOpen, setActiveSidebarLink])

    const handleNavLinkClick = (event, link) => {  // OTHERWISE BROWESER ROUTE WILL END WITH /undefined (i.e. localhost:4000/undefined) AS PARENT LINK HAS NO LINK KEY
        if (link === undefined) return event.preventDefault()
    }
    const handleInnerNavLinkClick = (path) => {
        setActiveSidebarLink(path);
        sessionStorage.setItem('activeSidebarLink', path)
    }


    const getClassname = (link, innerLinks) => {  // link OR innerLinks IS TRUE i.e. ONLY ONE OF THEM HAS VALUE THE OTHER IS UNDEFINED
        // console.log(link, innerLinks, activeSidebarLink);
        if (activeSidebarLink === link && activeSidebarLink !== undefined) return `sidebar__middle__link active_link`  // SIMPLE SIDEBAR LINK WITHOUT INNER LINKS IS ACTIVE
        if (innerLinks && innerLinks.find((innerLink) => innerLink.path === activeSidebarLink)) return `sidebar__middle__link active_inner_link` // SIDEBAR LINK WITH INNER LINKS IS ACTIVE
        return `sidebar__middle__link`  // DEFAULT CASE
    }

    useEffect(() => {  // OPEN INNER_LINKS_DIV IF ACTIVE ON PAGE LOAD AND SET ARROWS ACCORDINGLY
        const link = links.find(link => link.path === activeSidebarLink || link.innerLinks?.find(innerLink => innerLink.path === activeSidebarLink))
        Object.values(innerLinksRef.current).forEach(innerLinkRef => innerLinkRef.current.style.height = 0)  // CLOSE ALL INNER LINKS DIV

        if (link?.innerLinks) {
            const activeInnerLinksDiv = innerLinksRef.current[link.text].current  // GET THE ACTIVE INNER LINKS DIV
            const height = activeInnerLinksDiv.firstChild.clientHeight // GET THE ACTUAL HEIHGT
            activeInnerLinksDiv.style.height = height + "px"  // OPEN/CLOSE ONLY ACTIVE INNER LINKS DIV

            setInnerLinksOpen(Object.keys(innerLinksOpen).reduce((acc, innerLinkOpenKey) => { // SET THE ARROW DIRECTIONS ACCORDINGLY
                acc[innerLinkOpenKey] = innerLinkOpenKey === link.text && !activeInnerLinksDiv.clientHeight
                return acc
            }, {}))
        }
    }, [activeSidebarLink, links])  // innerLinksOpen AS A DEPENDENCY WILL RESULT IN INFINITE RERENDER

    const logout = () => {
        console.log('Logging out...');
        setActiveSidebarLink('');
        sessionStorage.removeItem('activeSidebarLink')
        setLoggedIn(false)
        localStorage.removeItem('token')
    }

    const getLink = ({text, path, icon, innerLinks}) => {
        return <Link to={`/${path}`} className={getClassname(path, innerLinks)}
                     onClick={(event) => handleNavLinkClick(event, path)}>
            <span className="svg-container">{icons[icon]}</span>
            <span className="sidebar__middle__link-text">{text}</span>
            {innerLinks && <span className="svg-container">{innerLinksOpen[text] ? <ArrowClose/> : <ArrowOpen/>}</span>}
        </Link>
    }

    const getInnerLink = ({path, icon, text, query_string = ''}) => {
        return <Link to={`/${path}${query_string}`} key={text}
                     className={`sidebar__middle__innerLink ${activeSidebarLink === path ? 'active' : ''}`}
                     onClick={() => handleInnerNavLinkClick(path)}>
            <span className="svg-container innerLink">{icons[icon]}</span>
            <span className="sidebar__middle__link-text">{text}</span>
        </Link>
    }
    return (
        <div className={sidebarOpen ? "sidebar open" : "sidebar"}>
            <div className="sidebar__top" onClick={() => {
                setSidebarOpen(!sidebarOpen);
                localStorage.setItem('sidebarOpen', !sidebarOpen)
            }}>
                <button className="sidebar__top__btn open-sidebar-btn svg-container">
                    <Hamburger className="white"/>
                </button>
                <button className="sidebar__top__btn close-sidebar-btn svg-container" style={{marginLeft: 'auto'}}>
                    <Arrow1 className="white"/>
                </button>
            </div>

            <div className="sidebar__middle">
                <ul className="sidebar__middle__links">
                    {links.map((singleLink, index) => {
                        const {text, innerLinks} = singleLink
                        return <li key={index} onClick={() => handleLinkClick(singleLink)}>
                            {sidebarOpen ? getLink(singleLink) :
                                <Hint hint={text} placement='right'>{getLink(singleLink)}</Hint>}

                            {innerLinks && // IF THERE ARE INNER LINKS, THEN DRAW THEM
                                <div ref={innerLinksRef.current[text]} className="sidebar__middle__innerLinks">
                                    <div onClick={e => e.stopPropagation()}>
                                        {innerLinks.map(innerLink => {
                                            return sidebarOpen ? getInnerLink(innerLink) :
                                                <React.Fragment key={innerLink.text}><Hint hint={innerLink.text}
                                                                                           placement='right'>{getInnerLink(innerLink)}</Hint></React.Fragment>
                                        })}
                                    </div>
                                </div>
                            }
                        </li>
                    })}
                </ul>
            </div>

            <div className="sidebar__bottom">
                {sidebarOpen
                    ? <>
                        <button className="sidebar__bottom__profile-btn svg-container">
                            <UserLine/>
                        </button>
                        <span className="sidebar__bottom__username">
                    {me?.name}
                </span>
                        <button className="sidebar__bottom__logout-btn svg-container" onClick={logout}>
                            <Logout/>
                        </button>
                    </>
                    :
                    <Hint hint="Выйти" placement="right">
                        <button className="sidebar__bottom__logout-btn svg-container" onClick={logout}>
                            <Logout/>
                        </button>
                    </Hint>
                }
            </div>
        </div>
    )

}

export default Sidebar

// const Hint = ({children, hint, placement='bottom'}) => {
//     return (
//         <div className="sidebar_closed_hint">
//             <Tippy
//                 content={<div>{hint || 'Тут должна быть подсказка'}</div>}
//                 placement={placement}
//                 interactive={false}
//             >
//                 {children}
//             </Tippy>
//         </div>
//     )
// }
