import axios from 'axios';
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory, useLocation, useParams} from 'react-router';
import {useGlobalContext} from '../../store/context/context';
import './avrs.css'
import {LoaderComponent} from '../../components/layout/loader/Loader';
import queryString from 'query-string'
import SwipeUp from '../../components/layout/swipe-up/SwipeUp';
import {TableV} from '../../components/layout/table/TableV';
import {TableVCopy} from "../../components/layout/table/TableVCopy";


export const Avrs = () => {
    const {
        setPopup,
        LLPs,
        setLLPs,
        avrStatuses,
        setAvrStatuses,
        setActiveTopbarLink,
        setActiveTopbarSubLink,
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        setShowFeedback,
        renderFeedback,
        setServerError
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const {id} = useParams()
    const location = useLocation();

    const {
        llp: selectedLLP,
        status: selectedStatus
    } = useMemo(() => new queryString.parse(location.search), [location.search])
    const history = useHistory()
    const [loadingLLPs, setLoadingLLPs] = useState(true)
    const [table, setTable] = useState({})
    const [loadingTable, setLoadingTable] = useState(true)
    const [errorTable, setErrorTable] = useState(null)

    const getInitialData = useCallback(async () => {
        try {
            setLoadingLLPs(true)
            if (selectedLLP && selectedStatus && !LLPs.length && !avrStatuses.length) {
                console.log('1');
                const responseLLPs = await axios.get('properties/llps/')
                setLLPs(responseLLPs.data)
                const responseAvrStatuses = await axios.get(`avrs/statuses/?llp=${selectedLLP}`)
                setAvrStatuses(responseAvrStatuses.data)
                // const responseTable = await axios.get(`${location.pathname}/?llp=${responseLLPs.data[0]}&status=${responseAvrStatuses.data[0]}`)
                // setTable(responseTable.data)
            } else if (selectedLLP && LLPs.length && !selectedStatus) {
                console.log('2');

                const responseAvrStatuses = await axios.get(`avrs/statuses/?llp=${selectedLLP}`)
                setAvrStatuses(responseAvrStatuses.data)
                // const responseTable = await axios.get(`${location.pathname}/?llp=${selectedLLP}&status=${responseAvrStatuses.data[0]}`)
                // setTable(responseTable.data)
                const status = !!responseAvrStatuses.data.length ? responseAvrStatuses.data[0] : null
                history.replace({
                    pathname: `/avrs`,
                    search: `?llp=${encodeURIComponent(selectedLLP)}&status=${status}`,
                })
            } else if (!selectedLLP && !selectedStatus) {
                console.log('3');

                const responseLLPs = await axios.get('properties/llps/')
                setLLPs(responseLLPs.data)
                const responseAvrStatuses = await axios.get(`avrs/statuses/?llp=${responseLLPs.data[0]}`)
                setAvrStatuses(responseAvrStatuses.data)
                // const responseTable = await axios.get(`${location.pathname}/?llp=${responseLLPs.data[0]}&status=${responseAvrStatuses.data[0]}`)
                // setTable(responseTable.data)
                history.replace({
                    pathname: `/avrs`,
                    search: `?llp=${encodeURIComponent(responseLLPs.data[0])}&status=${responseAvrStatuses.data[0]}`,
                })
            }
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
        } finally {
            setLoadingLLPs(false)
        }
    }, [LLPs.length, avrStatuses.length, selectedLLP, selectedStatus, setAvrStatuses, setLLPs, history, setLoadingLLPs, setServerError])
    useEffect(() => {
        getInitialData()
    }, [getInitialData])

    const getTable = useCallback(async () => {
        try {
            setLoadingTable(true)
            const response_table = await axios.get(`${location.pathname}/${location.search}`)
            setTable(response_table.data)
        } catch (error) {
            setErrorTable(error.response?.data || 'Server error')
        } finally {
            setLoadingTable(false)
        }
    }, [location.pathname, location.search])

    useEffect(() => {
        if (!selectedLLP || !selectedStatus) return
        getTable()
    }, [getTable, selectedStatus, selectedLLP])


    useEffect(() => {
        console.log(location.pathname.substring(1));  // clients
        setActiveSidebarLink('avrs')
    }, [setActiveSidebarLink, location.pathname,])

    useEffect(() => {
        setActiveTopbarLink(location.pathname + "?llp=" + selectedLLP)
        setActiveTopbarSubLink(location.pathname + decodeURI(location.search))
    }, [setActiveTopbarLink, location.pathname, location.search, setActiveTopbarSubLink, selectedLLP])

    useEffect(() => {
        if (!LLPs.length) return
        // const { llps } = location.state
        console.log(LLPs);
        setTopbarData({
            links: LLPs?.map(llp => {
                return {value: llp, url: `${location.pathname}?llp=${llp}`}
            }),
            sublinks: avrStatuses?.map(status => {
                return {value: status, url: `${location.pathname}?llp=${selectedLLP}&status=${status}`}
            }),
        })
    }, [setTopbarData, location.pathname, LLPs, avrStatuses, selectedLLP])

    useEffect(() => {
        setBreadcrumbs([
            {path: `${location.pathname}`, breadcrumb: `${table.title || ''}`},
        ])
    }, [setBreadcrumbs, id, location.pathname, table.title])


    const [loading, setLoading] = useState(false)
    const [selectAllValue, setSelectAllValue] = useState(true)
    const buttons = useMemo(() => {
        let dynamicBtns = []

        if (table.approve) {
            dynamicBtns.push({
                text: loading ? `Отправляется...` : `Подтвердить выбранные АВР`,
                icon: 'analysis',
                async onClick(rows, table, columnAccessors) {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.post(`avrs/approve/?llp=${selectedLLP}`, rows)
                        // console.log(response.data);
                        const {message} = response.data
                        renderFeedback('success', message || 'Success')
                        const responseAvrStatuses = await axios.get(`avrs/statuses/?llp=${selectedLLP}`)
                        setAvrStatuses(responseAvrStatuses.data)
                        const status = !!responseAvrStatuses.data.length ? responseAvrStatuses.data[0] : null
                        history.replace({
                            pathname: `/avrs`,
                            search: `?llp=${encodeURIComponent(selectedLLP)}&status=${status}`,
                        })
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
            })
        }

        if (selectedStatus === 'Ожидает' || selectedStatus === 'Подтверждено') {
            dynamicBtns.push({
                text: selectAllValue ? `Выбрать все` : `Убрать все`,
                icon: selectAllValue ? 'check' : 'uncheck',
                async onClick(rows, table, columnAccessors, setRows) {
                    if (loading) return
                    const updatedRows = rows.map(row => ({...row, selected: {...row.selected, value: selectAllValue}}))
                    setRows(updatedRows)
                    setSelectAllValue(oldValue => !oldValue)
                },
            })
        }

        if (table.download) {
            dynamicBtns.push({
                text: loading ? `Скачивается...` : `Скачать`,
                icon: 'download',
                async onClick(rows) {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.post(`avrs/download/?llp=${selectedLLP}`, rows, {responseType: 'blob'})
                        // --------------
                        var reader = new FileReader();
                        reader.onload = function () {
                            console.log(response.headers);
                            const filename = response.headers['content-disposition'].split('filename=')[1];
                            const url = window.URL.createObjectURL(new Blob([JSON.parse(reader.result)], {
                                type: 'application/json',
                                encoding: 'UTF-8'
                            }));
                            // let url = window.URL.createObjectURL(blob);
                            console.log(url);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = filename || 'test.json';
                            a.click();
                            URL.revokeObjectURL(url);
                            // window.location.href = response.url;
                            // setMessage('АВР скачаны')
                            renderFeedback('success', `Файл с названием ${filename} скачан`)
                        }
                        reader.readAsText(response.data);
                        // --------------

                        const responseAvrStatuses = await axios.get(`avrs/statuses/?llp=${selectedLLP}`)
                        setAvrStatuses(responseAvrStatuses.data)
                        const status = !!responseAvrStatuses.data.length ? responseAvrStatuses.data[0] : null
                        history.replace({
                            pathname: `/avrs`,
                            search: `?llp=${encodeURIComponent(selectedLLP)}&status=${status}`,
                        })
                    } catch (error) {
                        // console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
            })
        }


        if (location.pathname.endsWith('edit')) {
            const avrEditStaticBtns = [  // ON VIEW PAGE FOR BOTH KPI/BILLING
                // {
                //     text: `Готово`,
                //     icon: 'confirm',
                //     async onClick() {
                //         // try {
                //         //     console.log(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`);
                //         //     // console.log(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/edit/head/`);
                //         //     await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/edit/head/`)
                //         //     history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`)
                //         // } catch (error) {
                //         //     renderFeedback('danger', error.response?.data?.message || 'Server Error')
                //         // }
                //     },
                // }
            ]
            return [...avrEditStaticBtns, ...dynamicBtns]
        }
        const avrViewStaticBtns = [  // ON VIEW PAGE FOR BOTH KPI/BILLING
            // {
            //     text: `Редактировать`,
            //     icon: 'edit',
            //     async onClickFunction() {
            //         try {
            //             console.log(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`);
            //             // console.log(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/edit/head/`);
            //             await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/edit/head/`)
            //             history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`)
            //         } catch (error) {
            //             renderFeedback('danger', error.response?.data?.message || 'Server Error')
            //         }
            //     },
            // },
        ]
        console.log(dynamicBtns);
        return [...avrViewStaticBtns, ...dynamicBtns]
    }, [location.pathname, renderFeedback, loading, table, selectedLLP, setAvrStatuses, history, selectedStatus, selectAllValue])

    useEffect(() => {  // ON LOCATION.SEARCH CHANGE IF POPUP IS OPEN THEN CLOSE IT AUTOMATICALLY
        setPopup({
            visible: false,
            data: {},
            url: null,
            title: ''
        })
    }, [location.pathname, setPopup, location.search])


    // ----------------- ROW CLICK START ---------------------
    const [showSwipeUp, setShowSwipeUp] = useState(false)
    const [swipeUpData, setSwipeUpData] = useState({})
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
        try {
            if (table.finance) {
                console.log('finance');
                // setSwipeUpInfo({visible: true, data: t})
                const response = await axios.get(`avrs/${request_id}/preview/`)
                setShowSwipeUp(true)

                const dynamicBtns = []
                if (response.data.decline) {
                    dynamicBtns.push({
                        text: loading ? `Отклоняется...` : `Отклонить АВР`,
                        icon: 'analysis',
                        async onClick(rows, table, columnAccessors) {
                            if (loading) return
                            try {
                                setLoading(true)
                                const response = await axios.get(`avrs/${request_id}/preview/decline/`)
                                // console.log(response.data);
                                const {message} = response.data
                                renderFeedback('success', message || 'Success')
                                const responseAvrStatuses = await axios.get(`avrs/statuses/?llp=${selectedLLP}`)
                                setAvrStatuses(responseAvrStatuses.data)
                                const status = !!responseAvrStatuses.data.length ? responseAvrStatuses.data[0] : null
                                history.replace({
                                    pathname: `/avrs`,
                                    search: `?llp=${encodeURIComponent(selectedLLP)}&status=${status}`,
                                })
                                setShowSwipeUp(false)
                                setSwipeUpData({table: null, buttons: []})
                            } catch (error) {
                                // console.log(error.message);
                                renderFeedback('danger', error.response?.data?.message || 'Server Error')
                            } finally {
                                setLoading(false)
                            }
                        },
                    })
                }
                setSwipeUpData({
                    table: response.data,
                    buttons: [
                        {  // COPY AVR
                            text: 'Скопировать АВР',
                            icon: 'group',
                            onClick(rows, table, columnAccessors) {
                                try {
                                    let $table = document.createElement('table')
                                    let $thead = document.createElement('thead')
                                    let $tbody = document.createElement('tbody')

                                    let $tr = document.createElement('tr')
                                    columnAccessors.forEach(accessor => {
                                        // const header = headers[accessor]
                                        if (!table.headers[accessor].visible) return  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                                        let $th = document.createElement('th')
                                        const {label} = table.headers[accessor]
                                        // console.log(label);
                                        $th.innerHTML = label
                                        $tr.appendChild($th)
                                    })
                                    $thead.appendChild($tr)

                                    table.rows.forEach(row => {
                                        let $tr = document.createElement('tr')

                                        columnAccessors.forEach(accessor => {
                                            const {value} = row[accessor]
                                            if (!table.headers[accessor].visible) return   // MEANS THIS COLUMN IS HIDDEN IN DND BY USER

                                            let $td = document.createElement('td')
                                            $td.innerHTML = value
                                            $tr.appendChild($td)
                                        })

                                        $tbody.appendChild($tr)
                                    })

                                    $table.appendChild($thead)
                                    $table.appendChild($tbody)
                                    console.log($table);
                                    $table.setAttribute('id', 'tableToCopyyy')
                                    document.body.appendChild($table)
                                    const copyElementToClipboard = (element) => {
                                        window.getSelection().removeAllRanges();
                                        let range = document.createRange();
                                        range.selectNode(typeof element === 'string' ? document.getElementById(element) : element);
                                        window.getSelection().addRange(range);
                                        document.execCommand('copy');
                                        window.getSelection().removeAllRanges();
                                        document.body.removeChild($table)
                                    }
                                    copyElementToClipboard('tableToCopyyy')
                                    renderFeedback('success', 'Таблица успешно скопирована')
                                } catch (error) {
                                    renderFeedback('danger', 'Что-то пошло не так при попытке скопировать таблицу')
                                }
                            }
                        },
                        {  // GO INTO AVR
                            text: 'Провалиться в АВР',
                            icon: 'forward',
                            async onClick(rows, table, columnAccessors) {
                                try {
                                    await axios.get(`${location.pathname}/${request_id}/view/head/`)
                                    history.push({
                                        pathname: `/avrs/${request_id}/view`,
                                    })
                                } catch (error) {
                                    renderFeedback('danger', error.response?.data.message || 'Server Error')
                                }
                            }
                        },
                        ...dynamicBtns
                    ]
                })
                return
            }

            await axios.get(`${location.pathname}/${request_id}/view/head/`)
            history.push({
                pathname: `/avrs/${request_id}/view`,
            })
        } catch (error) {
            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- ROW CLICK STOP ---------------------


    /////////////////////////////////////////////////


    if (loadingLLPs) return <div><LoaderComponent text="Loading LLPs..."/></div>

    return (<>
            <div className='avrs page'>
            </div>

            {loadingTable
                ? <LoaderComponent/>
                : errorTable
                    ? <div>{errorTable.message}</div>
                    : <TableV
                        table={table}
                        buttons={buttons}
                        handleRowClick={handleRowClick}
                        refreshData={getTable}
                        // paginationEnable={25}
                    />}

            <SwipeUp showSwipeUp={showSwipeUp} setShowSwipeUp={setShowSwipeUp} swipeUpData={swipeUpData}
                     setSwipeUpData={setSwipeUpData}/>
        </>
    )
}
