import React, {useMemo, useState} from "react";
import getFilteredRows from "../../components/layout/table/getFilteredRows";

function usePagination(data, itemsPerPage, filter, sortOrder, sortedColumnAccessor) {
    const [currentPage, setCurrentPage] = useState(1);
    const data1 = useMemo(() => {
        return getFilteredRows(data, filter)
    }, [filter, sortOrder, sortedColumnAccessor, data])
    const maxPage = Math.ceil(data1.length / itemsPerPage);

    function currentData() {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data1.slice(begin, end);
    }

    function next() {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    }

    function prev() {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }

    function jump(page) {
        const pageNumber = Math.max(1, page);
        setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
    }

    return { next, prev, jump, currentData, currentPage, maxPage };
}

export default usePagination;
