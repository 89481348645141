import React, {forwardRef} from 'react'
import {BsCurrencyExchange} from 'react-icons/bs';
import DateNumberField from '../../../forms/date/DateNumber';
import DateRangeField from '../../../forms/date/DateRange';
import {SelectPositionedField} from '../../../forms/select/SelectPositioned';
import {HintHeader} from '../../hint/Hint'


const CurrencyIcon = forwardRef((props, ref) => {
    return <span ref={ref}><BsCurrencyExchange/>:</span>;
});
const DateRangeFieldFwdRef = forwardRef((props, ref) => {
    const {name, value, onChange} = props
    return <div ref={ref}>
        <DateRangeField label="" name={name} value={value} onChange={onChange} editable/>
    </div>
});

export const TableDateFields = ({date_fields, onDateFieldsChange}) => {
    return (
        <div className="table-date-fields">
            {Object.keys(date_fields).map((date_field_accessor, index) => {
                const {label, value, type, options} = date_fields[date_field_accessor]
                return <div key={index} className="table-date-field">
                    {label && <p className='table-date-field__label'>
                        {date_field_accessor === 'currency'
                            ? <HintHeader hint={label}>
                                <CurrencyIcon/>
                            </HintHeader>
                            : type === 'date_range_field'
                                ? null
                                : `${label}:`
                        }
                    </p>
                    }
                    {type === 'span'
                        ? <span className='table-date-field__span'>{value}</span>
                        : type === 'select_field'
                            ? <SelectPositionedField label="" name={date_field_accessor} value={value} options={options}
                                                     onChange={onDateFieldsChange} editable/>
                            : type === 'date_range_field'
                                ? <HintHeader hint={label}>
                                    <DateRangeFieldFwdRef label="" name={date_field_accessor} value={value}
                                                          onChange={onDateFieldsChange} editable/>
                                </HintHeader>
                                : <DateNumberField label="" name={date_field_accessor} value={value}
                                                   onChange={onDateFieldsChange} editable/>
                    }
                </div>
            })}
        </div>
    )
}
