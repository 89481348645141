import React, {useCallback, useEffect, useState} from 'react'
import './popup.css'

import CloseButton from '../../buttons/close/CloseButton'
// import Radio from '../../forms/radio/Radio'
// import SelectField from '../../forms/select/Select_1'
import RadioField from '../../forms/radio/Radio'
import DateField from '../../forms/date/Date'
import NumberField from '../../forms/number/Number'
import axios from 'axios'
import {useGlobalContext} from '../../../store/context/context'
import MultiSelectField from '../../forms/multi_select/MultiSelect'
import {SelectField} from '../../forms/select/Select'
import TextFieldTextArea from '../../forms/text/TextTextArea'
import {LoaderComponent} from '../loader/Loader'

export const PopupFillKpi = () => {
    const {
        popupFill: {data, url, title, button: {visible = true, text = 'Отправить'} = {}} = {},
        setPopupFill,
        renderFeedback,
        setShowFeedback
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const [formFields] = useState({
        text_field: TextFieldTextArea,
        select_field: SelectField,
        date_field: DateField,
        radio_field: RadioField,
        number_field: NumberField,
        multi_select_field: MultiSelectField
    })
    const {children, ...parent} = data
    // console.log(parent);
    const [formDataParent, setFormDataParent] = useState(parent)
    const [formDataChildren, setFormDataChildren] = useState(children)
    const [loading, setLoading] = useState(false)
    // const history = useHistory()

    const handleParentFieldChange = event => {
        // console.log(event);
        console.log({[event.target.name]: event.target.value});
        setFormDataParent({
            ...formDataParent,
            [event.target.name]: {
                ...formDataParent[event.target.name],
                value: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
            }
        })
    }
    const calculateSum = formDataChildrenCopy => formDataChildrenCopy.reduce((previousValue, currentValue) => currentValue['fill_sum_contract'].value + previousValue, 0)

    const [sum, setSum] = useState(calculateSum(formDataChildren))

    const handleChildrenFieldChange = (event, index) => {
        // console.log({[event.target.name]: event.target.value});
        const formDataChildrenCopy = JSON.parse(JSON.stringify(formDataChildren))

        if (event.target.name === 'fill_sum_contract') {   // ONLY 'fill_sum_contract' IS CHANGING  // NECESSARY TO CHECK CAUSE WHEN CHANGING UNEDITABLE NUMBER_FIELDS THEY AS WELL CALL THIS FUNCTION
            formDataChildrenCopy[index][event.target.name].value = event.target.value
            formDataChildrenCopy[index]['fill_sum_account'].value = event.target.value / formDataChildrenCopy[index]['fill_exchange_cost'].value

            const sum = calculateSum(formDataChildrenCopy)

            formDataChildrenCopy.map(child => {
                child['fill_spread'].value = Number((Number(child['fill_sum_contract'].value) * 100 / sum).toFixed(2))
                return child
            })

            setSum(sum)
            setFormDataChildren(formDataChildrenCopy)
        } else {
            formDataChildrenCopy[index][event.target.name].value = event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
            setFormDataChildren(formDataChildrenCopy)
        }
    }

    const checkFormForCorrectness = formData => Object.keys(formData).every(accessor => !formData[accessor].required || !formData[accessor].editable || !!String(formData[accessor].value))
    const handleSubmit = useCallback(async event => {
        // console.log(event);
        event && event.preventDefault()
        console.log('submitting...');
        try {
            const areAllFieldsFilled = checkFormForCorrectness(formDataParent) && formDataChildren.every(checkFormForCorrectness)
            if (!areAllFieldsFilled) {
                renderFeedback('danger', 'Пожалуйста, заполните все обязательные поля!')
                return
            }
            setLoading(true)
            const response = await axios.post(url, {...formDataParent, children: [...formDataChildren]})
            // console.log(response.data);
            renderFeedback('success', response.data.message || 'Success')
            setPopupFill({visible: false, data: {}, url: null})
            // setTimeout(() => {
            //     window.location.reload();
            // }, 500)
            // history.go(0)
        } catch (error) {
            // console.log(error.message, error.response.data.message);
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        } finally {
            setLoading(false)
        }
    }, [formDataChildren, formDataParent, renderFeedback, setPopupFill, url])

    useEffect(() => {
        function handleEscKeyClick(event) {
            if (loading) return
            const key = event.key; // const {key} = event; in ES6+
            if (key === "Escape") {
                setPopupFill({visible: false, data: {}, url: null})
            }

            if (key === 'Enter') {
                console.log(event.target.classList.contains('text__input'))
                if (event.target.classList.contains('text__input')) return
                handleSubmit()
            }
        }

        document.addEventListener('keydown', handleEscKeyClick);

        return () => document.removeEventListener('keydown', handleEscKeyClick)
    }, [setPopupFill, handleSubmit, loading])

    const closePopup = () => setPopupFill({visible: false, data: {}})

    if (loading) return (
        <div className="popup-container popup-fill-kpi background">
            <div className="popup" style={{width: '90%'}} onDoubleClick={event => event.stopPropagation()}>
                <LoaderComponent text="Отправляется..."/>
            </div>
        </div>
    )

    return (
        <div className="popup-container popup-fill-kpi background" onDoubleClick={closePopup}>
            <div className="popup" style={{width: '90%'}} onDoubleClick={event => event.stopPropagation()}>
                {/* <div className="popup-outer"> */}
                <h2 className="popup__title">{title}</h2>
                <div className="popup__close-btn-container" onClick={closePopup}>
                    <CloseButton/>
                </div>
                <div className="popup__content">
                    <form autoComplete="off" className="popup__form" style={{overflow: 'unset'}}>
                        {Object.keys(formDataParent).map((accessor, index) => {

                            if (formDataParent[accessor] instanceof String || typeof formDataParent[accessor] === 'string') {
                                return <p key={accessor} className="popup__form__subtitle">{accessor}</p>
                            }
                            const {hint, label, type, value, options, editable, required} = formDataParent[accessor]
                            // console.log(formData[accessor]);
                            const FormField = formFields[type]
                            return <FormField key={accessor} label={label} name={accessor} value={value}
                                              options={options} editable={editable} required={required} hint={hint}
                                              onChange={handleParentFieldChange}/>
                        })}
                    </form>
                    <hr className="popup__hr"/>
                    <form autoComplete="off" className="popup__form">
                        {formDataChildren.map((child, child_index) => {
                            return <div key={child_index} className="popup__form__children">
                                {Object.keys(child).map(accessor => {
                                    if (child[accessor] instanceof String || typeof child[accessor] === 'string') {
                                        return <p key={accessor} className="popup__form__subtitle">{child[accessor]}</p>
                                    }
                                    const {hint, label, type, value, options, editable, required} = child[accessor]
                                    // console.log(formData[accessor]);
                                    const FormField = formFields[type]
                                    return (<>
                                        <FormField key={accessor} label={label} name={accessor} value={value}
                                                   options={options} editable={editable} required={required} hint={hint}
                                                   onChange={event => handleChildrenFieldChange(event, child_index)}/>
                                    </>)
                                })}
                            </div>
                        })}
                    </form>
                    <h3 className="popup__fill-total">
                        <span className="popup__fill-total__label">Итого:</span>
                        <span className="popup__fill-total__value">{sum}</span>
                    </h3>
                    {visible && <button type="submit" className="popup__submit-btn button"
                                        onClick={handleSubmit}>{text}</button>}
                </div>
            </div>
        </div>
    )
}
