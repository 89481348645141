import React, {useCallback, useEffect, useState} from 'react'
import './reports.css'

import {useGlobalContext} from '../../store/context/context'
import {useHistory, useLocation} from 'react-router'
import axios from 'axios'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import {TableV} from '../../components/layout/table/TableV'
import {useCancelToken} from '../../utils/js/useCancelToken'
import downloadCSV from '../../utils/js/downloadCSV'

// function useQuery() {
//     return new queryString.parse(useLocation().search);
// }

export const ReportsAnalytics = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        renderFeedback,
        setShowFeedback,
        setServerError
    } = useGlobalContext()
    const {newCancelToken, isCancel} = useCancelToken();

    const [didMount, setDidMount] = useState(false);
    useEffect(() => {
        setDidMount(true);
        return () => setDidMount(false);
    }, [])

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const history = useHistory()
    const location = useLocation()
    // console.log(location);

    // const query = useQuery()
    // console.log(query);

    const [dateFields, setDateFields] = useState({
        // month: {
        //     label: 'month',
        //     value: response.data.month || '',
        //     type: 'select_field',
        //     options: [],
        // },
        // year: {
        //     label: 'year',
        //     value: response.data.year || '',
        //     type: 'select_field',
        //     options: [],
        // }
    })
    // const [downloadIncomeFields, setDownloadIncomeFields] = useState({
    //     month: new Date().getMonth(),
    //     year: new Date().getFullYear(),
    //     llp: '',
    // })

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [table, setTable] = useState({})

    const getInitialDataAndReplace = useCallback(async () => {
        console.log('getInitialDataAndReplace called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get(location.pathname + '/', {
                cancelToken: newCancelToken()
            })
            setTable(response.data)

            if (response.data.month && response.data.year) {
                setDateFields({
                    month: {
                        label: 'месяц',
                        value: response.data.month || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1)
                    },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    }
                })
            } else {
                setDateFields([])
            }

            history.replace(location.pathname + `?month=${response.data.month}&year=${response.data.year}`)

        } catch (error) {
            if (isCancel(error)) return console.log('request cancelled: ReportsAnalytics');

            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setError(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, history, setServerError, newCancelToken, isCancel])
    // console.log(table);

    const getInitialData = useCallback(async () => {
        console.log('getInitialData called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get(location.pathname + '/' + location.search, {
                cancelToken: newCancelToken()
            })
            setTable(response.data)
            if (response.data.month && response.data.year) {
                setDateFields({
                    month: {
                        label: 'месяц',
                        value: response.data.month || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1)
                    },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    }
                })
            } else {
                setDateFields([])
            }
        } catch (error) {
            if (isCancel(error)) return console.log('request cancelled: ReportsManager');

            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setError(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, location.search, setServerError, newCancelToken, isCancel])

    useEffect(() => {
        // console.log(location, history.action)
        if (history.action === 'REPLACE') return
        if (!location.search) return getInitialDataAndReplace()
        getInitialData()
    }, [getInitialData, getInitialDataAndReplace, location, history.action])


    const handleFieldChange = event => {
        console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)
    const handleDateSubmitButtonClick = async () => {
        console.log('Called........  2');
        try {
            setError(false)
            setLoadingOnDateSubmit(true)

            const response = await axios.get(location.pathname + `/?month=${dateFields.month.value}&year=${dateFields.year.value}`)
            setTable(response.data)
            history.replace(location.pathname + `?month=${response.data.month}&year=${response.data.year}`)

        } catch (error) {
            console.log(error.message);
            renderFeedback('danger', error.response?.data.message || 'Server error')
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }


    useEffect(() => {
        setActiveSidebarLink(location.pathname.substring(1))  // tzs/kpi?status=Активно
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/tzs/fills', breadcrumb: table?.title || '', clickable: false},
        ])
    }, [setBreadcrumbs, table?.title])

    const buttons = [
        {
            text: 'Скачать',
            icon: 'download',
            onClick() {
                try {
                    downloadCSV(table, table.rows, "Report Analytics.csv")
                } catch (error) {
                    console.error(error);
                    renderFeedback('danger', error.message || 'Unknown error!')
                }
            }
        },
    ]


    // const handleDownloadIncomeFieldChange = event => {
    //     console.log({[event.target.name]: event.target.value});
    //     setDownloadIncomeFields({
    //         ...downloadIncomeFields,
    //         [event.target.name]: event.target.value
    //     })
    // }

    // const submitDownloadIncomeForm = async (event) => {
    //     event.preventDefault()
    //     try {
    //         // setLoading(true)
    //         const url = `reports/revenue/?llp=${downloadIncomeFields.llp}&month=${downloadIncomeFields.month}&year=${downloadIncomeFields.year}`
    //         // const response = await axios.post(`avrs/download/?llp=${selectedLLP}`, rows, { responseType: 'blob' })
    //         const response = await axios.get(url, {responseType: 'blob'})
    //         // --------------
    //         var reader = new FileReader();
    //         reader.onload = function () {
    //             console.log(response.headers);
    //             const filename = response.headers['content-disposition'].split('filename=')[1];
    //             const url = window.URL.createObjectURL(new Blob([JSON.parse(reader.result)], {
    //                 type: 'application/json',
    //                 encoding: 'UTF-8'
    //             }));
    //             // let url = window.URL.createObjectURL(blob);
    //             console.log(url);
    //             let a = document.createElement('a');
    //             a.href = url;
    //             a.download = filename || 'test.json';
    //             a.click();
    //             URL.revokeObjectURL(url);
    //             // window.location.href = response.url;
    //             // setMessage('АВР скачаны')
    //             renderFeedback('success', `Файл с названием ${filename} скачан`)
    //
    //         }
    //         reader.readAsText(response.data);
    //         // --------------
    //     } catch (error) {
    //         // console.log(error.message);
    //         renderFeedback('danger', error.response?.data?.message || 'Server Error')
    //     } finally {
    //         // setLoading(false)
    //     }
    // }

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        // const request_id = row['request_id'].value
        //row['request_id'].value,
    }
    // ----------------- ROW CLICK START ---------------------

    if (!didMount) return null;

    // if (loading) return <LoaderComponent />
    if (error) return <div>{error.message}</div>
    return (<>
            <div className="reports page">
            </div>

            {loadingOnDateSubmit || loading
                ? <LoaderComponent text={loadingOnDateSubmit ? 'Loading on date submit...' : 'Загрузка...'}/>
                : <TableV
                    table={table}
                    date_fields={dateFields}
                    buttons={buttons.reverse()}
                    handleRowClick={handleRowClick}
                    onDateFieldsChange={handleFieldChange}
                    handleDateSubmitButtonClick={handleDateSubmitButtonClick}
                    refreshData={getInitialData}
                />
            }
        </>
    )
}
