import React, {useCallback, useEffect, useState} from 'react'
import '../clients/clients.css'

import {useGlobalContext} from '../../store/context/context'
import {useHistory, useLocation} from 'react-router'
import axios from 'axios'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import {TableV} from '../../components/layout/table/TableV'
import {useCancelToken} from "../../utils/js/useCancelToken";
import {TableVCopy} from "../../components/layout/table/TableVCopy";

export const UserCurrentTasks = () => {
    const { newCancelToken, isCancel } = useCancelToken();
    const {setTopbarData, setBreadcrumbs, setActiveSidebarLink, setShowFeedback, setServerError, setActiveTopbarLink, me} = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const history = useHistory()
    const location = useLocation()

    // const { response: table, error, loading } = useAxios('clients/')

    const [table, setTable] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const [dateFields, setDateFields] = useState({
        // month: {
        //     label: 'month',
        //     value: response.data.month || '',
        //     type: 'select_field',
        //     options: [],
        // },
        // year: {
        //     label: 'year',
        //     value: response.data.year || '',
        //     type: 'select_field',
        //     options: [],
        // },
        // currency: {
        //     label: 'валюта',
        //     value: response.data.currency || 'KZT',
        //     type: 'select_field',
        //     options: ['KZT', 'RUB', 'USD']
        // },
    })

    const handleFieldChange = event => {
        console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }

    const getTable = useCallback(async () => {
        console.log('getInitialDataAndReplace called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get("properties/brandtask/current" + location.search)
            // const response = await axios.get("properties/brandtask/current" + '/', {
            //     cancelToken: newCancelToken()
            // })
            setTable(response.data)

            if (response.data.month_from && response.data.year_from && response.data.month_to && response.data.year_to) {
                setDateFields({
                    month_from: {
                        label: 'От',
                        value: response.data.month_from || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1)
                    },
                    year_from: {
                        label: 'От',
                        value: response.data.year_from || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    },
                    month_to: {
                        label: 'До',
                        value: response.data.month_to || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1)
                    },
                    year_to: {
                        label: 'До',
                        value: response.data.year_to || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index)
                    },
                })
            } else {
                setDateFields([])
            }
            history.replace(location.pathname + `?month_from=${response.data.month_from}&year_from=${response.data.year_from}&month_to=${response.data.month_to}&year_to=${response.data.year_to}`)
        } catch (error) {
            if (isCancel(error)) return console.log('request cancelled: ReportsManager');

            if (error.message === 'Network Error') return setServerError(true)

            if (error.response.status >= 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || { message: `Error. Code ${error.response.status}. Text: ${error.response.status}` })
            // setError(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.search, location.pathname, history, setServerError, newCancelToken, isCancel])
    useEffect(() => {
        getTable()
    }, [getTable])
    const getUrl = useCallback((root = false) => {
        if (root) {
            if (location.pathname.lastIndexOf('/') === 0) return location.pathname.substring(location.pathname.lastIndexOf('/') + 1) // /clients ==>> clients
            return location.pathname.substring(1, location.pathname.indexOf('/', 1))  // i.e. /clients/id/brands ==>> clients
        }
        return location.pathname.substring(location.pathname.lastIndexOf('/') + 1)  // i.e. /clients/id/brands ==>> brands   OR   /clients ==>> clients
    }, [location.pathname])
    useEffect(() => {
        const parentURL = getUrl()
        // console.log(parentURL);  // client
        setActiveSidebarLink(parentURL)
    }, [setActiveSidebarLink, getUrl, location.pathname])

    useEffect(() => {
        setActiveTopbarLink('/properties/brandtask/current')  // `/clients/${id}/brands`
    }, [setActiveTopbarLink])

    useEffect(() => {
        if(me && me.show_all_tasks || me && me.department_id === 15){
            setTopbarData({
                links: [
                    {
                        value: "Еженедельные",
                        url: `/users/tasks/weekly`,
                        badge: null
                    },
                    {
                        value: "Ежемесячные",
                        url: `/users/tasks/monthly`,
                        badge: null
                    },
                    {
                        value: "Проектные",
                        url: `/users/tasks/project?status=Создать`,
                        badge: null
                    },
                    {
                        value: "Загруженность",
                        url: `/properties/departments/${me?.department_id}/workload?period=Последние_7_дней`
                    },
                    {
                        value: "Текущие",
                        url: `/properties/brandtask/current`,
                        badge: null
                    },
                    {
                        value: "Статистика текущие",
                        url: `/properties/brandtask/stat_current`,
                        badge: null
                    },
                    {
                        value: "Потенциальные",
                        url: `/properties/brandtask/potential`,
                        badge: null
                    },
                    {
                        value: "Статистика потенциальные",
                        url: `/properties/brandtask/stat_potential`,
                        badge: null
                    },
                    {
                        value: "Дисквалификация",
                        url: `${location.pathname.split('/').slice(0, -1).join('/')}/disqualification`,
                        badge: null
                    },
                ],
                sublinks: null,
            })
        }else{
            if(me && me.department_id === 19 || me && me.department_id === 30
                || me && me.department_id === 26
                || me && me.department_id === 28
            ){
                setTopbarData({
                    links: [
                        {
                            value: "Текущие",
                            url: `/properties/brandtask/current`,
                            badge: null
                        },
                        {
                            value: "Статистика текущие",
                            url: `/properties/brandtask/stat_current`,
                            badge: null
                        },
                        {
                            value: "Потенциальные",
                            url: `/properties/brandtask/potential`,
                            badge: null
                        },
                        {
                            value: "Статистика потенциальные",
                            url: `/properties/brandtask/stat_potential`,
                            badge: null
                        },
                        {
                            value: "Дисквалификация",
                            url: `${location.pathname.split('/').slice(0, -1).join('/')}/disqualification`,
                            badge: null
                        },
                    ],
                    sublinks: null,
                })
            }else{
                setTopbarData({
                    links: [
                        {
                            value: "Еженедельные",
                            url: `/users/tasks/weekly`,
                            badge: null
                        },
                        {
                            value: "Ежемесячные",
                            url: `/users/tasks/monthly`,
                            badge: null
                        },
                        {
                            value: "Проектные",
                            url: `/users/tasks/project?status=Создать`,
                            badge: null
                        },
                        {
                            value: "Загруженность",
                            url: `/properties/departments/${me?.department_id}/workload?period=Последние_7_дней`
                        },
                    ],
                    sublinks: null,
                })
            }
        }

    }, [setTopbarData, location.pathname, me?.department_id])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/clients', breadcrumb: table?.title || ''},
            // { path: '/users/:id', breadcrumb: 'id-breadcrumb' },
        ])
    }, [setBreadcrumbs, table?.title, location])

    const buttons = [
        {
            text: 'Добавить клиента',
            icon: 'plus',
            url: 'clients/new/',
            popup_title: 'Клиент',
            popup_callback() {
                getTable()
            }
        },
    ]

    // ----------------- ROW CLICK START ---------------------
    // const handleRowClick = async row => {
    //     const request_id = row['request_id'].value
    //
    //     // console.log('CLIENTS: rowclick');
    //     history.push({
    //         pathname: `clients/${request_id}/brands`,
    //     })
    // }
    // ----------------- ROW CLICK START ---------------------

    const handleDateSubmitButtonClick = async () => {
        console.log('Called........  2');
        try {
            setError(false)
            const response = await axios.get("properties/brandtask/current" + `?month_from=${dateFields.month_from.value}&year_from=${dateFields.year_from.value}&month_to=${dateFields.month_to.value}&year_to=${dateFields.year_to.value}`)
            setTable(response.data);
            console.log(response.data.rows);
            history.replace(location.pathname + `?month_from=${response.data.month_from}&year_from=${response.data.year_from}&month_to=${response.data.month_to}&year_to=${response.data.year_to}`)
        } catch (error) {
            console.log(error.message);
            // renderFeedback('danger', error.response?.data.message || 'Server error')
        } finally {
            // setLoadingOnDateSubmit(false)
        }
    }

    if (loading) return <LoaderComponent/>
    if (error) return <div>{error.message}</div>
    return (<>
            <div className="clients page">
            </div>

            <TableVCopy
                table={table}
                date_fields={dateFields}
                // buttons={buttons.reverse()}
                // handleRowClick={handleRowClick}
                refreshData={getTable}
                handleDateSubmitButtonClick={handleDateSubmitButtonClick}
                onDateFieldsChange={handleFieldChange}
                // href={{
                //     from: '/clients',
                //     to: 'brands'
                // }}
            />
        </>
    )
}