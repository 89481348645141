import React, {useRef, useState} from 'react'
import Text from '../text/Text'
// import './select.css'
import {BsSearch} from 'react-icons/bs'
import {useGlobalContext} from '../../../store/context/context'
import SelectOptions from './SelectOptions'


export const SelectFieldForPopup = ({
                                        options,
                                        value,
                                        value_id,
                                        label,
                                        name,
                                        onChange,
                                        onDoubleClick,
                                        rowClickable,
                                        editable = true,
                                        required,
                                        parent,
                                    }) => {
    const [inputValue, setInputValue] = useState(value || '')
    const [selectedOption, setSelectedOption] = useState(value || '')
    const [searchText, setSearchText] = useState('')
    const selectRef = useRef(null)

    const {renderFeedback} = useGlobalContext()

    const handleOptionClick = async option => {
        let event
        if (option.hasOwnProperty('value_id')) {
            event = {
                target: {
                    name,
                    value: option.value,
                    value_id: option.value_id
                }
            }
        } else {
            event = {
                target: {
                    name,
                    value: option
                }
            }
        }
        // console.log(event.target);

        if (parent === "table-container") {  // WHEN CHANGING FIELDS INSIDE TABLE CELLS WITHOUT CHANGING REQUEST RIGHT AWAY, ADDING EMPLOYER'S BONUS year, month, bonus TABLE
            try {
                const {message} = await onChange(event)
                console.log(message);

                renderFeedback('success', message || 'Success')
                // setShowOptionsList(false)
                setInputValue(event.target.value)
                setSelectedOption(event.target.value)
                setSearchText('')

                const label = selectRef.current.querySelector('label')
                label.classList.add('focused', 'filled')
            } catch (error) {
                // console.log(error);
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            }
        } else { // WHEN CHANGING IN POPUP COMPONENT
            onChange && onChange(event)
            // setShowOptionsList(false)
            setInputValue(event.target.value)
            setSelectedOption(event.target.value)
            setSearchText('')


            const label = selectRef.current.querySelector('label')
            label.classList.add('focused', 'filled')
        }
    }

    const handleTextChange = event => {
        setInputValue(event.target.value)
        setSearchText(event.target.value)
    }


    return (
        <div className={`select_for_popup form-field ${editable ? 'editable' : 'uneditable'}`} ref={selectRef}>
            <div className="form-field__top">
                <Text type="text" label={label} name={name} value={inputValue} onChange={handleTextChange}
                      parentForm="select" rowClickable={rowClickable} editable={editable} required={required}/>
                <button className="svg-container form-field__open-button" type="button"
                        style={{width: "14px", marginRight: '.5rem'}}><BsSearch/></button>
            </div>
            <SelectOptions options={options} searchText={searchText} selectedOption={selectedOption}
                           handleOptionClick={handleOptionClick} className=""/>
        </div>
    )
}
