import React, {useRef, useState} from 'react'
import './table.css'
import {useGlobalContext} from '../../../store/context/context'
import {ReactComponent as Pencil} from '../../../utils/icons/pencil.svg'
import {ReactComponent as Trash1} from '../../../utils/icons/trash_1.svg'
import {ReactComponent as Tick1} from '../../../utils/icons/tick_1.svg'
import RadioField from '../../forms/radio/Radio'
import NumberField from '../../forms/number/Number'
import axios from 'axios'
import {useLocation} from 'react-router'
import CheckboxField from '../../forms/checkbox/Checkbox'
import TextAreaFieldMU from '../../forms/textarea_mu/TextAreaMU'
import {CheckboxField2} from '../../forms/checkbox/Checkbox2'
import {Hint, HintTable} from '../hint/Hint'
import DateNumberField from '../../forms/date/DateNumber'
import {SelectPositionedField} from '../../forms/select/SelectPositioned'
import useDebounce from '../../../utils/js/useDebounce'


export const TableForWeeklyTasksBody = ({
                                            table,
                                            setTasksTable,
                                            id,
                                            startDate,
                                            add_subtask_btn = true,
                                            valueType = 'value'
                                        }) => {
    const {renderFeedback, tableIcons, SPECIAL_VALUES} = useGlobalContext()

    // const history = useHistory()
    const location = useLocation()

    const [columnAccessors, setColumnAccessors] = useState(table.order?.length ? table.order : Object.keys(table.headers))
    // const [rows, setRows] = useState(table.rows)
    // const [headers, setHeaders] = useState(table.headers)
    // const [sortOrder, setSortOrder] = useState('original')
    // const [sortedColumnAccessor, setSortedColumnAccessor] = useState(null)
    // const inputRef = useRef(null)
    const tableContentRef = useRef(null)
    // const tableCaptionContainerRef = useRef(null)
    const tableRef = useRef(null)
    // const [tbodyDisplay, setTBodyDisplay] = useState('table-row-group')  // OR 'none'
    const [tbodyDisplay, setTBodyDisplay] = useState(JSON.parse(localStorage.getItem('tbodyDisplayObject'))?.[table.request_id] || 'table-row-group')  // OR 'none'


    const [formFields] = useState({
        text_field: TextAreaFieldMU,
        // select_field: SelectField,
        select_field: SelectPositionedField,
        // date_field: DateField,
        date_field: DateNumberField,
        radio_field: RadioField,
        number_field: NumberField,
        checkbox_field: CheckboxField,
        checkbox_field2: CheckboxField2,
        // textarea_field: TextAreaField,
    })


    // const handleFieldChange = (event, accessor, row, type) => {
    //     if (event.target.name !== document.activeElement.name) return // PREVENTS INFINITE REQUESTS CALLED ON FORCED CHANGE OF FIELDS WHEN FILTERING AND SORTING
    //     if (!!row[accessor].is_task_name || type === 'checkbox_field2') {  // IF TASK_NAME IS BEING EDITED THEN JUST DON'T SEND REQUEST BUT UPDATE STATE
    //         const newRow = {
    //             ...row,
    //             [accessor]: {  // [event.target.name] === [accessor]
    //                 ...row[accessor],
    //                 value: event.target.value
    //             }
    //         }
    //         const updatedRows = table.rows.map(singleRow => singleRow.request_id.value === row.request_id.value ? newRow : singleRow)
    //         return setTasksTable(oldTableData => {
    //             return { ...oldTableData, body: oldTableData.body.map(singleTable  => singleTable.request_id === table.request_id ? {...singleTable, rows: updatedRows} : singleTable)}
    //         })
    //     }

    //     return new Promise(async (resolve, reject) => {
    //         const newRow = {
    //             ...row,
    //             [accessor]: {  // [event.target.name] === [accessor]
    //                 ...row[accessor],
    //                 value: event.target.value
    //             }
    //         }

    //         try {
    //             const response = await axios.post(`${location.pathname}/${row[accessor].set_url}`, newRow)

    //             const updatedRows = table.rows.map(row => row.request_id.value === response.data.request_id.value ? response.data : row)

    //             const updatedHeaders = JSON.parse(JSON.stringify(table.headers))
    //             Object.keys(updatedHeaders).forEach(accessor => {
    //                 if (updatedHeaders[accessor].type === 'number') {
    //                     updatedHeaders[accessor].label = updatedRows.reduce((acc, curr) => curr[accessor].value === -999999 ? acc : acc + curr[accessor].value, 0)
    //                 }
    //             })

    //             setTasksTable(oldTableData => {
    //                 return { ...oldTableData, body: oldTableData.body.map(singleTable  => singleTable.request_id === table.request_id ? {...singleTable, headers: updatedHeaders, rows: updatedRows} : singleTable)}
    //             })

    //             resolve(response.data)
    //         } catch (error) {
    //             renderFeedback('danger', error.response?.data.message || 'Server Error')
    //             reject(error.message)
    //         }
    //     })
    // }

    // ----------------- CELL CHANGE START ---------------------
    const [isSendRequest, setIsSendRequest] = useState(false)  // THIS IS USED AS A TRIGGER FOR SENDING REQUEST WHEN FIELD HAS BEEN STOP FROM CHANGING FOR 0.5s
    const [isSendRequestImmediately, setIsSendRequestImmediately] = useState(false)  // THIS IS USED AS A TRIGGER FOR SENDING REQUEST WHEN FIELD HAS BEEN STOP FROM CHANGING FOR 0.5s
    const useDebounceInfo = useRef({
        url: '',
        dataToSend: {},
    })

    const sendRequest = async () => {
        try {
            // setLoading(true)  // BAD IDEA CAUSE THIS FLASHES THE TZ ON EDIT PAGE AS WELL AS BRINGS THE SCROLL TO INITIAL POSITION
            const {url, dataToSend} = useDebounceInfo.current
            const response = await axios.post(url, {...dataToSend})
            if (response.data.hasOwnProperty('message')) return renderFeedback('success', response.data.message || 'Success')

            const updatedRows = table.rows.map(row => row.request_id.value === response.data.request_id.value ? response.data : row)

            const updatedHeaders = JSON.parse(JSON.stringify(table.headers))
            Object.keys(updatedHeaders).forEach(accessor => {
                if (updatedHeaders[accessor].type === 'number') {
                    updatedHeaders[accessor].label = updatedRows.reduce((acc, curr) => curr[accessor].value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? acc : acc + curr[accessor].value, 0)
                }
            })

            setTasksTable(oldTableData => {
                return {
                    ...oldTableData,
                    body: oldTableData.body.map(singleTable => singleTable.request_id === table.request_id ? {
                        ...singleTable,
                        headers: updatedHeaders,
                        rows: updatedRows
                    } : singleTable)
                }
            })

            // setRows(rows.map(singleRow => singleRow.frontend_id.value === dataToSend.frontend_id.value ? response.data : singleRow))
        } catch (error) {
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        }
    }

    useDebounce(() => {
        sendRequest()
    }, 500, [isSendRequest])  // SEND REQUEST AFTER .5 seconds
    useDebounce(() => {
        sendRequest()
    }, 0, [isSendRequestImmediately])  // SEND REQUEST IMMEDIATELY

    const handleFieldChange = (event, row, type) => {
        // console.log(event.target.name !== document.activeElement.name);
        if (event.target.name !== document.activeElement.name) return // IN AVR FOOT, WHEN WE CHANGE ONE CELL, UPDATED CHILD IS RECEIVED AND ROWS_STATE IS CHANGED,
        // WHICH IN TURN FORCES THE FORCEFULLY CHANGED CELL(DIFFERENT) CALL THIS FUNCTION AND ANOTHER REQUEST IS SEND AGAIN UNWILLINGLY. TO PREVENT THIS WE CHECK IF THE CHANGED CELL IS FOCUSED OR NOT

        if (!!row[event.target.name].is_task_name || type === 'checkbox_field2') {  // IF TASK_NAME IS BEING EDITED THEN JUST DON'T SEND REQUEST BUT UPDATE STATE
            const newRow = {
                ...row,
                [event.target.name]: {  // [event.target.name] === [accessor]
                    ...row[event.target.name],
                    value: event.target.value
                }
            }
            const updatedRows = table.rows.map(singleRow => singleRow.request_id.value === row.request_id.value ? newRow : singleRow)
            return setTasksTable(oldTableData => {
                return {
                    ...oldTableData,
                    body: oldTableData.body.map(singleTable => singleTable.request_id === table.request_id ? {
                        ...singleTable,
                        rows: updatedRows
                    } : singleTable)
                }
            })
        }

        let dataToSend
        const newRow = {
            ...row,
            [event.target.name]: {  // [event.target.name] === [accessor]
                ...row[event.target.name],
                value: event.target.value
            }
        }
        if (event.target.value_id) newRow[event.target.name].value_id = event.target.value_id // FOR SELECT FIELD 2

        dataToSend = newRow

        // let rowsCopy = JSON.parse(JSON.stringify(table.rows))
        // // dataToSend = rowsCopy.filter(singleRow => singleRow.frontend_id.value === row.frontend_id.value)[0]
        // dataToSend = rowsCopy.filter(singleRow => singleRow.request_id.value === row.request_id.value)[0]

        // dataToSend[event.target.name].value = event.target.value
        // if (event.target.value_id) dataToSend[event.target.name].value_id = event.target.value_id // FOR SELECT FIELD 2

        let url = `${location.pathname}/${row[event.target.name].set_url}`

        useDebounceInfo.current = {
            url,
            dataToSend,
        }

        if (dataToSend[event.target.name].type === 'select_field' || dataToSend[event.target.name].type === 'select_field2' || dataToSend[event.target.name].type === 'date_field') {
            setIsSendRequestImmediately(!isSendRequestImmediately)
        } else {
            setIsSendRequest(!isSendRequest)
        }
    }
    // ----------------- CELL CHANGE STOP ---------------------

    const addNewSubTask = async () => {
        try {
            const response = await axios.get(`${location.pathname}/new/?task_id=${table.request_id}&start_date=${startDate}`)
            const newSubTask = response.data

            const updatedRows = [...table.rows, newSubTask]
            setTasksTable(oldTableData => {
                return {
                    ...oldTableData,
                    body: oldTableData.body.map(singleTable => singleTable.request_id === table.request_id ? {
                        ...singleTable,
                        rows: updatedRows
                    } : singleTable)
                }
            })
        } catch (error) {
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        }
    }

    const formatValue = (value, type, sign) => {
        if (type === 'number_field') {
            let number = !!sign ? Number(value) * 100 : value
            let [whole, decimal] = String(number).split('.')

            let reversed = whole.split('').reverse().join('')
            let arr = []
            for (let i = 0; i < reversed.length; i = i + 3) {
                arr.push(reversed.substring(i, i + 3).split('').reverse().join(''))
            }

            return decimal ? arr.reverse().join(' ') + ',' + decimal : arr.reverse().join(' ')
        } else if (type === 'boolean_field') {
            return !!value ? 'Да' : 'Нет'
        }

        return value
    }

    // WEEKLY TASKS FUNTIONS START
    const handleCheckButtonClick = async row_request_id => {
        try {
            const rowToSend = table.rows.filter(row => row.request_id.value === row_request_id)[0]

            const response = await axios.post(`${location.pathname}/edit/`, rowToSend)  // UPDATED ROW, i.e., editable: true -> false, AS A RESULT THE ICON ALSO CHANGES

            const updatedRows = table.rows.map(oldRow => oldRow.request_id.value === row_request_id ? response.data : oldRow)
            setTasksTable(oldTableData => {
                return {
                    ...oldTableData,
                    body: oldTableData.body.map(singleTable => singleTable.request_id === table.request_id ? {
                        ...singleTable,
                        rows: updatedRows
                    } : singleTable)
                }
            })
        } catch (error) {
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }

    const handleEditButtonClick = row_request_id => {
        const updatedRows = table.rows.map(oldRow => oldRow.request_id.value === row_request_id ? {
            ...oldRow,
            task_name: {...oldRow.task_name, editable: !oldRow.task_name.editable}
        } : oldRow)
        return setTasksTable(oldTableData => {
            return {
                ...oldTableData,
                body: oldTableData.body.map(singleTable => singleTable.request_id === table.request_id ? {
                    ...singleTable,
                    rows: updatedRows
                } : singleTable)
            }
        })

    }

    const handleDeleteButtonClick = async row_request_id => {
        try {
            const response = await axios.get(`${location.pathname}/status/archive/?request_id=${row_request_id}`)

            const updatedRows = table.rows.filter(oldRow => oldRow.request_id.value !== row_request_id)
            const updatedHeaders = JSON.parse(JSON.stringify(table.headers))
            Object.keys(updatedHeaders).forEach(accessor => {
                if (updatedHeaders[accessor].type === 'number') {
                    updatedHeaders[accessor].label = updatedRows.reduce((acc, curr) => curr[accessor].value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? acc : acc + curr[accessor].value, 0)
                }
            })
            setTasksTable(oldTableData => {
                return {
                    ...oldTableData,
                    body: oldTableData.body.map(singleTable => singleTable.request_id === table.request_id ? {
                        ...singleTable,
                        rows: updatedRows,
                        headers: updatedHeaders
                    } : singleTable)
                }
            })

            renderFeedback('success', response.data.message || 'Подзадача успешно удалена')
        } catch (error) {
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // WEEKLY TASKS FUNTIONS END

    // TOGGLE TBODY START
    const handleTHeadClick = () => {
        // console.log(getComputedStyle(tbodyRef.current).display === 'none');
        let tbodyDisplayObject = JSON.parse(localStorage.getItem('tbodyDisplayObject')) || {}
        tbodyDisplayObject[table.request_id] = tbodyDisplay === 'none' ? 'table-row-group' : 'none'

        localStorage.setItem('tbodyDisplayObject', JSON.stringify(tbodyDisplayObject))
        setTBodyDisplay(prev => prev === 'none' ? 'table-row-group' : 'none')
    }
    // TOGGLE TBODY END

    const getTableTdButtons = (row_request_id, editable) => {
        return (
            <div className="buttons">
                {editable
                    ? <Hint hint='Сохранить'>
                        <button className="svg-container green-button"
                                onClick={() => handleCheckButtonClick(row_request_id)}><Tick1/></button>
                    </Hint>
                    : <Hint hint='Редактировать'>
                        <button className="svg-container yellow-button"
                                onClick={() => handleEditButtonClick(row_request_id)}><Pencil/></button>
                    </Hint>
                }
                <Hint hint='Удалить'>
                    <button className="svg-container red-button"
                            onClick={() => handleDeleteButtonClick(row_request_id)}><Trash1/></button>
                </Hint>
            </div>
        )
    }

    // ----------------- RENDER TABLE BODY START ---------------------
    const getTableBody = () => {
        return table.rows.map((row, idx) => {
            return (
                <tr key={idx} className={`table__row ${table.clickable ? 'clickable' : 'unclickable'}`}>
                    {/* WHEN FILLS ROW CLICKED, WE NEED TO SEND THE WHOLE ROW, THUS SEND "row" AS A SECOND PARAMETER */}
                    {columnAccessors.map(accessor => {
                        const {
                            value,
                            type,
                            editable,
                            color,
                            options = [],
                            sign,
                            is_task_name,
                            value2 = undefined
                        } = row[accessor]
                        if (!table.headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                        if (value === null) return <td key={accessor} className={`table__td ${accessor}`}
                                                       style={{backgroundColor: color || '#fff'}}>
                            <div style={{padding: '0 8px'}}>----</div>
                        </td>

                        if (editable && valueType !== 'value2') {
                            const FormField = formFields[type]
                            return (
                                <td key={accessor} className={`table__td ${accessor}`}
                                    style={{backgroundColor: color || '#fff'}}>
                                    <div className={`table__td__center ${accessor}`}>
                                        {/* IF NOT PROJECT TASKS AND THIS CELL IS FOR TASK_NAME, THEN SHOW CIRCLE */}
                                        {is_task_name !== undefined && <div className={`circle ${is_task_name}`}></div>}
                                        <div style={{flex: '1'}}>
                                            <FormField
                                                name={accessor}
                                                value={value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? null : value}
                                                onChange={(event) => handleFieldChange(event, row, type)}
                                                editable={true}
                                                parent="table-container"
                                                options={options}
                                                rowClickable={table.clickable}
                                                sign={sign}
                                            />
                                        </div>
                                        {is_task_name !== undefined && getTableTdButtons(row.request_id.value, editable)}
                                    </div>
                                </td>
                            )
                        }
                        return (
                            <td key={accessor} className={`table__td ${accessor}`}
                                style={{backgroundColor: color !== '#FFFFFF' ? color : ''}}>
                                <div className={`table__td__center ${accessor}`}>
                                    {/* IF NOT PROJECT TASKS AND THIS CELL IS FOR TASK_NAME, THEN SHOW CIRCLE */}
                                    {is_task_name !== undefined && <div className={`circle ${is_task_name}`}></div>}
                                    {/* <div style={{flex: '1'}}>
                                        {value === SPECIAL_NUMBER ? '' : formatValue(value, type, sign)}
                                        {sign && ` ${sign}`}
                                    </div> */}
                                    <div style={{flex: '1'}}
                                         dangerouslySetInnerHTML={{__html: value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? '' : value2 !== undefined && valueType === 'value2' ? formatValue(value2, '', sign) : formatValue(value, type, sign)}}>
                                        {/* {value === SPECIAL_NUMBER ? '' : formatValue(value, type, sign)} */}
                                    </div>
                                    {is_task_name !== undefined && getTableTdButtons(row.request_id.value, editable)}
                                </div>
                            </td>
                        )
                    })}
                </tr>
            )
        })
    }
    // ----------------- RENDER TABLE BODY STOP ---------------------
    // ----------------- RENDER TABLE HEADER START ---------------------
    return (
        <div className="table-for-tasks-block">
            <div className="table-container" ref={tableContentRef}>
                <table className="table" id={id} ref={tableRef}>
                    <thead className={`table__thead`} onClick={handleTHeadClick}>
                    <tr>
                        {columnAccessors.map(accessor => {
                            if (!table.headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                            const {label, width, normative, frequency, hint} = table.headers[accessor]

                            if (label === 'icon') {
                                let Icon = accessor === 'open' ? tableIcons['target1'] : tableIcons[accessor]
                                return (
                                    // <th key={accessor} className="table__th" style={{width: '.1%', minWidth: width || '50px'}} >
                                    <th key={accessor} className="table__th" style={{width: '.1%'}}>
                                        <div className={`table__th__center ${accessor}`}
                                             style={{width: width || '24px', justifyContent: 'center'}}>
                                            <span className="svg-container"><Icon style={{width: '24px'}}/></span>
                                            {hint && <HintTable hint={hint}/>}
                                        </div>
                                    </th>
                                )
                            }

                            return (
                                <th key={accessor}
                                    className={`table__th ${accessor}`}
                                    style={{width: width === 'auto' ? '100%' : '1%'}}>
                                    <div className={`table__th__center ${accessor}`} style={{width: width || '199px'}}>
                                        <div className="table__th__value">
                                            {label}
                                            {(normative || frequency) &&
                                                <div className="table__th__value__criterias">
                                                    {normative && <p>Норматив: <span>{normative} минут</span></p>}
                                                    {frequency && <p>Частота: <span>{frequency}</span></p>}
                                                </div>
                                            }
                                        </div>
                                        {hint && <HintTable hint={hint}></HintTable>}
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                    </thead>
                    <tbody style={{display: tbodyDisplay}}>
                    {getTableBody()}
                    {add_subtask_btn && <tr className="table__row" onClick={addNewSubTask}>
                        <td colSpan={columnAccessors.length} className="table__td table__td__add-button">
                            <div className="table__td__center" style={{justifyContent: 'center'}}>
                                Добавить {table.type === 'other' ? 'прочую задачу' : 'подзадачу'}
                            </div>
                        </td>
                    </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
    // ----------------- RENDER TABLE HEADER STOP ---------------------
}
