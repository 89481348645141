import React from 'react'
import './checkbox.css'

import {Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel} from '@material-ui/core'

const CheckboxField = ({label, name, onChange, helperText, options, editable = true}) => {
    const handleChange = e => {
        // const newOptions = options.map(option => option.name === e.target.name ? {...option, value: e.target.checked} : option)
        const newOptions = options.map(option => option.label === e.target.name ? {
            ...option,
            value: e.target.checked
        } : option)

        const event = {
            target: {
                name,
                value: newOptions
            }
        }

        onChange && onChange(event, 'options')
    };

    // const { gilad, jason, antoine } = state;
    return (
        // <div className={`checkbox form-field ${editable ? 'editable' : 'uneditable'}`}>
        <div className={`checkbox form-field`}>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <FormControl sx={{m: 3}} component="fieldset" variant="standard">
                    <FormLabel component="legend">{label}</FormLabel>
                    <FormGroup row>
                        {options.map(option => {
                            return (
                                <FormControlLabel
                                    key={option.label}
                                    disabled={!editable}
                                    label={option.label}
                                    control={<Checkbox checked={option.value} disableRipple color="primary" size="small"
                                                       onChange={handleChange}
                                                       name={option.label}/>}  // NURA WANTED IT TO BE LABEL NOT NAME IN CASE OF SOME BUGS NURA IS THE ONE WHO IS GUILTY
                                />
                            )
                        })}
                    </FormGroup>
                    <FormHelperText>{helperText}</FormHelperText>
                </FormControl>
            </Box>
        </div>
    )
}

export default CheckboxField
