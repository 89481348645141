import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import './switch.css'

export default function SwitchLabels({label = "Label", onChange}) {

    const handleChange = event => {
        // console.log(event.target.checked);
        onChange(event.target.checked)
    }

    return (
        <div className='switch-field'>
            <FormControlLabel control={<Switch onChange={handleChange}/>} label={label}/>
        </div>
        //   <FormControlLabel disabled control={<Switch />} label="Disabled" />
    );
}
