import React from 'react'
import './checkbox_textarea.css'
import CheckboxField from '../checkbox/Checkbox'
import TextAreaFieldMU from '../textarea_mu/TextAreaMU'

export const CheckboxTextareaField = ({
                                          value = 'qwert',
                                          input_value = 'input',
                                          options = [],
                                          onChange,
                                          type = "text",
                                          label,
                                          name,
                                          hint,
                                          parentForm,
                                          editable = true,
                                          required,
                                          rowClickable = false,
                                          handleValueRemoveButtonClick
                                      }) => {

    const handleFormFieldClick = event => {
        rowClickable && event.stopPropagation()
    }

    const handleCheckboxFieldChange = event => {
        console.log('select field change....', {[event.target.name]: event.target.value});
        onChange && onChange(event, 'options')
    }

    const handleTextAreaFieldChange = event => {
        console.log('textarea field change....', {[event.target.name]: event.target.value});
        onChange && onChange(event, 'input_value')
    }


    return (
        <div className={`checkbox_textarea form-field ${editable ? 'editable' : 'uneditable'}`}
             onClick={handleFormFieldClick} style={{pointerEvents: !editable && 'none'}}>
            <CheckboxField key={'options'} label={label} name={name} value={value} options={options} editable={editable}
                           required={required} hint={hint} onChange={handleCheckboxFieldChange}/>
            <TextAreaFieldMU key={'input_value'} label={label} name={name} value={input_value} options={options}
                             editable={editable} required={required} hint={hint} onChange={handleTextAreaFieldChange}/>
        </div>
    )
}
