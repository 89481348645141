import React from 'react'
import './select_text.css'
import {SelectField} from '../select/Select'
import TextField from '../text/Text'

export const SelectTextField = ({
                                    value = 'qwert',
                                    input_value = 'input',
                                    options = [1, 2, 3, 4, 5],
                                    onChange,
                                    type = "text",
                                    label,
                                    name,
                                    hint,
                                    editable = true,
                                    required,
                                    rowClickable = false,
                                }) => {


    // console.log(value);
    const handleFormFieldClick = event => {
        // event.preventDefault()
        rowClickable && event.stopPropagation()
        // console.log('inside', event.target);
    }

    const handleSelectFieldChange = event => {
        console.log('select field change....', {[event.target.name]: event.target.value});
        onChange && onChange(event, 'value')
    }

    const handleTextFieldChange = event => {
        console.log('text field change....', {[event.target.name]: event.target.value});
        onChange && onChange(event, 'input_value')

    }


    return (
        <div className={`select_text flex form-field ${editable ? 'editable' : 'uneditable'}`}
             onClick={handleFormFieldClick} style={{pointerEvents: !editable && 'none'}}>
            {/* <label htmlFor={name} className={`text__label ${focused}`} style={{display: !label && "none" }}>{label}</label> */}
            <SelectField key={'index'} label={label} name={name} value={value} options={options} editable={editable}
                         required={required} hint={hint} onChange={handleSelectFieldChange}/>
            <TextField key={'index'} label={label} name={name} value={input_value} options={options} editable={editable}
                       required={required} hint={hint} onChange={handleTextFieldChange}/>
        </div>
    )
}
