import React, {useEffect} from 'react'
import {useGlobalContext} from '../../../store/context/context'
import './alert.css'

export const Alert = ({onAlertChange}) => {
    const {alert: {title, body, onSuccessFunction}, setAlert, setShowFeedback} = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const handleCancel = () => {
        setAlert({
            visible: false,
            title: '',
            body: '',
            onSuccessFunction: () => {
            }
        })
    }

    const handleOK = () => {
        onSuccessFunction && onSuccessFunction()
        setAlert({
            visible: false,
            title: '',
            body: '',
            onSuccessFunction: () => {
            }
        })
    }

    return (
        <div className="alert__background background" style={{cursor: 'default'}}>
            <div className="alert">
                <h3 className="alert__title">{title}</h3>
                <p className="alert__body">{body}</p>
                <div className="alert__buttons">
                    <button className="alert__button alert__button__cancel" onClick={handleCancel}>Нет</button>
                    <button className="alert__button alert__button__ok" onClick={handleOK}>Да</button>
                </div>
            </div>
        </div>
    )
}
