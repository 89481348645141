import { TextareaAutosize } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../store/context/context';
import './textarea_mu.css'

const TextAreaFieldMU = ({value='', onChange, label, name, editable=true, required, maxRows, minRows }) => {
    const { showFeedback, popup, popupFill } = useGlobalContext()
    const [inputValue, setInputValue] = useState(value)    
    const handleOnChange = (event) => {  // WAS ALTERED DUE TO TZ_KPI ROW FIELD CHANGE USE_DEBOUNSE HOOK
        // console.log('eeeee');
        setInputValue(event.target.value)
        onChange && editable && onChange(event)
    }

    useEffect(() => {
        // console.log('text area use effect [value]');
        setInputValue(value)
    }, [value])

    return (
        <div className={`textarea form-field ${editable ? 'editable' : 'uneditable'}`}>
            <TextareaAutosize
                aria-label="empty textarea"
                // placeholder="Empty"
                // value={value}
                value={inputValue || ''}  // WERE THROWN SOME ERRORS BECAUSE OF: Warning: `value` prop on `textarea` should not be null
                onChange={handleOnChange}
                style={{
                    width:"100%",
                    resize: 'none', 
                    borderColor: editable && required && !value && showFeedback && (popup.visible || popupFill.visible) ? 'red' : '',
                }}
                maxRows={maxRows}
                minRows={minRows}
                className="text__input"
                disabled={!editable}
                name={name}
            />
        </div>
    );
}

export default TextAreaFieldMU


// MultilineTextFields   // IN CASE WE NEED ALTERNATIVE FOR TEXTAREA AS TEXTFIELD MULTILINE
// <Box
    //   component="form"
    //   sx={{
    //     '& .MuiTextField-root': { m: 1, width: 'inherit' },
    //   }}
    //   noValidate
    //   autoComplete="off"
    // >
    //   <div>
    //     <TextField
    //     //   id="filled-textarea"
    //     //   label="Multiline Placeholder"
    //     //   placeholder="Placeholder"
    //       onChange={handleChange}
    //       multiline
    //       value={value}
    //       variant="standard"
    //     />
    //   </div>
    // </Box>