import React from 'react'
import './checkbox.css'

import {Checkbox, FormControlLabel} from '@material-ui/core'
// import { useGlobalContext } from '../../../store/context/context'

export const CheckboxField2 = ({hint, value, name, label, onChange, editable = true, parent, rowClickable}) => {
    // const { renderFeedback } = useGlobalContext()

    const handleChange = e => {
        console.log('checkbox2 changed', e.target);

        const event = {
            target: {
                name,
                value: e.target.checked
            }
        }
        document.activeElement.name = name  // OTHERWISE CHECKBOX2 CANNOT BE CHECKED
        onChange && onChange(event)
    }

    const handleFormFieldClick = event => {
        // event.preventDefault()
        rowClickable && event.stopPropagation()
    }

    return (
        // <div className={`checkbox form-field ${editable ? 'editable' : 'uneditable'}`}>
        <div className={`checkbox2 checkbox form-field`}>
            {
                !!label
                    ? <FormControlLabel
                        disabled={!editable}
                        label={label}
                        // labelPlacement='start'
                        control={<Checkbox
                            name={name}
                            checked={value}
                            disabled={!editable}
                            disableRipple
                            color="primary"
                            size="small"
                            style={{padding: '0'}}
                            onChange={handleChange}
                            onClick={handleFormFieldClick}
                        />}  // NURA WANTED IT TO BE LABEL NOT NAME IN CASE OF SOME BUGS NURA IS THE ONE WHO IS GUILTY
                    />
                    : <Checkbox
                        name={name}
                        checked={value}
                        disabled={!editable}
                        disableRipple
                        color="primary"
                        size="small"
                        style={{padding: '0'}}
                        onChange={handleChange}
                        onClick={handleFormFieldClick}
                    />

            }
        </div>
    )
}
