import axios from 'axios';
import React, {useCallback, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router';
import ClientInfo from '../../components/layout/client-info/ClientInfo'
import {LoaderComponent} from '../../components/layout/loader/Loader';
import {TableV} from '../../components/layout/table/TableV';
import {useGlobalContext} from '../../store/context/context';
import './permissions.css'
// import { useAxios } from '../../utils/js/useAxios';


const Permissions = () => {
    const {
        setTopbarData,
        setActiveTopbarLink,
        setBreadcrumbs,
        setActiveSidebarLink,
        setPopup,
        setShowFeedback,
        setServerError,
        renderFeedback
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const {id} = useParams()

    const location = useLocation();

    const previousPath = location.pathname.split('/').slice(0, -2).join('/')

    const [table, setTable] = useState({})
    const [client, setClient] = useState({name: ''})
    const [loadingClient, setLoadingClient] = useState(true)
    const [loadingTable, setLoadingTable] = useState(true)
    const [errorClient, setErrorClient] = useState(null)
    const [errorTable, setErrorTable] = useState(null)

    const getClient = useCallback(async () => {
        try {
            setLoadingClient(true)
            const response_client = await axios.get(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/`)
            setClient(response_client.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorClient(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorClient(error.response?.data|| 'Server error')
        } finally {
            setLoadingClient(false)
        }
    }, [location.pathname, setServerError])
    const getTable = useCallback(async () => {
        try {
            setLoadingTable(true)
            const response_table = await axios.get(`${location.pathname}/`)
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})

            // setErrorTable(error.response?.data|| 'Server error')
        } finally {
            setLoadingTable(false)
        }
    }, [location.pathname, setServerError])
    useEffect(() => {
        getClient()
        getTable()
    }, [getClient, getTable])

    useEffect(() => {
        setActiveSidebarLink('properties/departments')
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setActiveTopbarLink(location.pathname)  // `/clients/${id}/brands`
    }, [setActiveTopbarLink, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [
                {value: 'Доступы', url: `${previousPath}/${id}/permissions`},
                {value: 'Задачи', url: `${previousPath}/${id}/tasks`},
            ],
            sublinks: null
        })
    }, [setTopbarData, id, previousPath])

    useEffect(() => {
        const previousPath = location.pathname.split('/').slice(0, -2).join('/')
        setBreadcrumbs([
            {path: '/properties/departments', breadcrumb: 'Отделы'},
            {path: previousPath, breadcrumb: `Должности`},  // in case we nadd page 'client'
            {path: `${location.pathname}`, breadcrumb: `${table.title || ''}`},  // links[getURL()] WAS HERE PREVIOUSLY
        ])
    }, [setBreadcrumbs, id, location.pathname, client.name, table.title])

    const buttons = [
        // {
        //     text: `Добавить должность`,
        //     icon: 'plus',
        //     url: `${location.pathname}/positions/new/`,
        //     popup_title: 'Новая должность',
        // },
    ]

    useEffect(() => {  // ON LOCATION.PATHNAME CHANGE IF POPUP IS OPEN THEN CLOSE IT AUTOMATICALLY
        setPopup({
            visible: false,
            data: {},
            url: null,
            title: ''
        })
    }, [location.pathname, setPopup])
    console.log('efef');

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        // const request_id = row['request_id'].value
    }
    // ----------------- ROW CLICK STOP ---------------------

    // ----------------- EDIT CLIENT START ---------------------
    const editClient = async () => {
        const url = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit/`
        try {
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать должность',
                callback() {
                    getClient()
                    getTable()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- EDIT CLIENT STOP ---------------------

    return (<>
            <div className='permissions page'>
                {loadingClient
                    ? <LoaderComponent/>
                    : errorClient
                        ? <div>{errorClient.message}</div>
                        : <ClientInfo inline client={client} editClient={editClient}/>}
                {/* <ClientInfo client={client}/> */}
            </div>

            {loadingTable
                ? <LoaderComponent/>
                : errorTable
                    ? <div>{errorTable.message}</div>
                    : <TableV
                        table={table}
                        buttons={buttons.reverse()}
                        handleRowClick={handleRowClick}
                        refreshData={getTable}
                    />}

        </>
    )
}

export default Permissions

// const buttons = [
//     {
//         text: 'Новый АВР',
//         icon: 'plus',
//         url: ''
//     },
//     {
//         text: 'Edit',
//         icon: 'edit',
//         url: ''
//     },
//     {
//         text: 'История изменений ТЗ',
//         icon: 'book',
//         url: ''
//     },
// ]

// const client = {
//     name: "Асем Ай",
//     data: [
//         {
//             key: 'Клиент',
//             value: 'Dizzy TOO'
//         },
//         {
//             key: 'БИИ/ИНН/ИИН',
//             value: '"1234567890"'
//         },
//         {
//             key: 'Тип бренда',
//             value: 'KPI'
//         },
//     ]
// }


// useEffect(() => {
//    console.log(location.pathname); // result: '/secondpage'
//    console.log(location.state); // result: 'some_value'
// }, [location]);

// const { response: table, error: tableError, loading: tableLoading } = useAxios(`${location.pathname}/`)
// const { response: client, error: clientError, loading: clientLoading } = useAxios(`clients/${id}/`)
