import React, {useEffect, useState} from 'react'
import './helper.css'

import {useGlobalContext} from '../../store/context/context'
import {useLocation} from 'react-router'
import axios from 'axios'
import {LoaderComponent} from '../../components/layout/loader/Loader'

import HowToCreateTz1 from '../../utils/videos/HowToCreateTz1.mov'

export const Helper = () => {
    const {setTopbarData, setBreadcrumbs, setActiveSidebarLink, setShowFeedback} = useGlobalContext()
    // const [activeHelperId, setActiveHelperId] = useState(null)
    const [helpers, setHelpers] = useState([
        {
            id: 1,
            title: 'Как создать Клиента/Бренд, привязать Аккаунты и Договор',
            body() {
                return (<>
                    <video src={HowToCreateTz1} width="350" height="220" controls controlsList="nodownload"
                           className="helper__video"></video>
                    {/* <iframe className="helper__video" width="350" height="220" src="https://www.youtube.com/embed/J2cMyCWZ0qg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    {/* <iframe className="helper__video" width="350" height="220" src="https://www.youtube.com/embed/5qap5aO4i9A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    <div className="helper__section"><h3>Описание:</h3> Создание Клиента и Бренда; Добавление аккаунтов
                        рекламных систем в Teams, Добавление договора с клиентом в Teams
                    </div>
                    <div className="helper__section"><h3>Результат:</h3> В Teams все готово, для создания ТЗ по клиенту
                    </div>
                    <div className="helper__section">
                        <h3>Исходные данные:</h3>
                        <ol className="helper__list">
                            <li className="helper__list-item">
                                <p>
                                    Данные из подписанного с Клиентом договора* и Приложения (если договор еще на
                                    подписи, то можно использовать финальную версию, которая на подписи):
                                </p>
                                <ul className="helper__inner-list">
                                    <li className="helper__inner-list-item">Наименование Клиента</li>
                                    <li className="helper__inner-list-item">БИН</li>
                                    <li className="helper__inner-list-item">Наименование Бренда(ов) с которыми вы
                                        работаете
                                    </li>
                                    <li className="helper__inner-list-item">Вы должны знать в какой валюте клиент вам
                                        будет платить
                                    </li>
                                    <li className="helper__inner-list-item">Наименование нашего ТОО с которым заключен
                                        договор
                                    </li>
                                    <li className="helper__inner-list-item">Наличие/Отсутствие пункта о валютной
                                        индексации в договоре
                                    </li>
                                </ul>
                                <p className="helper__note">
                                    *в идеале, когда вы это берете из <a
                                    href="https://docs.google.com/spreadsheets/d/1MBFzpTVY7aZxA2ZoL450lX8RQkOOWTfTTLDqECH7Oto/edit#gid=1471893466"
                                    target="_blank" rel="noreferrer" className="helper__link">Реестра договоров с
                                    клиентами</a>, который заполняется Юристом, когда вы ему приносите подписанный
                                    оригинал
                                </p>
                            </li>
                            <li className="helper__list-item">
                                <p>
                                    У вас должен быть утвержденный (или на стадии финального согласования) медиаплан
                                    размещения с клиентов, на основании которого Вы подадите заявку в отдел
                                    Контекста на создания Вам аккаунтов. Чтоб подать заявку на создание аккаунтов,
                                    необходимо:
                                </p>
                                <ul className="helper__inner-list">
                                    <li className="helper__inner-list-item">
                                        Убедитесь, что в Телеграмм вы добавленны в группу Запуски Рекламных Кампаний -
                                        вы должны были туда добавиться автоматически при трудоустройстве, если этого не
                                        произошло обратитесь в HR и сообщите о проблеме т.к. возможно Вас нет в иных
                                        важных группах. <a href="https://t.me/joinchat/mlQ4G0Vxu7pkY2Yy" target="_blank"
                                                           rel="noreferrer" className="helper__link">Приглашение в
                                        группу</a>.
                                    </li>
                                    <li className="helper__inner-list-item">
                                        В группе пишем сообщение в свободной форме о том, что вам нужно. Пример:
                                        <blockquote className="helper__blockquote">
                                            Привет, прошу создать мне аккаунты для Клиента ХХХХ ТОО, Бренд YYYY в
                                            следующих рекламных системах:
                                            <div style={{margin: '0 1rem'}}>
                                                <p className="">Google</p>
                                                <p className="">Yandex Direct</p>
                                                <p className="">FB&Insta</p>
                                                <p className="">TikTok</p>
                                            </div>
                                        </blockquote>
                                    </li>
                                    <li className="helper__inner-list-item">
                                        Дожидаетесь ответа в личку или в чате, в качестве ответа Вам предоставят ID
                                        аккаунтов. Если Вам долго (более 2-х часов) не ответили, можно еще раз
                                        напомнить о своем запросе в Чате или написать Начальнику отдела Контекста с
                                        просьбой помочь с решением вопроса.
                                    </li>
                                </ul>
                            </li>
                            <p className="helper__list-item">
                                {/* <mark> */}
                                Дополнительно: Без договора не возможно создать ТЗ, но иногда бывает потребность в
                                тестовом запуске (т.е. вообще не факт, что договор будет) - для таких целей создавайте
                                договор с наименованием Тестовый договор, а дата = сегодня. (если в будущем начнем
                                работать, не забывайте отредактировать договор на правильный)
                                {/* </mark> */}
                            </p>
                        </ol>
                    </div>
                </>)
            },
            isActive: false,
        },
        {
            id: 2,
            title: 'Распространенные ошибки при закрытии АВР и пути их решения',
            body() {
                return (<>
                    <video width="350" height="220" controls controlsList="nodownload"
                           className="helper__video"></video>
                    {/* <iframe className="helper__video" width="350" height="220" src="https://www.youtube.com/embed/J2cMyCWZ0qg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    {/* <iframe className="helper__video" width="350" height="220" src="https://www.youtube.com/embed/5qap5aO4i9A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    <div className="helper__section"><h3>Описание:</h3> Причины по которым АВР не закрывается, или его
                        можно закрыть не правильно
                    </div>
                    <div className="helper__section"><h3>Результат:</h3> Вероятность того, что вы закроете АВР без
                        косяков 99%
                    </div>
                    <div className="helper__section">
                        <h3>Ошибки и пути решения:</h3>
                        <ol className="helper__list">
                            <li className="helper__list-item">
                                <p>
                                    <b>При закрытии АВР отсутствует какой либо инструмент в списке или вообще нет ни
                                        одного инструмента на закрытие</b>
                                </p>
                                <p>
                                    <b>Решение:</b> Убедитесь, что вы завершили ТЗ, на которое хотите закрыть АВР. Перед
                                    тем как Завершать ТЗ убедитесь в том, что в ТЗ есть полный перечень всех
                                    инструментов/наборов инструментов, которые вы будете закрывать, а также удалите из
                                    ТЗ все инструменты, которые не запускались вообще. Если видите, что чего, то нет, то
                                    нажимаете редактировать и добавляете недостающие инструменты/набор инструментов,
                                    после добавления просите контекстника заполнить Тип набора, ID кампаний, факт KPI
                                    (если инструмент Manual), а также правильно установить даты плана и факта
                                    инструмента ={'>'} если в забытом инструменте использовался ID аккаунта, который
                                    ранее (минимум чем за день) уже был в системе Teams или Manual инструмент, то данные
                                    подгружаются сразу и вы можете закрывать АВР, если в забытом наборе использовался ID
                                    аккаунта, которого ранее не было в Teams, то ждите 9:00 следующего дня и только
                                    после этого вы сможете закрыть АВР.
                                </p>
                                <p>
                                    Если вы завершили ТЗ и только потом обнаружили, что нет какого-то инструмента,
                                    обратитесь в Отдел разработки и попросите вернуть ТЗ из архива и проделайте весь
                                    путь описанный выше.
                                </p>
                                <p>
                                    Примечание: Если вы столкнулись с такой проблемой как отсутствие инструмента или
                                    набора инструментов в ТЗ перед закрытием АВР, то задайте себе вопрос, как вообще
                                    такое могло получиться, что вы и контекстник весь флайт, что-то крутили, но в ТЗ
                                    этого не было?
                                </p>
                            </li>
                            <li className="helper__list-item">
                                <p>
                                    <b>При формировании АВР в Teams данные Факт KPI/Бюджет без НДС и АК, а также Расход
                                        без НДС и АК не совпадают c цифрами из рекламного кабинета или вообще
                                        отсутствуют.</b>
                                </p>
                                <p>
                                    <b>Решение:</b> Убедитесь в правильности заполнения следующих полей: Дата начало,
                                    Дата конец, ID кампании, Аккаунт - тут вам может помочь контекстник. Возможно по
                                    данному ТЗ была смена КАМа/Менеджера - обратитесь в отдел разработки с указанием,
                                    что была смена КАМа/Менеджера.
                                </p>
                            </li>
                            <li className="helper__list-item">
                                <p>
                                    <b>Очень важный пункт 1.</b> Когда вы закрываете АВР внимательно посмотрите на два
                                    столбца: <b>Сумма без НДС План</b> (Teams автоматически считает, сколько
                                    клиент должен заплатить исходя из данных в ТЗ и выгруженных данных) и <b>Сумма без
                                    НДС факт</b> (вы пишите вручную данную сумму и именно она попадет в АВР).
                                    Если у вас большая разница между этими двумя цифрами, то у вас точно проблема: <b>либо
                                    у вас был перекрут/недокрут</b> и тут важно согласовать наши действия
                                    с КАМ, КД и ГД; <b>либо вы просто</b> не пропорционально разбиваете сумму между
                                    двумя системами из набора инструментов (Пример: За набор инструментов из двух
                                    систем, Клиент вам должен оплатить 200 тг, в Инструменте 1 С/С - 50 тг, в
                                    Инструменте 2 С/С 50 тг, а доход вы разбиваете так: Инструмент 1 - 180 тг,
                                    Инструмент 2 - 20 тг ={'>'} в этом случае у вас по Репорту Инструмент 1 будет иметь
                                    очень большую GP, а инструмент 2 отрицательную. Чтоб все было правильно,
                                    Доход по каждому инструменту нужно разбить пропорционально С/С, а именно в данном
                                    примере по 100 тг на каждый инструмент) <b>Либо у вас ошибка в ТЗ</b>, а
                                    именно вы не правильно поставили Цену или АК ={'>'} в этом случае не обязательно
                                    исправлять ТЗ, главное выставьте Клиенту правильную сумму в АВР.
                                </p>
                            </li>
                            <li className="helper__list-item">
                                <p>
                                    <b>Не получается завершить ТЗ ={'>'}</b> Читай сообщения в верхней части экрана и
                                    исправляй, через внесение недостающих данных
                                </p>
                            </li>
                            <li className="helper__list-item">
                                <p>
                                    <b>АВР содержит неправильный договор или неправильное приложение.</b> Во первых
                                    нужно было внимательно смотреть на этот пункт при создании ТЗ, самая частая
                                    ошибка - клиент просит в АВР писать номер приложения и номер договора ={'>'} чтоб
                                    это обеспечить, нужно сначала создать такой договор (с названием как хочет
                                    клиент, чтоб в будущем было в АВР), а потом подавать ТЗ выбирая этот договор. Если
                                    вы все же не сделали так изначально: То создаете договор с нужным
                                    названием/приложением (ждете пока вам его утвердит Юрист и Бухгалтерия), в АВР если
                                    вы его уже отправляли, то нажимаете Вернуть АВР, а потом нажимаете
                                    Редактировать и выбираете нужный договор ={'>'} собираете АВР заново. Если вы еще не
                                    отправляли АВР ={'>'} выбираете нужный договор и собираете АВР
                                </p>
                            </li>
                            <li className="helper__list-item">
                                <p>
                                    <b>Функция Раздробить ТЗ и закрыть АВР.</b> Если у вас есть флайт, который длится
                                    более одного календарного месяца ={'>'} следовательно вам нужно закрыть 2-а
                                    АВР (за первый месяц, второй месяц и например третий месяц) Последовательность ваших
                                    действий: Заходите в ТЗ, нажимаете кнопку раздробить ТЗ ={'>'}
                                    автоматически у вас в бренде появятся два ТЗ (одно без изменений в названии - это ТЗ
                                    прошлого месяца, а у второго в названии флайта будет пометка divided -
                                    это вторая половинка ТЗ которая будет идти дальше). Заходите в первое ТЗ
                                    ={'>'} завершаете его и закрываете АВР, а по второму у вас продолжается рекламная
                                    кампания на остаток KPI, который перетек в вторую половину ТЗ
                                </p>
                            </li>
                            <li className="helper__list-item">
                                <p>
                                    <b>Ошибка проверьте Заполнение АВР.</b> Тут либо у вас не заполнен Курс валют
                                    ={'>'} заполните, Сумма без НДС факт по какому-то инструменту не заполнена ={'>'}
                                    заполните, Оплачиваемый расход (биллинг) не заполнен ={'>'} Заполните. Возможно у
                                    Вас пустое поле Поставщик в 1С - в данном случае вам необходимо обратиться в
                                    Финансовый отдел и попросить добавить договор с поставщиком для данного инструмента
                                    ={'>'} после того, как вам его заполнят, обратитесь в Отдел Разработки ={'>'}
                                    проделав эти шаги вы сможете закрыть АВР
                                </p>
                            </li>
                            <li className="helper__list-item">
                                <p>
                                    <b>Очень важный пункт 2.</b> Если у вас были подарочные баннеры/исследование/прочее
                                    для клиента, то внося эти траты в Teams обязательно указывайте
                                    контекстника. Если у вас были Баннеры, исследования и другие доп услуги за которые
                                    клиент платит ={'>'} в данном случае контекстника указывать не нужно (отдел
                                    контекста не получает бонусы с GP от баннеров, исследований и т.д., при этом затраты
                                    на такие вещи отнимаются от общей GP при расчете бонуса контекстника)
                                </p>
                            </li>
                        </ol>
                    </div>
                </>)
            },
            isActive: false,
        },
        {
            id: 3,
            title: 'Закрытие АВР и Функция Alert ТЗ/АВР',
            body() {
                return (<>
                    <video width="350" height="220" controls controlsList="nodownload"
                           className="helper__video"></video>
                    {/* <iframe className="helper__video" width="350" height="220" src="https://www.youtube.com/embed/J2cMyCWZ0qg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    {/* <iframe className="helper__video" width="350" height="220" src="https://www.youtube.com/embed/5qap5aO4i9A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    <div className="helper__section"><h3>Описание:</h3> Необходимые действия перед тем как приступить к
                        закрытию АВР. Как правильно в Teams закрывать АВР
                    </div>
                    <div className="helper__section"><h3>Результат:</h3> Закрытие АВР без ошибок с вероятностью 99,9% и
                        отсутствие вопросов по сверке данных у финансистов
                    </div>
                    <div className="helper__section"><h3>Подготовка к Закрытию АВР:</h3> Зайдите в раздел Мои ТЗ
                        =&gt; Alert ТЗ/АВР
                    </div>
                    <div className="helper__section">
                        <p>
                            {/* <mark> */}
                            В данном разделе все ваши ТЗ имеют три цвета:
                            {/* </mark> */}
                        </p>
                        <ol className="helper__list" style={{marginLeft: '1rem'}}>
                            <li className="helper__list-item">
                                <p>
                                    <span className='red'>Красный</span> означает, что ТЗ завершено, не менее одного
                                    инструмента имеет дату начала в прошлом месяце, АВР на текущий момент отсутствует.
                                </p>
                                <p>
                                    <b>Что делаем:</b> по таким ТЗ необходимо до 10 числа месяца следующего за отчетным
                                    выставлять АВР клиенту и отправить на подпись. Как только вы выставите АВР
                                    по всем инструментам из ТЗ где были траты - он пропадет у вас из списка.
                                </p>
                                <p>
                                    Если вдруг вы забыли выставить АВР до 14-го числа месяца следующего за отчетным, то
                                    вам нужно будет выставлять АВР текущим месяцем. (Забывать - это плохо для
                                    компании, клиента и вас в т.ч.)
                                </p>
                            </li>
                            <li className="helper__list-item">
                                <p>
                                    <span className='orange'>Оранжевый</span> означает, что ТЗ не завершено и
                                    сегодняшняя дата позднее, чем дата конца флайта самого позднего инструмента из ТЗ.
                                </p>
                                <p>
                                    <b>Что делаем:</b> Нужно завершить ТЗ в системе Teams. Очень важно перед тем как вы
                                    завершите ТЗ сделать следующее:
                                </p>
                                <ul className="helper__inner-list">
                                    <li className="helper__inner-list-item">
                                        убедиться в том, что “дата начала” и “дата конец” по каждому из инструментов в
                                        ТЗ стоит верная и соответствует действительности - это можно сделать задав
                                        вопрос контекстнику т.к. к моменту когда вы будете закрывать ТЗ он уже должен
                                        был поставить верные даты
                                    </li>
                                    <li className="helper__inner-list-item">
                                        убедитесь, что в ТЗ есть все инструменты по которым были траты (вдруг вы в
                                        течении месяца добавили новый инструмент, но не внесли его в ТЗ. Например некий
                                        Manual инструмент или прямое размещение)
                                    </li>
                                    <li className="helper__inner-list-item">
                                        убедитесь, что в ТЗ нет мусора - удалите все инструменты, по которым у вас не
                                        было трат. (например когда подавали ТЗ планировали откручивать в Google GDN,
                                        но по факту все открутили в РСЯ - в данном случае инструмент GDN нужно удалить,
                                        такие инструменты имеют статус “Не Запускался”) в 90% случаев когда система
                                        выдает ошибку при завершении ТЗ это именно потому, что вы не почистили “Мусор”.
                                    </li>
                                    <li className="helper__inner-list-item">
                                        Заполните данные по Manual инструментам, если их не заполнить, то вы не сможете
                                        завершить ТЗ (Manual инструменты, которые ведет отдел Контекста - заполняет
                                        Контекстник, а инструменты которые вы купили на стороне вы заполняете сами)
                                    </li>
                                </ul>
                            </li>
                            <li className="helper__list-item">
                                <p>
                                    <span className='green'>Зеленый</span> означает, ТЗ завершено и дата начала и конца
                                    всех инструментов относятся к текущему месяцу т.е. у вас еще достаточно времени на
                                    выставление АВР до
                                    10-го числа следующего месяца (но лучше не тянуть, чем быстрей АВР будет у клиента,
                                    тем быстрей он оплатит) 1-го числа следующего месяца, все Зеленые АВР
                                    станут красными и будут пропадать из списка по мере закрытия АВР по каждому их
                                    выкрашенных в красный ТЗ.
                                </p>
                            </li>

                        </ol>
                    </div>
                    <div className="helper__section">
                        <h3>Закрытие АВР:</h3>
                        <div style={{display: 'grid', rowGap: '1rem'}}>
                            <p>
                                Заходим в раздел Клиент =&gt; Авр ={'>'} +Добавить АВР
                            </p>
                            <p>
                                Важно: Система Teams не связана напрямую с 1С, поэтому если вы делаете АВР или
                                Редактируете его, обязательно нажимайте кнопку Отправить в 1С
                            </p>
                            <p>
                                Во вкладке АВР видите все ранее созданные АВР со статусами: Загружено (уже занесен в
                                1С), Ожидает (в ближайшее время будет внесен в 1С), Черновик (Черновик - АВР который вы
                                создали, но не нажали кнопку Отправить. не копите черновики, можете использовать его для
                                закрытия АВР в следующий период)
                            </p>
                        </div>
                    </div>
                    <div className="helper__section">
                        <h3>Кнопки:</h3>
                        <div style={{display: 'grid', rowGap: '1rem'}}>
                            <p>
                                Доступные кнопки при первичном создании АВР:
                            </p>
                            <p>
                                <b>Готово</b> - появляется при создании АВР или после нажатия Кнопки Редактировать.
                                Нажатие данной кнопки, означает сохранение изменений в системе Teams, без
                                отправки данных в 1С.
                            </p>
                            <p>
                                <b>Предпросмотр</b> - Нажав эту кнопку вы увидите будущую таблицу в бумажном АВР (для
                                АВР с большим кол-вом строк облегчает проверку)
                            </p>
                            <p>
                                <b>Предпросмотр для сверки</b> - Удобная функция если вы работаете с клиентом за %-т от
                                бюджета и перед выставлением АВР сверяете траты в аккаунтах с клиентом
                                или Вы просто хотите сделать сверку трат в Teams с тратами в аккаунтах - особенно
                                полезно делать если у вас в одном аккаунте много брендом или флайтов. Можно
                                внести всю информацию в АВР, нажать эту кнопку ={'>'} скопировать данные в excel
                                ={'>'} сверить ={'>'} и уже после сверки с самим собой или клиентом отправить
                                АВР на загрузку в 1С
                            </p>
                            <p>
                                <b>Предпросмотр ВП</b> - Очень важная кнопка, которая позволяет не сделать финансовых
                                ошибок, а также заранее минимизировать вопросы финансового аналитика по
                                Report Manager. Нажимая на нее вы видите ВП по всем инструментам из АВР уже со скидками
                                от поставщиков. Красным подсвечивается отрицательная ВП. Если вы видите
                                отрицательную ВП - либо у вас ошибка в сумме АВР за данный инструмент и ее нужно
                                исправить, либо вы осознанно с согласования руководителя, зная все обстоятельства
                                и имея понимание почему мы сработали в минус отправляете в 1С АВР с минусом.
                            </p>
                            <p>
                                <b>Отправить</b> - вы отправляете созданный в системе Teams АВР на загрузку в 1С, после
                                чего его бухгалтерия распечатывает и подписывает его, а вы забираете его из
                                кабинета Бухгалтерии и отправляете клиенту.
                            </p>
                            <p>
                                Вернуть АВР ={'>'} потом редактировать
                            </p>
                        </div>
                    </div>
                </>)
            },
            isActive: false,
        },
        // {
        //     id: 2,
        //     title: 'Why do you need an FAQ page?',
        //     body() {
        //         return 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit saepe sequi, illum facere necessitatibus cum aliquam id illo omnis maxime, totam soluta voluptate amet ut sit ipsum aperiam. Perspiciatis, porro!'
        //     },
        //     isActive: false,
        // },
        // {
        //     id: 3,
        //     title: 'Does it improves the user experience of a website?',
        //     body() {
        //         return 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit saepe sequi, illum facere necessitatibus cum aliquam id illo omnis maxime, totam soluta voluptate amet ut sit ipsum aperiam. Perspiciatis, porro!'
        //     },
        //     isActive: false,
        // },
    ])

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    // const history = useHistory()
    const location = useLocation()


    const [table, setTable] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const getTable = async () => {
        try {
            setLoading(true)
            const response_table = await axios.get('clients/')
            setTable(response_table.data)
        } catch (error) {
            setError(error.response?.data || 'Server error')
        } finally {
            setLoading(false)
        }
    }
    // useEffect(() => {
    //     getTable()
    // }, [])

    useEffect(() => {
        setActiveSidebarLink('helper/helper1')
    }, [setActiveSidebarLink])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/helper/helper1', breadcrumb: table?.title || ''},
            // { path: '/users/:id', breadcrumb: 'id-breadcrumb' },
        ])
    }, [setBreadcrumbs, table?.title, location])

    // const buttons = [
    //     {
    //         text: 'Добавить клиента',
    //         icon: 'plus',
    //         url: 'clients/new/',
    //         popup_title: 'Клиент',
    //         popup_callback() {
    //             getTable()
    //         }
    //     },
    // ]

    const toggleHelperItem = (id) => {
        setHelpers(helpers => {
            return helpers.map(helper => helper.id === id ? {...helper, isActive: !helper.isActive} : helper)
        })
        const helperBody = document.getElementById(id)
        const helperBodyContainer = helperBody.parentElement
        if (helperBodyContainer.getBoundingClientRect().height) {
            helperBodyContainer.style.height = '0px';

        } else {
            const {height} = helperBody.getBoundingClientRect()
            helperBodyContainer.style.height = height + 'px';
        }
    }


    if (loading) return <LoaderComponent/>
    if (error) return <div>{error.message}</div>
    return (<>
            {/* <Alert /> */}
            <div className="helper page">
                <h1 className="helper__heading">Helper</h1>
                <section className="helper__container">
                    {helpers.map(helper => {
                        const {id, title, body, isActive} = helper
                        return (
                            <div key={id} className='helper__item'>
                                <h1 className={`helper__title ${isActive ? 'active' : ''}`}
                                    onClick={() => toggleHelperItem(id)}>{title}</h1>
                                <div className='helper__body__container'>
                                    <div id={id} className="helper__body">{body()}</div>
                                </div>
                            </div>
                        )
                    })}

                </section>
            </div>

        </>
    )
}
