import React, {useCallback, useEffect, useState} from 'react'
import './kpi_analysis_archive.css'

import {useGlobalContext} from '../../store/context/context'
import {useHistory, useLocation} from 'react-router'
import axios from 'axios'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import {TableV} from '../../components/layout/table/TableV'

// function useQuery() {
//     return new queryString.parse(useLocation().search);
// }

export const KpiAnalysisArchive = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        setActiveTopbarLink,
        setShowFeedback,
        setServerError
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const history = useHistory()
    const location = useLocation()

    const [dateFields, setDateFields] = useState({
        // month: {
        //     label: 'month',
        //     value: response.data.month || '',
        //     type: 'select_field',
        //     options: [],
        // },
        // year: {
        //     label: 'year',
        //     value: response.data.year || '',
        //     type: 'select_field',
        //     options: [],
        // }
    })

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [table, setTable] = useState({})

    const getInitialDataAndReplace = useCallback(async () => {
        console.log('getInitialDataAndReplace called................');
        try {
            setError(false)
            setLoading(true)
            const response = await axios.get(`${location.pathname}/`)
            setTable(response.data)

            if (response.data.month && response.data.year) {
                setDateFields({
                    month: {
                        label: 'месяц',
                        value: response.data.month || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1),
                    },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index),
                    }
                })
            } else {
                setDateFields([])
            }

            history.replace(location.pathname + `?month=${response.data.month}&year=${response.data.year}`)

        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTable(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, history, setServerError])

    const getInitialData = useCallback(async () => {
        console.log('getInitialData called................');
        try {
            setError(false)
            setLoading(true)

            const response = await axios.get(`${location.pathname}/` + location.search)

            setTable(response.data)

            if (response.data.month && response.data.year) {
                setDateFields({
                    month: {
                        label: 'месяц',
                        value: response.data.month || '',
                        type: 'select_field',
                        options: Array.from({length: 12}, (val, index) => index + 1),
                    },
                    year: {
                        label: 'год',
                        value: response.data.year || '',
                        type: 'select_field',
                        options: Array.from({length: 11}, (val, index) => new Date().getFullYear() - 5 + index),
                    }
                })
            } else {
                setDateFields([])
            }
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTable(error.response?.data.message || 'Server error')
        } finally {
            setLoading(false)
        }
    }, [location.search, location.pathname, setServerError])

    useEffect(() => {
        if (history.action === 'REPLACE') return  // TABLE DATA IS ALREADY SET
        if (!location.search) return getInitialDataAndReplace()  // ON FIRST VISIT
        getInitialData() // ON PAGE REFRESH
    }, [getInitialData, getInitialDataAndReplace, location, history.action])

    const handleFieldChange = event => {
        console.log({[event.target.name]: event.target.value});
        setDateFields({
            ...dateFields,
            [event.target.name]: {...dateFields[event.target.name], value: event.target.value}
        })
    }

    const [loadingOnDateSubmit, setLoadingOnDateSubmit] = useState(false)
    const handleDateSubmitButtonClick = async () => {
        try {
            setError(false)
            setLoadingOnDateSubmit(true)

            const response = await axios.get(location.pathname + `/?&month=${dateFields.month.value}&year=${dateFields.year.value}`)
            setTable(response.data)

            setActiveTopbarLink(location.pathname + `?&month=${response.data.month}&year=${response.data.year}`)  // /tzs/kpi?status=Пополнено&month=${response.data.month}&year=${response.data.year}
            history.replace(location.pathname + `?&month=${response.data.month}&year=${response.data.year}`)

        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
        } finally {
            setLoadingOnDateSubmit(false)
        }
    }


    useEffect(() => {
        setActiveSidebarLink(location.pathname.substring(1))  // tzs/kpi?status=Активно
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/tzs/fills', breadcrumb: table?.title || '', clickable: false},
        ])
    }, [setBreadcrumbs, table?.title])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    const buttons = [
        // {
        //     text: 'Добавить tz',
        //     icon: 'plus',
        //     url: 'clients/new/',
        //     popup_title: 'клиент',
        // },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        // const request_id = row['request_id'].value
        //row['request_id'].value,
    }
    // ----------------- ROW CLICK START ---------------------

    if (loading) return <LoaderComponent/>
    if (error) return <div>{error.message}</div>
    return (<>
            <div className="kpi-analysis-archive page">
            </div>

            {loadingOnDateSubmit
                ? <div>Loading on date submit...</div>
                : <TableV
                    table={table}
                    date_fields={dateFields}
                    buttons={buttons.reverse()}
                    handleRowClick={handleRowClick}
                    onDateFieldsChange={handleFieldChange}
                    handleDateSubmitButtonClick={handleDateSubmitButtonClick}
                    refreshData={getInitialData}
                />
            }
        </>
    )
}
