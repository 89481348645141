import axios from 'axios';
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useLocation, useParams} from 'react-router';
import ClientInfo from '../../components/layout/client-info/ClientInfo'
import {LoaderComponent} from '../../components/layout/loader/Loader';
import {TableV} from '../../components/layout/table/TableV';
import {useGlobalContext} from '../../store/context/context';
import './additional.css'
// import { useAxios } from '../../utils/js/useAxios';


const Additional = () => {
    const {
        setTopbarData,
        setActiveTopbarLink,
        setBreadcrumbs,
        setActiveSidebarLink,
        setPopup,
        setShowFeedback,
        renderFeedback,
        setServerError
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const {id} = useParams()
    const location = useLocation();
    const contractURL = useMemo(() => location.pathname.slice(0, location.pathname.lastIndexOf('/')), [location.pathname])


    const [table, setTable] = useState({})
    const [client, setClient] = useState({name: ''})
    const [loadingClient, setLoadingClient] = useState(true)
    const [loadingTable, setLoadingTable] = useState(true)
    const [errorClient, setErrorClient] = useState(null)
    const [errorTable, setErrorTable] = useState(null)

    const getClient = useCallback(async () => {
        try {
            setLoadingClient(true)
            const response_client = await axios.get(`${contractURL}/`)
            setClient(response_client.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorClient(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorClient(error.response?.data|| 'Server error')
        } finally {
            setLoadingClient(false)
        }
    }, [contractURL, setServerError])
    const getTable = useCallback(async () => {
        try {
            setLoadingTable(true)
            const response_table = await axios.get(`${location.pathname}/`)
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTable(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
        } finally {
            setLoadingTable(false)
        }
    }, [location.pathname, setServerError])
    useEffect(() => {
        getClient()
        getTable()
    }, [getClient, getTable])

    const getUrl = useCallback((root = false) => {
        if (root) {
            if (location.pathname.lastIndexOf('/') === 0) return location.pathname.substring(location.pathname.lastIndexOf('/') + 1) // /clients ==>> clients
            return location.pathname.substring(1, location.pathname.indexOf('/', 1))  // i.e. /clients/id/brands ==>> clients
        }
        return location.pathname.substring(location.pathname.lastIndexOf('/') + 1)  // i.e. /clients/id/brands ==>> brands   OR   /clients ==>> clients
    }, [location.pathname])


    useEffect(() => {
        const parentURL = getUrl('root')
        // console.log(parentURL);  // clients
        setActiveSidebarLink(parentURL)
    }, [setActiveSidebarLink, getUrl, location.pathname])

    useEffect(() => {
        setActiveTopbarLink(location.pathname)  // `/clients/${id}/brands`
    }, [setActiveTopbarLink, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [
                // {value: 'Бренды', url: `/clients/${id}/brands`},
                // {value: 'Договоры', url: `/clients/${id}/contracts`},
                // {value: 'Аккаунты', url: `/clients/${id}/accounts`},
                // {value: 'АВР', url: `/clients/${id}/avrs`}
            ],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/clients', breadcrumb: 'Клиенты'},
            {path: `${contractURL.slice(0, contractURL.lastIndexOf('/'))}`, breadcrumb: `Договоры`},  // in case we nadd page 'client'
            {path: `${location.pathname}`, breadcrumb: `${table.title || ''}`},  // links[getURL()] WAS HERE PREVIOUSLY
        ])
    }, [setBreadcrumbs, id, location.pathname, client.name, getUrl, table.title, contractURL])


    const buttons = [
        {
            text: `Создать приложение`,
            icon: 'plus',
            url: `${location.pathname}/new/`,
            popup_title: 'Создать приложение',
            popup_callback() {
                getTable()
            }
        },
    ]

    useEffect(() => {  // ON LOCATION.PATHNAME CHANGE IF POPUP IS OPEN THEN CLOSE IT AUTOMATICALLY
        setPopup({
            visible: false,
            data: {},
            url: null,
            title: ''
        })
    }, [location.pathname, setPopup])
    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value

        try {
            const url = `${location.pathname}/${request_id}/edit/`
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать приложение',
                callback() {
                    getTable()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })
            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }

    }
    // ----------------- ROW CLICK STOP ---------------------

    // ----------------- EDIT CLIENT START ---------------------
    const editClient = async () => {
        try {
            const url = `${contractURL}/edit/`
            const response = await axios.get(url)
            setPopup({
                visible: true,
                data: response.data,
                url,
                title: 'Редактировать договор с клиентом',
                callback() {
                    getClient()
                }
            })
        } catch (error) {
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            if (error.response.status === 500) return setServerError(true)
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // ----------------- EDIT CLIENT STOP ---------------------

    return (<>
            <div className='additional page'>
                {loadingClient
                    ? <LoaderComponent/>
                    : errorClient
                        ? <div>{errorClient.message}</div>
                        : <ClientInfo inline client={client} editClient={editClient}/>}
                {/* <ClientInfo client={client}/> */}
            </div>

            {loadingTable
                ? <LoaderComponent/>
                : errorTable
                    ? <div>{errorTable.message}</div>
                    : <TableV
                        table={table}
                        buttons={buttons.reverse()}
                        handleRowClick={handleRowClick}
                        refreshData={getTable}
                        href={(() => {
                            let href
                            switch (table.title) {
                                case 'Договоры':
                                    href = null
                                    break
                                case 'АВР':
                                    href = {
                                        from: location.pathname,
                                        to: 'view'
                                    }
                                    break
                                default:
                                    href = {
                                        from: location.pathname,
                                        to: ''
                                    }
                            }
                            return href
                        })()
                        }
                    />}
        </>
    )
}

export default Additional

// const buttons = [
//     {
//         text: 'Новый АВР',
//         icon: 'plus',
//         url: ''
//     },
//     {
//         text: 'Edit',
//         icon: 'edit',
//         url: ''
//     },
//     {
//         text: 'История изменений ТЗ',
//         icon: 'book',
//         url: ''
//     },
// ]

// const client = {
//     name: "Асем Ай",
//     data: [
//         {
//             key: 'Клиент',
//             value: 'Dizzy TOO'
//         },
//         {
//             key: 'БИИ/ИНН/ИИН',
//             value: '"1234567890"'
//         },
//         {
//             key: 'Тип бренда',
//             value: 'KPI'
//         },
//     ]
// }


// useEffect(() => {
//    console.log(location.pathname); // result: '/secondpage'
//    console.log(location.state); // result: 'some_value'
// }, [location]);

// const { response: table, error: tableError, loading: tableLoading } = useAxios(`${location.pathname}/`)
// const { response: client, error: clientError, loading: clientLoading } = useAxios(`clients/${id}/`)
