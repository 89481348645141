import React, {useState} from 'react'
import {GoPrimitiveDot} from 'react-icons/go'
import {ReactComponent as Pencil} from '../../../assets/sidebar-icons/pencil.svg'
import {ReactComponent as Trash1} from '../../../utils/icons/trash_1.svg'
import {ReactComponent as ArrowOpen} from '../../../assets/sidebar-icons/arrow-open.svg'
import {ReactComponent as ArrowClose} from '../../../assets/sidebar-icons/arrow-close.svg'
import './client-info.css'

const ClientInfo = ({
                        inline,
                        client: {name, data = []},
                        editClient = null,
                        closeable = true,
                        button: {text = 'Редактировать', icon = 'edit'} = {}
                    }) => {
    const [showClienInfo, setShowClientInfo] = useState(true)

    const [buttonIcons] = useState({
        'edit': Pencil,
        'delete': Trash1
    })

    const drawClientDataContainer = () => {
        return data.map((singleData, index) => {
            return <div key={index} className="client__data">
                <div className="client__data__left">
                    <p className="client__key">{singleData.key}</p>
                    <p className="client__value">{singleData.value}</p>
                </div>
                {index !== data.length - 1 &&
                    <span className="client__data__right svg-container">
                    <GoPrimitiveDot className="gray"/>
                </span>}
            </div>
        })
    }

    const Icon = buttonIcons[icon]
    // VERSION 1.0
    if (inline) {
        return (
            <div className="client__info client__info__inline">
                <h2 className="client__name__inline">{name}</h2>
                <div className="client__data-container client__data-container__inline">
                    {drawClientDataContainer()}
                </div>
                {editClient && <button className={`button button-2 ${icon}`} onClick={editClient}>
                    <span className="svg-container"><Icon className={icon === 'delete' ? 'white' : ''}/></span>
                    <span>{text}</span>
                </button>}
            </div>
        )
    }
    // VERSION 2.0
    return (
        <div className="client__info">
            <div className="client__info__top">
                <h2 className="client__name">{name}</h2>
                {closeable && <button className="svg-container" onClick={() => setShowClientInfo(!showClienInfo)}>
                    {showClienInfo ? <ArrowClose/> : <ArrowOpen/>}
                </button>}
                {editClient && <button className={`button button-2 ${icon}`} onClick={editClient}>
                    <span className="svg-container"><Icon className={icon === 'delete' ? 'white' : ''}/></span>
                    <span>{text}</span>
                </button>}
            </div>
            {showClienInfo &&
                <div className="client__data-container">
                    {drawClientDataContainer()}
                </div>}
        </div>
    )
}

export default ClientInfo
