export default function getFilteredRows(rows, filter) {
    let filteredRows = []
    let rowIsToBeIncluded = true
    for (let i = 0; i < rows.length; i++) {
        rowIsToBeIncluded = Object.keys(filter).every(accessor => {
            return String(rows[i][accessor].value).toLowerCase().includes(filter[accessor].toLowerCase())
        })

        if (rowIsToBeIncluded) filteredRows.push(rows[i])
        else rowIsToBeIncluded = true
    }
    return filteredRows
}
