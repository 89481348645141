import React, {useEffect} from 'react'
import {useLocation} from 'react-router'
import {useGlobalContext} from '../../store/context/context'

export const Empty = () => {
    const {setTopbarData, setBreadcrumbs, setActiveSidebarLink, setShowFeedback} = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const location = useLocation()

    useEffect(() => {
        console.log(location.pathname.substring(1));
        // console.log(parentURL);  // client
        setActiveSidebarLink(location.pathname.substring(1))
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null,
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/', breadcrumb: 'Главная'},
        ])
    }, [setBreadcrumbs])
    return (
        <div>
            Still in development...
        </div>
    )
}
