import axios from 'axios'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory, useLocation, useParams} from 'react-router'
import {AnimatedBackgroundLoader, LoaderComponent} from '../../components/layout/loader/Loader'
import SwipeUp from '../../components/layout/swipe-up/SwipeUp'
import TableTzBottom from '../../components/layout/table_tz/TableTzBottom'
import TableTzTop from '../../components/layout/table_tz/TableTzTop'
import {useGlobalContext} from '../../store/context/context'
import './avr.css'

export const Avr = () => {
    const {setTopbarData, setBreadcrumbs, setActiveSidebarLink, renderFeedback, setServerError} = useGlobalContext()
    // useEffect(() => {
    //     setShowFeedback(false)
    // }, [setShowFeedback])

    const {id} = useParams()
    const location = useLocation()
    const history = useHistory()

    const urlEndPart = useMemo(() => location.pathname, [location.pathname])

    const [tableHead, setTableHead] = useState({})
    const [tableBody, setTableBody] = useState({})
    const [tableFoot, setTableFoot] = useState({})
    const [loadingTableHead, setLoadingTableHead] = useState(true)
    const [loadingTableBody, setLoadingTableBody] = useState(true)
    const [loadingTableFoot, setLoadingTableFoot] = useState(true)
    const [errorTableHead, setErrorTableHead] = useState(null)
    const [errorTableBody, setErrorTableBody] = useState(null)
    const [errorTableFoot, setErrorTableFoot] = useState(null)
    const [loading, setLoading] = useState(false)


    const getHead = useCallback(async () => {
        try {
            setLoadingTableHead(true)
            const response_table_head = await axios.get(`${urlEndPart}/head/`)
            setTableHead(response_table_head.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTableHead(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTableHead(error.response?.data)
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        } finally {
            setLoadingTableHead(false)
        }
    }, [renderFeedback, urlEndPart, setServerError])

    const getBody = useCallback(async () => {
        try {
            setLoadingTableBody(true)
            const response_table_body = await axios.get(`${urlEndPart}/body/`)
            setTableBody(response_table_body.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTableBody(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTableBody(error.response?.data?.message)
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        } finally {
            setLoadingTableBody(false)
        }
    }, [renderFeedback, urlEndPart, setServerError])

    const getFoot = useCallback(async () => {
        try {
            setLoadingTableFoot(true)
            const response_table_foot = await axios.get(`${urlEndPart}/foot/`)
            setTableFoot(response_table_foot.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setErrorTableFoot(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setErrorTableFoot(error.response?.data?.message)
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        } finally {
            setLoadingTableFoot(false)
        }
    }, [renderFeedback, urlEndPart, setServerError])

    const getTableHeadAndBodyandFoot = useCallback(() => {
        getHead()
        getBody()
        getFoot()
    }, [getHead, getBody, getFoot])

    useEffect(() => {
        getTableHeadAndBodyandFoot()
    }, [getTableHeadAndBodyandFoot])


    useEffect(() => {
        setActiveSidebarLink('avrs')
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData, id])

    useEffect(() => {
        // console.log(urlStartPart, urlStartPart.substring(0, location.pathname.lastIndexOf('/')));
        setBreadcrumbs([
            {path: '/avrs', breadcrumb: 'Список АВР'},
            {path: `${location.pathname}`, breadcrumb: `АВР`},  // links[getURL()] WAS HERE PREVIOUSLY)
        ])
    }, [setBreadcrumbs, id, location.pathname])

    useEffect(() => {
        // window.onbeforeunload = (event) => {
        //     const e = event || window.event;
        //     // Cancel the event
        //     e.preventDefault();
        //     console.log('eeee');
        //     if (e) {
        //       e.returnValue = ''; // Legacy method for cross browser support
        //       console.log('dddddddde');
        //     }

        //     // console.log('efefefefef');
        //     return ''; // Legacy method for cross browser support
        // };
        // window.beforeunload = (e) => {
        //     console.log('Stop this');
        //     e.preventDefault()
        //     e.returnValue = '';
        // };
    }, [])

    const [showSwipeUp, setShowSwipeUp] = useState(false)
    const [swipeUpData, setSwipeUpData] = useState({})

    const handlePreviewClick = useCallback(async (previewType) => {
        if (loading) return
        try {
            setLoading(true)
            const url = `${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/${previewType}/`
            const response = await axios.get(url)
            setShowSwipeUp(true)

            let staticBtns = []
            let sendAvrButton = null
            if (previewType !== 'vp') {
                staticBtns.push({  // copy avr
                    text: 'Скопировать АВР',
                    icon: 'group',
                    onClick(rows, table, columnAccessors) {
                        try {
                            let $table = document.createElement('table')
                            let $thead = document.createElement('thead')
                            let $tbody = document.createElement('tbody')

                            let $tr = document.createElement('tr')
                            columnAccessors.forEach(accessor => {
                                // const header = headers[accessor]
                                if (!table.headers[accessor].visible) return  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                                let $th = document.createElement('th')
                                const {label} = table.headers[accessor]
                                // console.log(label);
                                $th.innerHTML = label
                                $tr.appendChild($th)
                            })
                            $thead.appendChild($tr)

                            table.rows.forEach(row => {
                                let $tr = document.createElement('tr')

                                columnAccessors.forEach(accessor => {
                                    const {value} = row[accessor]
                                    if (!table.headers[accessor].visible) return   // MEANS THIS COLUMN IS HIDDEN IN DND BY USER

                                    let $td = document.createElement('td')
                                    $td.innerHTML = value
                                    $tr.appendChild($td)
                                })

                                $tbody.appendChild($tr)
                            })

                            $table.appendChild($thead)
                            $table.appendChild($tbody)
                            console.log($table);
                            $table.setAttribute('id', 'tableToCopyyy')
                            document.body.appendChild($table)
                            const copyElementToClipboard = (element) => {
                                window.getSelection().removeAllRanges();
                                let range = document.createRange();
                                range.selectNode(typeof element === 'string' ? document.getElementById(element) : element);
                                window.getSelection().addRange(range);
                                document.execCommand('copy');
                                window.getSelection().removeAllRanges();
                                document.body.removeChild($table)
                            }
                            copyElementToClipboard('tableToCopyyy')
                            renderFeedback('success', 'Таблица успешно скопирована')
                        } catch (error) {
                            renderFeedback('danger', 'Что-то пошло не так при попытке скопировать таблицу')
                        }
                    }
                })
            } else if (previewType === 'vp' && tableHead.send_avr) {
                sendAvrButton = {
                    text: `Отправить`,
                    icon: 'send',
                    async onClickFunction() {
                        if (loading) return
                        try {
                            setLoading(true)
                            const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/send/`)
                            // console.log(response.data);
                            const {message} = response.data
                            renderFeedback('success', message || 'Success')
                            // const path = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`
                            // history.push(path)
                            setShowSwipeUp(false)
                            getTableHeadAndBodyandFoot()
                        } catch (error) {
                            console.log(error.message);
                            renderFeedback('danger', error.response?.data?.message || 'Server Error')
                        } finally {
                            setLoading(false)
                        }
                    },
                    alert: {
                        title: 'Внимание',
                        body: 'Вы собираетесь отправить данный АВР, продолжить?',
                    },
                }
            }

            setSwipeUpData({
                table: response.data,
                buttons: [
                    ...staticBtns
                ],
                sendAvrButton
            })
        } catch (error) {
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        } finally {
            setLoading(false)
        }
    }, [location.pathname, renderFeedback, loading, getTableHeadAndBodyandFoot, tableHead.send_avr, urlEndPart])
    const buttons = useMemo(() => {
        let dynamicBtns = []
        // if (tableHead.send_avr) {  // ALERT // LOADING
        //     dynamicBtns.push({
        //         text: `Отправить`,
        //         icon: 'send',
        //         async onClickFunction() {
        //             if (loading) return
        //             try {
        //                 setLoading(true)
        //                 const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/send/`)
        //                 // console.log(response.data);
        //                 const { message } = response.data
        //                 renderFeedback('success', message || 'Success')
        //                 // const path = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`
        //                 // history.push(path)
        //                 getTableHeadAndBodyandFoot()
        //             } catch (error) {
        //                 console.log(error.message);
        //                 renderFeedback('danger', error.response?.data?.message || 'Server Error')
        //             } finally {
        //                 setLoading(false)
        //             }
        //         },
        //         alert: {
        //             title: 'Внимание',
        //             body: 'Вы собираетесь отправить данный АВР, продолжить?',
        //         },
        //     })
        // }
        if (tableHead.back) {  // ALERT // LOADING
            dynamicBtns.push({
                text: `Вернуть АВР`,
                icon: 'back',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/back/`)
                        // console.log(response.data);
                        const {message} = response.data
                        renderFeedback('success', message || 'Success')
                        getTableHeadAndBodyandFoot()
                    } catch (error) {
                        console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
                alert: {
                    title: 'Внимание',
                    body: 'Вы собираетесь вернуть данный АВР, продолжить?',
                },
            })
        }
        if (tableHead.delete) {  // ALERT // LOADING
            dynamicBtns.push({
                text: `Отправить на удаление`,
                icon: 'delete',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/delete/`)
                        // console.log(response.data);
                        const {message} = response.data
                        renderFeedback('success', message || 'Success')
                        getTableHeadAndBodyandFoot()
                    } catch (error) {
                        console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
                alert: {
                    title: 'Внимание',
                    body: 'Вы собираетесь отправить данный АВР на удаление, продолжить?',
                },
            })
        }

        let staticBtns = [
            {  // preview
                text: `Предпросмотр`,
                icon: 'preview',
                async onClickFunction() {
                    handlePreviewClick('preview')
                }
            },
            {  // preview for comparison
                text: `Предпросмотр для сверки`,
                icon: 'preview',
                async onClickFunction() {
                    handlePreviewClick('compare')
                }
            },
            {  // preview for vp
                text: `Предпросмотр ВП`,
                icon: 'preview',
                async onClickFunction() {
                    handlePreviewClick('vp')
                }
            },
            {
                text: `Удалить черновик`,
                icon: 'delete',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        const response = await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/draftdelete/`)
                        // console.log(response.data);
                        const {message} = response.data
                        renderFeedback('success', message || 'Success')
                        history.push('/avrs')
                    } catch (error) {
                        console.log(error.message);
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
                alert: {
                    title: 'Внимание',
                    body: 'Вы собираетесь удалить черновик, продолжить?',
                },
            },
        ]

        if (location.pathname.endsWith('edit')) { // ON EDIT PAGE
            const avrEditStaticBtns = [
                {  // check
                    text: `Готово`,
                    icon: 'confirm',
                    async onClickFunction() {  // SEND TO VIEW
                        try {
                            // console.log(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`);
                            history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/view`)
                        } catch (error) {
                            renderFeedback('danger', error.response?.data?.message || 'Server Error')
                        }
                    },
                }
            ]
            return [...avrEditStaticBtns, ...dynamicBtns, ...staticBtns]
        }

        const avrViewStaticBtns = [  // ON VIEW PAGE
            {  // edit // LOADING
                text: `Редактировать`,
                icon: 'edit',
                async onClickFunction() {
                    if (loading) return
                    try {
                        setLoading(true)
                        // console.log(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`);
                        await axios.get(`${urlEndPart.substring(0, urlEndPart.lastIndexOf('/'))}/edit/head/`)
                        history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/edit`)
                    } catch (error) {
                        renderFeedback('danger', error.response?.data?.message || 'Server Error')
                    } finally {
                        setLoading(false)
                    }
                },
            },

        ]
        return [...avrViewStaticBtns, ...dynamicBtns, ...staticBtns]
    }, [location.pathname, renderFeedback, history, tableHead, urlEndPart, getTableHeadAndBodyandFoot, loading, handlePreviewClick])

    // rows setRows

    // const updatedTableHeadRows = useRef(null)

    const updateTableHead = avr_sum_vat => {
        const tableHeadCopy = JSON.parse(JSON.stringify(tableHead))
        tableHeadCopy.rows[0].avr_sum_vat.value = avr_sum_vat
        setTableHead(tableHeadCopy)
    }


    if (loading) <LoaderComponent/>
    return (<>
            <div className="avr page">
            </div>

            {loadingTableHead
                ? <AnimatedBackgroundLoader text='Загрузка шапки...'/>
                : errorTableHead
                    ? <div>{errorTableHead.message}</div>
                    : <TableTzTop
                        table={tableHead}
                        setTable={setTableHead}
                        buttons={buttons}
                        head_request_id={tableHead.rows[0].request_id.value}
                        getBody={getBody}
                        getFoot={getFoot}
                        parentComponent="avr"
                        deleteOptionEnabledInSelect={true}
                    />}
            {loadingTableBody || loadingTableHead
                ? <AnimatedBackgroundLoader text='Загрузка акта выполненных работ...'/>
                : errorTableBody
                    ? <div>{errorTableBody.message}</div>
                    : <TableTzBottom
                        table={tableBody}
                        setTable={setTableBody}
                        head_request_id={tableHead.rows[0].request_id.value}
                        parentComponent="avr"
                        tableType="body"
                        refreshTableBottomData={getBody}
                        updateTableHead={updateTableHead}
                        getHead={getHead}
                        getBody={getBody}
                    />}
            {loadingTableFoot || loadingTableHead
                ? <AnimatedBackgroundLoader text='Загрузка дополнительных услуг...'/>
                : errorTableFoot
                    ? <div>{errorTableFoot.message}</div>
                    : <TableTzBottom
                        table={tableFoot}
                        setTable={setTableFoot}
                        head_request_id={tableHead.rows[0].request_id.value}
                        parentComponent="avr"
                        tableType="foot"
                        refreshTableBottomData={getFoot}
                        updateTableHead={updateTableHead}
                    />}

            <SwipeUp showSwipeUp={showSwipeUp} setShowSwipeUp={setShowSwipeUp} swipeUpData={swipeUpData}
                     setSwipeUpData={setSwipeUpData} refreshTableData={handlePreviewClick}/>
        </>
    )
}


