import React, {useEffect, useRef, useState} from 'react'
import './table.css'
import {useGlobalContext} from '../../../store/context/context'
import {ReactComponent as Trash1} from '../../../utils/icons/trash_1.svg'
import {ReactComponent as Tick1} from '../../../utils/icons/tick_1.svg'
import {ReactComponent as Cancel} from '../../../utils/icons/cancel.svg'
import {ReactComponent as PlayButton2NoBgColor} from '../../../utils/icons/play-button-2-no-bgcolor.svg'
import RadioField from '../../forms/radio/Radio'
import NumberField from '../../forms/number/Number'
import axios from 'axios'
import {useLocation} from 'react-router'
import CheckboxField from '../../forms/checkbox/Checkbox'
import TextAreaFieldMU from '../../forms/textarea_mu/TextAreaMU'
import {CheckboxField2} from '../../forms/checkbox/Checkbox2'
import {Hint, HintTable} from '../hint/Hint'
import DateNumberField from '../../forms/date/DateNumber'
import {SelectPositionedField} from '../../forms/select/SelectPositioned'
import useDebounce from '../../../utils/js/useDebounce'


export const TableForProjectTasksBody = ({
                                             table,
                                             setTable,
                                             handleRowClick,
                                             id,
                                         }) => {
    const {
        setPopupLogs,
        renderFeedback,
        tableIcons,
        SPECIAL_VALUES
    } = useGlobalContext()

    const location = useLocation()

    const [filter] = useState(() => {  // useMemo or smth like that
        const filterObject = {}
        Object.keys(table.headers).forEach(accessor => filterObject[accessor] = '')
        return filterObject
    })

    const [columnAccessors] = useState(table.order?.length ? table.order : Object.keys(table.headers))
    // const [rows, setRows] = useState(table.rows)
    // const [headers, setHeaders] = useState(table.headers)
    const [sortOrder] = useState('original')
    const [sortedColumnAccessor] = useState(null)
    const [showSearchField, setShowSearchField] = useState(undefined)
    const inputRef = useRef(null)
    const tableContentRef = useRef(null)
    const tableRef = useRef(null)

    const [formFields] = useState({
        text_field: TextAreaFieldMU,
        // select_field: SelectField,
        select_field: SelectPositionedField,
        // date_field: DateField,
        date_field: DateNumberField,
        radio_field: RadioField,
        number_field: NumberField,
        checkbox_field: CheckboxField,
        checkbox_field2: CheckboxField2,
        // textarea_field: TextAreaField,
    })






    useEffect(() => {  // SETS FOCUS AUTOMATICALLY TO NEEDED INPUT
        (showSearchField !== undefined) && inputRef.current.focus()
    }, [showSearchField])


    useEffect(() => {  // TOGGLES FILTER & SEARCH DIVs
        const handleInputBlur = event => {
            if (event.target !== inputRef.current && !event.target.classList.contains('filter__icon')) {
                // CLICKED OUTSIDE SEARCH(input) FIELD AND FILTER ICON
                // AS CLICKING ON FILTER ICON TOGGLES POPUP ON ITS OWN
                setShowSearchField(undefined)
            }
        }

        document.addEventListener('mousedown', handleInputBlur)
        return () => document.removeEventListener('mousedown', handleInputBlur)
    }, [])

    // ----------------- CELL CHANGE START ---------------------
    const [isSendRequest, setIsSendRequest] = useState(false)  // THIS IS USED AS A TRIGGER FOR SENDING REQUEST WHEN FIELD HAS BEEN STOP FROM CHANGING FOR 0.5s
    const [isSendRequestImmediately, setIsSendRequestImmediately] = useState(false)  // THIS IS USED AS A TRIGGER FOR SENDING REQUEST WHEN FIELD HAS BEEN STOP FROM CHANGING FOR 0.5s
    const useDebounceInfo = useRef({
        url: '',
        dataToSend: {},
    })

    const sendRequest = async () => {
        try {
            // setLoading(true)  // BAD IDEA CAUSE THIS FLASHES THE TZ ON EDIT PAGE AS WELL AS BRINGS THE SCROLL TO INITIAL POSITION
            const {url, dataToSend} = useDebounceInfo.current
            const response = await axios.post(url, {...dataToSend})
            if (response.data.hasOwnProperty('message')) return renderFeedback('success', response.data.message || 'Success')

            const updatedRows = table.rows.map(row => row.request_id.value === response.data.request_id.value ? response.data : row)

            const updatedHeaders = JSON.parse(JSON.stringify(table.headers))
            Object.keys(updatedHeaders).forEach(accessor => {
                if (updatedHeaders[accessor].type === 'number') {
                    updatedHeaders[accessor].label = updatedRows.reduce((acc, curr) => curr[accessor].value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? acc : acc + curr[accessor].value, 0)
                }
            })

            setTable(oldTableData => {
                return {...oldTableData, rows: updatedRows}
            })
        } catch (error) {
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        }
    }

    useDebounce(() => {
        sendRequest()
    }, 500, [isSendRequest])  // SEND REQUEST AFTER .5 seconds
    useDebounce(() => {
        sendRequest()
    }, 0, [isSendRequestImmediately])  // SEND REQUEST IMMEDIATELY

    const handleFieldChange = (event, row, type) => {
        if (event.target.name !== document.activeElement.name) return // IN AVR FOOT, WHEN WE CHANGE ONE CELL, UPDATED CHILD IS RECEIVED AND ROWS_STATE IS CHANGED,
        // WHICH IN TURN FORCES THE FORCEFULLY CHANGED CELL(DIFFERENT) CALL THIS FUNCTION AND ANOTHER REQUEST IS SEND AGAIN UNWILLINGLY. TO PREVENT THIS WE CHECK IF THE CHANGED CELL IS FOCUSED OR NOT

        if (!!row[event.target.name].is_task_name || type === 'checkbox_field2') {  // IF TASK_NAME IS BEING EDITED THEN JUST DON'T SEND REQUEST BUT UPDATE STATE
            const newRow = {
                ...row,
                [event.target.name]: {  // [event.target.name] === [accessor]
                    ...row[event.target.name],
                    value: event.target.value
                }
            }

            const updatedRows = table.rows.map(singleRow => singleRow.request_id.value === row.request_id.value ? newRow : singleRow)
            return setTable(oldTableData => {
                return {...oldTableData, rows: updatedRows}
            })
        }

        let dataToSend
        const newRow = {
            ...row,
            [event.target.name]: {  // [event.target.name] === [accessor]
                ...row[event.target.name],
                value: event.target.value
            }
        }
        if (event.target.value_id) newRow[event.target.name].value_id = event.target.value_id // FOR SELECT FIELD 2

        dataToSend = newRow

        // let rowsCopy = JSON.parse(JSON.stringify(table.rows))
        // // dataToSend = rowsCopy.filter(singleRow => singleRow.frontend_id.value === row.frontend_id.value)[0]
        // dataToSend = rowsCopy.filter(singleRow => singleRow.request_id.value === row.request_id.value)[0]

        // dataToSend[event.target.name].value = event.target.value
        // if (event.target.value_id) dataToSend[event.target.name].value_id = event.target.value_id // FOR SELECT FIELD 2

        let url = `${location.pathname}/${row[event.target.name].set_url}`

        useDebounceInfo.current = {
            url,
            dataToSend,
        }

        if (dataToSend[event.target.name].type === 'select_field' || dataToSend[event.target.name].type === 'select_field2' || dataToSend[event.target.name].type === 'date_field') {
            setIsSendRequestImmediately(!isSendRequestImmediately)
        } else {
            setIsSendRequest(!isSendRequest)
        }
    }

    const handleIconButtonClick = async (icon_accessor, row, isEditable) => {
        if (!Boolean(isEditable)) return // NOT EDITABLE
        if (icon_accessor === 'task_comment') {

            try {
                // setLoading(true)
                const url = `${location.pathname}/comment/${row.request_id.value}/`
                const response = await axios.get(url)
                let {data, date_from, date_to} = response.data
                // let { data, date_from, date_to } = {
                //     data: [
                //         {
                //             // author: "Comment #3",
                //             text: "aaaaaaaaaaaa bbbbbbbbbb cccccc ddddddd",
                //             timestamp: "27.12.2021, 03:22:50"
                //         },
                //         {
                //             // author: "Comment #2",
                //             text: "qqqqqqqqqqqwwwwwwweee eeeerrttyyyu",
                //             timestamp: "24.12.2021, 03:22:50"
                //         },
                //         {
                //             // author: "Comment #3",
                //             text: "testestestesetsetsetset rssrgd",
                //             timestamp: "20.12.2021, 03:22:50"
                //         },
                //     ],
                //     date_from: "26.11.2021",
                //     date_to: "27.12.2021"
                // }

                setPopupLogs({
                    type: 'comments', // logs or comments,
                    visible: true,
                    data,
                    title: 'История комментариев',
                    url,
                    date_from,
                    date_to
                })
            } catch (error) {
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            } finally {
                // setLoading(false)
            }
        }
    }

    const formatValue = (value, type, sign) => {
        if (type === 'number_field') {
            let number = !!sign ? Number(value) * 100 : value
            let [whole, decimal] = String(number).split('.')

            let reversed = whole.split('').reverse().join('')
            let arr = []
            for (let i = 0; i < reversed.length; i = i + 3) {
                arr.push(reversed.substring(i, i + 3).split('').reverse().join(''))
            }

            return decimal ? arr.reverse().join(' ') + ',' + decimal : arr.reverse().join(' ')
        } else if (type === 'boolean_field') {
            return !!value ? 'Да' : 'Нет'
        }

        return value
    }

    // PROJECT TASKS FUNTIONS START
    const handleCompleteButtonClick = async row_request_id => {
        try {
            const response = await axios.get(`${location.pathname}/status/complete/?request_id=${row_request_id}`)  // UPDATED ROW, i.e., editable: true -> false, AS A RESULT THE ICON ALSO CHANGES

            const updatedRows = table.rows.filter(oldRow => oldRow.request_id.value !== row_request_id)
            setTable(oldTableData => {
                return {...oldTableData, rows: updatedRows}
            })

            renderFeedback('success', response.data.message || 'Подзадача успешно завершена')
        } catch (error) {
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }

    const handleActivateButtonClick = async row_request_id => {
        try {
            const response = await axios.get(`${location.pathname}/status/active/?request_id=${row_request_id}`)  // UPDATED ROW, i.e., editable: true -> false, AS A RESULT THE ICON ALSO CHANGES

            const updatedRows = table.rows.filter(oldRow => oldRow.request_id.value !== row_request_id)
            setTable(oldTableData => {
                return {...oldTableData, rows: updatedRows}
            })

            renderFeedback('success', response.data.message || 'Подзадача успешно активирована')
        } catch (error) {
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }

    const handleCancelButtonClick = async row_request_id => {
        try {
            const response = await axios.get(`${location.pathname}/status/cancel/?request_id=${row_request_id}`)  // UPDATED ROW, i.e., editable: true -> false, AS A RESULT THE ICON ALSO CHANGES

            const updatedRows = table.rows.filter(oldRow => oldRow.request_id.value !== row_request_id)
            setTable(oldTableData => {
                return {...oldTableData, rows: updatedRows}
            })
            renderFeedback('success', response.data.message || 'Подзадача успешно отменена')

        } catch (error) {
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }

    const handleDeleteButtonClick = async row_request_id => {
        try {
            const response = await axios.get(`${location.pathname}/status/archive/?request_id=${row_request_id}`)

            const updatedRows = table.rows.filter(oldRow => oldRow.request_id.value !== row_request_id)
            setTable(oldTableData => {
                return {...oldTableData, rows: updatedRows}
            })

            renderFeedback('success', response.data.message || 'Подзадача успешно удалена')
        } catch (error) {
            renderFeedback('danger', error.response?.data.message || 'Server Error')
        }
    }
    // PROJECT TASKS FUNTIONS END

    const getTableTdButtons = (row_request_id, editable) => {
        return (
            <div className="buttons grid">
                <Hint hint='Завершить'>
                    <button className="svg-container green-button"
                            onClick={() => handleCompleteButtonClick(row_request_id)}><Tick1/></button>
                </Hint>
                <Hint hint='Активировать'>
                    <button className="svg-container yellow-button"
                            onClick={() => handleActivateButtonClick(row_request_id)}><PlayButton2NoBgColor/></button>
                </Hint>
                <Hint hint='Удалить/Заархивировать'>
                    <button className="svg-container red-button"
                            onClick={() => handleDeleteButtonClick(row_request_id)}><Trash1/></button>
                </Hint>
                <Hint hint='Отменить'>
                    <button className="svg-container blue-button"
                            onClick={() => handleCancelButtonClick(row_request_id)}><Cancel style={{color: '#fff'}}/>
                    </button>
                </Hint>
            </div>
        )
    }

    // ----------------- RENDER TABLE BODY START ---------------------
    const getTableBody = () => {
        let filteredRows = []
        let rowIsToBeIncluded = true
        for (let i = 0; i < table.rows.length; i++) {
            rowIsToBeIncluded = Object.keys(filter).every(accessor => {
                // return rows[i][accessor].value.toString().toLowerCase().includes(filter[accessor].toLowerCase())   // null.toString() OR undefined.toString() RESULT IN ERROR THUS USE String(null) or String(undefined)
                return String(table.rows[i][accessor].value).toLowerCase().includes(filter[accessor].toLowerCase())
            })

            if (rowIsToBeIncluded) filteredRows.push(table.rows[i])
            else rowIsToBeIncluded = true
        }

        return filteredRows.map((row, idx) => {
            return (
                <tr key={idx} className={`table__row ${table.clickable ? 'clickable' : 'unclickable'}`}
                    onClick={() => table.clickable ? handleRowClick(row) : {}}>
                    {/* WHEN FILLS ROW CLICKED, WE NEED TO SEND THE WHOLE ROW, THUS SEND "row" AS A SECOND PARAMETER */}
                    {columnAccessors.map(accessor => {
                        const {value, type, editable, color, options = [], sign, is_task_name} = row[accessor]
                        if (!table.headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                        if (value === null) return <td key={accessor} className={`table__td ${accessor}`}
                                                       style={{backgroundColor: color || '#fff'}}>
                            <div style={{padding: '0 8px'}}>----</div>
                        </td>

                        if (type === 'icon') { // IF THE CELL IS ICON TYPE, THEN RETURN ICON
                            // const isCellClickable = headers[accessor].label === 'icon'
                            const Icon = tableIcons[value]
                            return (  // THIS IS WHERE add BUTTON IS
                                <td key={accessor} className='table__td'
                                    style={{cursor: editable ? 'pointer' : 'default'}}
                                    onClick={() => handleIconButtonClick(accessor, row, editable)}>
                                    <div className={`table__td__center ${accessor}`} style={{justifyContent: 'center'}}>
                                        <span className="svg-container"><Icon/></span>
                                    </div>
                                </td>
                            )
                        }

                        if (editable) {
                            const FormField = formFields[type]
                            return (
                                <td key={accessor} className={`table__td ${accessor}`}
                                    style={{backgroundColor: color || '#fff'}}>
                                    <div className={`table__td__center ${accessor}`}>
                                        {/* IF NOT PROJECT TASKS AND THIS CELL IS FOR TASK_NAME, THEN SHOW CIRCLE */}
                                        {/* {!project_tasks && is_task_name !== undefined && <div className={`circle ${is_task_name}`}></div>} */}
                                        <div style={{flex: '1'}}>
                                            <FormField
                                                name={accessor}
                                                value={value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? null : value}
                                                onChange={(event) => handleFieldChange(event, row, type)}
                                                editable={true}
                                                parent="table-container"
                                                options={options}
                                                rowClickable={table.clickable}
                                                sign={sign}
                                            />
                                        </div>
                                        {is_task_name !== undefined && getTableTdButtons(row.request_id.value, editable)}
                                    </div>
                                </td>
                            )
                        }
                        return (
                            <td key={accessor} className={`table__td ${accessor}`}
                                style={{backgroundColor: color !== '#FFFFFF' ? color : ''}}>
                                <div className={`table__td__center ${accessor}`}>
                                    {/* IF NOT PROJECT TASKS AND THIS CELL IS FOR TASK_NAME, THEN SHOW CIRCLE */}
                                    {/* {!project_tasks && is_task_name !== undefined && <div className={`circle ${is_task_name}`}></div>} */}
                                    <div style={{flex: '1'}}
                                         dangerouslySetInnerHTML={{__html: value === SPECIAL_VALUES.NULL_ALIAS_FOR_NUMBER_FIELD ? '' : formatValue(value, type, sign)}}>
                                        {/* {value === SPECIAL_NUMBER ? '' : formatValue(value, type, sign)} */}
                                    </div>
                                    {is_task_name !== undefined && getTableTdButtons(row.request_id.value, editable)}
                                </div>
                            </td>
                        )
                    })}
                </tr>
            )
        })
    }
    // ----------------- RENDER TABLE BODY STOP ---------------------
    // ----------------- RENDER TABLE HEADER START ---------------------
    return (
        <div className="table-for-tasks-block">
            {/* <div className="table-caption-container" ref={tableCaptionContainerRef}>
            <button className="svg-container" onClick={() => setShowColumnsList(!showColumnsList)}><Filter className="yellow"/></button>

            <span className="table-caption">{table.title}</span>

            {buttons && <div className="btns-container">
            {buttons.map((button, index) => {
                return <button key={index} className="button button-2" onClick={() => handleButtonClick(button)}>
                    <span className="svg-container">{buttonIcons[button.icon]}</span>
                    <span>{button.text}</span>
                </button>
            })}
            </div>}
        </div> */}

            <div className="table-container" ref={tableContentRef}>
                <table className="table" id={id} ref={tableRef}>
                    <thead className={`table__thead`}>
                    <tr>
                        {columnAccessors.map(accessor => {
                            if (!table.headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                            const {label, width, normative, frequency, hint} = table.headers[accessor]

                            if (label === 'icon') {
                                let Icon = accessor === 'open' ? tableIcons['target1'] : tableIcons[accessor]
                                return (
                                    // <th key={accessor} className="table__th" style={{width: '.1%', minWidth: width || '50px'}} >
                                    <th key={accessor} className="table__th" style={{width: '.1%'}}>
                                        <div className={`table__th__center ${accessor}`}
                                             style={{width: width || '24px', justifyContent: 'center'}}>
                                            <span className="svg-container"><Icon style={{width: '24px'}}/></span>
                                            {hint && <HintTable hint={hint}/>}
                                        </div>
                                    </th>
                                )
                            }

                            return (
                                <th key={accessor}
                                    className={accessor === sortedColumnAccessor ? `table__th ${accessor} ${sortOrder}` : `table__th ${accessor}`}
                                    style={{width: width === 'auto' ? '100%' : '1%'}}>
                                    <div className={`table__th__center ${accessor}`} style={{width: width || '199px'}}>
                                        {/* <div val="" className="filter">
                                        <span
                                            className={filter[accessor] ? "filter__icon active" : "filter__icon"}
                                            tabIndex="1"
                                            onClick={e => {e.stopPropagation(); setShowSearchField(showSearchField === accessor ? undefined : accessor);}}
                                        >
                                            <Search />
                                        </span>
                                        {showSearchField === accessor &&
                                        <div className="filter__popup">
                                            <input
                                                type="text"
                                                ref={inputRef}
                                                placeholder="Фильтр"
                                                onChange={e => handleInputChange(e, accessor)}
                                                onClick={e => e.stopPropagation()}
                                                // onBlur={() => setShowSearchField(undefined)}
                                                value={filter[accessor]}
                                            />
                                        </div>
                                        }
                                    </div> */}
                                        <div className="table__th__value">
                                            {label}
                                            {(normative || frequency) &&
                                                <div className="table__th__value__criterias">
                                                    {normative && <p>Норматив: <span>{normative} минут</span></p>}
                                                    {frequency && <p>Частота: <span>{frequency}</span></p>}
                                                </div>
                                            }
                                        </div>
                                        {hint && <HintTable hint={hint}></HintTable>}
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {getTableBody()}
                    {/* {add_subtask_btn && <tr className="table__row" onClick={addNewSubTask}>
                    <td colSpan={columnAccessors.length} className="table__td table__td__add-button" >
                        <div className="table__td__center" style={{justifyContent: 'center'}}>
                            Добавить {table.type === 'other' ? 'прочую задачу' : 'подзадачу'}
                        </div>
                    </td>
                </tr>} */}
                    </tbody>
                </table>
            </div>
        </div>
    )
    // ----------------- RENDER TABLE HEADER STOP ---------------------
}
