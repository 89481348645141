import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router'
import axios from 'axios'
import {v4 as uuidv4} from 'uuid';
import './table.css'

import {ReactComponent as Filter} from '../../../utils/icons/filter.svg'
import {ReactComponent as Search} from '../../../utils/icons/filter-1.svg'
import {ReactComponent as Pin} from '../../../utils/icons/push-pin.svg'
import {ReactComponent as PinFill} from '../../../utils/icons/push-pin-fill.svg'
import {ReactComponent as Coin1} from '../../../utils/icons/coin_1.svg'

import {useGlobalContext} from '../../../store/context/context'

import RadioField from '../../forms/radio/Radio'
import NumberField from '../../forms/number/Number'
import CheckboxField from '../../forms/checkbox/Checkbox'
import {SelectPositionedField} from '../../forms/select/SelectPositioned'
import TextAreaFieldMU from '../../forms/textarea_mu/TextAreaMU'
import {CheckboxField2} from '../../forms/checkbox/Checkbox2'
import {formatValue} from './formatValue';
import {HintHeader} from '../hint/Hint';
import DateNumberField from '../../forms/date/DateNumber';
import {SendButton} from '../../buttons/send/SendButton';
import {TableDateFields} from './assets/TableDateFields';
import getFilteredRows from './getFilteredRows';
import debounce from "lodash.debounce";
import usePagination from "../../../utils/hooks/usePagination";
import {Pagination} from "@mui/material";

// href={from: '', to: ''}


export const TableV = ({
                            setTable,
                           table,
                           buttons,
                           date_fields = {},
                           onDateFieldsChange,
                           handleRowClick,
                           handleDateSubmitButtonClick,
                           refreshData,
                           id,
                           parentElement,
                           href = null,
                           paginationEnable=0
                       }) => {
    const {
        mainCenterTopBottomMargin,
        setPopup,
        renderFeedback,
        buttonIcons,
        setAlert,
        setError,
        tableIcons,
        setPopupLogs
    } = useGlobalContext()

    const [didMount, setDidMount] = useState(false);

    useEffect(() => {
        setDidMount(true);
        return () => setDidMount(false);
    }, [])

    console.log('DID_MOUNT: ', didMount);
    const history = useHistory()
    const location = useLocation()

    const [filter, setFilter] = useState(() => {  // useMemo or smth like that
        const filterObject = {}
        Object.keys(table.headers).forEach(accessor => filterObject[accessor] = '')
        return filterObject
    })

    const [columnAccessors, setColumnAccessors] = useState(table.order?.length ? table.order : Object.keys(table.headers))
    const [rows, setRows] = useState(table.rows.map(row => {
        return {...row, frontend_id: {value: uuidv4()}}
    }))
    const [headers, setHeaders] = useState({...table.headers, frontend_id: {visible: false}})
    const [sortOrder, setSortOrder] = useState('original')
    const [sortedColumnAccessor, setSortedColumnAccessor] = useState(null)
    const [showSearchField, setShowSearchField] = useState(undefined)
    const inputRef = useRef(null)
    const tableContentRef = useRef(null)
    const tableCaptionContainerRef = useRef(null)
    const tableRef = useRef(null)

    let [page, setPage] = useState(1);
    const PER_PAGE = paginationEnable !== 0 ? paginationEnable : rows.length;

    let _DATA = usePagination(rows, PER_PAGE, filter, sortOrder, sortedColumnAccessor);

    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    const [formFields] = useState({
        text_field: TextAreaFieldMU,
        // select_field: SelectField,
        select_field: SelectPositionedField,
        // date_field: DateField,
        date_field: DateNumberField,
        radio_field: RadioField,
        number_field: NumberField,
        checkbox_field: CheckboxField,
        checkbox_field2: CheckboxField2,
        // textarea_field: TextAreaField,
    })

    const postTableSettings = async (newHeaders = headers) => {
        if (!table.is_settings_enabled) {
            renderFeedback('danger', 'Внимание! Настройки для этой таблицы не будут сохранены!')
            return
        }
        const {clickable, buttons, set_header_url, title, is_settings_enabled, reset_settings} = table
        const data = {
            title,
            clickable,
            buttons,
            set_header_url,
            headers: newHeaders,
            order: columnAccessors,
            is_settings_enabled,
            reset_settings,
        }
        try {
            const result = await axios.post(set_header_url, data);
            console.log(result);
            // setResponse(result.data);
        } catch (error) {
            console.log(error.response);
            // setError(error.response.data);
        } finally {
            // setLoading(false);
        }
    }

    const resizeTable = useCallback(() => {
        if (!didMount) return
        // resizeCells()
        const topbar = document.querySelector('.topbar')
        // const windowHeight = window.innerHeight
        const windowHeight = parentElement ? document.querySelector(`.${parentElement}`).getBoundingClientRect().height : window.innerHeight
        const topbarHeight = topbar.getBoundingClientRect().height
        const topBottomMargins = mainCenterTopBottomMargin * 2
        const tableCaptionContainerHeight = tableCaptionContainerRef.current.getBoundingClientRect().height  // table-caption-container
        const newHeight = windowHeight - topbarHeight - topBottomMargins - tableCaptionContainerHeight - 1  // -1 because otherwise appears horizontal scroll bar

        if (tableContentRef.current.firstElementChild.getBoundingClientRect().height > newHeight) {
            tableContentRef.current.style.height = `${newHeight}px`
        } else if (tableContentRef.current.firstElementChild.getBoundingClientRect().height < newHeight) {
            tableContentRef.current.style.height = 'auto'
        }

    }, [mainCenterTopBottomMargin, parentElement, didMount])

    // const resizeCells = () => {
    //     // console.log('RESIZE CELLS');
    //     const tableWidth = tableContentRef.current?.offsetWidth
    //     const tableThs = document.querySelectorAll('.table__th')
    //     const tableTds = document.querySelectorAll('.table__td')
    //     const cellWidth = tableWidth / tableThs.length - 18;  // minus td padding of td and th 8px
    //     // console.log(tableWidth, tableThs.length, cellWidth);
    //     if (cellWidth < 200) {
    //         [...tableThs].forEach(tableTh => {
    //             // tableTh.firstElementChild.style.width = cellWidth > 60 ? cellWidth + "px" : "60px"
    //             tableTh.firstElementChild.style.width = "200px"
    //
    //         });
    //         [...tableTds].forEach(tableTd => {
    //             // tableTd.firstElementChild.style.width = cellWidth > 60 ? cellWidth + "px" : "60px"
    //             tableTd.firstElementChild.style.width = "200px"
    //         })
    //     }
    // }

    useEffect(() => {
        resizeTable() // ALSO RESIZES CELLS
        window.addEventListener('resize', resizeTable)
        return () => window.removeEventListener('resize', resizeTable)
    }, [resizeTable, didMount, filter, headers, columnAccessors])

    // useEffect(() => {  // RESIZE CELLS ON SIDEBAR OPEN/CLOSE
    //     document.querySelector('.sidebar').addEventListener('transitionend', resizeCells)
    //     return () => document.querySelector('.sidebar')?.removeEventListener('transitionend', resizeCells)
    // }, [])
    // useEffect(() => {  // RESIZE CELLS ON COLUMNS ARE HIDDEN/SHOWN
    //     resizeCells()
    // }, [headers])

    const handleInputChange = (e, accessor) => {  // SEARCH HANDLER
        setFilter({...filter, [accessor]: e.target.value})
    }
    const sort = (accessor, dataType) => { // SORT FUNCTION
        // PREVENTS CLICK EVENT WHEN SELECTING --- START
        const selection = window.getSelection();
        // console.log(selection.toString());
        if (selection.toString()) return
        // PREVENTS CLICK EVENT WHEN SELECTING --- FINISH

        let sortableRows = [], unsortableRows = []
        const unacceptableValues = ['-', 'ERROR']
        rows.forEach(row => {
            if (unacceptableValues.includes(row[accessor].value)) {
                unsortableRows.push(row)
            } else {
                sortableRows.push(row)
            }
        })

        if (sortOrder === 'original' || sortedColumnAccessor !== accessor) {
            if (dataType === 'string') {
                sortableRows.sort((row1, row2) => row1[accessor].value.toLowerCase().localeCompare(row2[accessor].value.toLowerCase()))
            } else if (dataType === 'number') {
                sortableRows.sort((row1, row2) => {
                    if (typeof row1[accessor].value === 'string' && typeof row2[accessor].value === 'string') {
                        const num1 = Number(row1[accessor].value.substring(0, row1[accessor].value.length - 1))
                        const num2 = Number(row2[accessor].value.substring(0, row2[accessor].value.length - 1))
                        return num1 - num2
                    } else if (typeof row1[accessor].value === 'string' && typeof row2[accessor].value === 'number') {
                        const num1 = Number(row1[accessor].value.substring(0, row1[accessor].value.length - 1))
                        const num2 = row2[accessor].value
                        return num1 - num2
                    } else if (typeof row2[accessor].value === 'string' && typeof row1[accessor].value === 'number') {
                        const num1 = row1[accessor].value
                        const num2 = Number(row2[accessor].value.substring(0, row2[accessor].value.length - 1))
                        return num1 - num2
                    } else return row1[accessor].value - row2[accessor].value
                })
            } else if (dataType === 'date') {
                sortableRows.sort((row1, row2) => {
                    let [dd1, mm1, yy1] = row1[accessor].value.split('.')
                    let [dd2, mm2, yy2] = row2[accessor].value.split('.')

                    if (yy1 !== yy2) return yy1 - yy2
                    else {
                        if (mm1 !== mm2) return mm1 - mm2
                        else {
                            if (dd1 !== dd2) return dd1 - dd2
                            else return 0
                        }
                    }
                })
            }

            setSortOrder('ascending')
            setSortedColumnAccessor(accessor)
            console.log('Sorted by ascending order');
        } else if (sortOrder === 'ascending' && sortedColumnAccessor === accessor) {

            sortableRows.reverse()
            setSortOrder('descending')
            console.log('Sorted by descending order');
        } else if (sortOrder === 'descending' && sortedColumnAccessor === accessor) {

            setSortOrder('original')
            console.log('Sorted by original order');
            // setRows(table.rows)
            setRows(table.rows.map(row => {
                return {...row, frontend_id: {value: uuidv4()}}
            }))
            return
        }

        setRows([...sortableRows, ...unsortableRows])
    }


    useEffect(() => {  // SETS FOCUS AUTOMATICALLY TO NEEDED INPUT
        (showSearchField !== undefined) && inputRef.current.focus()
    }, [showSearchField])


    useEffect(() => {  // TOGGLES FILTER & SEARCH DIVs
        const handleInputBlur = event => {
            if (event.target !== inputRef.current && !event.target.classList.contains('filter__icon')) {
                // CLICKED OUTSIDE SEARCH(input) FIELD AND FILTER ICON
                // AS CLICKING ON FILTER ICON TOGGLES POPUP ON ITS OWN
                setShowSearchField(undefined)
            }

            if (tableCaptionContainerRef.current && !tableCaptionContainerRef.current.querySelector('.dnd-group')?.contains(event.target) && event.target !== tableCaptionContainerRef.current.querySelector('.svg-container')) {
                setShowColumnsList(false)
            }
        }

        document.addEventListener('mousedown', handleInputBlur)
        return () => document.removeEventListener('mousedown', handleInputBlur)
    }, [])

    // ----------------- DRAG-N-DROP START ---------------------
    const [showColumnsList, setShowColumnsList] = useState(false)
    const [currentDragAccessor, setCurrentDragAccessor] = useState(null)

    const handleDragStart = (event, dragStartAccessor) => {
        console.log('Start Drag: ', dragStartAccessor);
        setCurrentDragAccessor(dragStartAccessor)
        setTimeout(() => { // HELPS IN STYLING
            event.target.classList.add('start')
        }, 0)
    }

    const handleDragOver = (event, dragOverAccessor) => {
        event.preventDefault()
        event.target.classList.add('over')
        // CURRENT_DRAG_ACCESSOR IS EQUAL TO DRAG_OVER_ACCCESSOR BECAUSE:
        // 1) WE JUST GRABBED ONE ITEM AND THE MOUSE IS STILL ON IT
        // 2) WE ALREADY MOVED ONE SPACE UP/DOWN AND THUS THE BELOW IF IS FULLFILLED
        // IT MEANS THAT WE ALREADY SWAPPED TWO ITEMS AND NOW THE DRAG-OVER-ACCESSOR IS THE SAME AS CURRENT-DRAG-ACCESSOR
        // CAUSE CURRENT-DRAG-ACCESSOR DOES NOT CHANGE AS LONG AS WE KEEP GRABBING, AND UNTIL WE RELEASE IT AND PICK ANOTHER ITEM
        // AND DRAG-OVER-ACCESSOR HAS NOW BECOME AS CURRENT-DRAG-ACCESSOR CAUSE WE ARE ALREADY CURRENTLY DRAGGING OVER THE ITEM WE HAVE PICKED

        if (dragOverAccessor !== currentDragAccessor) {
            // CURRENT_DRAG_ACCESSOR IS NOT EQUAL TO DRAG_OVER_ACCCESSOR
            setColumnAccessors(oldValues => {
                let newValues = JSON.parse(JSON.stringify(oldValues))
                const grabbedValueIndex = newValues.indexOf(currentDragAccessor)
                const overValueIndex = newValues.indexOf(dragOverAccessor)
                // k-i OR k-j IS TRUE IS k=i OR k=j ACCORDINGLY
                // let swap= (arr,i,j) => arr.map((e,k) => k-i ? (k-j ? e : arr[i]) : arr[j]);  // not inplace solution I
                // let swap= (arr,i,j) => {let t=arr[i]; arr[i]=arr[j]; arr[j]=t; return arr}  // inplace solution I
                let swap = (arr, i, j) => {
                    [arr[i], arr[j]] = [arr[j], arr[i]];
                    return arr
                }  // inplace solution II
                return swap(newValues, grabbedValueIndex, overValueIndex)
            })
        }
    }
    const handleDragLeave = (event) => {
        // console.log(`Leave Drag: `, event.target);
        event.target.classList.remove('over')
    }
    const handleDragEnd = (event) => {
        // console.log(`End Drag: `, event.target);
        event.target.classList.remove('over')
        event.target.classList.remove('start')
    }

    const handleDrop = (event) => {
        // console.log('Drop Drag: ', index);
        event.preventDefault()

        event.target.classList.remove('over')

        postTableSettings()
    }

    const toggleColumns = accessor => {
        // KEEP TRACK OF ALL HIDDEN COLUMN VALUE'S
        // IF ALREADY HIDDEN THEN SHOW AND VICE VERSA (i.e. TOOGLE)
        const newHeaders = {...headers}
        newHeaders[accessor].visible = !newHeaders[accessor].visible
        setHeaders(newHeaders)

        postTableSettings(newHeaders)
    }

    const resetTableSettings = async () => {
        try {
            console.log('resetting table settings...');
            const response = await axios.post(table.reset_settings)
            // console.log(response.data);
            refreshData()
            renderFeedback('success', response.data.message || 'Success')
        } catch (error) {
            console.log(error.message);
            renderFeedback('danger', error.response?.data?.message || 'Server error')
        }
    }
    // ----------------- DRAG-N-DROP STOP ---------------------


    // ----------------- CELL CHANGE START ---------------------

    const sendRequest = async (url = '', dataToSend = {}) => {
        try {
            // setLoading(true)  // BAD IDEA CAUSE THIS FLASHES THE TZ ON EDIT PAGE AS WELL AS BRINGS THE SCROLL TO INITIAL POSITION
            const response = await axios.post(url, {...dataToSend})
            if (response.data.hasOwnProperty('message')) return renderFeedback('success', response.data.message || 'Success')

            setRows(rows.map(singleRow => singleRow.frontend_id.value === dataToSend.frontend_id.value ? response.data : singleRow))
        } catch (error) {
            console.log(error.message);
            renderFeedback('danger', error.response?.data?.message || 'Server Error')
        }
    }

    const getOptions = (get_url, dataToSend) => {
        // console.log(dataToSend);
        return new Promise(async (resolve, reject) => {
            try {
                // const { get_url } = isOutsideChildrenClicked ? headers[accessor] : headers['children'][accessor]
                // console.log(get_url);
                const response = await axios.post(`${location.pathname}/${get_url}`, dataToSend)
                // console.log(response.data);
                const options = response.data
                resolve(options)
                // resolve([1,3,4,5,6,7])
            } catch (error) {
                console.log(error.message);
                // renderFeedback('danger', error.response.data.message)
                reject(error)
            }
        })
    }
    // console.log(rows);

    const debounceFn = useCallback(debounce(sendRequest, 500), []);

    const handleFieldChange = (event, row, type) => {
        // console.log(event.target.name !== document.activeElement.name);
        if (event.target.name !== document.activeElement.name) return // IN AVR FOOT, WHEN WE CHANGE ONE CELL, UPDATED CHILD IS RECEIVED AND ROWS_STATE IS CHANGED,
        // WHICH IN TURN FORCES THE FORCEFULLY CHANGED CELL(DIFFERENT) CALL THIS FUNCTION AND ANOTHER REQUEST IS SEND AGAIN UNWILLINGLY. TO PREVENT THIS WE CHECK IF THE CHANGED CELL IS FOCUSED OR NOT

        if (type === 'checkbox_field2') {
            console.log('checkbox_field2');
            const newRow = {
                ...row,
                [event.target.name]: {  // [event.target.name] === [accessor]
                    ...row[event.target.name],
                    value: event.target.value
                }
            }

            return setRows(rows.map(singleRow => {
                return singleRow.frontend_id.value === row.frontend_id.value ? newRow : singleRow
            }))
        }

        let dataToSend
        const newRow = {
            ...row,
            [event.target.name]: {  // [event.target.name] === [accessor]
                ...row[event.target.name],
                value: event.target.value
            }
        }
        if (event.target.value_id) newRow[event.target.name].value_id = event.target.value_id // FOR SELECT FIELD 2

        dataToSend = newRow

        // let dataToSend
        // let rowsCopy = JSON.parse(JSON.stringify(rows))
        // dataToSend = rowsCopy.filter(singleRow => singleRow.frontend_id.value === row.frontend_id.value)[0]

        // dataToSend[event.target.name].value = event.target.value
        // if (event.target.value_id) dataToSend[event.target.name].value_id = event.target.value_id // FOR SELECT FIELD 2

        let url = `${location.pathname}/${row[event.target.name].set_url}`
        debounceFn(url, dataToSend)
    }
    // ----------------- CELL CHANGE STOP ---------------------


    useEffect(() => {  // PIN USE EFFECT
        if (!didMount) return

        console.log('applyStylesEffect');
        const array = []
        columnAccessors.forEach(accessor => {  // TO KEEP PINNED COLUMNS IN SYNC WITH DRAG-N-DROP
            if (headers[accessor].pinned && headers[accessor].visible) array.push(accessor)
        })
        // console.log(array);
        let totalWidthOfPinnedColumns = 0

        array.forEach((column_accessor, col_index) => {
            const allHeaderColumnsPinned = tableRef.current.querySelectorAll(`.table__th.${column_accessor}`)
            const allBodyColumnsPinned = tableRef.current.querySelectorAll(`.table__td.${column_accessor}`)
            // console.log(tableRef.current);

            const applyStyles = (type, columnPinned, index, zIndex, bgColor, boxShadow = '') => {
                columnPinned.style.position = 'sticky';
                columnPinned.style.left = totalWidthOfPinnedColumns + 'px';
                columnPinned.style.zIndex = zIndex;
                columnPinned.style.backgroundColor = bgColor !== '#FFFFFF' ? bgColor : '#B4BBC7';
                // boxShadow && console.log(boxShadow);
                if (type === 'th' && column_accessor === sortedColumnAccessor) {
                    if (sortOrder === 'ascending') columnPinned.style.boxShadow = '3px 0px 5px rgb(9, 9, 9, 0.22), inset 0 -5px 0 0 #FFBF49'
                    else if (sortOrder === 'descending') columnPinned.style.boxShadow = '3px 0px 5px rgb(9, 9, 9, 0.22), inset 0 5px 0 0 #FFBF49'
                    else columnPinned.style.boxShadow = '3px 0px 5px rgb(9, 9, 9, 0.22)'
                } else {
                    columnPinned.style.boxShadow = '3px 0px 5px rgb(9, 9, 9, 0.22)'
                }
            };

            [...allHeaderColumnsPinned].forEach((columnPinned) => {
                applyStyles('th', columnPinned, col_index, 101)
                // applyStyles(columnPinned, col_index, 101, '', getComputedStyle(columnPinned).boxShadow)
            });
            [...allBodyColumnsPinned].forEach((columnPinned, rowIndex) => {
                // console.log(rows, rowIndex, column_accessor);
                applyStyles('td', columnPinned, col_index, 100, rows[rowIndex][column_accessor].color)
            })

            totalWidthOfPinnedColumns += headers[column_accessor].width
        })
    }, [headers, rows, columnAccessors, filter, didMount, sortOrder, sortedColumnAccessor])
    const pinColumn = (event, accessor) => {
        console.log('resetStylesEffect');

        event.stopPropagation()
        const newHeaders = {...headers}
        if (newHeaders[accessor].pinned) {  // IF PINNED THEN RESET STYLES
            const allHeaderColumnsPinned = tableRef.current.querySelectorAll(`.table__th.${accessor}`);
            const allBodyColumnsPinned = tableRef.current.querySelectorAll(`.table__td.${accessor}`);

            const resetStyles = (columnPinned, color, zIndex) => {
                columnPinned.style.position = 'sticky';
                columnPinned.style.left = 'unset';
                columnPinned.style.zIndex = zIndex;
                columnPinned.style.backgroundColor = color;
                columnPinned.style.boxShadow = 'unset'
            }
            [...allHeaderColumnsPinned].forEach(columnPinned => {
                resetStyles(columnPinned, '#F3FAFF', 100)
            });
            [...allBodyColumnsPinned].forEach((columnPinned, rowIndex) => {
                console.log(rows[rowIndex][accessor].color)
                resetStyles(columnPinned, rows[rowIndex][accessor].color, 0)
            })
        }

        newHeaders[accessor].pinned = !newHeaders[accessor].pinned
        setHeaders(newHeaders)

        postTableSettings(newHeaders)
    }

    // ----------------- BUTTON CLICK START ---------------------
    const handleButtonClick = async ({
                                         url,
                                         popup_title = '',
                                         feedback = false,
                                         new_page = false,
                                         popup_callback,
                                         onClick,
                                         alert
                                     }) => {
        if (alert) {
            setAlert({
                visible: true,
                title: alert.title,
                body: alert.body,
                onSuccessFunction: onClick
            })

            return
        }

        if (onClick) return onClick(rows, table, columnAccessors, setRows, getFilteredRows(rows, filter))  // IDEA CAME DURING THE PROCESS  // EXISTS IN BRAND.JSX && CLIENT.JSX && AVRS.JSX  &&&& AvrNew.jsx + ClientAvr.jsx
        // rows ARE SEND FOR AvrNew.jsx + ClientAvr.jsx COMPONENTS FOR ADD BTN && table, columnAccessors FOR COPY BTN
        // console.log(url, popup_title);
        // if (new_page) {
        //     return history.push({
        //         pathname: url,
        //     })
        // }
        try {
            const response = await axios.get(url)
            // console.log(response.data);
            if (feedback) {  // IT MEANS THAT WE DO NOT NEED TO SHOW THE POPUP INSTEAD WE SHOW FEEDBACK
                renderFeedback('success', response.data.message || 'Success')
                history.goBack()
                // setTimeout(() => {
                // }, 2000)
                return
            }

            setPopup({
                visible: true,
                data: response.data,
                url,
                title: popup_title[0].toUpperCase() + popup_title.substring(1),
                callback: popup_callback
            })
        } catch (error) {
            console.log(error.message);
            setPopup({
                visible: false,
                data: {},
                url: null,
                title: ''
            })

            renderFeedback('danger', error.response.data.message || 'Error!')

            if (feedback) {  // RECHECK LATER, MAYBE THIS IS REDUNDANT
                renderFeedback('danger', error.message || 'Error!')
            }
        }
    }

    const handleRateIconClick = () => {
    }

    const handleIconButtonClick = async (icon_accessor, row, isEditable) => {
        if (!Boolean(isEditable)) return // NOT EDITABLE
        //    console.log(icon_accessor);
        if (icon_accessor === 'tz_comment') {
            console.log(icon_accessor);

            try {
                // setLoading(true)
                const url = `${location.pathname}/tz_comment/${row.request_id.value}/`
                const response = await axios.get(url)
                let {data, date_from, date_to} = response.data
                setPopupLogs({
                    type: 'tz_comment', // logs or comments,
                    visible: true,
                    data,
                    title: 'История комментариев',
                    url,
                    date_from,
                    date_to
                })
            } catch (error) {
                renderFeedback('danger', error.response?.data?.message || 'Server Error')
            } finally {
            }
        }else if (icon_accessor === 'current_table_add'){
            axios.post('https://team-backend-new.tds.media/properties/brandtask/current/new', {...row}).then(
                res => {
                    const temp_rows = [...rows];
                    const insert_index = rows.indexOf(row);
                    temp_rows.splice(insert_index+1, 0, {...res.data, frontend_id: {value: uuidv4()}});
                    setRows(temp_rows);
                }
            ).catch(
                err => {console.log(err)}
            )
        }else if (icon_accessor === 'current_table_remove'){
            axios.post('https://team-backend-new.tds.media/properties/brandtask/current/delete', {...row}).then(
                res => {
                    const temp_rows = [...rows];
                    const insert_index = rows.indexOf(row);
                    temp_rows.splice(insert_index, 1);
                    setRows(temp_rows)
                }
            ).catch(
                err => {renderFeedback('danger', 'Все задачи по бренду уже удалены')}
            )
        }else if (icon_accessor === 'potential_table_add'){
            axios.post('https://team-backend-new.tds.media/properties/brandtask/potential/new', {...row}).then(
                res => {
                    const temp_rows = [...rows];
                    const insert_index = rows.indexOf(row);
                    temp_rows.splice(insert_index+1, 0, {...res.data, frontend_id: {value: uuidv4()}});
                    setRows(temp_rows)
                }
            ).catch(
                err => {console.log(err)}
            )
        }else if (icon_accessor === 'potential_table_remove'){
            axios.post('https://team-backend-new.tds.media/properties/brandtask/potential/delete', {...row}).then(
                res => {
                    const temp_rows = [...rows];
                    const insert_index = rows.indexOf(row);
                    temp_rows.splice(insert_index, 1);
                    setRows(temp_rows)
                }
            ).catch(
                err => {renderFeedback('danger', 'Все задачи по бренду уже удалены')}
            )
        }
    }

    const getHREF = row => {
        if (href) {
            var linkHref = ''
            const {from = '', to = '', params = [], request_id = true} = href
            if (!!from) linkHref += from
            if (request_id) linkHref += `/${row['request_id'].value}`
            if (!!to) linkHref += `/${to}`
            if (params.length) {
                linkHref += '?'
                params.forEach((param, index) => {
                    linkHref += index === 0 ? `${param}=${row[param].value}` : `&${param}=${row[param].value}`
                })
            }

            return linkHref
        }

        if (!!location.search) return `${location.pathname}${location.search}`
        return location.pathname
    }

    const handleTableRowClick = (row) => {
        const selection = window.getSelection();
        // console.log(selection.toString());
        if (selection.toString()) return
        handleRowClick(row)
    }

    // const getNumberOfVisibleColumns = () => {
    //     return Object.keys(headers).reduce((acc, curr) => headers[curr].visible ? acc + 1 : acc, 0)
    // }


    const getCellSpan = (value, type, sign, bold) => (
        <span
            style={{fontWeight: bold && '600'}}
            dangerouslySetInnerHTML={{__html: formatValue(value, type, sign, table.is_round)}}>
        </span>)

    // ----------------- RENDER TABLE BODY START ---------------------
    const getTableBody = () => {

        return _DATA.currentData().map((row, idx) => {
            return (
                <tr key={idx} className={`table__row ${table.clickable ? 'clickable' : 'unclickable'}`}>
                    {/* <a key={idx} style={{display: "table-row"}}  href={getHREF(row)} className={`table__row ${table.clickable ? 'clickable' : 'unclickable'}`} onClick={(event) => table.clickable ? handleTableRowClick(event, row) : event.preventDefault()}>  */}
                    {/* WHEN FILLS ROW CLICKED, WE NEED TO SEND THE WHOLE ROW, THUS SEND "row" AS A SECOND PARAMETER */}
                    {columnAccessors.map(accessor => {
                        const {
                            value,
                            type,
                            editable,
                            color,
                            options,
                            sign,
                            label,
                            bold,
                            hint,
                            get_url,
                            // set_url
                        } = row[accessor]
                        if (!headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                        if (value === null && type !== 'date_field') return <td key={accessor} className={`table__td ${accessor}`}
                                                       style={{backgroundColor: color || '#fff'}}>
                            <div style={{padding: '0 8px'}}>----</div>
                        </td>
                        // console.log(color);

                        if (type === 'icon') { // IF THE CELL IS ICON TYPE, THEN RETURN ICON
                            // const isCellClickable = headers[accessor].label === 'icon'
                            const Icon = tableIcons[value]
                            return (  // THIS IS WHERE add BUTTON IS
                                <td key={accessor} className='table__td'
                                    style={{cursor: editable ? 'pointer' : 'default', maxWidth:50, width:50}}
                                    onClick={() => handleIconButtonClick(accessor, row, editable)}>
                                    <div className={`table__td__center ${accessor}`} style={{width:"100%", maxWidth:"50px", display:"flex", justifyContent:"center"}}>
                                        <span className="svg-container"><Icon style={{width:20}}/></span>
                                    </div>
                                </td>
                            )
                        }

                        if (editable || type === 'checkbox_field2') {
                            const FormField = formFields[type]
                            return (
                                <td key={accessor} className={`table__td ${accessor}`}
                                    style={{backgroundColor: color || '#fff'}}>
                                    {/* <a key={idx} style={{display: "table-row"}}  href={getHREF(row)} className={`table__row ${table.clickable ? 'clickable' : 'unclickable'}`} onClick={(event) => table.clickable ? handleTableRowClick(event, row) : event.preventDefault()}>  */}
                                    <div className={`table__td__center ${accessor}`} style={{width:"100%"}}>
                                        <FormField
                                            name={accessor}
                                            value={value}
                                            label={label}
                                            onChange={(event) => handleFieldChange(event, row, type)}
                                            editable={editable}
                                            options={options}
                                            getOptions={() => getOptions(get_url, row)}
                                            rowClickable={table.clickable}
                                            sign={sign}
                                        />
                                        {accessor === 'report_rate' &&
                                            <button style={{marginLeft: '0.5rem', cursor: !editable && 'default'}}
                                                    onClick={() => handleRateIconClick(row, idx, editable)}><Coin1/>
                                            </button>}
                                    </div>
                                    {/* </a> */}
                                </td>
                            )
                        }

                        if (table.clickable) {
                            return (
                                <td key={accessor} className={`table__td ${accessor}`}
                                    style={{backgroundColor: color !== '#FFFFFF' ? color : ''}}
                                    onClick={() => table.clickable ? handleTableRowClick(row) : {}}>
                                    <a
                                        href={getHREF(row)}
                                        key={idx}
                                        className={`table__td__center ${accessor} ${table.clickable ? 'clickable' : 'unclickable'} pointer-events-none`}
                                        onClick={(event) => event.preventDefault()}
                                    >
                                        {hint ? <HintHeader
                                            hint={hint}>{getCellSpan(value, type, sign, bold)}</HintHeader> : getCellSpan(value, type, sign, bold)}
                                    </a>
                                </td>
                            )
                        }

                        return (
                            <td key={accessor} className={`table__td ${accessor}`}
                                style={{backgroundColor: color !== '#FFFFFF' ? color : ''}}
                                onClick={() => table.clickable ? handleTableRowClick(row) : {}}>
                                <div
                                    key={idx}
                                    className={`table__td__center ${accessor} ${table.clickable ? 'clickable' : 'unclickable'} pointer-events-none`}
                                    onClick={(event) => event.preventDefault()}
                                >
                                    {hint ? <HintHeader
                                        hint={hint}>{getCellSpan(value, type, sign, bold)}</HintHeader> : getCellSpan(value, type, sign, bold)}
                                </div>
                            </td>
                        )
                    })}
                </tr>
                // </a>
            )
        })
    }
    // ----------------- RENDER TABLE BODY STOP ---------------------

    if (!didMount) {
        return null;
    }

    // ----------------- RENDER TABLE HEADER START ---------------------
    return (
        <div className="table-block">
            <div className="table-caption-container" ref={tableCaptionContainerRef}>
                <button className="svg-container" onClick={() => setShowColumnsList(!showColumnsList)}><Filter
                    className="yellow"/></button>
                {showColumnsList &&
                    // <ul className='dnd-group' onDragOver={e => e.preventDefault()}>
                    <ul className='dnd-group'>
                        {!!table.reset_settings &&
                            <li className='dnd-item reset-settings-btn' onClick={resetTableSettings}>Сбросить
                                настройки</li>}
                        {!table.is_settings_enabled &&
                            <li className='dnd-item reset-settings-btn settings-disabled'>Настройки не сохранятся</li>}
                        {columnAccessors.map(accessor => {
                            const header = headers[accessor]
                            return (
                                <li
                                    key={accessor}
                                    className='dnd-item'
                                    style={{opacity: !headers[accessor].visible && '.5'}}  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                                    onClick={() => toggleColumns(accessor)}
                                    draggable
                                    onDragStart={event => handleDragStart(event, accessor)}
                                    onDragLeave={event => handleDragLeave(event)}
                                    onDragEnd={event => handleDragEnd(event)}
                                    onDragOver={event => handleDragOver(event, accessor)}
                                    onDrop={event => handleDrop(event)}
                                >
                                    {header.label}
                                </li>)
                        })}
                    </ul>}
                <span className="table-caption">{table.title}</span>
                {!!Object.keys(date_fields).length && <>
                    <TableDateFields date_fields={date_fields} onDateFieldsChange={onDateFieldsChange}/>
                    <SendButton onClick={handleDateSubmitButtonClick}/>
                    {/* <button className="button button-2" style={{padding: 7}} onClick={handleDateSubmitButtonClick}>
                <span>Отправить</span>
            </button> */}
                </>}
                {buttons && <div className="btns-container">
                    {buttons.map((button, index) => {
                        return <button key={index} className="button button-2"
                                       onClick={() => handleButtonClick(button)}>
                            <span className="svg-container">{buttonIcons[button.icon]}</span>
                            <span>{button.text}</span>
                        </button>
                    })}
                </div>}
            </div>
            {/* <div class="div1">
<div class="in" onMouseDown={ind}>

</div>
</div> */}
            <div className="table-container" ref={tableContentRef}>
                {/* <div className="table-container" style={{height: `${tableContainerHeight}px`}} ref={tableContentRef}> */}
                <table className="table" id={id} ref={tableRef}>
                    <thead className="table__thead">
                    <tr>
                        {columnAccessors.map(accessor => {
                            // const header = headers[accessor]
                            if (!headers[accessor].visible) return null  // MEANS THIS COLUMN IS HIDDEN IN DND BY USER
                            const {label, width, pinned, type, hint} = headers[accessor]
                            return (
                                // <th key={idx} className={idx === sortedColumnIndex ? `table__th ${sortOrder}` : "table__th"} onClick={() => sort(idx, header.type)}>
                                <th
                                    key={accessor}
                                    className={accessor === sortedColumnAccessor ? `table__th ${accessor} ${sortOrder}` : `table__th ${accessor}`}
                                    onClick={() => sort(accessor, type)}>
                                    <div
                                        style={{width: width || '199px'}}
                                        className={`table__th__center ${accessor}`}
                                    >
                                        <div val="" className="filter">
                                        <span
                                            className={filter[accessor] ? "filter__icon active" : "filter__icon"}
                                            tabIndex="1"
                                            onClick={e => {
                                                e.stopPropagation();
                                                setShowSearchField(showSearchField === accessor ? undefined : accessor);
                                            }}
                                        >
                                            <Search/>
                                        </span>
                                            {showSearchField === accessor &&
                                                <div className="filter__popup">
                                                    <input
                                                        type="text"
                                                        ref={inputRef}
                                                        placeholder="Фильтр"
                                                        onChange={e => handleInputChange(e, accessor)}
                                                        onClick={e => e.stopPropagation()}
                                                        // onBlur={() => setShowSearchField(undefined)}
                                                        value={filter[accessor]}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        {hint
                                            ? <HintHeader hint={hint}>
                                                <span className="table__th__value">{label}</span>
                                            </HintHeader>
                                            : <span className="table__th__value">{label}</span>
                                        }
                                        <button className="svg-container table__th__pin"
                                                onClick={event => pinColumn(event, accessor)}>{pinned ? <PinFill/> :
                                            <Pin/>}</button>
                                        {/* <button className="svg-container table__th__unfold" onClick={(event) => lengthenColumn(event, idx)}>{unfolded[idx] ? <BsArrowLeft /> : <BsArrowRight />}</button> */}
                                        {/* <div className={`col-handle ${accessor}`} onClick={e => e.stopPropagation()} onMouseDown={e => resizeTableColumn(e, accessor)}></div> */}
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {getTableBody()}
                    </tbody>
                </table>
            </div>
            {paginationEnable !== 0 && <div style={{marginTop: "1rem"}}>
                <Pagination
                    count={_DATA.maxPage}
                    size="large"
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChange}
                />
            </div>}
        </div>
    )
}
