import React, {useCallback, useEffect, useRef, useState} from 'react';
import moment from 'moment'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './date.css'
import Text from '../text/Text';
import {ReactComponent as CalendarIcon} from '../../../assets/sidebar-icons/carbon_calendar.svg'
import {toggleFormFieldBottom} from '../../../utils/js/toggleFormFieldBottom';


export default function DateRangeField({label, name, value = [], onChange, parent, editable, required}) {
    const dateObject = value.map(val => moment(val, "DD/MM/YYYY").toDate())
    console.log(value, dateObject);

    const [dateValue, setValue] = useState(value.length ? dateObject : ''); // sting.replace(/./g, '/') DOSE THE JOB TOO
    const [showCalendar, setShowCalendar] = useState(false)
    const calendarRef = useRef()
    const dateRef = useRef()
    const formFieldTopRef = useRef(null)


    const handleClickOutside = useCallback(event => {
        // CHECK WETHER REACT-CALENDAR CLICKED OR NOT
        if (dateRef.current.contains(event.target)) {
            // console.log('Clicked inside react-calendar');
            return
        }
        // console.log('Clicked outside react-calendar');
        const label = dateRef.current.querySelector('label')
        if (!dateValue) { // CALENDAR IS CLOSED WITHOUT CHOSING ANY DATE, THUS RETURN THE LABEL BACK TO ITS INITIAL PLACE
            label.classList.remove('focused', 'filled')
        } else { // CALENDAR IS CLOSED AND DATE IS CHOSED, THUS APPLY NECESSARY STYLES
            label.classList.add('focused', 'filled')
        }

        setShowCalendar(false) // CLICKED OUTSIDE REACT-CALENDAR, THUS CLOSE IT
    }, [dateValue])
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [handleClickOutside])


    const transformCalendar = useCallback(() => {
        console.log(formFieldTopRef.current.getBoundingClientRect());
        const {bottom, left} = formFieldTopRef.current.getBoundingClientRect()

        calendarRef.current.style.display = 'block'
        calendarRef.current.style.position = 'fixed'
        calendarRef.current.style.top = bottom + 'px'
        calendarRef.current.style.left = left + 'px'

        const calendarRight = left + 350
        const calendarBottom = bottom + 333
        let windowBottom, windowRight
        windowBottom = window.innerHeight
        windowRight = window.innerWidth
        if (windowRight < calendarRight) {
            calendarRef.current.style.left = `${left - (calendarRight - windowRight) - 20}px`
        }

        if (windowBottom < calendarBottom) {
            calendarRef.current.style.top = `${bottom - (calendarBottom - windowBottom) - 20}px`
        }


    }, [])
    useEffect(() => {
        showCalendar ? transformCalendar() : calendarRef.current.style.display = 'none'
    }, [showCalendar, transformCalendar])

    // const toggleFormFieldBottom = event => {
    //     const label = dateRef.current.querySelector('label')
    //     const input = dateRef.current.querySelector('input')

    //     if (!showCalendar) { // CALENDAR IS ABOUT TO BE OPENED
    //         label.classList.remove('focused', 'filled') // THUS APPLY FOSUED TO LABEL
    //         label.classList.add('focused')
    //         input.focus()
    //     } else { // CALENDAR IS ABOUT TO BE CLOSED AND
    //         input.blur()
    //         if (dateValue) { // NO DATE WAS CHOSEN, THUS RETURN THE LABEL BACK TO ITS INITIAL PLACE
    //             label.classList.add('focused', 'filled')
    //         } else { // DATE IS CHOSED, THUS APPLY NECESSARY STYLES
    //             label.classList.remove('focused', 'filled')
    //         }
    //     }
    //     setShowCalendar(!showCalendar)  // OPEN/CLOSE CALENDAR
    // }
    const toggleCalendar = (e) => {
        if (!editable) return
        formFieldTopRef.current = e.target.parentElement

        toggleFormFieldBottom(dateRef, showCalendar, dateValue, setShowCalendar)
        // console.log(e.target);
    }

    const handleCalendarChange = (dateValue) => {
        console.log(dateValue);
        const label = dateRef.current.querySelector('label')
        label.classList.add('filled') // DATE IS CHOSED, THUS APPLY NECESSARY STYLES (p.s.: 'focused' is already applied)

        setValue(dateValue)
        setShowCalendar(false)

        const event = {
            target: {
                name,
                value: dateValue.map(val => moment(val).format('DD.MM.YYYY'))
            }
        }

        document.activeElement.name = name
        onChange && onChange(event)
    }

    const handleClickMonth = dateValue => {
        console.log(dateValue);
    }

    // 'en-GB': British English uses day-month-year order → "20/12/2012"
    // 'en-US:  US English uses month-day-year order → "12/19/2012"
    return (
        <div className={`date form-field ${editable ? 'editable' : 'uneditable'}`} ref={dateRef}>
            <div onClick={toggleCalendar} className="form-field__top">
                {/* <Text type="text" label={label} name={name} value={dateValue && new Date(dateValue).toLocaleDateString('en-GB')} parentForm="date" editable={editable} required={required}/> */}
                <Text type="text" label={label} name={name} value={value.length ? `${value[0]} - ${value[1]}` : ''}
                      parentForm="date" editable={editable} required={required}/>
                <button className={`svg-container form-field__open-button ${editable ? '' : 'disabled'}`} type="button">
                    <CalendarIcon/></button>
            </div>
            {/* {showCalendar &&  */}
            <Calendar
                onChange={handleCalendarChange}
                value={dateValue}
                locale="ru-Ru"
                inputRef={calendarRef}
                className="date__react-calendar form-field__bottom"
                onClickMonth={handleClickMonth}
                selectRange
            />
            {/* } */}
        </div>
    );
}


// import 'date-fns';
// import React from 'react';
// import Grid from '@material-ui/core/Grid';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';

// export default function Picker() {
//   // The first commit of Material-UI
//   const [selectedDate, setSelectedDate] = React.useState(new Date());

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   return (
//     <MuiPickersUtilsProvider utils={DateFnsUtils}>
//       <Grid container justifyContent="space-around">
//         <KeyboardDatePicker
//           disableToolbar
//           variant="inline"
//           format="MM/dd/yyyy"
//           margin="normal"
//           id="date-picker-inline"
//           label="Date picker inline"
//           value={selectedDate}
//           onChange={handleDateChange}
//           KeyboardButtonProps={{
//             'aria-label': 'change date',
//           }}
//         />
//         {/* <KeyboardDatePicker
//           margin="normal"
//           id="date-picker-dialog"
//           label="Date picker dialog"
//           format="MM/dd/yyyy"
//           value={selectedDate}
//           onChange={handleDateChange}
//           KeyboardButtonProps={{
//             'aria-label': 'change date',
//           }}
//         /> */}
//         {/* <KeyboardTimePicker
//           margin="normal"
//           id="time-picker"
//           label="Time picker"
//           value={selectedDate}
//           onChange={handleDateChange}
//           KeyboardButtonProps={{
//             'aria-label': 'change time',
//           }}
//         /> */}
//       </Grid>
//     </MuiPickersUtilsProvider>
//   );
// }

// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: 200,
//   },
// }));

// export default function DatePickers() {
//   const classes = useStyles();

//   return (
//     <form className={classes.container} noValidate>
//       <TextField
//         id="date"
//         label="Birthday"
//         type="date"
//         defaultValue="2017-05-24"
//         className={classes.textField}
//         InputLabelProps={{
//           shrink: true,
//         }}
//       />
//     </form>
//   );
// }
