import React, {useCallback, useEffect, useState} from 'react'
import './providers.css'
import {useGlobalContext} from '../../store/context/context'
import {useHistory, useLocation} from 'react-router'
import axios from 'axios'
import {LoaderComponent} from '../../components/layout/loader/Loader'
import {TableV} from '../../components/layout/table/TableV'

const Providers = () => {
    const {
        setTopbarData,
        setBreadcrumbs,
        setActiveSidebarLink,
        setLLPs,
        setShowFeedback,
        setServerError
    } = useGlobalContext()

    useEffect(() => {
        setShowFeedback(false)
    }, [setShowFeedback])

    const history = useHistory()
    const location = useLocation()

    const [table, setTable] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const getTable = useCallback(async () => {
        try {
            setLoading(true)
            const response_table = await axios.get('finance/providers/')
            setTable(response_table.data)
        } catch (error) {
            if (error.response.status === 500) {
                // return setErrorTable({message: error.response.statusText })
                return setServerError(true)
            }
            setError(error.response?.data || {message: `Error. Code ${error.response.status}. Text: ${error.response.status}`})
            // setError(error.response?.data|| 'Server error')
        } finally {
            setLoading(false)
        }
    }, [setServerError])
    useEffect(() => {
        getTable()
    }, [getTable])

    useEffect(() => {
        setActiveSidebarLink(location.pathname.substring(1))
    }, [setActiveSidebarLink, location.pathname])

    useEffect(() => {
        setTopbarData({
            links: [],
            sublinks: null
        })
    }, [setTopbarData])

    useEffect(() => {
        setBreadcrumbs([
            {path: '/finance/providers', breadcrumb: table?.title || ''},
            // { path: '/users/:id', breadcrumb: 'id-breadcrumb' },
        ])
    }, [setBreadcrumbs, table?.title])

    useEffect(() => {
        setLLPs([])
    }, [setLLPs])

    const buttons = [
        {
            text: 'Добавить поставщика',
            icon: 'plus',
            url: 'finance/providers/new/',
            popup_title: 'Новый поставщик',
            popup_callback() {
                getTable()
            }
        },
    ]

    // ----------------- ROW CLICK START ---------------------
    const handleRowClick = async row => {
        const request_id = row['request_id'].value
        history.push({
            // pathname: `/finance/providers/${client_id}/contracts?LLP=${encodeURIComponent(response.data[0])}`,
            pathname: `/finance/providers/${request_id}/contracts`,
            // search: `?LLP=${encodeURIComponent(response.data[0])}`,
            // state: { llps: response.data }
        })
    }
    // ----------------- ROW CLICK START ---------------------

    if (loading) return <LoaderComponent/>
    if (error) return <div>{error.message}</div>

    return (<>
            <div className="providers page">
            </div>

            <TableV
                table={table}
                buttons={buttons.reverse()}
                handleRowClick={handleRowClick}
                refreshData={getTable}
                href={{
                    from: '/finance/providers',
                    to: 'contracts'
                }}
            />
        </>
    )
}

export default Providers
